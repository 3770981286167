import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {replace} from 'lodash'
import { connect } from 'react-redux'
import { Container, Grid, Typography, Button as MaterialButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Input, Button, Form, message } from 'antd'
import LoadingIcon from 'mdi-react/LoadingIcon'
import MaterialTableComponent from '../../../components/Table/MaterialTable'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { bindActionCreators } from 'redux'
import { useEffect } from 'react'
import {
  createBookingSession,
  onChangeBackupDateInSelectRoomBooking,
  destroyBookingSession,
} from '../../../actions/booking'
import {get} from 'lodash'
import {withTranslation} from 'react-i18next'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 0'
  },
  title: {
    padding: '30px 0 10px',
    marginBottom: '20px',
    color: theme.colorAccent,
    width: 'max-content',
    borderBottom: `2px solid ${theme.colorAccent}`
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchField: {
    width: '40%',
    margin: '0 10px'
  },
  searchButton: {
    display: 'flex',
    alignItems: 'center',
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  emptyText: {
    position: 'relative'
  }
}))

const RoomFound = props => {
  const classes = useStyles()
  const { history, listAvailableRooms, bookingRequest, createBookingSession, isCreatingBookingSession,
    onChangeBackupDateInSelectRoomBooking, backupDataInSelectRoomBooking, t, i18n
  } = props
  const [loading, setLoading] = useState(false)
  moment.locale(i18n.language === 'en' ? 'en-gb' : 'de')
  
  useEffect(() => {
    if(!listAvailableRooms.length) {
      history.goBack()
    }
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  // const [searchValue, setSearchValue] = useState('')
  // const [searched, setSearched] = useState(false)
  // const handleSearch = (e) => {
  //   e.preventDefault()
  //   setSearched(true)
  //   searchListCustomer(searchValue)
  // }

  const selectedRoom = get(backupDataInSelectRoomBooking, 'selectedRoom', {})
  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
    { key: 'name', numeric: true, disablePadding: false, label: t('BOOKING.RoomName') },
    { key: 'max_attendances', numeric: true, disablePadding: false, label: t('BOOKING.MaxParticipant') },
    { key: 'size', numeric: true, disablePadding: false, label: t('BOOKING.RoomSize') },
    { key: 'permanent_facilities', numeric: true, disablePadding: false, label: t('BOOKING.Permanent'),
      render: (data) => {
        return (
          <span>
            {data.map((item, index) => {
              if(index > 0) {
                return `, ${item.name}`
              }
              return item.name
            })}
          </span>
        )
      }
    },
  ]

  const handleNext = () => {
    if(selectedRoom.id) {
      createBookingSession({
        ...bookingRequest,
        room_id: selectedRoom.id,
        // additional_requirement: 'test'
      })
    } else {
      message.info(t('BOOKING.PleaseSelectTheRoomFirst'))
    }
  }


  return (
    <Container>
      <Grid className={classes.root}>
        {loading ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
        {/* <Grid item sm={12} xs={12} md={12} >
          <form onSubmit={handleSearch}
            style={{ width: '100%' }}
            className={classes.searchContent}
          >
            <Input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className={classes.searchField}
              placeholder="Type something to search (email, company, name, ...)"
            />
            <Button
              type="primary"
              htmlType='submit'
              className={classes.searchButton}
              loading={isSearchingListUser}
            ><MagnifyIcon />
              &nbsp;Search
            </Button>
          </form>
        </Grid> */}
        <Grid item sm={12} md={12}>
          <MaterialTableComponent
            singleSelect
            selectedItem={get(selectedRoom, 'id', null) ? [get(selectedRoom, 'id', '')] : []}
            columns={headCells}
            rows={listAvailableRooms}
            title={`${listAvailableRooms.length} ${t('BOOKING.RoomsFound')}`}
            emptyBool={!listAvailableRooms.length}
            emptyContent={() => {
              return (
                <Typography
                  className={classes.emptyText}
                  align="center"
                  variant="subtitle1"
                >
                  {t('BOOKING.CannotFoundAvailableRoom')}
                </Typography>
              )
            }}
            onSelect = {(data) => onChangeBackupDateInSelectRoomBooking({selectedRoom: data})}
            pagination={false}
          />

        </Grid>
        <Grid item xs={12}>
          <div className={classes.footerContent}>
            <MaterialButton
              onClick={() => props.destroyBookingSession()}
              className={classes.backButton}
            >
              {t('BOOKING.Back')}
            </MaterialButton>
            {/* <Link to={paths.CreateNewBooking_AddBookingDetail}> */}
            <Button type="primary"
              onClick={() => handleNext()}
              loading={isCreatingBookingSession}
            >
              {t('BOOKING.Next')}
            </Button>
            {/* </Link> */}
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

const mapState = ({ bookingReducer }) => ({
  listAvailableRooms: bookingReducer.listAvailableRooms,
  bookingRequest: bookingReducer.bookingRequest,
  isCreatingBookingSession: bookingReducer.isCreatingBookingSession,
  backupDataInSelectRoomBooking: bookingReducer.backupDataInSelectRoomBooking,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    createBookingSession,
    onChangeBackupDateInSelectRoomBooking,
    destroyBookingSession,
  },
  dispatch
)

RoomFound.propTypes = {
  history: PropTypes.object,
  listAvailableRooms: PropTypes.array,
  createBookingSession: PropTypes.func,
  bookingRequest: PropTypes.object,
  isCreatingBookingSession: PropTypes.bool,
}

export default withRouter(connect(mapState, mapDispatch)( withTranslation('common') (RoomFound)))
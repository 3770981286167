import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { Grid, GridList, GridListTile, Divider } from '@material-ui/core'
import Image4 from '../../../../assets/images/teaBreak.jpg'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    marginTop: '20px !important',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridItem: {
    // height: '85px !important',
    // width: '25% !important',
  },
  mainImage: {
    height: '250px !important',
  },
  facilityInfo: {
    paddingLeft: '30px'
  },
  idContent: {
    paddingBottom: '10px'
  },
  nameContent: {
    color: theme.colorAccent,
    paddingBottom: '20px'
  },
  detailLabel: {
    fontSize: '1rem'
  },
  descriptionContent: {
    paddingBottom: '20px',
    '& p': {
      marginBottom: 'unset'
    }
  },
  priceContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  priceLable: {
    fontSize: '1rem'
  },
  listPrice: {
    textAlign: 'right'
  }

}))

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },

})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function ServiceDetailModal(props) {
  const { isOpen, closeModal } = props
  const classes = useStyles()
  const tileData = [
    {
      img: Image4,
      title: 'Image',
      author: 'author',
    },
    {
      img: Image4,
      title: 'Image',
      author: 'author',
    },
    {
      img: Image4,
      title: 'Image',
      author: 'author',
    },
    {
      img: Image4,
      title: 'Image',
      author: 'author',
    },
    {
      img: Image4,
      title: 'Image',
      author: 'author',
    },
  ]

  return (
    <div>
      <Dialog onClose={closeModal} maxWidth="md" aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={closeModal}>

        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={5}>
              <GridList cols={1} cellHeight={300}>
                <GridListTile className={classes.mainImage}>
                  <img src={Image4} alt={''} />
                </GridListTile>
              </GridList>
              <GridList className={classes.gridList} cols={3.5} cellHeight={100}>
                {tileData.map(tile => (
                  <GridListTile key={tile.img} className={classes.gridItem}>
                    <img src={tile.img} alt={tile.title} />
                    {/* <GridListTileBar
                      title={tile.title}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}

                    /> */}
                  </GridListTile>
                ))}
              </GridList>
            </Grid>
            <Grid item md={7} className={classes.facilityInfo}>
              <Typography variant="h6" className={classes.idContent}>ID #1234</Typography>
              <Typography variant="h6" className={classes.nameContent}>Tea break service</Typography>
              <Typography>
                <span className={classes.detailLabel}>Detail</span>
              </Typography>
              <Typography  className={classes.descriptionContent}>
                Tea break service includes food and beverage such as tea, cakes, coffee, etc
                <p>- No. of service: 10 people</p>
                <p>- Service time: every Friday afternoon</p>
              </Typography>
              <Typography className={classes.priceContent}>
                <span className={classes.priceLable}>Price per person</span>
                <div className={classes.listPrice}>
                  <p>$10.00</p>
                </div>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button autoFocus onClick={closeModal} color="default" disableElevation>
            Delete
          </Button>
          <Button autoFocus onClick={closeModal} color="primary" variant="contained" disableElevation>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ServiceDetailModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func
}
import { bind } from 'decko'
import BaseApi from './baseApi';
import {Login, GetProfile, GetSession, RefreshToken} from '../../constants/endpoints.constants'
export default class Public extends BaseApi {
  @bind
  async login(data) {
    return await this.http.post(Login(), data);
  }

  @bind
  async getProfile() {
    console.log("call get Profile")
    await this.checkAuthenticatedStatus()
    return await this.http.get(GetProfile());
  }

  @bind
  async getSession(data) {
    return await this.http.get(GetSession(), data);
  }

  @bind
  async refreshToken(data) {
    return await this.http.post(RefreshToken(), data);
  }
}
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Prototype from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button as MaterialButton,
  Paper,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { CURRENCY_MAPPING, PAYMENT_METHOD } from "../../../helpers/constant";
import { formatMoney } from "../../../helpers/bookings";
import { history } from "../../../config/store";
import * as paths from "../../../constants/paths.constants";
import * as selectors from "../../../selectors/booking";
import LoadingIcon from "mdi-react/LoadingIcon";
import {
  getCreatedBookingSummary,
  updateBookingState,
  resetBookingSession,
  confirmBooking,
} from "../../../actions/booking";
import { get } from "lodash";
import { Form, Button, Modal } from "antd";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: "20px",
  },
  textAreaAutoSize: {
    width: "100%",
    borderRadius: "5px",
  },
  bookingInfo: {
    padding: "5px 0",
    paddingLeft: "0",
  },
  roomImage: {
    width: "100px",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f7f7f7",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    borderBottom: "1px solid #ddd",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  footerContent: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  bookingInfoDetail: {
    margin: "5px 0",
  },
  table: {
    // minWidth: 700,
    "& .MuiTableCell-head": {
      fontWeight: "bold",
    },
  },
  tableContainer: {
    // width: 'max-content'
  },
  title: {
    paddingBottom: "20px",
    borderBottom: "1px solid #ddd",
  },
  bookingId: {
    fontWeight: "bold",
  },
}));

const SendEmailNotifyModal = (props) => {
  const {
    t,

    visible,
  } = props;

  return (
    <Modal
      visible={visible}
      title={t("Notify customer by email?")}
      okText={t("Yes")}
      cancelText={t("No")}
      {...props}
    >
      <p>
        {t(
          "Do you want to send confirmation notification to customer by email?"
        )}
      </p>
    </Modal>
  );
};

const BookingConfirm = (props) => {
  const classes = useStyles();
  const {
    getCreatedBookingSummary,
    booking,
    currencyUnit,
    history,
    invoiceRows,
    grandTotalInvoice,
    updateBookingState,
    isUpdatingBookingState,
    bookingSession,
    resetBookingSession,
    confirmBooking,
    isConfirming,
    t,
  } = props;

  const [notifyModalVisible, showNotifyModal] = useState(false);

  useEffect(() => {
    if (booking.session == undefined) {
      getCreatedBookingSummary();
    }
    if (!bookingSession.state) {
      history.push(paths.ViewAllBooking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, bookingSession]);

  const cc = get(booking, "currency");

  const handleConfirm = () => {
    showNotifyModal(true);
    // history.push(paths.CreateNewBooking_Completed)
  };

  // const {handleBack, confirm} = props
  return (
    <Container>
      <Form>
        {isUpdatingBookingState || isConfirming ? (
          <div className="loading-refresh">
            <LoadingIcon />
          </div>
        ) : (
          ""
        )}
        <Grid container className={classes.mainGrid}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.bookingInfo}>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" className={classes.bookingId}>
                      {t("BOOKING.BookingConfirmation")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>
                      {t("Date")}: {booking.date}
                    </span>
                  </Grid>
                </Grid>
                {/* <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Time: {booking.startTime} - {booking.endTime}</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Duration: {booking.duration} {booking.duration > 1 ? 'hours' : 'hour'}</span>
                  </Grid>
                </Grid> */}
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>
                      {t("BOOKING.NumberOfAttendants")}: {booking.participants}{" "}
                      {t("BOOKING.people")}
                    </span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>-</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>
                      {t("BOOKING.FacilitiesInclude")}:{" "}
                      {booking.extraFacilityCategories.join(", ")}
                    </span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>
                      {t("BOOKING.CateringServices")}:{" "}
                      {booking.cateringNames.join(", ")}
                    </span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>
                      {t("BOOKING.PaymentMethod")}:{" "}
                      {PAYMENT_METHOD[get(bookingSession, "payment_method", 1)]}
                    </span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>-</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className={classes.bookingInfo}>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" className={classes.bookingId}>
                      {t("BOOKING.CustomerInfo")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>
                      {t("BOOKING.FullName")}: {booking.customerName}
                    </span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Email: {booking.customerEmail}</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>-</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>
                      {t("BOOKING.AdditionalRequirementsNotes")}:{" "}
                      {booking.additionalRequirements}
                    </span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>-</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} className={classes.bookingInfo}>
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("BOOKING.BookingItems")}
                        </TableCell>
                        <TableCell align="left">{t("BOOKING.Date")}</TableCell>
                        <TableCell align="left">
                          {t("Price")} ({cc})
                        </TableCell>
                        <TableCell align="left">
                          {t("BOOKING.Quantity")}
                        </TableCell>
                        <TableCell align="left">
                          {t("BOOKING.Discount")}
                        </TableCell>
                        <TableCell align="left">
                          {t("BOOKING.TotalPrice")} ({cc})
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.date}</TableCell>
                          <TableCell align="left">
                            {formatMoney(row.avg_price)}
                          </TableCell>
                          <TableCell align="left">{row.quantity}</TableCell>
                          <TableCell align="left">
                            {row.avg_discount_pct} %
                          </TableCell>
                          <TableCell align="left">
                            {formatMoney(row.total_price)}
                          </TableCell>
                        </TableRow>
                      ))}

                      <TableRow>
                        <TableCell rowSpan={3} />
                        <TableCell rowSpan={3} />
                        <TableCell rowSpan={3} />
                        <TableCell rowSpan={3} />
                        <TableCell>{t("BOOKING.Subtotal")}</TableCell>
                        <TableCell align="left">
                          {formatMoney(get(grandTotalInvoice, "subTotal"))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("BOOKING.Tax")}</TableCell>
                        <TableCell align="left">
                          {formatMoney(get(grandTotalInvoice, "tax"))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("BOOKING.Total")}</TableCell>
                        <TableCell align="left">
                          {formatMoney(get(grandTotalInvoice, "grandTotal"))}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.footerContent}>
              <MaterialButton
                onClick={() => {
                  updateBookingState({ state: 4 });
                  history.goBack();
                }}
                className={classes.backButton}
              >
                {t("BOOKING.Back")}
              </MaterialButton>
              <Button
                type="primary"
                onClick={() => handleConfirm()}
                loading={isConfirming}
              >
                {t("BOOKING.Confirm")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Form>
      <SendEmailNotifyModal
        {...props}
        visible={notifyModalVisible}
        onOk={() => {
          confirmBooking({notify: true})
          showNotifyModal(false)
        }}
        onCancel={() => {
          confirmBooking({notify: false})
          showNotifyModal(false)
        }}
      />
    </Container>
  );
};

const mapState = ({ bookingReducer }) => ({
  booking: selectors.summaryCreatedBookingSessionInfo({ bookingReducer }),
  invoiceRows: selectors.getBookingInvoice({ bookingReducer }),
  currencyUnit: selectors.getCurrencyUnit({ bookingReducer }),
  grandTotalInvoice: selectors.getGrandTotalInvoice({ bookingReducer }),
  isUpdatingBookingState: bookingReducer.isUpdatingBookingState,
  bookingSession: bookingReducer.bookingSession,
  isConfirming: bookingReducer.isConfirming,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getCreatedBookingSummary,
      updateBookingState,
      resetBookingSession,
      confirmBooking,
    },
    dispatch
  );

BookingConfirm.propTypes = {
  history: Prototype.object,
  booking: Prototype.object,
  invoiceRows: Prototype.array,
  getCreatedBookingSummary: Prototype.func,
  resetBookingSession: Prototype.func,
  isUpdatingBookingState: Prototype.bool,
};

export default withRouter(
  connect(mapState, mapDispatch)(withTranslation("common")(BookingConfirm))
);

import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Select } from 'antd'
import { Table, Tag } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { closeSearchAvailableRooms } from '../../actions/modal'
import BookingModal from '../../views/BookingTable/components/BookingModal'
import * as actions from '../../actions/bookings/list'
import * as selectors from '../../selectors/bookings/list'


const { Option } = Select

const BookingList = (props) => {
  let match = useRouteMatch();

  const {
    t,

    selectableTabs,
    selectedTab,
    items,
    isFetching,
    selectablePaymentStatuses,
    selectableBookingStatuses,

    onChangeTab,
    onChangePage,
    onNewBookingClick,
    onChangeBookingStatusesFilter,
    onChangePaymentStatusesFilter,
    onSubmitFilter,
  } = props

  const columns = [
    {
      title: t('Confirmation No'),
      dataIndex: 'confirmationNo',
      key: 'confirmationNo',
      render: (text, record) => <Link to={`${match.path}/detail/${record.id}`}>{t(text)}</Link>,
    },
    {
      title: t('Room'),
      dataIndex: 'room',
      key: 'room',
    },
    {
      title: t('Customer'),
      dataIndex: 'customer',
      key: 'customer',
      render: (customer, record) => (
        <Grid container direction="column">
          <Grid item><Typography variant="subtitle1">{_.get(record, 'customer.name')}</Typography></Grid>
          <Grid item><Typography variant="body1">{_.get(record, 'customer.company')}</Typography></Grid>
          <Grid item><Typography variant="body2">{_.get(record, 'customer.email')}</Typography></Grid>
        </Grid>
      )
    },
    {
      title: t('Booking Date'),
      dataIndex: 'bookingDate',
      key: 'bookingDate',
      render: (bookingDate) => (
        <Grid container direction="column">
          <Grid item container justify="space-between">
            <Grid item><Typography variant="body1">{t("From")}</Typography></Grid>
            <Grid item><Typography variant="body1">{_.get(bookingDate, "startDate")}, {_.get(bookingDate, 'startTime')}</Typography></Grid>
          </Grid>
          <Grid item container justify="space-between">
            <Grid item><Typography variant="body1">{t("To")}</Typography></Grid>
            <Grid item><Typography variant="body1">{_.get(bookingDate, "endDate")}, {_.get(bookingDate, 'endTime')}</Typography></Grid>
          </Grid>
        </Grid>
      )
    },
    {
      title: t('Payment Status'),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (paymentStatus) => (
        <Tag color={_.get(paymentStatus, 'color')}>{t(_.get(paymentStatus, 'name'))}</Tag>
      )
    },
    {
      title: t('Booking Status'),
      dataIndex: 'bookingStatus',
      key: 'bookingStatus',
      render: (bookingStatus) => (
        <Tag color={_.get(bookingStatus, 'color')}>{t(_.get(bookingStatus, 'name'))}</Tag>
      )
    },
  ]
  return (
    <Paper>
      <PageHeader
        backIcon={false}
        onBack={() => props.history.back()}
        title={t("ALL BOOKINGS")}
        subTitle={t("List of all booking")}
        extra={[
          <Button color="primary" variant="contained" onClick={onNewBookingClick}>
            {t("New Booking")}
          </Button>,
        ]}
      >
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item container direction="column" style={{ width: 200 }}>
            <Grid item><Typography variant="body1">{t("Payment Status")}</Typography></Grid>
            <Grid item>
            <Select 
              placeholder={t("Payment Status")}
              mode="multiple"
              style={{ width: "100%" }}
              onChange={onChangePaymentStatusesFilter}
              value={_.get(props, 'filter.selectedPaymentStatuses', [])}
            >
              {_.map(selectablePaymentStatuses, it => (
                <Option value={it.id} key={it.id}>{t(it.name)}</Option>

              ))}
            </Select>
            </Grid>
          </Grid>
          <Grid item container direction="column" style={{ width: 200 }}>
            <Grid item><Typography variant="body1">{t("Booking Status")}</Typography></Grid>
            <Grid item>
            <Select 
              placeholder={t("Booking Status")}
              style={{ width: "100%" }}
              mode="multiple"
              onChange={onChangeBookingStatusesFilter}
              value={_.get(props, 'filter.selectedBookingStatuses', [])}
            >
              {_.map(selectableBookingStatuses, it => (
                <Option value={it.id} key={it.id}>{it.name}</Option>
              ))}
            </Select>
            </Grid>
          </Grid>
          <Grid item>
            <Button 
              size="small" 
              variant="contained"
              onClick={onSubmitFilter}
              disabled={_.get(props, 'filter.isSubmitting', false)}
            >
              {t("Filter")}
            </Button>
          </Grid>
        </Grid>
      </PageHeader>

      <div style={{ padding: 24 }}>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          onChange={(e, value) => onChangeTab(value)}
        >
          {_.map(selectableTabs, tab => (
            <Tab label={t(tab.name)} value={tab.id} />
          ))}
        </Tabs>

        <Table
          size="medium"
          columns={columns}
          dataSource={items}
          pagination={false}
          loading={isFetching}
        />

        <Grid container style={{ paddingTop: 24 }} justify="flex-end" alignItems="flex-end">
          <Grid item>
            <Pagination
              hideOnSinglePage={true}
              current={props.page}
              pageSize={props.pageSize}
              total={props.total}
              onChange={(page, pageSize) => onChangePage(page)}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}


class BookingListContainer extends Component {
  state = { isOpenSearchAvailableRoomModal: false }

  componentDidMount() {

  }

  render() {
    return (
      <>
        <BookingList 
          {...this.props} 
          onNewBookingClick={() => this.setState({ isOpenSearchAvailableRoomModal: true })} 
        />

        <BookingModal
          isOpen={this.state.isOpenSearchAvailableRoomModal}
          closeModal={() => this.setState({ isOpenSearchAvailableRoomModal: false })}
        />
      </>
    )
  }
}


const mapState = (state) => ({
  selectableTabs: selectors.getSelectableTabs(state),
  selectedTab: selectors.getSelectedTab(state),
  items: selectors.getItems(state),
  isFetching: selectors.isFetching(state),
  page: selectors.getPage(state),
  pageSize: selectors.getPageSize(state),
  total: selectors.getTotal(state),

  selectablePaymentStatuses: selectors.getSelectablePaymentStatuses(state),
  selectableBookingStatuses: selectors.getSelectableBookingStatuses(state),
  filter: selectors.getFilter(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    onChangeTab: actions.changeTab,
    onChangePage: actions.changePage,
    onChangeBookingStatusesFilter: actions.changeBookingStatusesFilter,
    onChangePaymentStatusesFilter: actions.changePaymentStatusesFilter,
    onSubmitFilter: actions.submitFilter,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(withTranslation("booking_management")(BookingListContainer)))

import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import {Tab, Tabs} from '@material-ui/core'
import { PageHeader, Button, Pagination } from 'antd'
import { Table, Tag } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import * as actions from '../../actions/discounts/list'
import * as selectors from '../../selectors/discounts/list'


const View = props => {
  let match = useRouteMatch();
  const {
    t,

    items,
    isFetching,
    selectableTabs,
    selectedTab,

    onChangePage,
    onChangeTab,
  } = props

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={`${match.path}/detail/${record.id}`}>{text}</Link>,
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('Start Date'),
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: t('End Date'),
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: t('Discounts'),
      key: 'discounts',
      dataIndex: 'discounts',
      render: discounts => (
        <span>
          {_.map(discounts, (discount, ix) => {
            const colors = ["geekblue", "orange", "blue"]

            return (
              <Tag color={colors[ix % 3]} key={ix}>
                {discount.toUpperCase()}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: t('Status'),
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => (
        <Tag color={_.get(record, 'status.color')}>{t(_.get(record, 'status.name'))}</Tag>
      ),
    },
  ];

  return (
    <Paper>
      <PageHeader
        backIcon={false}
        onBack={() => window.history.back()}
        title={t("ALL DISCOUNTS")}
        subTitle={t("List of all discounts")}
        extra={[
          <Link to={`${match.path}/new`}>
            <Button key="1" type="primary">
              {t("New Discount")}
            </Button>
          </Link>,
        ]}
      />

      <div style={{ padding: 24 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(e, value) => onChangeTab(value)}
        >
          {_.map(selectableTabs, tab => (
            <Tab label={t(tab.name)} value={tab.value} />
          ))}
        </Tabs>

        <Table
          size="medium"
          columns={columns}
          dataSource={items}
          pagination={false}
          loading={isFetching}
        />

        <Grid container style={{ paddingTop: 24 }} justify="flex-end" alignItems="flex-end">
          <Grid item>
            <Pagination
              hideOnSinglePage={true}
              current={props.page}
              pageSize={props.pageSize}
              total={props.total}
              onChange={(page, pageSize) => onChangePage(page)}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}

class DiscountListContainer extends Component {
  componentDidMount() {
    this.props.listDiscounts()
  }

  render() {
    return (
      <View {...this.props} />
    )
  }
}


const mapState = (state) => ({
  items: selectors.getItems(state),
  page: selectors.getPage(state),
  pageSize: selectors.getPageSize(state),
  total: selectors.getTotal(state),
  isFetching: selectors.isFetching(state),
  selectableTabs: selectors.getSelectableTabs(state),
  selectedTab: selectors.getSelectedTab(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    listDiscounts: actions.listDiscounts,
    onChangePage: actions.changePage,
    onChangeTab: actions.changeTab,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(withTranslation("discount_management")(DiscountListContainer)))

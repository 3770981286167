import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Detail from '../../components/rooms/DetailRoomType'
import * as actions from '../../actions/rooms/detail'
import {roomType as selectors} from '../../selectors/rooms/detail'


class RoomTypeDetail extends Component {
  componentDidMount() {
    let { match: { params: { id } } } = this.props
    this.props.retrieveRoomType(id)
  }

  render() {

    return (
      <Detail {...this.props} />
    )
  }
}


const mapState = (state) => ({
  isFetching: selectors.isFetching(state),
  name: selectors.getName(state),
  description: selectors.getDescription(state),
  publicTag: selectors.getPublicTag(state),
  coverImage: selectors.getCoverImage(state),
  rooms: selectors.getRooms(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveRoomType: actions.retrieveRoomType,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(RoomTypeDetail))

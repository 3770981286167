import * as _ from 'lodash'
export const MIN_H = 8
export const MAX_H = 22
export const SLOT_LENGTH = 14

export const timeRange2Slots = (start=MIN_H, end=MAX_H) => {
  const slotBin = _.map(_.range(SLOT_LENGTH), () => 0)
  _.forEach(_.range(start-MIN_H, end-MIN_H), index => {
    slotBin[index] = 1
  })
  const slotString = slotBin.join('')
  const slotInt = parseInt(slotString, 2)
  return [slotString, slotInt]
}

export const slots2Timerange = (slotInt) => {
  const slotString = _.padStart(slotInt.toString(2), SLOT_LENGTH, '0')
  const start = slotString.indexOf('1') + MIN_H
  const end = slotString.lastIndexOf('1') + MIN_H + 1
  return [start, end]
}

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { Form, Button, DatePicker, Modal, Select, Input } from 'antd'
import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import moment from 'moment'
import {cloneDeep} from 'lodash'
import {searchAvailableRooms} from '../../../actions/booking'
import { bindActionCreators } from 'redux'
import {withTranslation} from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    padding: '20px',
    textAlign: 'center'
  },
  searchButton: {
    padding: '0 80px',
    height: 30
  },
  radioContent: {
    textAlign: 'center',
    justifyContent: 'center',
    '& img': {
      width: 30
    }
  },
  title: {
    marginBottom: '30px'
  },
  formContent: {
    '& .ant-form-item': {
      marginBottom: '0',
      // '& .ant-form-item-children': {
      //   '& input': {
      //     width: '80%'
      //   }
      // }
    }
  },
  inputField: {
    '& input': {
      width: '70%'
    }
  },
  formLabel: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const BookingModal = props => {
  const classes = useStyles()
  const { isOpen, closeModal, history, form: { getFieldDecorator, validateFields, getFieldValue, setFieldsValue},
  searchAvailableRooms, isSearchingAvailableRooms, chosenDateInBookingTable, t
} = props
  const handleClose = () => {
    closeModal()
  }

  const convertTimeLineToBinary = (start_time, end_time) => {
    let temp = ''
    for (let i = 0; i < Number(start_time) - 8; i++) {
      temp += '0'
    }
    for (let i = Number(start_time) - 8 + 1; i <= Number(end_time) - 8; i++) {
      temp += '1'
    }
    for (let i = Number(end_time) - 8 + 1; i < 15; i++) {
      temp += '0'
    }
    console.log(temp)
    return parseInt(temp, 2)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        let timeslots = []
        const durationDate = values.end_date.diff(values.start_date, 'days')
        if (durationDate === 0) {
          const time = convertTimeLineToBinary(values.start_time, values.end_time)
          timeslots.push({ date: values.start_date.utc(true).format('YYYY-MM-DD'), time })
        } 
        else {
          if (durationDate > 0) {
            for (let i = 0; i <= durationDate; i++) {
              console.log()
              if(i === durationDate) {
                timeslots.push({
                  date: cloneDeep(values.start_date).add(i, 'days').format('YYYY-MM-DD'),
                  time: convertTimeLineToBinary(8, values.end_time)
                })
              } else if(i === 0) {
                timeslots.push({
                  date: cloneDeep(values.start_date).add(i, 'days').format('YYYY-MM-DD'),
                  time: convertTimeLineToBinary(values.start_time, 22)
                })
              } else {
                timeslots.push({
                  date: cloneDeep(values.start_date).add(i, 'days').format('YYYY-MM-DD'),
                  time: convertTimeLineToBinary(8, 22)
                })
              }
            }
          }
        }

        // console.log(timeslot)
        // console.log(values)
        searchAvailableRooms({
          permanent_facilities: [],
          participants: values.participants,
          timeslots
        })
        // history.push(paths.CreateNewBooking_FoundRoom)
      }
    })
    // closeModal()
  }

  const disableStartDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day')
  }

  const disableEndDate = (current) => {
    // Can not select days before today and today
    return current < (getFieldValue('start_date') || moment().startOf('day'))
  }

  return (
    <Modal
      // title="Basic Modal"
      visible={isOpen}
      // onOk={this.handleOk}
      onCancel={handleClose}
      footer={false}
      width={550}
    >
      <Container>
        <Form
          onSubmit={handleSubmit}
        >
          <Typography
            variant="h6"
            align="center"
            className={classes.title}
          >
            {t('BOOKING.PleaseSelectDateTime')}
          </Typography>
          <Grid container className={classes.formContent}>
            <Grid item xs={3} className={classes.formLabel}>
              {t('BOOKING.Participant')}
            </Grid>
            <Grid item xs={7} className={classes.inputField}>
              <Form.Item>
                {
                  getFieldDecorator('participants', {
                    initialValue: 2,
                    rules: [{ required: true, message: t('BOOKING.PleaseInputANumber') }]

                  })(
                    <Input type="number" min={1} />
                  )
                }
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container className={classes.formContent}>
            <Grid item xs={3} className={classes.formLabel}>
              {t('BOOKING.Start')}
            </Grid>
            <Grid item xs={7} >
              <Form.Item>
                {
                  getFieldDecorator('start_date', {
                    initialValue: moment(chosenDateInBookingTable) < moment().startOf('day') ? moment() :  moment(chosenDateInBookingTable),
                    rules: [{ required: true, message: t('BOOKING.RequireDate') },
                      {validator: (rule, value, callback) => {
                        if(value) {
                          if(value < moment().startOf('day')) {
                            callback(t('BOOKING.TheStartDateMustBeEqualOrAfterToday.'))
                          }
                        }
                        return callback()
                      }}
                    ],
                    getValueFromEvent: (value) => {
                      if(getFieldValue('end_date') < value.startOf('day') || !getFieldValue('end_date')) {
                        setFieldsValue({end_date: value})
                      }
                      return value
                    } 
                  })(
                    <DatePicker 
                      disabledDate={disableStartDate}
                    />
                  )
                }
              </Form.Item>
            </Grid>
            <Grid item xs={2}>
              <Form.Item>
                {
                  getFieldDecorator('start_time', {
                    initialValue: 8,
                    rules: [{ required: true, message: t('BOOKING.RequireTime') },
                      
                    ]

                  })(
                    <Select

                    >
                      {[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].map((item, index) => {
                        return (
                          <Select.Option key={index} value={item}>{item}h</Select.Option>
                        )
                      })}
                    </Select>
                  )
                }
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container className={classes.formContent}>
            <Grid item xs={3} className={classes.formLabel}>
              End
            </Grid>
            <Grid item xs={7} >
              <Form.Item>
                {
                  getFieldDecorator('end_date', {
                    initialValue: moment(chosenDateInBookingTable) < moment().startOf('day') ? moment() :  moment(chosenDateInBookingTable),
                    rules: [{ required: true, message: t('BOOKING.RequireDate') },
                      {validator: (rule, value, callback) => {
                        if(value) {
                          if(value < moment().startOf('day')) {
                            callback(t('BOOKING.TheEndDateMustBeEqualOrOfterToday.'))
                          }
                          if(value < getFieldValue('start_date')) {
                            callback(t('BOOKING.TheEndDateMustBeEqualOrAfterStartDate.'))
                          }
                        }
                        return callback()
                      }} 
                    ]

                  })(
                    <DatePicker 
                      disabledDate={disableEndDate}
                    />
                  )
                }
              </Form.Item>
            </Grid>
            <Grid item xs={2}>
              <Form.Item>
                {
                  getFieldDecorator('end_time', {
                    initialValue: 12,
                    rules: [
                      { required: true, message: t('BOOKING.RequireTime') },
                      { validator: (rule, value, callback) => {
                        if(value) {
                          if(Math.floor((moment(getFieldValue('end_date')) - getFieldValue('start_date') ) / 86400000) ===0 ) {
                            if(value <= getFieldValue('start_time')) {
                              callback(t('BOOKING.TheEndTimeMustBeAfterStartTime.'))
                            }
                          }
                        }
                        return callback()
                      }}
                    ]
                  })(
                    <Select>
                      {[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].map((item, index) => {
                        return (
                          <Select.Option key={index} value={item}>{item}h</Select.Option>
                        )
                      })}
                    </Select>
                  )
                }
              </Form.Item>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.buttonGroup}>
            <Button type="primary" className={classes.searchButton}
              // onClick={() => handleSubmit()}
              htmlType="submit"
              loading={isSearchingAvailableRooms}
            >
              {t('BOOKING.Ok')}
            </Button>
          </Grid>
        </Form>
      </Container>
    </Modal>
  )
}

const mapState = ({bookingReducer}) => ({
  isSearchingAvailableRooms: bookingReducer.isSearchingAvailableRooms,
  chosenDateInBookingTable: bookingReducer.chosenDateInBookingTable,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    searchAvailableRooms
  },
  dispatch
)

BookingModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  confirm: PropTypes.func,
  form: PropTypes.object,
  history: PropTypes.object,
  isSearchingAvailableRooms: PropTypes.bool,
  searchAvailableRooms: PropTypes.func,
}

export default Form.create({
  name: 'bookingModal'
})(withRouter(connect(mapState, mapDispatch)( withTranslation('common') (BookingModal))))
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Grid, Typography, Button } from '@material-ui/core'
import { PageHeader, Input, Table } from 'antd'
import { Card, Empty, Tag } from 'antd';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'


const DetailCatering = (props) => {
  let { match: { params: { id } } } = props
  const {
    t,

    name,
    typeTag,
    description,
    activeTag,
    minParticipants,
    pricings,
    isFetching,

    match,
  } = props

  return (
    <>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t("CATERING DETAIL")}
        subTitle={name || ""}
        extra={[
          <Link to={`${match.url.replace("detail", "edit")}`}>
            <Button
              color="primary"
              variant="contained"
              size="small"
            >
              {t("Edit")}
            </Button>
          </Link>
        ]}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        <Card loading={isFetching}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">{name}</Typography>
            </Grid>

            <Grid item container direction="column">
              <Grid item><Typography variant="subtitle1"><b>{t("Description")}</b></Typography></Grid>
              <Grid item><Typography variant="body1">{description}</Typography></Grid>
            </Grid>
          </Grid>

          <Grid item container spacing={1}>
            <Grid item><Typography variant="subtitle1"><b>{t("Min Participants")}</b></Typography></Grid>
            <Grid item><Typography variant="subtitle1">{minParticipants}</Typography></Grid>
          </Grid>

          <Grid item container spacing={1}>
            <Grid item><Typography variant="subtitle1"><b>{t("Type")}</b></Typography></Grid>
            <Grid item><Tag color={typeTag.color}>{typeTag.name}</Tag></Grid>
          </Grid>

          <Grid item container spacing={1}>
            <Grid item><Typography variant="subtitle1"><b>{t("Is Active")}</b></Typography></Grid>
            <Grid item><Tag color={activeTag.color}>{activeTag.name}</Tag></Grid>
          </Grid>
        </Card>

        <Card loading={isFetching} style={{ marginTop: 24 }}>
          <Typography variant="h6">{t("Pricings")}</Typography>
          <Table
            pagination={false}
            columns={[
              {
                title: t("Minimum Quantity"),
                dataIndex: 'minQuantity',
                key: "minQuantity"
              },
              {
                title: t("Price"),
                dataIndex: 'price',
                key: 'price',
              }
            ]}
            dataSource={pricings}
          />
        </Card>
      </div>
    </>
  )
}

export default withRouter(withTranslation("catering_management")(DetailCatering))

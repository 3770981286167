import { combineReducers } from 'redux'
import * as C from "../../actionTypes/discounts/detail";
import { DISCOUNT } from "../../constants/models";
import moment from 'moment'
import * as _ from 'lodash'


const data = (state = {}, action) => {
  const {type, payload} = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      return payload
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const {type, payload} = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT:
      return true
    case C.RETRIEVE_DISCOUNT_COMPLETED:
    case C.RETRIEVE_DISCOUNT_FAILED:
      return false
    default:
      return state
  }
}

const customersList = combineReducers({
  page: (state=1, action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_CUSTOMERS_COMPLETED:
        return _.get(payload, 'page')
      case C.CHANGE_CUSTOMERS_LIST_PAGE:
        return payload
      default:
        return state
    }
  },
  pageSize: (state=10, action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_CUSTOMERS_COMPLETED:
        return _.get(payload, 'page_size')
      default:
        return state
    }
  },
  total: (state=0, action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_CUSTOMERS_COMPLETED:
        return _.get(payload, 'count')
      default:
        return state
    }
  },
  items: (state=[], action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_CUSTOMERS_COMPLETED:
        return _.get(payload, 'results')
      default:
        return state
    }
  },
  isFetching: (state = false, action) => {
    const {type} = action
    switch (type) {
      case C.LIST_CUSTOMERS:
        return true
      case C.LIST_CUSTOMERS_COMPLETED:
      case C.LIST_CUSTOMERS_FAILED:
        return false
      default:
        return state
    }
  }
})


const roomsList = combineReducers({
  page: (state=1, action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_ROOMS_COMPLETED:
        return _.get(payload, 'page')
      case C.CHANGE_ROOMS_LIST_PAGE:
        return payload
      default:
        return state
    }
  },
  pageSize: (state=10, action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_ROOMS_COMPLETED:
        return _.get(payload, 'page_size')
      default:
        return state
    }
  },
  total: (state=0, action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_ROOMS_COMPLETED:
        return _.get(payload, 'count')
      default:
        return state
    }
  },
  items: (state=[], action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_ROOMS_COMPLETED:
        return _.get(payload, 'results')
      default:
        return state
    }
  },
  isFetching: (state = false, action) => {
    const {type} = action
    switch (type) {
      case C.LIST_ROOMS:
        return true
      case C.LIST_ROOMS_COMPLETED:
      case C.LIST_ROOMS_FAILED:
        return false
      default:
        return state
    }
  }
})

const reducers = combineReducers({
  data,
  customersList,
  roomsList,
  isFetching,
})

export default reducers

import React, { useEffect, useMemo, useState, useCallback, forwardRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import {
  Container, Grid, Typography, TextField,
  RadioGroup, FormControlLabel, Radio, Toolbar,
  Chip, FormControl, InputAdornment, IconButton,
  InputLabel, Select, Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import AddOutlined from '@material-ui/icons/AddOutlined'
import { get, upperCase, isEqual } from 'lodash'

import Editor from '../../../components/RichEditor'
import { AirplayOutlined, RssFeed } from '@material-ui/icons'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Upload, Icon, Modal, Form, Select as FormSelect, Input, Switch, message } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { MAX_IMG_SIZE_MB } from '../../../helpers/constant'
import { history } from '../../../config/store'
import {
  createRoom,
  clearRoomInfo,
  getRoom,
  updateRoom,
  listRooms,
  listPermFacs,
  listRoomTypes
} from '../../../actions/room'
import {
  getCcy,
  getCcySymbol,
  getUnit
} from '../../../selectors/public'
import { s2c } from '../../../helpers/helpers'
import LoadingIcon from 'mdi-react/LoadingIcon'
import Api from '../../../services/api'


const useStyles = makeStyles((theme) => ({
  formField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  formRoot: {
    '& .ant-form-item': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  roomInfoContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
    // '& .MuiGrid-item ': {
    //   padding: '15px'
    // }
  },
  roomCombinedContent: {
    display: 'block',
    minHeight: '225px',
    borderLeft: '1px solid #ddd'
  },
  chip: {
    minWidth: '120px',
    margin: '5px',
    justifyContent: 'space-between'
  },
  chip2: {
    padding: '5px 5px',
    margin: '5px',
  },
  addRoomCombinedIcon: {
    border: '1px solid',
    borderColor: theme.colorAccent,
    borderRadius: '50%',
    color: theme.colorAccent,
    width: '18px',
    height: '18px',
  },
  addRoomCombinedContent: {
    width: '100%'
  },
  padding15px: {
    padding: '15px'
  },
  roomCategoryLabelContent: {
    padding: '10px 0'
  },
  descriptionLabel: {
    padding: '10px 0'
  },
  addMoreRow: {
    '& .MuiTableCell-root': {
      color: 'rgba(162, 162, 162, 0.87)',
    },
    '& .MuiInput-underline:before': {
      border: 'unset'
    }
  },
  addMoreRowButtonContent: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      width: '80px'
    }
  },
  roomImagesContent: {
    margin: '20px 0'
  },
  roomImagesUploadContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
  },
  formSelectField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '200px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  selectRoomTypeContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  createNewTypeButton: {
    color: theme.colorAccent
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const _transformToDisplayedImage = (img) => {
  return ({
    uid: img.id,
    id: img.id,
    name: img.name,
    url: img.url,
    status: "done"
  })
}

const CreateNewRoom = props => {
  const {
    roomType,
    createRoom,
    match,
    getRoom,
    clearRoomInfo,
    roomInfo,
    updateRoom,
    isSavingRoom,
    isGettingRoomInfo,
    ccy,
    ccySymbol,
    unit,
    t
  } = props

  // form
  const { 
    getFieldDecorator, validateFields, setFieldsValue, 
    resetFields, getFieldValue } = props.form
  // combine room
  const { roomList, listRooms, isListingRoom } = props
  // permanent facs
  const { listPermFacs, permFacsList, isListingPermFacilities } = props
  // room type
  const { isListingRoomTypes, roomTypesList, listRoomTypes } = props

  const isUpdateView = match.path == paths.UpdateRoom
  const classes = useStyles()

  const DAYTIME_MAPPING = {
    MORNING: 15872, // 11111000000000
    AFTERNOON: 496, // 00000111110000
    FULLDAY: 16368  // 11111111110000
  }
  const pricingHeader = [
    { title: t('ROOMS.BlockHours'), field: 'hour' },
    { title: t('ROOMS.Price'), field: 'price' },
  ]
  const [pricingData, setPricingData] = useState([
    { hour: 'Morning', price: 0 },
    { hour: 'Afternoon', price: 0 },
    { hour: 'Fullday', price: 0 },
  ])

  const [fileList, setFileList] = useState([])

  const [combinedRooms, setCombinedRooms] = useState([])
  const [isCombinedRoom, setIsCombinedRoom] = useState(false)
  const [selectedPermFacilities, setPermFacilities] = useState([])

  useEffect(() => {
    // first load
    clearRoomInfo()
    if (isUpdateView) {
      getRoom(match.params.id)
    }
    listRooms({ isCombinedRoom: false })
    listPermFacs()
    listRoomTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // tricky way to clear all form...
    clearRoomInfo()
    resetFields()
    setCombinedRooms([])
    setIsCombinedRoom(false)
    setPermFacilities([])
    setPricingData([
      { hour: 'Morning', price: 0 },
      { hour: 'Afternoon', price: 0 },
      { hour: 'Fullday', price: 0 },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // fetched room info
    if (isUpdateView && get(roomInfo, 'id')) {
      setFieldsValue(roomInfo)
      setCombinedRooms(roomInfo.combined_rooms)
      setIsCombinedRoom(roomInfo.is_combined_room)
      setPermFacilities(roomInfo.permanent_facilities)
      setPricingData(pricingData.map(p => {
        const fetchedPricing = roomInfo.pricings.find(
          fp => fp.apply_range_int == DAYTIME_MAPPING[upperCase(p.hour)])
        return ({
          ...p,
          price: get(fetchedPricing, 'price') ? get(fetchedPricing, 'price') / unit : 'N/A'
        })
      }))
      setFileList(get(roomInfo, 'images', []).map(_transformToDisplayedImage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomInfo])

  const handleChangeCategory = event => {
    const isCombinedRoom = event.target.value == 'combined'
    setIsCombinedRoom(isCombinedRoom)
    if (!isCombinedRoom) {
      setCombinedRooms([])
    }
  }
  const handleDeleteCombinedRoom = roomId => () => {
    setCombinedRooms(combinedRooms.filter(r => r.id != roomId))
  }
  const handleAddCombinedRoom = roomId => {
    setIsCombinedRoom(true)
    if (!combinedRooms.find(r => r.id == roomId)) {
      const roomToAdd = roomList.find(r => r.id == roomId)
      setCombinedRooms([...combinedRooms, roomToAdd])
    }
  }
  const handleDeletePermFacility = pfId => () => {
    setPermFacilities(selectedPermFacilities.filter(r => r.id != pfId))
  }
  const handleAddPermFacility = pfId => {
    if (!selectedPermFacilities.find(r => r.id == pfId)) {
      const pfToAdd = permFacsList.find(r => r.id == pfId)
      setPermFacilities([...selectedPermFacilities, pfToAdd])
    }
  }

  // check changes in form
  const formValues = props.form.getFieldsValue()
  const isFormChanged = () => {
    const isObjEqual = [
      'name',
      'description',
      'room_type',
      'max_attendances',
      'size',
      'images',
      'permanent_facilities',
      'is_published'
    ].every(f => {
      switch(f) {
        case 'name':
        case 'description':
        case 'room_type':
        case 'max_attendances':
        case 'is_published': 
        case 'size':
          if (get(roomInfo, f) != get(formValues, f)) return false
          break;
        case 'images':
          const imageIds = get(roomInfo, f, []).map(img => img.id)
          const formImageIds = (fileList || []).map(img => img.id)
          if (!isEqual(imageIds, formImageIds)) return false
          break;
        case 'permanent_facilities':
          const permanentFacilityIds = get(roomInfo, f, []).map(pf => pf.id)
          const formPfIds = selectedPermFacilities.map(pf => pf.id)
          if (!isEqual(permanentFacilityIds, formPfIds)) return false
          break;
        default:
          break;
      }
      return true
    })
    return !isObjEqual
  }  

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  )

  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }

  const handleChange = ({ file, fileList }) => {
    switch (file.status) {
      case "uploading":
        setFileList(fileList)
        break;
      case "done":
        setFileList(fileList.map(
          f => get(f, 'url') ? f : ({
            ...f, 
            url: get(f, 'response.url'),
            id: get(f, 'response.id'),
          })))
        break;
      case "removed":
        setFileList(fileList)
        break;
      default:
        break;
    }
  }

  const api = new Api()
  const handleUpload = async ({file, onSuccess }) => {
    const data = new FormData()
    data.append('file', file)
    const res = await api.image.uploadSingleImage(data)
    onSuccess(res)
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(`${t('ROOMS.YouCanOnlyUploadXFile')}`);
    }
    const isLt2M = file.size / 1024 / 1024 < MAX_IMG_SIZE_MB.ROOM;
    if (!isLt2M) {
      message.error(`${t('ROOMS.ImageMustSmallerThan')} ${MAX_IMG_SIZE_MB.ROOM_TYPE}MB!`);
    }
    return isJpgOrPng && isLt2M;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        const roomData = {
          ...values,
          combined_room_ids: combinedRooms.map(r => r.id),
          is_combined_room: isCombinedRoom,
          permanent_facility_ids: selectedPermFacilities.map(pf => pf.id),
          pricing_objs: pricingData.map(p => ({
            apply_range_int: DAYTIME_MAPPING[upperCase(p.hour)],
            price: parseInt(parseFloat(p.price) * unit)
          })),
          image_ids: fileList.map(f => f.id)
        }
        if (isUpdateView) {
          updateRoom(roomInfo.id, roomData)
        } else {
          createRoom(roomData)
        }
      }
    })
  }

  // Temp disable search room via API
  // const [searchValue, setSearchValue] = useState('')
  // const [data, setData] = useState([])

  // const handleSearchRooms = debounce(value => {
  //   setData([])
  //   setSearchValue(value)
  // }, 800);

  return (
    <Container>
      {isGettingRoomInfo ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Form onSubmit={handleSubmit} className={classes.formRoot}>
        <Grid container >
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={2} md={3}>
                <Typography variant='h6'>{t('ROOMS.RoomInformation')}</Typography>
              </Grid>
              <Grid item xs={10} md={9} className={classes.roomInfoContent}>
                <Grid container>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Typography>* {t('ROOMS.RoomName')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('name', {
                        rules: [
                          {
                            required: true,
                            message: `${t('ROOMS.RoomName')} ${t('ROOMS.IsRequired')}`,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12} className={classes.padding15px}>
                    <RadioGroup aria-label="gender" name="gender2" value={isCombinedRoom ? 'combined' : 'single'} onChange={handleChangeCategory}>
                      <Grid container >
                        <Grid item xs={3} md={3} className={classes.roomCategoryLabelContent}>
                          <Typography>{t('ROOMS.RoomCategory')}</Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <FormControlLabel
                            value='single'
                            control={<Radio disabled={isUpdateView} color="primary" />}
                            label="Single"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <FormControlLabel
                            value='combined'
                            control={<Radio disabled={isUpdateView} color="primary" />}
                            label="Combined"
                            labelPlacement="start"
                          />
                          <Toolbar className={classes.roomCombinedContent}>
                            <Typography variant="caption" component="h6">{t('ROOMS.RoomCombinationIncludes')}</Typography>
                            {combinedRooms.map(data => {
                              return (
                                <Chip
                                  key={data.id}
                                  label={data.name}
                                  onDelete={handleDeleteCombinedRoom(data.id)}
                                  className={classes.chip}
                                />
                              )
                            })}
                            <FormControl className={clsx(classes.addRoomCombinedContent)}>
                              <FormSelect
                                disabled={isUpdateView}
                                showSearch
                                style={{ width: '100%' }}
                                placeholder={t("ROOMS.SelectARoom")}
                                optionFilterProp="children"
                                value={undefined}
                                onChange={(e) => handleAddCombinedRoom(e)}
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              >
                                {roomList.filter(
                                  r => !combinedRooms.map(cr => cr.id).includes(r.id)).map(d => (
                                  <FormSelect.Option key={d.id}>
                                    {d.name}
                                  </FormSelect.Option>
                                ))}
                              </FormSelect>
                            </FormControl>
                          </Toolbar>
                        </Grid>
                      </Grid>
                    </RadioGroup >
                  </Grid>

                  <Grid item md={12} className={classes.padding15px}>
                    <Typography className={classes.descriptionLabel}>{t('ROOMS.Description')}</Typography>
                    {/* <Editor /> */}
                    <Form.Item>
                      {getFieldDecorator('description', {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(<Input.TextArea
                        placeholder={t("ROOMS.WritingRoomDescription")}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />)}
                    </Form.Item>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} md={6} className={classes.padding15px}>
                      <Typography>{t('ROOMS.Size')}</Typography>
                      <Form.Item>
                        {getFieldDecorator('size', {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(<TextField
                          type="number"
                          className={classes.formField}
                          // id="outlined-error"
                          label=""
                          InputProps={{
                            endAdornment: <InputAdornment position="end">m2</InputAdornment>,
                          }}
                        // variant="outlined"
                        />)}
                      </Form.Item>
                    </Grid>
                    <Grid item xs={6} md={6} className={classes.padding15px}>
                      <Typography>* {t('ROOMS.Capacity')}</Typography>
                      <Form.Item>
                        {getFieldDecorator('max_attendances', {
                          rules: [
                            {
                              required: true,
                              message: `${t('ROOMS.Capacity')} ${t('ROOMS.IsRequired')}`,
                            },
                          ],
                        })(<TextField
                          type="number"
                          className={classes.formField}
                          // id="outlined-error"
                          label=""
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{t('ROOMS.peoples')}</InputAdornment>,
                          }}
                        // variant="outlined"
                        />)}
                      </Form.Item>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} md={6} className={classes.padding15px}>
                      <Typography>* {t('ROOMS.PriceList')}</Typography>
                      <TableContainer >
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {pricingHeader.map((item, index) => {
                                return (
                                  <TableCell key={index}>{item.title}</TableCell>
                                )
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pricingData.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell key={index} align="left">{t(`ROOMS.${row.hour}`)}</TableCell>
                                <TableCell align="left">
                                  <TextField
                                    type="number"
                                    className={classes.formField}
                                    // id="outlined-error"
                                    label=""
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">{ccySymbol} </InputAdornment>,
                                      endAdornment: <InputAdornment position="end">{ccy}</InputAdornment>
                                    }}
                                    inputProps={{ step: 0.01, min: 0 }}
                                    value={row.price}
                                    onChange={(e) => setPricingData(pricingData.map(
                                      (r, i) => i == index ? ({ ...r, price: e.target.value }) : r))}
                                  // variant="outlined"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={6} md={6} className={classes.padding15px}>
                      <Typography>{t('ROOMS.RoomFacilities')}</Typography>
                      {selectedPermFacilities.map(data => {
                        return (
                          <Chip
                            key={data.id}
                            label={data.name}
                            onDelete={handleDeletePermFacility(data.id)}
                            className={classes.chip}
                          />
                        )
                      })}
                      <FormControl className={clsx(classes.addRoomCombinedContent)}>
                        <FormSelect
                          showSearch
                          style={{ width: '100%' }}
                          placeholder={t("ROOMS.SelectAFacility")}
                          optionFilterProp="children"
                          value={undefined}
                          onChange={(e) => handleAddPermFacility(e)}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {permFacsList.filter(pf => !selectedPermFacilities.map(
                            spf => spf.id).includes(pf.id)).map(d => (
                              <FormSelect.Option key={d.id}>
                                {d.name}
                              </FormSelect.Option>
                            ))}
                        </FormSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6} className={classes.padding15px}>
                      <Typography className={classes.descriptionLabel}>{t('ROOMS.PublishRoom')}</Typography>
                      {/* <Editor /> */}
                      <Form.Item>
                        {getFieldDecorator('is_published', {
                          rules: [],
                        })(<Switch checked={getFieldValue('is_published')} />)}
                      </Form.Item>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.roomImagesContent}>
            <Grid item xs={2} md={3}>
              <Typography variant='h6'>{t('ROOMS.RoomImages')}</Typography>
              <Typography variant='caption'>800x600 (px) Max {MAX_IMG_SIZE_MB.ROOM}Mb</Typography>
            </Grid>
            <Grid item xs={10} md={9} className={classes.roomImagesUploadContent}>
              <Upload
                beforeUpload={beforeUpload}
                customRequest={handleUpload}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item md={3} xs={2}>
              <Typography variant='h6'>{t('ROOMS.RoomTypes')}</Typography>
            </Grid>
            <Grid item md={6} xs={10} className={classes.selectRoomTypeContent}>
              <FormControl variant="outlined" className={classes.formControl}>
                {/* <InputLabel id="demo-simple-select-label">Select room type</InputLabel> */}
                <Form.Item>
                  {getFieldDecorator('room_type', {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(<FormSelect
                    className={classes.formSelectField}
                    placeholder={t("ROOMS.SelectRoomType")}
                  >
                    {roomTypesList.map(rt => (
                    <FormSelect.Option value={rt.id}>
                      {rt.room_type}
                    </FormSelect.Option>))}
                  </FormSelect>)}
                </Form.Item>
              </FormControl>
              or
              <Link to={paths.NewRoomTypes}>
                  <Button
                    color="default"
                    disableElevation
                    variant="contained"
                    className={classes.createNewTypeButton}
                  ><AddOutlined /> {t('ROOMS.CreateNewType')}</Button>
                </Link>
              </Grid>
            </Grid>
          <Grid item xs={12}>
            <div className={classes.footerContent}>
              <Button
                onClick={() => history.goBack()}
                className={classes.backButton}
                variant="contained" color="default"
                disableElevation
              >
              {isFormChanged() ? t('ROOMS.Discard'): t('ROOMS.Back')}
            </Button>
              <Button variant="contained" color="primary" disableElevation type="submit">
                {t('ROOMS.Publish')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Form>
    </Container>
  )
}

const mapState = ({ roomReducer, publicReducer }) => ({
  isGettingRoomInfo: roomReducer.isGettingRoomInfo,
  roomInfo: roomReducer.roomInfo,
  isListingRoom: roomReducer.isListingRoom,
  roomList: roomReducer.listRooms,
  permFacsList: roomReducer.listPermFacilities,
  isListingPermFacilities: roomReducer.isListingPermFacilities,
  isSavingRoom: roomReducer.isSavingRoom,
  isListingRoomTypes: roomReducer.isListingRoomTypes,
  roomTypesList: roomReducer.listRoomTypes,

  ccy: getCcy({ publicReducer }),
  ccySymbol: getCcySymbol({ publicReducer }),
  unit: getUnit({ publicReducer })
})

const mapDispatch = dispatch => bindActionCreators(
  {
    createRoom,
    updateRoom,
    getRoom,
    clearRoomInfo,
    listRooms,
    listPermFacs,
    listRoomTypes
  },
  dispatch
)

CreateNewRoom.propTypes = {
  name: PropTypes.string
}

CreateNewRoom.defaultProps = {
  name: ''
}

export default Form.create({
  name: 'newRoom',
  mapPropsToFields(props) {
    return {
      name: Form.createFormField({
        value: ''
      }),
      description: Form.createFormField({
        value: ''
      }),
      roomType: Form.createFormField({
        value: null
      }),
      maxAttendances: Form.createFormField({
        value: 0
      }),
      size: Form.createFormField({
        value: 0
      }),
    };
  },
})(withRouter(connect(mapState, mapDispatch)(withTranslation('common')(CreateNewRoom))))
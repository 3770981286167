import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Button as MaterialButton } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form, Select, Input, Icon } from 'antd'
import { searchAvailableExtraFacilities, removeSessionItems, addExtraFacility, retrieveExtraFacilityItemPricings } from '../../../actions/booking'
import { roundToUnit } from '../../../helpers/helpers'
import { CURRENCY_MAPPING } from '../../../helpers/constant'
import { formatMoney } from '../../../helpers/bookings'
import { get } from 'lodash'
import AddIcon from 'mdi-react/AddIcon'
import RemoveIcon from 'mdi-react/DeleteOutlineIcon'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import _ from 'lodash'
import {withTranslation} from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  facilitiesServiceContent: {
    borderTop: '1px solid #ddd',
    padding: '20px'
  },
  selectField: {
    '& .ant-select': {
      width: '80%',
    },
    '& .ant-form-item': {
      marginBottom: '0'
    }
  },
  cateringContent: {
    borderTop: '1px solid #ddd',
    padding: '20px'
  },
  buttonAddItem: {
    color: theme.colorAccent
  },
  faclityItemName: {
    display: 'flex',
    alignItems: 'center'
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  totalPrice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formContent: {
    marginBottom: '20px'
  },
  inputField: {
    '& .ant-input': {
      width: '80%',
    }
  },
}))

const AddExtraFacilitiesForm = props => {
  const classes = useStyles()
  const { form: { getFieldDecorator, validateFields, getFieldValue, setFields, setFieldsValue, resetFields },
    bookingSession, isSearchingAvailableExtraFacilities, searchAvailableExtraFacilities,
    addExtraFacility, removeSessionItems, configurationInfo, bookingRequest, retrieveExtraFacilityItemPricings,
    extraFacilityPricings, isGettingExtraFacilityPrice, t
  } = props
  const searchedRoom = get(bookingSession, 'items', []).filter(item => item.item_type === 1)
  const extraFacilities = get(props, 'extraFacilities', [])
  const listQuantity = (extraFacilities.find(item => item.id === getFieldValue('facility')) || {}).listAvailable || []

  let listRoomSelecting = get(bookingSession, 'items', []).filter(item => item.item_type === 1)
  let listFacilitiesSelecting = get(bookingSession, 'items', []).filter(item => item.item_type === 2)
  listRoomSelecting = listRoomSelecting.filter(item => {
    if (!listFacilitiesSelecting.find(it => it.date === item.date && getFieldValue('facility') === get(it, 'item.category.id'))) {
      return item
    }
  })
  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        const listData = []
        const {discount} = values
        console.log(values)
        listQuantity.map((item, index) => {
          if (index <= values.quantity - 1) {
            const data = {
              item_id: item,
              quantity: 1,
              date: values.date,
              time: listRoomSelecting.find(item => item.date === values.date).timeslots,
              price: Number(values.price) * 100,
              item_type: 2
            }
            if(discount !== '') {
              data.discount = Number(discount)
            }
            listData.push(data)
            // listRoomSelecting.map(it => {
            // })
          }
        })
        // console.log(listData)
        addExtraFacility({ items: listData })
        resetFields()
      }
    })
  }

  const listExtraFacilitiesSelected = _.chain(bookingSession.items || []).filter(item => item.item_type === 2).groupBy(item => {
    return item.date
  }).map((value, key) => ({
    name: key,
    items: _.chain(value || []).groupBy(it => {
      return it.item.category.name
    }).map((value2, key2) => ({ name: key2, items: value2 })).value()
  })).value()

  const getItemPriceDefault = data  => {
    const listData = []
    listQuantity.map((item, index) => {
      if (index <= data - 1) {
        listData.push({
          item_id: item,
          quantity: 1,
          date: getFieldValue('date'),
          time: listRoomSelecting.find(item => item.date === getFieldValue('date')).timeslots,
          // price: Number(values.price) * 100,
          item_type: 2
        })
        // listRoomSelecting.map(it => {
        // })
      }
    })
    retrieveExtraFacilityItemPricings({items: listData})
  }

  useEffect(() => {
    const priceDefault = _.mean((extraFacilityPricings || []).map(item => item.price)) / get(configurationInfo, 'unit', 100) || 0
    const discountDefault = _.mean((extraFacilityPricings || []).map(item => item.discount))
    setFieldsValue({
      price: priceDefault,
      discount: discountDefault
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraFacilityPricings,])

  const subTotal = Number(getFieldValue('price') || 0) * Number(getFieldValue('quantity'))
  const total = subTotal - (subTotal * Number(getFieldValue('discount') || 0)) / 100

  return (
    <Grid container>
      <Grid item xs={12} md={12} className={classes.facilitiesServiceContent} >
        <Form
          onSubmit={handleSubmit}
        >
          <Grid container >
            <Grid item xs={3}>
              <Typography variant="h6" component="h6">
                {t('BOOKING.ExtraFacilities')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" component="h6">
                {t('BOOKING.Date')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" component="h6">
                {t('BOOKING.Quantity')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" component="h6">
                {t('BOOKING.Price')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h6" component="h6">
                {t('BOOKING.Discount')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h6" component="h6" align="center">
                {t('BOOKING.Total')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.formContent} >
            <Grid item xs={3} className={classes.selectField}>
              <Form.Item>
                {
                  getFieldDecorator('facility', {
                    rules: [{ required: true, message: t('BOOKING.RequireFacility') }],
                    getValueFromEvent: (value) => {
                      if (value && getFieldValue('date')) {
                        const time = listRoomSelecting.find(item => item.date === getFieldValue('date')).timeslots
                        searchAvailableExtraFacilities({
                          category: value,
                          timeslots: [{
                            date: getFieldValue('date'),
                            time
                          }]
                        })
                        setFieldsValue({
                          quantity: null,
                          price: 0,
                          discount: 0
                        })
                      }
                      return value
                    }
                  })(
                    <Select
                      loading={isSearchingAvailableExtraFacilities || isGettingExtraFacilityPrice}
                    >
                      {extraFacilities.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                        )
                      })
                      }
                    </Select>
                  )
                }
              </Form.Item>

            </Grid>
            <Grid item xs={2} className={classes.selectField}>
              <Form.Item>
                {
                  getFieldDecorator('date', {
                    rules: [{ required: true, message: t('BOOKING.RequireDate') }],
                    getValueFromEvent: (value) => {
                      if (value && getFieldValue('facility')) {
                        const time = listRoomSelecting.find(item => item.date === value).timeslots
                        searchAvailableExtraFacilities({
                          category: getFieldValue('facility'),
                          timeslots: [{
                            date: value,
                            time
                          }]
                        })
                        setFields({
                          quantity: null
                        })
                      }
                      return value
                    }
                  })(
                    <Select
                      disabled={isSearchingAvailableExtraFacilities || !listRoomSelecting.length}
                      // placeholder={listQuantity.length < 1 && 'Not available'}
                      loading={isSearchingAvailableExtraFacilities || isGettingExtraFacilityPrice}
                    >
                      {listRoomSelecting.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item.date}>{item.date}</Select.Option>
                        )
                      })
                      }
                    </Select>
                  )
                }
              </Form.Item>
            </Grid>
            <Grid item xs={2} className={classes.selectField}>
              <Form.Item>
                {
                  getFieldDecorator('quantity', {
                    rules: [{ required: true, message: t('BOOKING.RequireQuantity') }],
                    getValueFromEvent: (e) => {
                      getItemPriceDefault(e)
                      return e
                    }
                  })(
                    <Select
                      disabled={isSearchingAvailableExtraFacilities || !listQuantity.length}
                      placeholder={listQuantity.length < 1 && t('BOOKING.NotAvailable')}
                      loading={isGettingExtraFacilityPrice}
                    >
                      {listQuantity.map((item, index) => {
                        return (
                          <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                        )
                      })
                      }
                    </Select>
                  )
                }
              </Form.Item>
            </Grid>
            <Grid item xs={2} >
              <Form.Item
                // validateStatus="validating"
              >
                {
                  getFieldDecorator('price', {
                    // initialValue: priceDefault,
                    rules: [{ required: true, message: t('BOOKING.RequirePrice') }]
                  })(
                    <Input placeholder="Price" 
                      suffix={isGettingExtraFacilityPrice ? <Icon type="loading" /> : CURRENCY_MAPPING[configurationInfo.currency]}
                      // defaultValue={0.00}
                      type="number"
                      min="0"
                      // addonAfter={ isGettingExtraFacilityPrice ? <Icon type="loading" /> : null}
                      // max="10000"
                      step="0.01"
                      disabled={!getFieldValue('quantity') || isGettingExtraFacilityPrice}
                    />
                  )
                }
              </Form.Item>
            </Grid>
            <Grid item xs={1} className={classes.alignItemsCenter}>
              <Form.Item>
                {
                  getFieldDecorator('discount', {
                    // initialValue: discountDefault,
                    // rules: [{ required: true, message: 'Price is required' }]
                  })(
                    <Input 
                      // placeholder="0"
                      // defaultValue={0.00}
                      type="number"
                      min="0"
                      max="100"
                      step="1"
                      disabled={!getFieldValue('quantity')}
                      suffix={isGettingExtraFacilityPrice ? <Icon type="loading" /> : '%'}
                    />
                  )
                }
              </Form.Item>
            </Grid>
            <Grid item xs={1} className={classes.totalPrice}>
              {/* <Typography align="center"> */}
              {formatMoney((total || 0) * 100)}
              {/* </Typography> */}
            </Grid>
            <Grid item xs={1}>
              <MaterialButton className={classes.buttonAddItem}
                type="submit"
              ><AddIcon /></MaterialButton>
            </Grid>
          </Grid>
          {listExtraFacilitiesSelected.map((item, index) => {
            // const { price } = item
            return (
              <>
                {item.items.map((it, idx) => {
                  const price = get(it, 'items[0].price', 0)
                  console.log(price)
                  console.log(get(it, 'items[0]', {}))
                  const discount = get(it, 'items[0].discount_amount', 0)
                  const quantity = get(it, 'items', []).length
                  return (
                    <Grid key={index} container className={classes.faclityItem}>
                      <Grid item xs={3} className={classes.alignItemsCenter}>
                        {get(it, 'name', '')}
                      </Grid>
                      <Grid item xs={2} className={classes.alignItemsCenter}>
                        {get(item, 'name', '')}
                      </Grid>
                      <Grid item xs={2} className={classes.alignItemsCenter}>
                        {quantity}
                      </Grid>
                      <Grid item xs={2} className={classes.alignItemsCenter}>
                        {formatMoney(price, configurationInfo.unit)}
                      </Grid>
                      <Grid item xs={1} className={classes.alignItemsCenter}>
                        {discount} %
                      </Grid>
                      <Grid item xs={1} className={classes.totalPrice}>
                        <Typography align="center">
                          {formatMoney((price * quantity) - ((price * discount * quantity) / 100), configurationInfo.unit)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <MaterialButton
                          onClick={() => {
                            // const temp = listFacilitiesSelecting.filter(it => get(it, 'item.category.name') == item.name && it.date == item.date)
                            // console.log(temp)
                            removeSessionItems(get(it, 'items', []).map(it => it.id))
                          }}
                        ><RemoveIcon /></MaterialButton>
                      </Grid>
                    </Grid>
                  )
                })}
              </>
            )
          })}
        </Form>
      </Grid>

    </Grid>
  )
}

const mapState = ({ publicReducer, bookingReducer }) => ({
  bookingSession: bookingReducer.bookingSession,
  bookingRequest: bookingReducer.bookingRequest,
  isSearchingAvailableExtraFacilities: bookingReducer.isSearchingAvailableExtraFacilities,
  extraFacilities: bookingReducer.listExtraFacilities,
  configurationInfo: publicReducer.configurationInfo,
  extraFacilityPricings: bookingReducer.extraFacilityPricings,
  isGettingExtraFacilityPrice: bookingReducer.isGettingExtraFacilityPrice,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    searchAvailableExtraFacilities,
    addExtraFacility,
    removeSessionItems,
    retrieveExtraFacilityItemPricings
  },
  dispatch
)

AddExtraFacilitiesForm.propTypes = {
  searchAvailableExtraFacilities: PropTypes.func,
  removeSessionItems: PropTypes.func,
  addExtraFacility: PropTypes.func,
  retrieveExtraFacilityItemPricings: PropTypes.func,
  bookingSession: PropTypes.object,
  form: PropTypes.object,
  configurationInfo: PropTypes.object,
  extraFacilityPricings: PropTypes.object,
  isSearchingAvailableExtraFacilities: PropTypes.bool,
  isGettingExtraFacilityPrice: PropTypes.bool,
}

export default Form.create({
  name: 'addExtraFacilities'
})(connect(mapState, mapDispatch)( withTranslation('common') (AddExtraFacilitiesForm)))
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Doughnut, Chart } from 'react-chartjs-2'
import { makeStyles, useTheme } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  }
}))

// const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw
// Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
//   draw() {
//     originalDoughnutDraw.apply(this, arguments)

//     const chart = this.chart
//     const width = chart.width
//     const height = chart.height
//     const ctx = chart.ctx

//     const fontSize = (height / 64).toFixed(2)
//     ctx.font = `${fontSize}em sans-serif`
//     ctx.textBaseline = 'middle'

//     let max = 0
//     let color = '#ddd'
//     console.log(chart.config.data)
//     for (let i = 0 ;i < chart.config.data.datasets[0].data.length; i++) {
//       if(chart.config.data.datasets[0].data[i] > max) {
//         max = chart.config.data.datasets[0].data[i]
//         color = chart.config.data.datasets[0].backgroundColor[i]
//         ctx.fillStyle = chart.config.data.datasets[0].backgroundColor[i]
//       }
//     }


//     const text = `${max}%`
//     const textX = Math.round((width - ctx.measureText(text).width) / 2)
//     const textY = height / 2

//     ctx.fillText(text, textX, textY)
//   }
// })

const ChartDraw = props => {
  const { data: dataProp, className, ...rest } = props

  const classes = useStyles()
  const theme = useTheme()

  const data = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: []
  }

  for (const item of dataProp) {
    data.datasets[0].data.push(item.value)
    data.datasets[0].backgroundColor.push(item.color)
    data.labels.push(item.label)
  }
  console.log(dataProp)
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    elements: {
      center: {
        size: 20,
        text: (dataProp[0] || {}).value + '%',
        color: (dataProp[0] || {}).color, //Default black
        fontStyle: 'Helvetica', //Default Arial
        sidePadding: 15 //Default 20 (as a percentage)
      }
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: '#fff',
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        label: function (tooltipItem, data) {
          const label = data['labels'][tooltipItem['index']]
          const value = data['datasets'][0]['data'][tooltipItem['index']]

          return `${label}: ${value}%`
        }
      }
    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Doughnut
        data={data}
        options={options}
      />
    </div>
  )
}

ChartDraw.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
}

export default ChartDraw

import React from 'react'
import PropTypes from 'prop-types'
// import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import {withTranslation} from 'react-i18next'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '5px'
  },
  viewButtonGroup: {
    textAlign: 'right',
    display: 'block',
    width: '100%',
    marginBottom: '10px'
  },
  appBar: {
    marginBottom: '30px'
  }
}))

const Header = props => {
  const classes = useStyles()
  const { modeGrid, handleModeChange, t  } = props
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    // console.log(newValue)
    setValue(newValue)
    handleModeChange(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  return (

    <Container className={classes.root}>
      <AppBar className={classes.appBar} position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={t('BOOKING.Upcoming')} {...a11yProps(0)} />
          <Tab label={t('BOOKING.History')} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
    </Container>
  )
}

Header.propTypes = {
  modeGrid: PropTypes.bool,
  handleModeChange: PropTypes.func,
  t: PropTypes.func,
}

export default withTranslation('common') (Header)
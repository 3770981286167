import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, TextField, Toolbar, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {Link, withRouter} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import { Form } from 'antd'
import Image from '../../../assets/images/Stockholm_1.jpg'
import * as paths from '../../../constants/paths.constants'
import { history } from '../../../config/store'
import ASSETS from '../../../constants/assets'
import {requestResetPassword} from '../../../actions/user'


const useStyles = makeStyles((theme) => {
  return ({
    logo: {
      marginTop: '50px',
      // marginBottom: '50px',
      color: theme.colorAccent,
      width: '20%',
      cursor: 'pointer',
      position: 'absolute',
      top: '0',
      left: '40%',
      // width: '300px',
  
    },
    background: {
      minHeight: '230px',
      height: '25%',
      backgroundImage: `url(${Image})`,
      '& img': {
        // height: '55%',
        width: '20%'
      },
      backgroundSize: '100%',
      backgroundPositionY: '455px',
      opacity: 0.2
    },
    checkIcon: {
      '& svg': {
        width: '50px',
        height: '50px',
        color: theme.colorAccent,
      }
    },
    formRoot: {
      textAlign: 'center',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 300,
      },
    },
    formField: {
      '& .MuiInputBase-root': {
        // backgroundColor: 'white',
        borderRadius: '15px'
      }
    },
    fontBold: {
      fontWeight: 'bold'
    },
    textWelcome: {
      padding: '20px 0',
      color: theme.colorGray
    },
    verifySuccessText: {
      padding: '0px 0 10px',
      color: theme.colorGray
    },
    title: {
      color: theme.colorAccent,
      marginBottom: '20px',
      marginTop: '50px',
    },
    titleSubText: {
      color: theme.colorGray,
      padding: '0 20%'
    },
    buttonGroup: {
      justifyContent: 'center',
      '& button': {
        width: 300,
        padding: '10px 0',
        margin: '25px'
      }
    },
    forgotPassword: {
      cursor: 'pointer'
    },
    registerContent: {
      marginTop: '30px'
    },
    registerLink: {
      cursor: 'pointer'
    }
  })
})

const ForgotPassword = (props) => {
  const {form: {getFieldDecorator, validateFields}} = props
  const {t, requestResetPassword, isSubmitting} = props
  const classes = useStyles()

  const handleSubmit = (e) => {
    validateFields((errors, values) => {
      if (!errors) {
        requestResetPassword(values)
      }
    })
  }

  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid item md={12} sm={12} xs={12}>
        <Typography variant='h2' align='center' className={classes.background}>
        </Typography>
        <img src={ASSETS.LOGO} className={classes.logo} onClick={() => history.push(paths.HomePage)}
          alt=""
        />
        <Typography variant='h5' align='center' className={classes.title}>
          {t('AUTH.ForgotPassword')}
        </Typography>
        <Typography variant='body2' align='center' className={classes.titleSubText}>
          {t('AUTH.PleaseEnterYourRegisteredEmailBelowToReceiveTheResetPassword')}
        </Typography>
        <Form 
          initialValues={{email: ''}}
          onSubmit={e => {
            e.preventDefault();
            handleSubmit(e)
          }} 
          className={classes.formRoot}>
          <Grid container >
            <Grid item xs={12}>
              <Form.Item name="email">
                {getFieldDecorator('email', {  
                  rules: [
                    { 
                      required: true,
                      message: 'Please enter your email!',
                    }
                  ],
                  initialValue: ''
                }) (
                <TextField
                  className={classes.formField}
                  id="standard-basic"
                  label="Email"
                />)}
              </Form.Item>
            </Grid>
            <Grid item xs={12}>
              <Toolbar className={classes.buttonGroup}>
                <Button 
                  autoFocus 
                  color="primary" 
                  variant="contained" 
                  type='submit' 
                  disabled={isSubmitting}>
                  {t('AUTH.SendRequest')}
                </Button>
              </Toolbar>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' className={classes.forgotPassword}>
                <Link to={paths.LoginPage}>
                  {t('AUTH.BackToLOGIN')}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Form>
      </Grid>
      {/* <Grid item md={8} sm={8} xs={8} className={classes.background}>
        <img src={Image} alt=""/>
      </Grid> */}
    </Grid>
  )
}

const mapState = ({ userReducer }) => ({
  isSubmitting: userReducer.isRequestingChangePassword
})

const mapDispatch = dispatch => bindActionCreators(
  {
    requestResetPassword
  },
  dispatch
)

ForgotPassword.propTypes = {
  className: PropTypes.string,
}

ForgotPassword.defaultProps = {
}

export default  Form.create({
  name: 'forgotPassword'
})(withRouter(connect(mapState, mapDispatch)(withTranslation('common')(ForgotPassword))))
import React, { useEffect, useMemo, useState, useCallback, forwardRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, TextField, Button, RadioGroup, FormControlLabel,
  Toolbar, Chip, FormControl, Input, InputAdornment, IconButton, Radio, TableRow,
  TableCell, TableContainer, Table, TableHead, TableBody
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import AddOutlined from '@material-ui/icons/AddOutlined'
import Editor from '../../../components/RichEditor'
import { Input as AntdInput, Form, Select } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { CURRENCY_MAPPING } from '../../../helpers/constant'
import { createNewCatering, getListAllCaterings } from '../../../actions/caterings'
import LoadingIcon from 'mdi-react/LoadingIcon'
import {withTranslation} from 'react-i18next'
//Room imge
import Image1 from '../../../assets/images/Kopenhagen_1-2.jpg'
import Image2 from '../../../assets/images/Raum_19_Coaching.jpg'
import Image3 from '../../../assets/images/Stockholm_1.jpg'

import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'

const useStyles = makeStyles((theme) => ({
  formField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  roomInfoContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
    // '& .MuiGrid-item ': {
    //   padding: '15px'
    // }
  },
  roomCombinedContent: {
    display: 'block',
    minHeight: '225px',
    borderLeft: '1px solid #ddd'
  },
  chip: {
    minWidth: '120px',
    margin: '5px',
    justifyContent: 'space-between'
  },
  chip2: {
    padding: '5px 5px',
    margin: '5px',
  },
  addRoomCombinedIcon: {
    border: '1px solid',
    borderColor: theme.colorAccent,
    borderRadius: '50%',
    color: theme.colorAccent,
    width: '18px',
    height: '18px',
  },
  addRoomCombinedContent: {
    width: '100%',
    '& .ant-select-selection': {
      border: 'unset',
      borderBottom: '1px solid #d9d9d9'
    }
  },
  padding15px: {
    padding: '15px'
  },
  roomCategoryLabelContent: {
    padding: '10px 0'
  },
  descriptionLabel: {
    padding: '10px 0'
  },
  addMoreRow: {
    '& .MuiTableCell-root': {
      color: 'rgba(162, 162, 162, 0.87)',
    },
    '& .MuiInput-underline:before': {
      // border: 'unset'
    }
  },
  addMoreRowButtonContent: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      width: '80px'
    }
  },
  roomImagesContent: {
    margin: '20px 0'
  },
  roomImagesUploadContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
  },
  formSelectField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '200px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  roomsSelectedContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',

  },
  createNewTypeButton: {
    color: theme.colorAccent
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  roomListContent: {
    margin: '20px 0'
  },
  roomListButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  editViewTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorAccent,
    paddingBottom: '10px',
    borderBottom: `2px solid ${theme.colorAccent}`,
    marginBottom: '20px',
    marginTop: '30px',
  },
  addMoreRow: {
    '& .MuiTableCell-root': {
      color: 'rgba(162, 162, 162, 0.87)',
    },
    '& .MuiInput-underline:before': {
      border: 'unset'
    }
  },
  addMoreRowButtonContent: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      width: '80px'
    }
  },
  formContent: {
    '& .has-error': {
      '& .MuiInput-underline:before': {
        borderColor: '#f5222d',
        '&::before': {
          borderColor: '#f5222d'
        }
      }
    }
  }
}))

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const NewService = props => {
  const { editView, roomType, configurationInfo, form: { getFieldDecorator, validateFields }, 
  getListAllCaterings, listCaterings, isGettingCaterings, history, isCreatingCatering, createNewCatering,
  t, i18n
  } = props
  const classes = useStyles()

  useEffect(() => {
    if (!listCaterings.length) {
      getListAllCaterings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const [tableData, setTableData] = React.useState({
    columns: [
      { title: t('SERVICES.MinQuantity'), field: 'min_quantity' },
      { title: t('SERVICES.Price'), field: 'price' },
    ],
    data: [
      { hour: '1 hour', price: '$2.00' },
      { hour: '4 hour', price: '$5.00' },
      { hour: '8 hour', price: '$8.00' },
    ],
  })

  const [radioValue, setRadioValue] = useState(false)

  const handleChangeRadio = event => {
    setRadioValue(event.target.value === 'true')
  }

  const [listPrice, setListPrice] = useState([{}])

  const addEditableRowItem = () => {
    setListPrice([...listPrice, {}])
  }

  const listAvailableCaterings = listCaterings.filter(item => !item.is_combo)
  const [priceError, setPriceError] = useState(false)
  const handleSubmit = async e => {
    e.preventDefault()
    await validateFields(async (errors, values) => {
      if (!errors) {
        let priceExist = false
        await listPrice.map((item, index) => {
          if (values[`min${index}`] && values[`price${index}`]) {
            priceExist = true
          }
        })
        if (!priceExist) {
          setPriceError(true)
        } else {
          const data = {
            name: values.name,
            description: values.description,
            is_combo: radioValue,
            pricings: listPrice.map((item, index) => {
              if (values[`min${index}`] && values[`price${index}`]) {
                return {
                  price: Number(values[`price${index}`]),
                  min_quantity: Number(values[`min${index}`])
                }
              }
            }),
            caterings: values.caterings
          }
          console.log(data)
          createNewCatering(data)
        }
      }
    })
  }
  return (
    <Container>
      {(isGettingCaterings || isCreatingCatering) ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container >
        {editView &&
          <Grid item xs={12} md={12} >
            <Typography variant='h5' component="div"  >
              <Link onClick={() => history.goBack()} className={classes.editViewTitle} >
                <ChevronLeftIcon /> {t('SERVICE.EditCatering')} {roomType}
              </Link>
            </Typography>
          </Grid>
        }
        <Grid item xs={12} md={12}>
          <Form
            onSubmit={handleSubmit}
            className={classes.formContent}
          >
            <Grid container>
              <Grid item xs={2} md={3}>
                <Typography variant='h6'>{t('SERVICES.ServiceInfo')}</Typography>
              </Grid>
              <Grid item xs={10} md={9} className={classes.roomInfoContent}>
                <Grid container>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Typography>{t('SERVICES.Name')}</Typography>
                    <Form.Item>
                      {
                        getFieldDecorator('name', {
                          rules: [{ required: true, message: t('SERVICES.RequireName') }]
                        })(
                          <TextField
                            className={classes.formField}
                            // id="outlined-error"
                            label=""
                          // variant="outlined"
                          />
                        )
                      }
                    </Form.Item>

                  </Grid>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    {/* <Typography>ID</Typography>
                    <TextField
                      className={classes.formField}
                      // id="outlined-error"
                      label=""
                    // variant="outlined"
                    /> */}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12} className={classes.padding15px}>
                    <RadioGroup aria-label="gender" name="gender2" value={radioValue} onChange={handleChangeRadio}>
                      <Grid container >
                        <Grid item xs={3} md={3} className={classes.roomCategoryLabelContent}>
                          <Typography>{t('SERVICES.RoomCategory')}</Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label="Single"
                            labelPlacement="start"
                          // onChange={() => changeComboType(false)}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <FormControlLabel
                            value={true}
                            control={<Radio color="primary" />}
                            label="Combined"
                            labelPlacement="start"
                          // onChange={() => changeComboType(true)}
                          />
                          {radioValue && <Toolbar className={classes.roomCombinedContent}>
                            <Typography variant="caption" component="h6">{t('SERVICES.ThisComboIncludes')}</Typography>
                            
                            <Form.Item className={clsx(classes.addRoomCombinedContent)}>
                              {/* <InputLabel htmlFor="standard-adornment-password">Password</InputLabel> */}
                              {
                                getFieldDecorator('caterings', {

                                })(
                                  <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                  // endAdornment={
                                  //   <InputAdornment position="end">
                                  //     <IconButton
                                  //       aria-label="toggle password visibility"
                                  //     // onClick={handleClickShowPassword}
                                  //     // onMouseDown={handleMouseDownPassword}
                                  //     >
                                  //       <AddOutlined className={classes.addRoomCombinedIcon} />
                                  //     </IconButton>
                                  //   </InputAdornment>
                                  // }
                                  >
                                    {listAvailableCaterings.map((item, index) => {
                                      return (
                                        <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                      )
                                    })}
                                  </Select>

                                )
                              }
                            </Form.Item>
                          </Toolbar>
                          }
                        </Grid>
                      </Grid>
                    </RadioGroup >
                  </Grid>

                  <Grid item md={12} className={classes.padding15px}>
                    <Typography className={classes.descriptionLabel}>{t('SERVICES.Description')}</Typography>
                    <Form.Item>
                      {
                        getFieldDecorator('description', {
                          // rules: [{required: true, message: 'DE is required'}]
                        })(
                          <AntdInput.TextArea rows={4} />
                        )
                      }
                    </Form.Item>

                  </Grid>
                  <Grid item md={6} className={classes.padding15px}>
                    <Grid container>
                      <TableContainer >
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {tableData.columns.map((item, index) => {
                                return (
                                  <TableCell key={index}>{item.title}</TableCell>
                                )
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {listPrice.map((item, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell align="left">
                                    <Form.Item>
                                      {
                                        getFieldDecorator(`min${index}`, {
                                          getValueFromEvent: e => {
                                            setPriceError(false)
                                            console.log(Number(e.target.value))
                                            if(Number(e.target.value) >= 0) {
                                              return e.target.value
                                            } else {
                                              return 0
                                            }
                                          }
                                        })(
                                          <Input type="number" min="0" />
                                        )
                                      }
                                    </Form.Item>

                                  </TableCell>
                                  <TableCell align="left" className={classes.addMoreRowButtonContent}>
                                    <Form.Item>
                                      {
                                        getFieldDecorator(`price${index}`, {
                                          getValueFromEvent: e => {
                                            setPriceError(false)
                                            if(Number(e.target.value) >=0) {
                                              return e.target.value
                                            } else {
                                              return 0
                                            }
                                          }
                                        })(
                                          <Input type="number" min="0" startAdornment={`${CURRENCY_MAPPING[configurationInfo.currency]} `} />
                                        )
                                      }
                                    </Form.Item>
                                    {index === listPrice.length - 1 &&
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => addEditableRowItem()}
                                      // onMouseDown={handleMouseDownPassword}
                                      >
                                        <AddOutlined className={classes.addRoomCombinedIcon} />
                                      </IconButton>
                                    }
                                  </TableCell>
                                </TableRow>

                              )
                            })}

                          </TableBody>
                        </Table>
                      </TableContainer>
                      {priceError && <em style={{ color: 'red' }}>{t('SERVICES.RequirePrice')}</em>}
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.footerContent}>
                <Button
                  onClick={() => history.goBack()}
                  className={classes.backButton}
                  variant="contained" color="default"
                  disableElevation
                >
                  {t('SERVICES.Discard')}
                </Button>
                <Button variant="contained" color="primary" disableElevation
                  type='submit'
                >
                  {t('SERVICES.Publish')}
                </Button>
              </div>
            </Grid>

          </Form>
        </Grid>

      </Grid>
    </Container >
  )
}


const mapState = ({ cateringsReducer, publicReducer }) => ({
  isCreatingCatering: cateringsReducer.isCreatingCatering,
  configurationInfo: publicReducer.configurationInfo,
  listCaterings: cateringsReducer.listCaterings,
  isGettingCaterings: cateringsReducer.isGettingCaterings,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    createNewCatering,
    getListAllCaterings
  },
  dispatch
)

NewService.propTypes = {
  editView: PropTypes.bool,
  roomType: PropTypes.string
}

NewService.defaultProps = {
  editView: false,
  roomType: ''
}

export default connect(mapState, mapDispatch)(Form.create({
  name: 'addCatering'
})(withTranslation('common') (withRouter(NewService))))



{/* <Grid container className={classes.roomImagesContent}>
          <Grid item xs={2} md={3}>
            <Typography variant='h6'>Thumbnail</Typography>
            <Typography variant='caption'>800x600 (px) Max 1Mb</Typography>
          </Grid>
          <Grid item xs={10} md={9} className={classes.roomImagesUploadContent}>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Grid>
        </Grid>
      */}
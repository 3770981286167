import {
  LIST_PERM_FACILITIES,
  LIST_PERM_FACILITIES_SUCCESS,
  LIST_PERM_FACILITIES_FAIL,
  CREATE_ROOM,
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_FAIL,
  UPDATE_ROOM,
  UPDATE_ROOM_SUCCESS,
  UPDATE_ROOM_FAIL,
  LIST_ROOMS,
  LIST_ROOMS_SUCCESS,
  LIST_ROOMS_FAIL,
  GET_ROOM,
  GET_ROOM_SUCCESS,
  GET_ROOM_FAIL,
  GET_ROOM_AVAIL_ANALYTICS,
  GET_ROOM_AVAIL_ANALYTICS_SUCCESS,
  GET_ROOM_AVAIL_ANALYTICS_FAIL,
  GET_ROOM_BOOKING_ANALYTICS,
  GET_ROOM_BOOKING_ANALYTICS_SUCCESS,
  GET_ROOM_BOOKING_ANALYTICS_FAIL,
  LIST_ROOM_TYPES,
  LIST_ROOM_TYPES_SUCCESS,
  LIST_ROOM_TYPES_FAIL,
  CREATE_ROOM_TYPE,
  CREATE_ROOM_TYPE_SUCCESS,
  CREATE_ROOM_TYPE_FAIL,
  UPDATE_ROOM_TYPE,
  UPDATE_ROOM_TYPE_SUCCESS,
  UPDATE_ROOM_TYPE_FAIL,
  GET_ROOM_TYPE,
  GET_ROOM_TYPE_SUCCESS,
  GET_ROOM_TYPE_FAIL,
  LIST_ROOMS_READY_TO_ADD,
  LIST_ROOMS_READY_TO_ADD_SUCCESS,
  LIST_ROOMS_READY_TO_ADD_FAIL,
} from '../actionTypes/room.actionTypes'


export function createRoom(data) {
  return ({type: CREATE_ROOM, data})
}

export function createRoomSuccess(data) {
  return ({type: CREATE_ROOM_SUCCESS, data})
}

export function createRoomFail(error) {
  return ({type: CREATE_ROOM_FAIL, error})
}

export function updateRoom(id, data) {
  return ({type: UPDATE_ROOM, data: {id, ...data}})
}

export function updateRoomSuccess(data) {
  return ({type: UPDATE_ROOM_SUCCESS, data})
}

export function updateRoomFail(error) {
  return ({type: UPDATE_ROOM_FAIL, error})
}

export function getRoom(data) {
  return ({type: GET_ROOM, data})
}

export function clearRoomInfo() {
  return ({type: GET_ROOM_SUCCESS, data: null})
}

export function getRoomSuccess(data) {
  return ({type: GET_ROOM_SUCCESS, data})
}

export function getRoomFail(error) {
  return ({type: GET_ROOM_FAIL, error})
}

export function listRooms(data) {
  return ({type: LIST_ROOMS, data})
}

export function listRoomsSuccess(data) {
  return ({type: LIST_ROOMS_SUCCESS, data})
}

export function listRoomsFail(error) {
  return ({type: LIST_ROOMS_FAIL, error})
}

export function getRoomBookingAnalytics(data) {
  return ({type: GET_ROOM_BOOKING_ANALYTICS, data})
}

export function getRoomBookingAnalyticsSuccess(data) {
  return ({type: GET_ROOM_BOOKING_ANALYTICS_SUCCESS, data})
}

export function getRoomBookingAnalyticsFail(data) {
  return ({type: GET_ROOM_BOOKING_ANALYTICS_FAIL, data})
}

export function getRoomAvailAnalytics(data) {
  return ({type: GET_ROOM_AVAIL_ANALYTICS, data})
}

export function getRoomAvailAnalyticsSuccess(data) {
  return ({type: GET_ROOM_AVAIL_ANALYTICS_SUCCESS, data})
}

export function getRoomAvailAnalyticsFail(data) {
  return ({type: GET_ROOM_AVAIL_ANALYTICS_FAIL, data})
}

// Permanent facilities
export function listPermFacs(data) {
  return ({type: LIST_PERM_FACILITIES, data})
}

export function listPermFacsSuccess(data) {
  return ({type: LIST_PERM_FACILITIES_SUCCESS, data})
}

export function listPermFacsFail(error) {
  return ({type: LIST_PERM_FACILITIES_FAIL, error})
}

// room types
export function listRoomTypes(data) {
  return ({type: LIST_ROOM_TYPES, data})
}

export function listRoomTypesSuccess(data) {
  return ({type: LIST_ROOM_TYPES_SUCCESS, data})
}

export function listRoomTypesFail(error) {
  return ({type: LIST_ROOM_TYPES_FAIL, error})
}

export function createRoomType(data) {
  return ({type: CREATE_ROOM_TYPE, data})
}

export function createRoomTypeSuccess(data) {
  return ({type: CREATE_ROOM_TYPE_SUCCESS, data})
}

export function createRoomTypeFail(error) {
  return ({type: CREATE_ROOM_TYPE_FAIL, error})
}

export function updateRoomType(id, data) {
  return ({type: UPDATE_ROOM_TYPE, data: {id, ...data}})
}

export function updateRoomTypeSuccess(data) {
  return ({type: UPDATE_ROOM_TYPE_SUCCESS, data})
}

export function updateRoomTypeFail(error) {
  return ({type: UPDATE_ROOM_TYPE_FAIL, error})
}

export function getRoomType(data) {
  return ({type: GET_ROOM_TYPE, data})
}

export function clearRoomTypeInfo() {
  return ({type: GET_ROOM_TYPE_SUCCESS, data: null})
}

export function getRoomTypeSuccess(data) {
  return ({type: GET_ROOM_TYPE_SUCCESS, data})
}

export function getRoomTypeFail(error) {
  return ({type: GET_ROOM_TYPE_FAIL, error})
}

export function listRoomsReadyToAdd(data) {
  return ({type: LIST_ROOMS_READY_TO_ADD, data})
}

export function listRoomsReadyToAddSuccess(data) {
  return ({type: LIST_ROOMS_READY_TO_ADD_SUCCESS, data})
}

export function listRoomsReadyToAddFail(error) {
  return ({type: LIST_ROOMS_READY_TO_ADD_FAIL, error})
}

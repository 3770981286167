import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Detail from '../../components/caterings/Detail'
import * as actions from '../../actions/caterings/detail'
import * as selectors from '../../selectors/caterings/detail'


class CateringDetail extends Component {
  componentDidMount() {
    let { match: { params: { id } } } = this.props
    this.props.retrieveDetail(id)
  }

  render() {

    return (
      <Detail {...this.props} />
    )
  }
}


const mapState = (state) => ({
  isFetching: selectors.isFetching(state),
  name: selectors.getName(state),
  description: selectors.getDescription(state),
  typeTag: selectors.getTypeTag(state),
  activeTag: selectors.getActiveTag(state),
  pricings: selectors.getPricings(state),
  minParticipants: selectors.getMinParticipants(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveDetail: actions.retrieveCatering,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(CateringDetail))

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RoomTypeDetailComponent from './components/RoomTypeDetail'

class RoomTypeDetail extends Component {
  render() {
    return (
      <div>
        <RoomTypeDetailComponent />
      </div>
    )
  }
}

RoomTypeDetail.propTypes = {

}

export default RoomTypeDetail
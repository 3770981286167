import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import New from '../../components/users/New'
import * as actions from '../../actions/users/new'
import * as selectors from '../../selectors/users/new'


class UserNew extends Component {
  componentDidMount() {
    let { match: { params: { detailType } } } = this.props
  }

  render() {

    return (
      <New {...this.props} />
    )
  }
}


const mapState = (state) => ({
  isSubmitting: selectors.isSubmitting(state)
})

const mapDispatch = dispatch => bindActionCreators(
  {
    onSubmit: actions.submit,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(UserNew))

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RoomDetailComponent from './components/RoomDetail'
class RoomDetail extends Component {
  render() {
    return (
      <div>
        <RoomDetailComponent />
      </div>
    )
  }
}

RoomDetail.propTypes = {

}

export default RoomDetail
export const HomePage = '/'
export const LoginPage = '/login'
export const RegisterPage = '/register'
export const ForgotPasswordPage = '/forgotPassword'
export const Verifypage = '/verify'
export const ResetPasswordPage = '/resetPassword'
export const ChangePassword = '/accountSetting'

// Logined

export const Main = '/main' // root url

// Module Dashboard
export const Dashboard = Main + '/main'

// Module booking
export const BookingPage = Main + '/booking'
export const CreateNewBooking = BookingPage + '/create'
export const CreateNewBooking_CreateCustomer = CreateNewBooking + '/create-customer'
export const CreateNewBooking_FoundRoom = CreateNewBooking + '/found'
export const CreateNewBooking_SelectCustomer = CreateNewBooking + '/select-customer'
export const CreateNewBooking_AddBookingDetail = CreateNewBooking + '/add-detail'
export const CreateNewBooking_NewBookingCreated = CreateNewBooking + '/booking-created'
export const CreateNewBooking_Payment = CreateNewBooking + '/payment'
export const CreateNewBooking_BookingConfirm = CreateNewBooking + '/booking-confirm'
export const CreateNewBooking_Completed = CreateNewBooking + '/completed'
export const ViewAllBooking = BookingPage + '/view-all'
export const BookingDetail = ViewAllBooking + '/detail/:id'
export const AvailableRooms = BookingPage + '/available-room'
export const BookingCalendar = BookingPage + '/booking-calendar'
export const BookingTable = BookingPage + '/booking-table/:date'
//Module Profile
export const ProfilePage = Main + '/profile'

//Module Room
export const RoomPage = Main + '/room'
export const NewRoom = RoomPage + '/new-room'
export const UpdateRoom = RoomPage + '/edit/:id'
export const ListRooms = RoomPage + '/list-rooms'
export const ROOM_DETAIL = `${ListRooms}/detail/room/:id`
export const ROOM_TYPE_DETAIL = `${ListRooms}/detail/room-type/:id`
export const RoomDetail = RoomPage + '/detail/:id'

export const RoomTypePage = Main + '/room-type'
export const ListRoomTypes = RoomTypePage
export const NewRoomTypes = RoomTypePage + '/new'
export const UpdateRoomType = RoomTypePage + '/edit/:id'
export const ViewRoomTypeDetail = RoomTypePage + '/detail/:id'

// Module Users
export const UserPage = Main + '/user'
export const UpdateUser = UserPage + '/edit/:id'
export const NewUser = UserPage + '/new-user'
export const ListUser = UserPage + '/list-users'
export const UserDetail = UserPage + '/detail/:id'
// Module Falicities
export const FacilitiesPage = Main + '/facilities'
export const ListCategory = FacilitiesPage + '/category'
export const NewFacility = FacilitiesPage + '/new-facility'
export const ListFacilities = FacilitiesPage + '/list-facilities'

// Service
export const ServicePage = Main + '/service'
export const NewService = ServicePage + '/new-service'
export const CateringService = ServicePage + '/catering-service'

// Module Discounts
export const DiscountPage = Main + '/discount'
export const ListDiscounts = DiscountPage + '/list-discounts'
export const NewDiscount = DiscountPage + '/new-discount'
import React, { useEffect, useMemo, useState, useCallback, forwardRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, Input, Button, IconButton, FormControl, FormHelperText
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import AddOutlined from '@material-ui/icons/AddOutlined'
import Editor from '../../../components/RichEditor'
import { Upload, Icon, Modal, Input as AntdInput, Form } from 'antd'
import { Link } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { history } from '../../../config/store'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { cloneDeep } from 'lodash'
//Room imge
import Image1 from '../../../assets/images/Kopenhagen_1-2.jpg'
import Image2 from '../../../assets/images/Raum_19_Coaching.jpg'
import Image3 from '../../../assets/images/Stockholm_1.jpg'

import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import LoadingIcon from 'mdi-react/LoadingIcon'
import { bindActionCreators } from 'redux'
import { createNewFacilityCategory } from '../../../actions/facilities'
import { CURRENCY_MAPPING } from '../../../helpers/constant'
import {withTranslation} from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  formField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  roomInfoContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
    // '& .MuiGrid-item ': {
    //   padding: '15px'
    // }
  },
  roomCombinedContent: {
    display: 'block',
    minHeight: '225px',
    borderLeft: '1px solid #ddd'
  },
  chip: {
    minWidth: '120px',
    margin: '5px',
    justifyContent: 'space-between'
  },
  chip2: {
    padding: '5px 5px',
    margin: '5px',
  },
  addRoomCombinedIcon: {
    border: '1px solid',
    borderColor: theme.colorAccent,
    borderRadius: '50%',
    color: theme.colorAccent,
    width: '18px',
    height: '18px',
  },
  addRoomCombinedContent: {
    width: '100%'
  },
  padding15px: {
    padding: '15px'
  },
  roomCategoryLabelContent: {
    padding: '10px 0'
  },
  descriptionLabel: {
    padding: '10px 0'
  },
  addMoreRow: {
    '& .MuiTableCell-root': {
      color: 'rgba(162, 162, 162, 0.87)',
    },
    '& .MuiInput-underline:before': {
      border: 'unset'
    }
  },
  addMoreRowButtonContent: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      width: '80px'
    }
  },
  roomImagesContent: {
    margin: '20px 0'
  },
  roomImagesUploadContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
  },
  formControl: {
    width: '100%'
  },
  formSelectField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '200px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  roomsSelectedContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',

  },
  createNewTypeButton: {
    color: theme.colorAccent
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  roomListContent: {
    margin: '20px 0'
  },
  roomListButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  editViewTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorAccent,
    paddingBottom: '10px',
    borderBottom: `2px solid ${theme.colorAccent}`,
    marginBottom: '20px',
    marginTop: '30px',
  },
  formContent: {
    '& .has-error': {
      '& .MuiInput-underline:before': {
        borderColor: '#f5222d',
        '&::before': {
          borderColor: '#f5222d'
        }
      }
    }
  }
}))

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const NewFacility = props => {
  const { editView, roomType, configurationInfo, form: {getFieldDecorator, validateFields}, createNewFacilityCategory,
  isCreatingFacilityCategory, t
  } = props
  const classes = useStyles()

  const [tableData, setTableData] = React.useState({
    columns: [
      { title: 'Block Hours', field: 'hour' },
      { title: 'Price', field: 'price' },
    ],
    data: [
      { hour: '1 hour', price: '$2.00' },
      { hour: '4 hour', price: '$5.00' },
      { hour: '8 hour', price: '$8.00' },
    ],
  })

  const [listPrice, setListPrice] = useState([
    { slots: 5, apply_range_int: 15872, apply_range: '11111000000000', block: 'Morning', price: 0 },
    { slots: 5, apply_range_int: 496, apply_range: '00000111110000', block: 'Afternoon', price: 0 },
    { slots: 10, apply_range_int: 16383, apply_range: '11111111111111', block: 'All', price: 0 }
  ])

  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if(!errors) {
        const listPricings = listPrice.map(item => ({...item, price: Number(values[item.apply_range]) * 100 }))
        const data = {
          name: values.name,
          description: values.description,
          pricings: listPricings,
          quantity: Number(values.quantity)
        }
        console.log(data)
        createNewFacilityCategory(data)
        
      }
    })
  }

  return (
    <Container>
      {isCreatingFacilityCategory ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Form
        onSubmit={handleSubmit}
        className={classes.formContent}
      >
        <Grid container >
          {editView &&
            <Grid item xs={12} md={12} >
              <Typography variant='h5' component="div"  >
                <Link onClick={() => history.goBack()} className={classes.editViewTitle} >
                  <ChevronLeftIcon /> {t('FACILITIES.EditFacility')} {roomType}
                </Link>
              </Typography>
            </Grid>
          }
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={2} md={3}>
                <Typography variant='h6'>{t('BOOKING.FacitityInfo')}</Typography>
              </Grid>
              <Grid item xs={10} md={9} className={classes.roomInfoContent}>
                <Grid container>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Typography>{t('FACILITIES.Name')}</Typography>
                    <Form.Item>
                      {
                        getFieldDecorator('name', {
                          rules: [{required: true, message: t('FACILITIES.RequireName')}]
                        })(
                          <Input
                            className={classes.formField}
                          />
                        )
                      }
                    </Form.Item>
                  </Grid>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Typography>{t('FACILITIES.Quantity')}</Typography>
                    <Form.Item>
                      {
                        getFieldDecorator('quantity', {
                          rules: [{required: true, message: t('FACILITIES.RequireQuantity')}]
                        })(
                          <Input
                            type="number"
                            className={classes.formField}
                          />
                        )
                      }
                    </Form.Item>
                    
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12} className={classes.padding15px}>
                    <Form.Item>
                      {
                        getFieldDecorator('description', {
                          // rules: [{required: true, message: 'Description is required'}]
                        })(
                          <AntdInput.TextArea
                            rows={4}
                          />
                        )
                      }
                    </Form.Item>
                    <Typography className={classes.descriptionLabel}>{t('FACILITIES.Description')}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Typography>{t('FACILITIES.PriceList')}</Typography>
                    <TableContainer >
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {tableData.columns.map((item, index) => {
                              return (
                                <TableCell key={index}>{item.title}</TableCell>
                              )
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listPrice.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell align="left">{item.block}</TableCell>
                                <TableCell align="left">
                                  <Form.Item>
                                    {
                                      getFieldDecorator(item.apply_range, {
                                        rules: [{required: true, message: t('FACILITIES.RequirePrice')}],
                                        getValueFromEvent: e => { 
                                          if(Number(e.target.value) >=0) {
                                            return e.target.value
                                          } else {
                                            return 0
                                          }
                                        }
                                      })(
                                        <Input
                                          type='number'
                                          min="0"
                                          step="0.01"
                                          startAdornment={`${CURRENCY_MAPPING[configurationInfo.currency]} `}
                                        />
                                      )
                                    }
                                  </Form.Item>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                          {/* <TableRow className={classes.addMoreRow}>
                            <TableCell align="left">
                              <Input
                                label="Click to change"
                                value={rowEditable.hour}
                                onChange={(e) => setRowEditable({ ...rowEditable, hour: e.target.value })}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.addMoreRowButtonContent}>
                              <Input
                                label="$0.00"
                                value={rowEditable.price}
                                onChange={(e) => setRowEditable({ ...rowEditable, price: e.target.value })}
                              />
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => addEditableRowItem()}
                              // onMouseDown={handleMouseDownPassword}
                              >
                                <AddOutlined className={classes.addRoomCombinedIcon} />
                              </IconButton>
                            </TableCell>
                          </TableRow> */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container className={classes.roomImagesContent}>
            <Grid item xs={2} md={3}>
              <Typography variant='h6'>Thumbnail</Typography>
              <Typography variant='caption'>800x600 (px) Max 1Mb</Typography>
            </Grid>
            <Grid item xs={10} md={9} className={classes.roomImagesUploadContent}>
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </Grid>
          </Grid>
        */}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.footerContent}>
            <Button
              onClick={() => history.goBack()}
              className={classes.backButton}
              variant="contained" color="default"
              disableElevation
            >
              {t('FACILITIES.Discard')}
            </Button>
            <Button variant="contained" color="primary" disableElevation
              type='submit'
            >
              {t('FACILITIES.Publish')}
            </Button>
          </div>
        </Grid>
      </Form>
    </Container >
  )
}

const mapState = ({ facilitiesReducer, publicReducer }) => ({
  isCreatingFacilityCategory: facilitiesReducer.isCreatingFacilityCategory,
  configurationInfo: publicReducer.configurationInfo

})

const mapDispatch = dispatch => bindActionCreators(
  {
    createNewFacilityCategory
  },
  dispatch
)

NewFacility.propTypes = {
  editView: PropTypes.bool,
  roomType: PropTypes.string,
  configurationInfo: PropTypes.object,
}

NewFacility.defaultProps = {
  editView: false,
  roomType: ''
}

export default connect(mapState, mapDispatch)(Form.create({
  name: 'facility'
}) ( withTranslation('common') (NewFacility)))
import HttpClient from '../../config/httpClient'
import PublicApi from './public'
import AuthApi from './auth'
import UserApi from './user'
import BookingApi from './booking'
import RoomApi from './room'
import AnalyticsApi from './analytics'
import FacilitiesApi from './facilities'
import CateringsApi from './caterings'
import DiscountApi from './discount'
import ImageApi from './image'


export default class Api {
  constructor() {
    this.http = new HttpClient('')
    this.public = new PublicApi(this.http)
    this.auth = new AuthApi(this.http)
    this.user = new UserApi(this.http)
    this.booking = new BookingApi(this.http)
    this.room = new RoomApi(this.http)
    this.analytics = new AnalyticsApi(this.http)
    this.facilities = new FacilitiesApi(this.http)
    this.caterings = new CateringsApi(this.http)
    this.discount = new DiscountApi(this.http)
    this.image = new ImageApi(this.http)
  }
}

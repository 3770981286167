import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {get, replace} from 'lodash'
import {history} from '../../../config/store'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography, Button, IconButton, Toolbar } from '@material-ui/core'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as selectos from '../../../selectors/user'
import { Form, Input } from 'antd'
import {changePassword} from '../../../actions/user'
import { withTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    backgroundColor: '#840810'
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  formContent: {
    justifyContent: 'center',
    marginBottom: '20px'
  },
  formRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
      '& .MuiInputBase-input': {
        padding: '10.5px 14px'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ddd !important',
        color: '#ddd !important'
      }
    },
  },
  formField: {
    borderRadius: '15px',
    margin: '8px 0',
    minWidth: '150px',
    width: '90%',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '15px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '15px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
    '& .Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0
      }
    }
  },
  justifyContentCenter: {
    justifyContent: 'center'
  },
  pageTitle: {
    color: 'white',
    padding: '15px 0'
  },
  searchTitle: {
    margin: 'auto 0'
  },
  searchContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {

  },
  searchButton: {
    margin: '8px'
  },
  subTitle: {
    borderBottom: '1px solid',
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between'
  },
  actionsContent: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    '& svg': {
      width: '18px',
      height: '18px',
    }
  },
  container: {
    maxWidth: '75%',
  },
  buttonGroup: {
    marginBottom: '50px'
  },
  confirmChangeButton: {
    marginRight: '30px'
  },
  formLabel: {
    alignItems: 'center',
    display: 'flex',
  },
  changeInfoButtonGroup: {
    textAlign: 'center'
  }
}))

const ChangePassword = props => {
  const classes = useStyles()
  const { t, changePassword } = props
  const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form

  const handleSubmit = async(e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        changePassword(values)
      }
    })
  }
  return (
    <Toolbar component='nav' variant='dense' className={classes.root}>
      <Grid container className={classes.justifyContentCenter}>
        <Grid item xs={12} >
          <Typography variant='h6' align="left" className={classes.subTitle} component="div" >
            <span>{t('USERS.ChangePassword')}</span>
          </Typography>
        </Grid>
        <Form 
          initialValues={{
            new_password: '',
            re_new_password: '',
            current_password: ''
          }}
          onSubmit={handleSubmit} 
          className={classes.formRoot}>
          <Grid container>
            <Grid item xs={12} className={classes.formContent}>
              <Grid item xs={12} className={classes.formValue}>
                <Form.Item 
                  name="new_password"
                  label={t('USERS.NewPassword')}
                  hasFeedback
                >
                  {getFieldDecorator('new_password', {  
                    rules: [
                      { 
                        required: true,
                        message: 'Please input your password!',
                      }
                    ],
                    initialValue: ''
                  }) (<Input.Password />)}
                </Form.Item>
              </Grid>
              <Grid item xs={12} className={classes.formValue}>
                <Form.Item
                  name="re_new_password"
                  label={t('USERS.ConfirmNewPassword')}
                  dependencies={['new_password']}
                  hasFeedback
                >{getFieldDecorator('re_new_password', {  
                  rules: [
                    {
                      required: true,
                      message: 'Please reenter your new password!',
                    },
                    {validator: (rule, value, callback) => {
                        console.log(value)
                        if (!value || getFieldValue('new_password') === value) {
                          return callback();
                        }
                        return callback('The two passwords do not match!');
                    }},
                  ],
                  initialValue: ''
                })(<Input.Password />)}
                </Form.Item>
              </Grid>
              <Grid item xs={12} className={classes.formValue}>
                <Form.Item
                  name="current_password"
                  label={t('USERS.CurrentPassword')}
                >
                  {getFieldDecorator('current_password', {  
                    rules: [
                      { 
                        required: true,
                        message: 'Please enter your current password!',
                      }
                    ],
                    initialValue: ''
                  }) (<Input.Password />)}
                </Form.Item>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" variant="contained" 
                className={classes.confirmChangeButton} type="submit">
                {t('USERS.ChangePassword')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Toolbar>
  )
}

const mapState = ({ userReducer, publicReducer }) => ({
})

const mapDispatch = dispatch => bindActionCreators(
  {
    changePassword
  },
  dispatch
)

ChangePassword.propTypes = {
  className: PropTypes.string,
}

ChangePassword.defaultProps = {
}

export default  Form.create({
  name: 'changePassword'
})(withRouter(connect(mapState, mapDispatch)(withTranslation('common')(ChangePassword))))

import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import * as actions from '../actions/public'
import * as actionTypes from '../actionTypes/public.actionTypes'
import i18n from '../config/i18next'
import {get} from 'lodash'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import {history} from '../config/store'
import * as paths from '../constants/paths.constants'
function* getConfiguration({api}, {data}) {
  try {
    // yield delay(2000)
    const response = yield call(api.public.getConfiguration)
    yield put(actions.getConfigurationSuccess(response))
  } catch (e) {
    yield put(actions.getConfigurationFail())
  }
}

function* getProfile({api}, {data}) {
  try {
    console.log("Getting profile")
    const userInfo = yield call(api.auth.getProfile)
    if(userInfo) {
      yield put(actions.getProfileSuccess(userInfo))
    }
  } catch (e) {
    console.log(e)
    yield put(actions.getProfileFail(e))
  }
}

function* changeLanguage({api}, {data}) {
  try {
    // console.log('call sage')
    yield i18n.changeLanguage(data)
    localStorage.setItem('language', data)
    moment.locale(data === 'en' ? 'en-gb' : 'de')
    yield put(actions.changeLanguageComplete(data))
    if(history.location.pathname === paths.BookingCalendar) {
      window.location.reload()
    }
    // moment.locale(locale === 'zh' ? 'zh-cn' : 'en-ca')
  } catch (e) {
    const currentLocale = yield select(state => get(state, 'public.locale.lang', 'en'))
    yield put(actions.changeLanguageComplete(currentLocale))
  }
}

function publicSaga(deps) {
  return function* saga() {
    yield all([
      takeLatest(actionTypes.GET_CONFIGURATION, getConfiguration, deps),
      takeLatest(actionTypes.GET_PROFILE, getProfile, deps),
      takeLatest(actionTypes.CHANGE_LANGUAGE, changeLanguage, deps),
    ])
  }
}

export default publicSaga
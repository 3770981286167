import React from 'react'
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Grid, Container, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import {history} from '../../../config/store'
import * as paths from '../../../constants/paths.constants'
import {withRouter} from 'react-router-dom'
import AccountPlusOutlineIcon from 'mdi-react/AccountPlusOutlineIcon'
import {withTranslation} from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 0'
  },
  title: {
    padding: '10px 0',
    marginBottom: '20px',
    color: theme.colorAccent,
    width: '100%',
    borderBottom: `2px solid ${theme.colorAccent}`,
    display: 'flex',
    justifyContent: 'space-between'
    
  },
}))

const Header = (props) => {
  const {history, location, t, i18n} = props
  const classes = useStyles()
  const title = {
    [paths.CreateNewBooking]: '',
    [paths.CreateNewBooking_FoundRoom]: t('BOOKING.SelectARoom'),
    [paths.CreateNewBooking_SelectCustomer]: t('BOOKING.PickACustomer'),
    [paths.CreateNewBooking_AddBookingDetail]: null,
    [paths.CreateNewBooking_NewBookingCreated]: '',
    [paths.CreateNewBooking_Payment]: '',
  }
  if(title[history.location.pathname]) {
    return (
      <Container className={classes.container}>
        <Grid container className={classes.root}>
          <Grid item sm={12} xs={12} md={12}>
            <Typography className={classes.title} variant="h4" align="left" component="div">
              <span>{title[history.location.pathname]}</span>
              {history.location.pathname === paths.CreateNewBooking_SelectCustomer && 
                <Button
                  className={classes.addCustomerButton}
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => history.push(paths.CreateNewBooking_CreateCustomer)}
                >
                  <AccountPlusOutlineIcon/>&nbsp;{t('BOOKING.NewCustomer')}
                </Button>
              
              }
              {/* {history.location.pathname === paths.CreateNewBooking_NewBookingCreated ? 'New Booking Created!' : 'Create New Booking'} */}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    )
  }
  return null
}

const mapState = ({router}) => ({
  location: router.location
})

Header.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(connect(mapState) (withTranslation('common') (Header)))
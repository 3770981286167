import React, { useState, useEffect } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography, TextField, Button, IconButton } from '@material-ui/core'
import MaterialTableComponent from '../../../components/Table/MaterialTable'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import LoadingIcon from 'mdi-react/LoadingIcon'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { RemoveRedEyeOutlined } from '@material-ui/icons'
import FacilityDetailModal from './modals/FacilityDetailModal'
import {getFacilities} from '../../../actions/facilities'
import { bindActionCreators } from 'redux'
import {withTranslation} from 'react-i18next'
const useStyles = makeStyles(theme => ({
  root: {},
  formField: {
    borderRadius: '5px',
    width: '40%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  formFilterField: {
    borderRadius: '5px',
    width: '20%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px'
  },
  searchButton: {
    color: theme.colorAccent,
    marginRight: '20px'
  },
  newBookingButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '40px'
  }
}))


const ListFacilities = props => {
  const {history, getFacilities, isGettingFaclities, listFaclities, t} = props
  const classes = useStyles()
  const [openFacilityDetailModal, setOpenFacilityDetailModal] = useState(false)
  const [fetched, setFetched] = useState(false)
  useEffect(() => {
    if(!fetched) {
      getFacilities()
      setFetched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
    { key: 'name', numeric: true, disablePadding: false, label: 'Facility' },
    { key: 'description', numeric: true, disablePadding: false, label: 'Description' },
    { key: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
    {
      key: 'action',
      label: 'Action',
      render: (_, row) => {
        return (
          <>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setOpenFacilityDetailModal(true)}
            // onMouseDown={handleMouseDownPassword}
            >
              <RemoveRedEyeOutlined />
            </IconButton>
          </>
        )
      }
    }
  ]

  

  return (
    <Container>
      {isGettingFaclities ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container>
        <Grid item md={12}>
          <Grid container>
            <Grid item md={8} className={classes.searchContent}>
              {/* <Typography component='div'>Search</Typography>
              <TextField
                // error
                className={classes.formField}
                // id="outlined-error"
                label="Room, Room Types, Facilies"
                variant="outlined"
              />
              <Button className={classes.searchButton} color="default" variant="contained" disableElevation>
                <MagnifyIcon />
              </Button>
              <Typography component='div'>Filter by</Typography>
              <TextField
                // error
                className={classes.formFilterField}
                // id="outlined-error"
                label="Date"
                variant="outlined"
              /> */}
            </Grid>
            <Grid item md={4} className={classes.newBookingButtonContent}>
              <Link to={paths.NewFacility}>
                <Button color="primary" variant="contained" disableElevation>
                  {t('FACILITIES.AddFacility')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <MaterialTableComponent
            title={t('FACILITIES.Facilities')}
            columns={headCells}
            rows={listFaclities}
          />
        </Grid>
      </Grid>
      <FacilityDetailModal 
        isOpen={openFacilityDetailModal}
        closeModal={() => setOpenFacilityDetailModal(false)}
      />
    </Container>
  )
}

const mapState = ({facilitiesReducer}) => ({
  isGettingFaclities: facilitiesReducer.isGettingFaclities,
  listFaclities: facilitiesReducer.listFaclities,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getFacilities
  },
  dispatch
)

ListFacilities.propTypes = {
  className: PropTypes.string,
}

ListFacilities.defaultProps = {
  orders: []
}

export default withRouter(connect(mapState, mapDispatch) ( withTranslation('common') (ListFacilities)))

export const GET_LIST_ALL_CATERINGS = 'GET_LIST_ALL_CATERINGS'
export const GET_LIST_ALL_CATERINGS_SUCCESS = 'GET_LIST_ALL_CATERINGS_SUCCESS'
export const GET_LIST_ALL_CATERINGS_FAIL = 'GET_LIST_ALL_CATERINGS_FAIL'

export const CREATE_NEW_CATERING = 'CREATE_NEW_CATERING'
export const CREATE_NEW_CATERING_SUCCESS = 'CREATE_NEW_CATERING_SUCCESS'
export const CREATE_NEW_CATERING_FAIL = 'CREATE_NEW_CATERING_FAIL'

export const UPDATE_CATERING = 'UPDATE_CATERING'
export const UPDATE_CATERING_SUCCESS = 'UPDATE_CATERING_SUCCESS'
export const UPDATE_CATERING_FAIL = 'UPDATE_CATERING_FAIL'
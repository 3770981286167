import {createSelector} from 'reselect'
import * as _ from 'lodash'
import moment from 'moment'
import {convertNumToMonthName, convert24To12HoursClock, timeslotsToTimeline} from '../helpers/helpers'
import { BOOKING_STATUS } from '../helpers/constant'

const NOT_ACK = "N/A"
const _get_full_name = (user) => `${_.get(user, 'first_name', '')} ${_.get(user, 'last_name', NOT_ACK)}`
const _get_address = (user) => `${_.get(user, 'house_nbr') || ''} ${_.get(user, 'street') || ''}, ${_.get(user, 'city') || ''} ${_.get(user, 'country') || ''} ${_.get(user, 'post_code') || ''}`

export const users = ({userReducer}) => _.get(userReducer, 'listUsers.results', [])
export const userPagination = ({userReducer}) => {
  const {results, ...pagination} = _.get(userReducer, 'listUsers', {})
  return pagination
}

// transform user to user row as table schema
const _transformUserToTableRow = (user) => ({
  id: _.get(user, 'id', NOT_ACK),
  fullname: _get_full_name(user),
  email: _.get(user, 'email', NOT_ACK),
  term: user.is_long_term_customer ? 'Long Term' : 'Short Term',
  status: user.is_active ? 'Active': 'Inactive',
})

export const userRows = createSelector(
  [users],
  (users) => users.map(u => _transformUserToTableRow(u))
)

export const getSortOfUsers = ({userReducer}) => _.get(userReducer, 'filter.filter', 'staff')

// user detail
const _get_user_role = (user) => 
  _.get(user, 'is_admin') ? 'Admin' : _.get(user, 'is_staff') ? 'Staff' :
  _.get(user, 'is_long_term_customer') ? 'Long-Term Customer' : 'Customer'

const _transformToDisplayedUser = (user) => ({
  id: _.get(user, 'id', NOT_ACK),
  fullName: _get_full_name(user),
  email: _.get(user, 'email', NOT_ACK),
  role: _get_user_role(user),
  status: _.get(user, 'is_active') ? 'Active': 'Inactive',
  createdAt: moment(_.get(user, 'created_at')).format('MMM DD YYYY', NOT_ACK),
  company: _.get(user, 'company', NOT_ACK),
  contactPerson: _.get(user, 'contact_person', NOT_ACK),
  address: _get_address(user),
  sortOfUser: (_.get(user, 'is_admin') || _.get(user, 'is_staff') || _.get(user, 'is_supper_user')) ? 'staff' : 'customer'
})

export const isFetchingUserDetail = ({userReducer}) => 
  _.get(userReducer, 'isGettingUserInfo', false) 
  && _.get(userReducer, 'isFetchingUserBookingAnalytics', false)

export const getUserInfo = ({userReducer}) =>_transformToDisplayedUser(_.get(userReducer, 'userInfo'))

const _transformToTotalBookingChart = (bookingAnalyticsResult) => ({
  thisYear: {
    data: bookingAnalyticsResult.map(res => res.count),
    labels: bookingAnalyticsResult.map(res => convertNumToMonthName(res.month)),
  }
})

export const userBookingAnalytics = ({userReducer}) => 
  _transformToTotalBookingChart(_.get(userReducer, 'userAnalytics.results', []))


const _transformToDisplayedBooking = (booking) => ({
  ...booking,
  start: `${convert24To12HoursClock(timeslotsToTimeline(booking.start_slot_int)[0])} ${moment(booking.start_date).format("MMM DD, YYYY")}`,
  end: `${convert24To12HoursClock(timeslotsToTimeline(booking.end_slot_int)[1])} ${moment(booking.end_date).format("MMM DD, YYYY")}`,
  status: BOOKING_STATUS[parseInt(booking.status)],
})
export const getListIncomingBookings = ({bookingReducer}) => _.get(bookingReducer, 'listIncomingBookings', []).map(_transformToDisplayedBooking)
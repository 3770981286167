import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import New from '../../components/rooms/NewRoom'
import * as actions from '../../actions/rooms/new'
import {room as selectors} from '../../selectors/rooms/new'

class NewRoomType extends Component {
  componentDidMount() {
    this.props.onSearchRoomType("")
    this.props.onSearchCombinableRooms("")
    this.props.listPermanentFacilities()
  }

  render() {

    return (
      <New {...this.props} />
    )
  }
}


const mapState = (state) => ({
  isSubmitting: selectors.isSubmitting(state),
  selectableRoomTypes: selectors.selectableRoomTypes(state),
  selectableCombinedRooms: selectors.selectableCombinedRooms(state),
  selectablePermanentFacilities: selectors.selectablePermanentFacilities(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    onSubmit: actions.submitRoom,
    onSearchRoomType: actions.searchRoomTypes,
    onSearchCombinableRooms: actions.searchCombinableRooms,
    listPermanentFacilities: actions.listPermanentFacilities,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(NewRoomType))

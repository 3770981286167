import {createSelector} from 'reselect'
import { DISCOUNT } from '../../constants/models'
import moment from 'moment'
import * as _ from 'lodash'
import * as helpers from '../../helpers/discount'

const { LIST_BY, STATUS } = DISCOUNT

const FMT = 'DD.MM.YYYY'

const getReducer = ({discounts}) => discounts.list

export const getItems = createSelector(
  [getReducer],
  ({items, selectedTab}) => {
    return _.map(items, it => {
      const status = it.is_active? 
        moment(it.end_at) > moment() ? STATUS.ACTIVE : STATUS.EXPIRED : STATUS.INACTIVE
      return {
        ..._.pick(it, ['id', 'name', 'description']),
        startDate: moment(it.start_at).format(FMT),
        endDate: moment(it.end_at).format(FMT),
        discounts: [
          `room: ${it.discount_room}%`, 
          `facility: ${it.discount_exfacility}%`, 
          `catering: ${it.discount_catering}%`],
        status: helpers.getDiscountStatusTag(status)
      }
    })
  }
)

export const getPage = createSelector(
  [getReducer],
  ({page}) => page
)

export const getPageSize = createSelector(
  [getReducer],
  ({pageSize}) => pageSize
)

export const getTotal = createSelector(
  [getReducer],
  ({total}) => total
)

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const getSelectableTabs = () => {
  return [
    { name: "Active", value: LIST_BY.ACTIVE },
    { name: "Inactive", value: LIST_BY.INACTIVE },
  ]
}

export const getSelectedTab = createSelector(
  [getReducer],
  ({selectedTab }) => selectedTab
)

export const generateReqPayload = createSelector(
  [getReducer],
  ({page, pageSize, selectedTab}) => ({
    active: selectedTab == LIST_BY.ACTIVE? "true": "false",
    page, 
    page_size: pageSize,
    ordering: "-created_at"
  })
)

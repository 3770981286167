import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'
import { slots2Timerange } from '../../helpers/timeslot'
import { formatMoney } from '../../helpers/bookings'
import * as helpers from "../../helpers/exfacilities"

const getReducer = ({facilities}) => facilities.detail

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const getName = createSelector(
  [getReducer],
  ({data}) => _.get(data, "name")
)

export const getDescription = createSelector(
  [getReducer],
  ({data}) => _.get(data, "description")
)

export const getQuantity = createSelector(
  [getReducer],
  ({data}) => _.get(data, "quantity")
)

export const getActiveTag = createSelector(
  [getReducer],
  ({data}) => helpers.getActiveTag(_.get(data, "is_active"))
)

export const getPricings = createSelector(
  [getReducer],
  ({data}) => {
    const pricings = _.map(_.get(data, 'pricings', []), pricing => {
      const [start, end] = slots2Timerange(_.get(pricing, 'apply_range_int'))
      return {
        id: pricing.id,
        blockHours: `${_.padStart(start, 2, '0')}:00 - ${_.padEnd(end, 2, '0')}:00`,
        price: formatMoney(_.get(pricing, 'price'))
      }
    })
    return pricings
  }
)

import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch, useParams } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { PageHeader, Button, Pagination, Skeleton } from 'antd'
import { Table, Tag } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import * as PATH from '../../constants/paths.constants'
import * as actions from '../../actions/discounts/detail'
import * as selectors from '../../selectors/discounts/detail'
import * as _ from 'lodash'


const View = props => {
  let { id } = useParams();
  const { t } = props

  const customerColumns = [
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      render: (text) => <div>{text}</div>,
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Customer No'),
      dataIndex: 'customerNo',
      key: 'customerNo',
    },
    {
      title: t('Company'),
      dataIndex: 'company',
      key: 'company',
    },
  ];

  const roomColumns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      render: (text) => <div>{text}</div>,
    },
    {
      title: t('Room Type'),
      dataIndex: 'roomType',
      key: 'roomType',
    },
    {
      title: t('Size'),
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: t('Max Participants'),
      dataIndex: 'maxParticipants',
      key: 'maxParticipants',
    },
  ];

  const {
    isFetching,
    name,
    description,
    applyTo,

    isCustomersVisible,
    isFechingCustomers,
    customers,
    customersPagination,

    isRoomsVisible,
    isFechingRooms,
    rooms,
    roomsPagination,

    discounts,

    startDate,
    endDate,
    isActive,

  } = props

  return (
    <Paper>
      <PageHeader
        onBack={() => window.history.back()}
        title={t("DISCOUNT DETAIL")}
        subTitle={t("Detail about discount")}
        extra={[
          <Link to={`${PATH.ListDiscounts}/edit/${id}`}>
            <Button key="1" type="primary">
              {t("Edit")}
            </Button>
          </Link>,
        ]}
      />

      <div style={{ padding: 24 }}>
        <Grid container justify="center">
          <Grid item container direction="column" xs={8}>
            {isFetching && <Skeleton />}
            {!isFetching &&
              [
                <Grid item container spacing={2}>
                  <Grid item xs={2}>
                    <Typography variant="subtitle1"><b>{t("Name")}</b></Typography>
                  </Grid>
                  <Grid item><Typography variant="subtitle1">{name}</Typography></Grid>
                </Grid>,
                <Grid item container spacing={2}>
                  <Grid item xs={2} >
                    <Typography variant="subtitle1"><b>{t("Description")}</b></Typography>
                  </Grid>
                  <Grid item><Typography variant="subtitle1">{description}</Typography></Grid>
                </Grid>
              ]
            }

            <Divider style={{ marginTop: 32, marginBottom: 32 }} />

            <Grid item container spacing={2}>
              <Grid item xs={2} >
                <Typography variant="subtitle1"><b>{t("Apply To")}</b></Typography>
              </Grid>
              <Grid item><Typography variant="subtitle1">{applyTo}</Typography></Grid>
            </Grid>

            {isFetching && <Skeleton />}

            {!isFetching && isCustomersVisible &&
              <Grid item style={{ marginTop: 16 }}>
                <Table
                  size="small"
                  columns={customerColumns}
                  pagination={false}
                  dataSource={customers}
                  loading={isFechingCustomers}
                />
                <Grid container justify="flex-end" style={{ marginTop: 8 }}>
                  <Grid item>
                    <Pagination
                      current={customersPagination.page}
                      total={customersPagination.total}
                      pageSize={customersPagination.pageSize}
                      hideOnSinglePage={true}
                      onChange={(page, pageSize) => props.changeCustomersPage(page)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }

            {!isFetching && isRoomsVisible &&
              <Grid item style={{ marginTop: 16 }}>
                <Table
                  size="small"
                  columns={roomColumns}
                  pagination={false}
                  dataSource={rooms}
                  loading={isFechingRooms}
                />
                <Grid container justify="flex-end" style={{ marginTop: 8 }}>
                  <Grid item>
                    <Pagination
                      current={roomsPagination.page}
                      total={roomsPagination.total}
                      pageSize={roomsPagination.pageSize}
                      hideOnSinglePage={true}
                      onChange={(page, pageSize) => props.changeRoomsPage(page)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            <Divider style={{ marginTop: 32, marginBottom: 32 }} />

            {isFetching && <Skeleton />}
            {!isFetching && [
              <Grid item container spacing={2}>
                <Grid item xs={2} >
                  <Typography variant="subtitle1"><b>{t("Discounts")}</b></Typography>
                </Grid>
              </Grid>,
              <Grid item style={{ marginTop: 16 }}>
                {_.map(discounts, (discount, ix) => {
                  const colors = ["geekblue", "green", "blue"]

                  return (
                    <Tag color={colors[ix % 3]} key={ix}>
                      {discount.toUpperCase()}
                    </Tag>
                  );
                })}
              </Grid>
            ]}

            <Divider style={{ marginTop: 32, marginBottom: 32 }} />

            {isFetching && <Skeleton />}
            {!isFetching && [
              <Grid item>
                <Grid item xs={2} >
                  <Typography variant="subtitle1"><b>{t("Valid at")}</b></Typography>
                </Grid>
              </Grid>,

              <Grid item container spacing={2}>
                <Grid item><Typography variant="subtitle1"><b>{t("From")}</b></Typography></Grid>
                <Grid item xs={2}><Typography variant="subtitle1">{startDate}</Typography></Grid>

                <Grid item><Typography variant="subtitle1"><b>{t("To")}</b></Typography></Grid>
                <Grid item xs={2}><Typography variant="subtitle1">{endDate}</Typography></Grid>

                <Grid item><Typography variant="subtitle1"><b>{t("Is Active")}</b></Typography></Grid>
                <Grid item xs={2}>
                  <Tag color={isActive ? "green" : "volcano"}>{isActive ? t("Yes") : t("No")}</Tag>
                </Grid>
              </Grid>
            ]}
            
            <Divider style={{ marginTop: 32, marginBottom: 32 }} />

          </Grid>

        </Grid>
      </div>
    </Paper>
  )
}

class DiscountDetail extends Component {

  componentDidMount() {
    let { match: { params: { id } } } = this.props
    this.props.retrieveDetail(id)
  }

  render() {
    return (
      <View {...this.props} />
    )
  }
}


const mapState = (state) => ({
  name: selectors.getName(state),
  description: selectors.getDescription(state),
  applyTo: selectors.getApplyTo(state),
  isFetching: selectors.isFetching(state),

  isCustomersVisible: selectors.isCustomersVisible(state),
  customers: selectors.getCustomers(state),
  isFechingCustomers: selectors.isFechingCustomers(state),
  customersPagination: selectors.getCustomersPagination(state),

  isRoomsVisible: selectors.isRoomsVisible(state),
  rooms: selectors.getRooms(state),
  isFechingRooms: selectors.isFechingRooms(state),
  roomsPagination: selectors.getRoomsPagination(state),

  discounts: selectors.getDiscounts(state),

  startDate: selectors.getStartDateBeautify(state),
  endDate: selectors.getEndDateBeautify(state),
  isActive: selectors.isActive(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveDetail: actions.retrieveDiscount,
    changeCustomersPage: actions.changeCustomersListPage,
    changeRoomsPage: actions.changeRoomsListPage,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(withTranslation("discount_management")(DiscountDetail)))

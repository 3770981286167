import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import * as actionTypes from '../actionTypes/booking.actionTypes'
import * as actions from '../actions/booking'
import { history } from '../config/store'
import * as paths from '../constants/paths.constants'
import * as modalActions from '../actions/modal'
import {getLocale} from '../selectors/public'
import { message } from 'antd'
import {get} from 'lodash'
import {DownloadBookingInvoce, DownloadBookingInvoiceHTML} from '../constants/endpoints.constants'
const URL = process.env.REACT_APP_BASE_URL

function* getBookingSummary({ api }, { data }) {
  try {
    const bookingSummaries = yield call(api.booking.getBookingSummary, data)
    if (bookingSummaries) {
      yield put(actions.getBookingSummarySuccess(bookingSummaries))
    }
  } catch (e) {
    console.log(e)
    yield put(actions.getBookingSummaryFail())
  }
}

function* listBookingsByDate({ api }, { data }) {
  try {
    const date = data.format("YYYY-MM-DD")
    const roomBookings = yield call(
      api.booking.listBookingsByDateRange,
      { dateRange: [date, date] })
    if (roomBookings) {
      yield put(actions.listBookingsByDateSuccess(roomBookings))
      yield put(actions.setBookingTableDate(data))
    }
  } catch (e) {
    console.log(e)
    yield put(actions.listBookingsByDateFail())
  }
}

function* searchAvailableRoom({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.booking.searchAvailableRoom, data)
    if (response && response.length > 0) {
      yield put(actions.searchAvailableRoomsSuccess(response))
      yield put(actions.saveBookingRequest(data))
      yield put(modalActions.closeSearchAvailableRooms())
      history.push(paths.CreateNewBooking_FoundRoom)
    } else {
      message.error('We haven\'t found the available room for you. Please try again with another option. ')
      yield put(actions.searchAvailableRoomsFail())
    }

  } catch (e) {
    console.log(e)
    message.error('We haven\'t found the available room for you. Please try again with another option. ')
    yield put(actions.searchAvailableRoomsFail())
  }
}

function* createBookingSession({ api }, { data }) {
  try {
    const response = yield call(api.booking.createBookingSession, data)
    if (response) {
      yield put(actions.createBookingSessionSuccess(response))
      history.push(paths.CreateNewBooking_SelectCustomer)
    } else {
      yield put(actions.createBookingSessionFail())
    }
  } catch (e) {
    yield put(actions.createBookingSessionFail())
  }
}

function* attachSessionCustomer({ api }, { data }) {
  try {
    const response = yield call(api.booking.attachSessionCustomer, data)
    if (response) {
      yield call(api.booking.updateBookingState, {state: 2})
      yield put(actions.attachSessionCustomerSuccess(response))
      yield put(actions.getCreatedBookingSummary())
      history.push(paths.CreateNewBooking_AddBookingDetail)
    } else {
      yield put(actions.attachSessionCustomerFail())
    }
  } catch (e) {
    message.error(e.data.detail)
    yield put(actions.attachSessionCustomerFail())
  }
}

function* getCreatedBookingSummary({api}, {data}) {
  try {
    const createdBooking = yield call(api.booking.getBookingSession, data)

    if(createdBooking) {
      yield put(actions.getCreatedBookingSummarySuccess(createdBooking))
    }
  } catch (e) {
    console.log(e)
    yield put(actions.getCreatedBookingSummaryFail())
  }
}

function* getListRoomsBooked({ api }, { data }) {
  try {
    if (data.room_id) {
      const response = yield call(api.booking.getListRoomsBooked, data)
      if (response) {
        yield put(actions.getListRoomsBookedSuccess(response))
      } else {
        yield put(actions.getListRoomsBookedFail())
      }
    } else {
      yield put(actions.getListRoomsBookedFail())
    }
  } catch (e) {
    console.log(e)
    message.error(e.data.detail)
    yield put(actions.getListRoomsBookedSuccess())
  }
}

function* searchAvailableExtraFacilities({ api }, { data }) {
  try {
    const response = yield call(api.booking.searchExtraFacilities, data)
    if (response) {
      yield put(actions.searchAvailableExtraFacilitiesSuccess({ id: data.category, listAvailable: response }))
      // yield put(actions.getBookingSessionsSuccess())
    } else {
      yield put(actions.searchAvailableExtraFacilitiesFail())
    }
  } catch (e) {
    yield put(actions.searchAvailableExtraFacilitiesFail())
  }
}

function* addExtraFacility({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.booking.addSessionItems, data)
    // yield put(actions.getBookingSessions())
    yield put(actions.addExtraFacilitySuccess(response))
    yield put(actions.retrieveExtraFacilityItemPricingsSuccess([]))

  } catch (e) {
    console.log(e)
    yield put(actions.addExtraFacilityFail())
  }
}


function* searchAvailableCaterings({ api }, { data }) {
  try {
    // const bookingSessionId = yield select(getBookingSessionId)
    const response = yield call(api.booking.searchAvailableCaterings, data)
    if (response) {
      yield put(actions.searchCateringsSuccess())
      // yield put(actions.getBookingSessionsSuccess(response))
    } else {
      yield put(actions.searchCateringsFail())
    }
  } catch (e) {
    console.log(e)
    yield put(actions.searchCateringsFail())
  }
}

function* addSessionCatering({ api }, { data }) {
  try {
    // const bookingSessionId = yield select(getBookingSessionId)
    const response = yield call(api.booking.addSessionItems, data)
    if (response.id) {
      yield put(actions.addSessionCateringSuccess(response))
      yield put(actions.retrieveCateringItemPricingsSuccess([]))
    } else {
      yield put(actions.addSessionCateringFail())
    }
    // yield put(actions.getBookingSessions())

  } catch (e) {
    console.log(e)
    yield put(actions.addSessionCateringFail())
  }
}

function* removeSessionItems({ api }, { data }) {
  try {
    const response = yield call(api.booking.removeSessionItems, {
      ids: data
    })
    if(response) {
      yield put(actions.removeSessionItemsSuccess(response))
    }
    // yield put(actions.getBookingSessions())

  } catch (e) {
    yield put(actions.removeSessionItemsFail())

  }
}

function* getListCaterings({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.booking.getListCaterings, data)
    yield put(actions.getListCateringsSuccess(response))
  } catch (e) {
    yield put(actions.getListCateringsFail())
  }
}

function* getListExtraFacilities({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.booking.getListExtraFacilityCategories, data)
    yield put(actions.getListExtraFacilitiesSuccess(response))
  } catch (e) {
    yield put(actions.getListExtraFacilitiesFail())
  }
}

function* updateBookingRoom({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.booking.updateBookingRoom, data)
    yield put(actions.updateBookingRoomSuccess(response))
  } catch (e) {
    yield put(actions.updateBookingRoomFail())
  }
}

function* updateBookingCaterings({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.booking.updateBookingCaterings, data)
    yield put(actions.updateBookingCateringsSuccess(response))
  } catch (e) {
    yield put(actions.updateBookingCateringsFail())
  }
}
function* updateBookingExtraFacilities({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.booking.updateBookingExtraFacilities, data)
    yield put(actions.updateBookingExtraFacilitiesSuccess(response))
  } catch (e) {
    yield put(actions.updateBookingExtraFacilitiesFail())
  }
}


function* linkSessionPayment({api}, {data}) {
  try {
    yield call(api.booking.linkSessionPayment, data)
    // yield call(api.booking.updateBookingState, {state: 5})
    // yield put(actions.getBookingSessions())
    yield put(actions.linkSessionPaymentSuccess())
    yield put(actions.updateBookingState({state: 5}))
    history.push(paths.CreateNewBooking_BookingConfirm)
  } catch (e) {
    yield put(actions.linkSessionPaymentFail())
  }
}

function* confirmBooking({api}, {data}) {
  try {
    const resp = yield call(api.booking.confirmBooking, data)
    const lang = yield select(getLocale)
    // yield call(api.booking.updateBookingState, {state: 5})
    // yield put(actions.getBookingSessions())
    const bookingToken = yield call(api.booking.getBookingToken)
    const html = yield call(api.booking.downloadBookingInvoiceHTML, {
      booking_id: resp.id,
      token: get(bookingToken, 'token', ''),
      lang
    })
    console.log (html)
    yield put(actions.confirmBookingSuccess({...resp, 
      htmlSrc: `${URL}${DownloadBookingInvoiceHTML()}?booking_id=${resp.id}&token=${get(bookingToken, 'token', '')}&lang=${lang}`,
      html
    }))

    // yield call(api.booking.destroyBookingSession)
    yield put(actions.removeBookingState())
    history.push(paths.CreateNewBooking_Completed)
  } catch (e) {
    console.log(e)
    yield put(actions.confirmBookingFail())
  }
}

function* destroyBookingSession({api}, {data}) {
  try {
    yield call(api.booking.destroyBookingSession)
    // yield call(api.booking.updateBookingState, {state: 5})
    // yield put(actions.getBookingSessions())
    yield put(actions.destroyBookingSessionSuccess())
    history.goBack()
  } catch (e) {
    yield put(actions.destroyBookingSessionFail())
  }
}

function* getListIncomingBookings({api}, {data}) {
  try {
    const response = yield call(api.booking.getListIncomingBookings, data)
    yield put(actions.getListIncomingBookingsSuccess(response))
  } catch (e) {
    yield put(actions.getListIncomingBookingsFail(e))
  }
}

function* getListHistoryBookings({api}, {data}) {
  try {
    const response = yield call(api.booking.getListHistoryBookings, data)
    yield put(actions.getListIncomingBookingsSuccess(response))
  } catch (e) {
    yield put(actions.getListIncomingBookingsFail(e))
  }
}

function* updateBookingState({api}, {data}) {
  try {
    yield call(api.booking.updateBookingState, data)
    yield put(actions.getCreatedBookingSummary())
    yield put(actions.updateBookingStateSuccess())
  } catch (e) {
    yield put(actions.updateBookingStateFail())
  }
}

function* retrieveExtraFacilityItemPricings({api}, {data}) {
  try {
    const response = yield call(api.booking.retrieveItemPricings, data)
    yield put(actions.retrieveExtraFacilityItemPricingsSuccess(get(response, 'results')))
  } catch (e) {
    yield put(actions.retrieveExtraFacilityItemPricingsFail())
  }
}

function* retrieveCateringItemPricings({api}, {data}) {
  try {
    const response = yield call(api.booking.retrieveItemPricings, data)
    yield put(actions.retrieveCateringItemPricingsSuccess(get(response, 'results')))
  } catch (e) {
    yield put(actions.retrieveCateringItemPricingsFail())
  }
}

function* searchListCustomerInBooking({api}, {data}) {
  try {
    const response = yield call(api.user.searchListCustomer, data)
    if(get(response, 'results', []).length) {
      yield put(actions.searchListCustomerInBookingSuccess(response))
      yield put(actions.onChangeBackupDateInSelectCustomerBooking({selectedCustomer: null}))
    } else {
      message.error('No result found. Please try again!')
      yield put(actions.searchListCustomerInBookingFail())
    }
    // if(get(response, 'results', []).length > 0) {
    // }
  } catch (e) {
    console.log(e)
    yield put(actions.searchListCustomerInBookingFail())
  }
}

function* changeBookingRoomPrice({api}, {data}) {
  try {
    message.info('Updating...', 0)
    const response = yield call(api.booking.changeBookingRoomPrice, data)
    yield put(actions.changeBookingRoomPriceSuccess(response))
    message.destroy()
    message.success('Update successfully')
    // if(get(response, 'results', []).length > 0) {
    // }
  } catch (e) {
    console.log(e)
    yield put(actions.changeBookingRoomPriceFail())
  }
}

function* getBookingDetail({api}, {data}) {
  try {
    // message.info('Updating...', 0)
    const response = yield call(api.booking.getBookingDetail, data)
    const lang = yield select(getLocale)
    const bookingToken = yield call(api.booking.getBookingToken)
    const html = yield call(api.booking.downloadBookingInvoiceHTML, {
      booking_id: data,
      token: get(bookingToken, 'token', ''),
      lang
    })
    console.log (html)
    yield put(actions.getBookingDetailSuccess({...response, 
      htmlSrc: `${URL}${DownloadBookingInvoiceHTML()}?booking_id=${data}&token=${get(bookingToken, 'token', '')}&lang=${lang}`,
      html
    }))
    // message.destroy()
    // message.success('Update successfully')
    // if(get(response, 'results', []).length > 0) {
    // }
  } catch (e) {
    console.log(e)
    yield put(actions.getBookingDetailFail())
  }
}
function* downloadBookingInvoice({api}, {data}) {
  try {
    // message.info('Updating...', 0)
    
    const response = yield call(api.booking.getBookingToken)
    const lang = yield select(getLocale)

    console.log(response)
    var link = document.createElement('a')
    link.href = `${URL}${DownloadBookingInvoce()}?booking_id=${data}&token=${get(response, 'token', '')}&lang=${lang}`
    link.target= '_blank'
    link.download = true
    document.body.appendChild(link)
    link.click()    
    yield put(actions.downloadPdfSuccess())
    // message.destroy()
    // message.success('Update successfully')
    // if(get(response, 'results', []).length > 0) {
    // }
  } catch (e) {
    console.log(e)
    yield put(actions.downloadPdfFail())
  }
}

function bookingSaga(deps) {
  return function* saga() {
    yield all([
      takeLatest(actionTypes.GET_BOOKING_SUMMARY, getBookingSummary, deps),
      takeLatest(actionTypes.LIST_BOOKINGS_BY_DATE_RANGE, listBookingsByDate, deps),
      takeLatest(actionTypes.SEARCH_AVAILABLE_ROOMS, searchAvailableRoom, deps),
      takeLatest(actionTypes.CREATE_BOOKING_SESSION, createBookingSession, deps),
      takeLatest(actionTypes.ATTACH_SESSION_CUSTOMER, attachSessionCustomer, deps),
      takeLatest(actionTypes.GET_CREATED_BOOKING_SUMMARY, getCreatedBookingSummary, deps),
      takeLatest(actionTypes.GET_LIST_ROOMS_BOOKED, getListRoomsBooked, deps),
      takeLatest(actionTypes.GET_LIST_CATERINGS, getListCaterings, deps),
      takeLatest(actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES, getListExtraFacilities, deps),

      takeLatest(actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES, searchAvailableExtraFacilities, deps),
      takeLatest(actionTypes.ADD_EXTRA_FACILITIES, addExtraFacility, deps),
      takeLatest(actionTypes.SEARCH_AVAILABLE_CATERINGS, searchAvailableCaterings, deps),
      takeLatest(actionTypes.ADD_SESSION_CATERING, addSessionCatering, deps),
      takeLatest(actionTypes.REMOVE_SESSION_ITEMS, removeSessionItems, deps),
      takeLatest(actionTypes.UPDATE_BOOKING_ROOM, updateBookingRoom, deps),
      takeLatest(actionTypes.UPDATE_BOOKING_CATERINGS, updateBookingCaterings, deps),
      takeLatest(actionTypes.UPDATE_BOOKING_EXTRA_FACILITIES, updateBookingExtraFacilities, deps),
      takeLatest(actionTypes.LINK_SESSION_PAYMENT, linkSessionPayment, deps),
      takeLatest(actionTypes.CONFIRM_BOOKING, confirmBooking, deps),
      takeLatest(actionTypes.DESTROY_BOOKING_SESSION, destroyBookingSession, deps),
      takeLatest(actionTypes.GET_LIST_INCOMING_BOOKING, getListIncomingBookings, deps),
      takeLatest(actionTypes.GET_LIST_HISTORY_BOOKING, getListHistoryBookings, deps),
      takeLatest(actionTypes.UPDATE_BOOKING_STATE, updateBookingState, deps),
      takeLatest(actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS, retrieveExtraFacilityItemPricings, deps),
      takeLatest(actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS, retrieveCateringItemPricings, deps),
      takeLatest(actionTypes.SEARCH_LIST_CUSTOMER_IN_BOOKING, searchListCustomerInBooking, deps),
      takeLatest(actionTypes.CHANGE_BOOKING_ROOM_PRICE, changeBookingRoomPrice, deps),
      takeLatest(actionTypes.GET_BOOKING_DETAIL, getBookingDetail, deps),
      takeLatest(actionTypes.DOWNLOAD_PDF, downloadBookingInvoice, deps),
    ])
  }
}

export default bookingSaga
export const SEARCH_LIST_CUSTOMER = 'SEARCH_LIST_CUSTOMER'
export const SEARCH_LIST_CUSTOMER_SUCCESS = 'SEARCH_LIST_CUSTOMER_SUCCESS'
export const SEARCH_LIST_CUSTOMER_FAIL = 'SEARCH_LIST_CUSTOMER_FAIL'

export const CREATE_USER_IN_BOOKING = 'CREATE_USER_IN_BOOKING'
export const CREATE_USER_IN_BOOKING_SUCCESS = 'CREATE_USER_IN_BOOKING_SUCCESS'
export const CREATE_USER_IN_BOOKING_FAIL = 'CREATE_USER_IN_BOOKING_FAIL'

export const LIST_USERS = "LIST_USERS"
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS'
export const LIST_USERS_FAIL = 'LIST_USERS_FAIL'

export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const GET_USER_INFO = "GET_USER_INFO"
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL'

export const GET_USER_ANALYTICS = "GET_USER_ANALYTICS"
export const GET_USER_ANALYTICS_SUCCESS = 'GET_USER_ANALYTICS_SUCCESS'
export const GET_USER_ANALYTICS_FAIL = 'GET_USER_ANALYTICS_FAIL'

export const SET_USER_FILTER = 'SET_USER_FILTER'

export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD'
export const REQUEST_RESET_PASSWORD_FAIL = 'REQUEST_RESET_PASSWORD_FAIL'
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'

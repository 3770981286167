import { bind } from 'decko'
import {c2s} from '../../helpers/helpers';
import BaseApi from './baseApi'
import {
  CreateRoom,
  ListRooms,
  UpdateRoom,
  GetRoom,
  ListPermFacs,
  ListRoomTypes,
  CreateRoomType,
  UpdateRoomType,
  GetRoomType,
  RoomTypeListRoomsReadyToAdd,
} from '../../constants/endpoints.constants'
export default class Room extends BaseApi {
  @bind
  async createRoom(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(CreateRoom(), c2s(data));
  }

  @bind
  async updateRoom(id, data) {
    await this.checkAuthenticatedStatus()
    return await this.http.request.patch(UpdateRoom(id), c2s(data));
  }

  @bind
  async listRooms(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(ListRooms(), c2s(data));
  }

  @bind
  async getRoom(id) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(GetRoom(id));
  }

  @bind
  async listPermFacs(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(ListPermFacs(), c2s(data));
  }

  // Room types
  @bind
  async listRoomTypes(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(ListRoomTypes(), c2s(data));
  }

  @bind
  async createRoomType(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(CreateRoomType(), c2s(data));
  }

  @bind
  async updateRoomType(id, data) {
    await this.checkAuthenticatedStatus()
    return await this.http.request.patch(UpdateRoomType(id), c2s(data));
  }

  @bind
  async getRoomType(id) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(GetRoomType(id));
  }

  @bind
  async listRoomsReadyToAdd(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(RoomTypeListRoomsReadyToAdd(), c2s(data));
  }

  @bind
  async searchRooms(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post("/list_rooms", data)
  }

  @bind
  async searchRoomTypes(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post("/room/internal/list_room_types", data)
  }
}

const C = "ROOM.NEW"

export const SUBMIT_ROOM = `${C}.SUBMIT_ROOM`
export const SUBMIT_ROOM_COMPLETED = `${C}.SUBMIT_ROOM_COMPLETED`
export const SUBMIT_ROOM_FAILED = `${C}.SUBMIT_ROOM_FAILED`

export const SUBMIT_ROOM_TYPE = `${C}.SUBMIT_ROOM_TYPE`
export const SUBMIT_ROOM_TYPE_COMPLETED = `${C}.SUBMIT_ROOM_TYPE_COMPLETED`
export const SUBMIT_ROOM_TYPE_FAILED = `${C}.SUBMIT_ROOM_TYPE_FAILED`

export const SEARCH_ROOM_TYPES = `${C}.SEARCH_ROOM_TYPES`
export const SEARCH_ROOM_TYPES_COMPLETED = `${C}.SEARCH_ROOM_TYPES_COMPLETED`
export const SEARCH_ROOM_TYPES_FAILED = `${C}.SEARCH_ROOM_TYPES_FAILED`

export const LIST_PERMANENT_FACILITY = `${C}.LIST_PERMANENT_FACILITY`
export const LIST_PERMANENT_FACILITY_COMPLETED = `${C}.LIST_PERMANENT_FACILITY_COMPLETED`
export const LIST_PERMANENT_FACILITY_FAILED = `${C}.LIST_PERMANENT_FACILITY_FAILED`

export const SEARCH_COMBINABLE_ROOM = `${C}.SEARCH_COMBINABLE_ROOM`
export const SEARCH_COMBINABLE_ROOM_COMPLETED = `${C}.SEARCH_COMBINABLE_ROOM_COMPLETED`
export const SEARCH_COMBINABLE_ROOM_FAILED = `${C}.SEARCH_COMBINABLE_ROOM_FAILED`

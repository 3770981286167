import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'
import * as helpers from '../../helpers/user'
import * as bookingHelpers from '../../helpers/bookings'
import {convertNumToMonthName} from '../../helpers/helpers'

import { BOOKING, FORMAT } from '../../constants/models'

const { LIST_BY } = BOOKING
const { DATE } = FORMAT

const getReducer = ({users}) => users.new

export const isSubmitting = createSelector(
  [getReducer],
  ({isSubmitting}) => isSubmitting
)

import _ from 'lodash'
import {DISCOUNT_TO} from './constant'
import moment from 'moment'

import Images1 from '../assets/images/kopenhagen_1_2.jpg'
import Images2 from '../assets/images/kopenhagen_2_1.jpg'
import Images3 from '../assets/images/kopenhagen_2_2.jpg'
import Images4 from '../assets/images/Raum_18.jpg'
import Images5 from '../assets/images/Raum_19_Coaching.jpg'
import Images6 from '../assets/images/Raum_21.jpg'
import Images7 from '../assets/images/Stockholm_2.jpg'
import Images8 from '../assets/images/Raum_28.jpg'
import Images9 from '../assets/images/Raum_28_3P.jpg'
import Images0 from '../assets/images/Raum_23.jpg'

export const getImage = (value = 0) => {
  const id = value % 10
  const listImages = {
    Images1,
    Images2,
    Images3,
    Images4,
    Images5,
    Images6,
    Images7,
    Images8,
    Images9,
    Images0,
  }
  return listImages[`Images${id}`]
}


export const binaryToTimeline = value => {
  const firstTime = value.indexOf('1') + 8
  const lastTime = value.lastIndexOf('1') + 9
  return [firstTime, lastTime]
}

export const timeslotsToBinary = value => {
  let binary = (value >>> 0).toString(2)
  while (binary.length < 14) {
    binary = '0' + binary
  }
  return binary
}

export const timeslotsToTimeline = (value) => {
  let binary = timeslotsToBinary(value)
  const firstTime = binary.indexOf('1') + 8
  const lastTime = binary.lastIndexOf('1') + 9
  return [firstTime, lastTime]
}

export const convertTimeLineToBinary = (start_time, end_time) => {
  let temp = ''
  for (let i = 0; i < Number(start_time) - 8; i++) {
    temp += '0'
  }
  for (let i = Number(start_time) - 8 + 1; i <= Number(end_time) - 8; i++) {
    temp += '1'
  }
  for (let i = Number(end_time) - 8 + 1; i < 15; i++) {
    temp += '0'
  }
  // console.log(temp)
  return temp
}


export const roundToUnit = (data, unit) => {
  const unitString = unit ? unit.toString().replace('1', '') : '00'
  if (!data) return `0.${unitString}`
  data = data / (unit || 1)
  let value = _.round(data, unitString.length).toString()
  if (value.split('.').length > 1) {
    return value
  } else {
    value = value + `.${unitString}`
  }
  const total1 = value.split('.')[0]
  const total2 = value.split('.')[1].substring(0, unitString.length)
  const result = total1 + '.' + total2
  return result
}

export const c2s = (obj) => {
  /**
   * Convert camel case keys to snake case keys
   */
  return _.transform(obj, (res,val,key) => {
    res[_.snakeCase(key)] = val
    return res
  }, {})
}

export const s2c = (obj) => {
  /**
   * Convert snake case keys to camel case keys
   */
  return _.transform(obj, (res,val,key) => {
    res[_.camelCase(key)] = val
    return res
  }, {})
}

export const convert24To12HoursClock = (time) => {
  const ampm = time >= 12 ? 'PM' : 'AM'
  const hours = time % 12
  return hours + ':00' + ampm
}

export const convertDiscountToSpecified = (value) => {
  if(value === 7) return ['All']
  let binary = (value >>> 0).toString(2)
  while (binary.length < 3) {
    binary = '0' + binary
  }
  const temp = binary.split('')
  let listDiscountTo = []
  temp.map((item, index) => {
    if(item === '1') {
      listDiscountTo.push(DISCOUNT_TO[index])
    }
  })
  return listDiscountTo
}

export const pathWithId = (path, id) => _.replace(path, ':id', id)

export const convertNumToMonthName = (num) => moment().month(num-1).format("MMM")
export const getDisplayedPrice = (price, ccsym, unit) => `${ccsym} ${(price / unit || 0).toFixed(2)}`
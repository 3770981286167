import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import { message } from 'antd'
import * as C from '../../actionTypes/caterings/list'
import * as EDIT_C from '../../actionTypes/caterings/edit'
import * as NEW_C from '../../actionTypes/caterings/new'
import { history } from '../../config/store'
import * as _ from 'lodash'

function* listCaterings({api}) {
  try {
    const response = yield call(api.caterings.getListAllCaterings)
    yield put({type: C.LIST_CATERING_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.LIST_CATERING_FAILED, payload: e})
    message.error("Error: ", _.get(e, 'data.detail'))
    console.error(e)
  }
}

function listSaga(deps) {
  return [
      takeLeading(C.LIST_CATERING, listCaterings, deps),
      takeLeading(EDIT_C.SUBMIT_COMPLETED, listCaterings, deps),
      takeLeading(NEW_C.SUBMIT_COMPLETED, listCaterings, deps),
  ]
}

export default listSaga

import React, { Component, useState } from 'react'
import { withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Input, Upload, Icon, message } from 'antd'
import { Table, Tag, Form, Switch, Select } from 'antd';
import { Radio } from 'antd';
import { Card, Col, Row } from 'antd';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import PictureWall from "../images/PicturesWall";
import TimeslotPricingWall from "../pricings/TimeslotPricingWall"
import { ROOM } from '../../constants/models'
import Api from '../../services/api'
import * as helpers from '../../helpers/timeslot'

const { TextArea } = Input;
const api = new Api()

const { LIST_BY } = ROOM

const EditRoom = (props) => {
  let match = useRouteMatch();
  let { match: { params: { id } } } = props
  const {
    t,

    isFetching,
    name,
    description,
    size,
    maxParticipants,
    leadTime,
    freeCancelDays,
    isPublic,
    selectedRoomType,
    permanentFacilities,

    images,
    pricings,
    isCombinedRoom,
    combinedRooms,

    isSubmitting,
    selectableRoomTypes,
    selectableCombinedRooms,
    selectablePermanentFacilities,

    onSubmit,
    onSearchRoomType,
    onSearchCombinableRooms,

    form
  } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = form

  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        console.log(values)
        const image_ids = _.map(_.get(values, 'images'), im => im.id)
        const p_fac_ids = _.get(values, 'permanent_facilities')
        const combined_room_ids = _.map(_.get(values, 'combined_rooms'), it => it)
        const pricing_objs = _.map(_.get(values, 'pricings'), p => ({
          price: _.floor(parseFloat(p.price)*100),
          apply_range_int: helpers.timeRange2Slots(p.start, p.end)[1]
        }))
        values = _.set(values, 'combined_room_ids', combined_room_ids)
        values = _.set(values, 'image_ids', image_ids)
        values = _.omit(values, ['images'])
        values = _.set(values, 'pricing_objs', pricing_objs)
        values = _.set(values, 'permanent_facility_ids', p_fac_ids)
        values = _.set(values, 'id', id)
        onSubmit(values)
      } else {
        console.error(errors)
        message.error(t("Please input required fields!!"))
      }
    })
  }

  return (
    <>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t("EDIT ROOM")}
        subTitle={name}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        <Form 
          onSubmit={handleSubmit}
          scrollToFirstError={true}
        >
          <Card style={{ marginBottom: 32 }} loading={isFetching}>
            <Form.Item
              label={t("Room Type")}
              name="roomType"
            >
              {getFieldDecorator('room_type', {
                rules: [{ required: true, message: t('Please select a room type!') }],
                initialValue: selectedRoomType
              })(
                <Select
                  showSearch
                  filterOption={false}
                  onSearch={onSearchRoomType}
                >
                  {_.map(selectableRoomTypes, it => (
                    <Select.Option key={it.id} value={it.id}>{it.name}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              label={t("Name")}
              name="name"
            >
              {getFieldDecorator('name', {
                rules: [{ required: true, message: t('Please input name!') }],
                initialValue: name,
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label={t("Description")}
              name="description"
            >
              {getFieldDecorator('description', {
                rules: [{ required: true, message: t('Please input a short description!') }],
                initialValue: description,
              })(
                <TextArea rows={4} />
              )}
            </Form.Item>

            <Form.Item
              label={t("Size") + ` (m²)`}
              name="size"
            >
              {getFieldDecorator('size', {
                rules: [{ required: true, message: t('Please input size!') }],
                initialValue: size,
              })(
                <Input type="number" min={0} />
              )}
            </Form.Item>

            <Form.Item
              label={t("Max Participants")}
              name="maxParticipants"
            >
              {getFieldDecorator('max_attendances', {
                rules: [{ required: true, message: t('Please input Max Participants!')}],
                initialValue: maxParticipants,
              })(
                <Input type="number" min={0} />
              )}
            </Form.Item>

            <Form.Item
              label={t("Lead time") + ` (${t("days")})`}
              name="leadTime"
            >
              {getFieldDecorator('lead_time', {
                rules: [{ required: true, message: t('Please input lead time!') }],
                initialValue: leadTime,
              })(
                <Input type="number" min={0} />
              )}
            </Form.Item>

            <Form.Item
              label={t("Free cancel days") + ` (${t("days")})`}
              name="freeCancelDays"
            >
              {getFieldDecorator('free_cancel_days', {
                rules: [{ required: true, message: t('Please input free cancel days!') }],
                initialValue: freeCancelDays,
              })(
                <Input type="number" min={0} />
              )}
            </Form.Item>

            <Form.Item
              label={t("Permanent Facilities")}
              name="facilities"
            >
              {getFieldDecorator('permanent_facilities', {
                rules: [{ required: true, message: t('Please set some facilities!') }],
                initialValue: permanentFacilities,
              })(
                <Select
                  mode="multiple"
                >
                  {_.map(selectablePermanentFacilities, it => (
                    <Select.Option value={it.id} key={it.id}>{it.name}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              label={t("Is Public")}
              name="isPublic"
              valuePropName="checked"
            >
              {getFieldDecorator('is_published', {
                valuePropName: 'checked',
                initialValue: isPublic
              })(
                <Switch />
              )}
            </Form.Item>
          </Card>

          <Card style={{ marginBottom: 32 }} loading={isFetching}>
            <Form.Item
              label={t("Is Combined From Other Rooms")}
              name="isCombinedRoom"
              disabled
            >
              {getFieldDecorator('is_combined_room', {
                valuePropName: 'checked',
                initialValue: isCombinedRoom,
              })(
                <Switch disabled />
              )}
            </Form.Item>
            {isCombinedRoom &&
              <Form.Item
              label={t("Combined Rooms")}
              name="combinedRooms"
            >
              {getFieldDecorator('combined_rooms', {
                initialValue: combinedRooms,
              })(
                <Select
                  mode="multiple"
                  filterOption={false}
                  autoClearSearchValue={true}
                  onSearch={onSearchCombinableRooms}
                  onChange={() => onSearchCombinableRooms("")}
                >
                  {_.map(selectableCombinedRooms, it => (
                    <Select.Option key={it.id} value={it.id}>{it.name}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            }
          </Card>

          <Card style={{ marginBottom: 32 }} loading={isFetching}>
            <Form.Item
              label={t("Pricings")}
              name="pricings"
              valuePropName="pricings"
            >
              {getFieldDecorator('pricings', {
                valuePropName: 'pricings',
                rules: [
                  { required: true, message: t('You must provide at least one pricing!')},
                  {
                    validator(rule, value, callback) {
                      if ((value || []).filter(p => !p.price || p.price == 0).length) {
                        callback("Price must be greater than 0");
                      }
                      callback();
                    }
                  }
                ],
                initialValue: pricings,
                onChange: (pricings) => setFieldsValue({"pricings": pricings})
              })(
                <TimeslotPricingWall />
              )}
            </Form.Item>
          </Card>

          <Card style={{ marginBottom: 32 }} loading={isFetching}>
            <Form.Item
              label={t("Images")}
              name="images"
              valuePropName="images"
            >
              {getFieldDecorator('images', {
                valuePropName: 'images',
                initialValue: images,
                onChange: (images) => setFieldsValue({"images": images})
              })(
                <PictureWall />
              )}
            </Form.Item>
          </Card>

          <Grid container justify="flex-end">
            <Grid item>
              <Form.Item>
                <Button
                  variant="contained"
                  color="primary"
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                >
                  {t("Submit")}
                </Button>
              </Form.Item>
            </Grid>
          </Grid>
        </Form>
      </div>
    </>
  )
}

export default Form.create({
  name: 'EditRoom',
  scrollToFirstError: true,
})(withRouter(withTranslation("room_management")(EditRoom)))

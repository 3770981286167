import React, { Fragment, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import {get} from 'lodash'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Drawer, Divider, Paper, Avatar, Typography } from '@material-ui/core'
import { Hidden } from '@material-ui/core'
import ASSETS from '../../../../../constants/assets'
// import * as paths from '../../../../../constants/paths.constants'
import useRouter from '../../../../../utils/useRouter'
import { Navigation } from '../../../../../components'
import navigationConfig from './navigationConfig'
import {withRouter} from 'react-router-dom'
import * as paths from '../../../../../constants/paths.constants'
import _ from 'lodash'
import {withTranslation} from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  content: {
    padding: theme.spacing(2)
  },
  logoContent: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  logo: {
    width: '80%',
    cursor: 'pointer'
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  }
}))

const NavBar = props => {
  const { openMobile, onMobileClose, className, history, t, i18n, ...rest } = props

  const classes = useStyles()
  const router = useRouter()
  const profile = useSelector(state => get(state, 'publicReducer.profile'))

  const _get_user_role = (user) => 
  get(user, 'is_admin') ? 'Admin' : get(user, 'is_staff') ? 'Staff' :
  get(user, 'is_long_term_customer') ? 'Long-Term Customer' : 'Customer'


  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname])

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.logoContent}>
        <img
          alt="Logo"
          className={classes.logo}
          // component={RouterLink}
          src={ASSETS.LOGO}
          onClick={() => history.push(paths.HomePage)}
        />
      </div>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={get(profile, 'avatar')}
          to={paths.ProfilePage}
        />
        <Typography
          className={classes.name}
          variant="h5"
        >
          {get(profile, 'first_name', '')} {get(profile, 'last_name', '')}
        </Typography>
        <Typography variant="body2">{t(`NAV_BAR.${_get_user_role(profile)}`)}</Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
            exact={list.exact}
            t={t}
            i18n={i18n}
          />
        ))}
      </nav>
    </div>
  )

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

export default withRouter( withTranslation('common') (NavBar))

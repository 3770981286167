import { DISCOUNT, FORMAT } from '../constants/models'
import moment from 'moment'
import * as _ from 'lodash'

const { STATUS } = DISCOUNT

export const getDiscountStatusTag = (status) => {
  switch (status) {
    case STATUS.ACTIVE:
      return {color: "green", name: "Active"}
    case STATUS.INACTIVE:
      return {color: "red", name: "Inactive"}
    case STATUS.EXPIRED:
      return {color: "orange", name: "Expired"}
    default:
      return {color: "gray", name: "UNKNOW"}
  }
}

import { combineReducers } from 'redux'
import listRoom from './list'
import newRoom from './new'
import detailRoom from './detail'
import editRoom from './edit'

const reducers = combineReducers({
  list: listRoom,
  new: newRoom,
  detail: detailRoom,
  edit: editRoom,
});

export default reducers

import { combineReducers } from 'redux'
import * as C from "../../actionTypes/users/list";
import * as _ from 'lodash'
import { USER } from '../../constants/models'

const { LIST_BY } = USER

const page = (state = 1, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_USER_COMPLETED:
      return _.get(payload, 'page', 1)
    case C.CHANGE_PAGE:
      return payload
    case C.CHANGE_TAB:
      return 1
    default:
      return state
  }
}

const total = (state = 0, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_USER_COMPLETED:
      return _.get(payload, 'count', 0)
    default:
      return state
  }
}

const pageSize = (state = 10, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_USER_COMPLETED:
      return _.get(payload, 'page_size', 10)
    default:
      return state
  }
}

const items = (state = [], action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_USER_COMPLETED:
      return _.get(payload, 'results')
    case C.CHANGE_TAB:
      return []
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const {type} = action
  switch (type) {
    case C.LIST_USER:
    case C.CHANGE_TAB:
    case C.CHANGE_PAGE:
    case C.SUBMIT_SEARCH:
      return true
    case C.LIST_USER_COMPLETED:
    case C.LIST_USER_FAILED:
      return false
    default:
      return state
  }
}

const selectedTab = (state = LIST_BY.STAFF, action) => {
  const {type, payload} = action
  switch (type) {
    case C.CHANGE_TAB:
      return payload
    default:
      return state
  }
}

const searchText = (state = "", action) => {
  const {type, payload} = action
  switch (type) {
    case C.CHANGE_SEARCH_TEXT:
      return payload
    default:
      return state
  }
}

const exportUser = combineReducers({
  retrieving: (state = false, action) => {
    const {type, payload} = action
    switch (type) {
      case C.RETRIEVE_EXPORT_TOKEN:
        return true
      case C.RETRIEVE_EXPORT_TOKEN_COMPLETED:
      case C.RETRIEVE_EXPORT_TOKEN_FAILED:
        return false
      default:
        return state
    }
  },

  token: (state = "", action) => {
    const {type, payload} = action
    switch (type) {
      case C.RETRIEVE_EXPORT_TOKEN_COMPLETED:
        return _.get(payload, "token")
      case C.RETRIEVE_EXPORT_TOKEN_FAILED:
        return ""
      default:
        return state
    }
  }
})

const reducers = combineReducers({
  page,
  pageSize,
  total,
  items,
  isFetching,
  selectedTab,
  searchText,
  exportUser,
})

export default reducers

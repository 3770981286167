import React, { useEffect, useState, useRef } from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import {get, isEqual} from 'lodash'
import {
  Container, Grid, Typography, TextField, Button, FormControlLabel, Checkbox
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import AddOutlined from '@material-ui/icons/AddOutlined'
import SelectRoomsModal from './modals/SelectRoomsModal'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import Editor from '../../../components/RichEditor'
import { Upload, Icon, Modal, Form, Select as FormSelect, Input, Switch, message } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { history } from '../../../config/store'
import MaterialTableComponent from '../../../components/Table/MaterialTableHandlePaging'
//Room imge
import { MAX_IMG_SIZE_MB } from '../../../helpers/constant'
import {
  createRoomType,
  clearRoomTypeInfo,
  getRoomType,
  updateRoomType,
  listRoomsReadyToAdd,
  listRoomsReadyToAddFail
} from '../../../actions/room'
import * as selectors from '../../../selectors/room'
import { s2c } from '../../../helpers/helpers'
import LoadingIcon from 'mdi-react/LoadingIcon'
import Api from '../../../services/api'


const useStyles = makeStyles((theme) => ({
  formField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  roomInfoContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
    // '& .MuiGrid-item ': {
    //   padding: '15px'
    // }
  },
  roomCombinedContent: {
    display: 'block',
    minHeight: '225px',
    borderLeft: '1px solid #ddd'
  },
  chip: {
    minWidth: '120px',
    margin: '5px',
    justifyContent: 'space-between'
  },
  chip2: {
    padding: '5px 5px',
    margin: '5px',
  },
  addRoomCombinedIcon: {
    border: '1px solid',
    borderColor: theme.colorAccent,
    borderRadius: '50%',
    color: theme.colorAccent,
    width: '18px',
    height: '18px',
  },
  addRoomCombinedContent: {
    width: '100%'
  },
  padding15px: {
    padding: '15px'
  },
  roomCategoryLabelContent: {
    padding: '10px 0'
  },
  descriptionLabel: {
    padding: '10px 0'
  },
  addMoreRow: {
    '& .MuiTableCell-root': {
      color: 'rgba(162, 162, 162, 0.87)',
    },
    '& .MuiInput-underline:before': {
      border: 'unset'
    }
  },
  addMoreRowButtonContent: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      width: '80px'
    }
  },
  roomImagesContent: {
    margin: '20px 0'
  },
  roomImagesUploadContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
  },
  formSelectField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '200px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  roomsSelectedContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',

  },
  createNewTypeButton: {
    color: theme.colorAccent
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  roomListContent: {
    margin: '20px 0'
  },
  roomListButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  editViewTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorAccent,
    paddingBottom: '10px',
    borderBottom: `2px solid ${theme.colorAccent}`,
    marginBottom: '20px',
    marginTop: '30px',

  }
}))

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const compareOnFields = (obj1, obj2, fields) => {
  var diffCnt = 0;
  (fields || []).forEach(f => {
    if (get(obj1, f) != get(obj2, f)) diffCnt += 1
  })
  return diffCnt > 0
}

const _transformToDisplayedImage = (img) => {
  return ({
    uid: img.id,
    id: img.id,
    name: img.name,
    url: img.url,
    status: "done"
  })
}

const CreateNewRoomType = props => {
  const { 
    match, 
    getRoomType, 
    isGettingRoomTypeInfo, 
    roomTypeInfo, 
    isSavingRoomType,
    createRoomType,
    updateRoomType,
    roomRows,
    listRoomsReadyToAdd,
    isListingRoomReadyToAdd,
    roomsReadyToAdd,
    t
  } = props
  const {
    getFieldDecorator,
    validateFields, 
    setFieldsValue, 
    getFieldValue
  } = props.form
  const editView = match.path == paths.UpdateRoomType

  const classes = useStyles()

  const [fileList, setFileList] = useState([])

  const roomHeadCells = [
    { key: 'id', disablePadding: true, label: '#ID' },
    { key: 'name', disablePadding: false, label: t('ROOMS.RoomName') },
    { key: 'capacity', disablePadding: false, label: t('ROOMS.Capacity') },
  ]

  const [openSelectRoomsModal, setOpenSelectRoomsModal] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [newRoomsList, setNewRoomsList] = useState([])
  const [roomsToAdd, setRoomsToAdd] = useState([])
  const [roomsToDelete, setRoomsToDelete] = useState([])
  const [selectedRoomIds, setSelectedRoomIds] = useState([])
  
  useEffect(() => {
    // first load
    clearRoomTypeInfo()
    if (editView) {
      getRoomType(match.params.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ])

  useEffect(() => {
    // fetched room info
    if (editView && get(roomTypeInfo, 'id')) {
      setFieldsValue(roomTypeInfo)
      setNewRoomsList(roomRows)
      setFileList(get(roomTypeInfo, 'images', []).map(_transformToDisplayedImage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomTypeInfo])

  useEffect(() => {
    // fetched rooms ready to add
    if (openSelectRoomsModal) {
      if (get(match, 'params.id')) {
        listRoomsReadyToAdd({ room_type_id: get(match, 'params.id')})
      }
      else {
        listRoomsReadyToAdd({})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSelectRoomsModal])

  useEffect(() => {
    if (roomsToAdd.length > 0 || roomsToDelete.length > 0 ){
      // delete rooms
      let _newRoomsList = newRoomsList.filter(r => !roomsToDelete.map(dr => dr.id).includes(r.id))
      // add rooms
      setNewRoomsList([..._newRoomsList, ...roomsToAdd])
      // reset
      setRoomsToAdd([])
      setRoomsToDelete([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomsToAdd, roomsToDelete])

  // check changes in form
  const formValues = props.form.getFieldsValue()
  const isFormChanged = () => {
    const isObjEqual = [
      'room_type',
      'description',
      'is_published',
      'images',
      'rooms'
    ].every(f => {
      switch(f) {
        case 'room_type':
        case 'description':
        case 'is_published':
          if (get(roomTypeInfo, f) != get(formValues, f)) return false
          break;
        case 'images':
          const imageIds = get(roomTypeInfo, f, []).map(img => img.id)
          const formImageIds = (fileList || []).map(img => img.id)
          if (!isEqual(imageIds, formImageIds)) return false
          break;
        case 'rooms':
          const formRoomIds = (newRoomsList || []).map(r => r.id)
          const roomIds = get(roomTypeInfo, f, []).map(r => r.id)
          if (!isEqual(roomIds, formRoomIds)) return false
        default:
          break;
      }
      return true
    })
    return !isObjEqual
  }

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">{t('ROOMS.Upload')}</div>
    </div>
  )

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }

  const handleChange = ({ file, fileList }) => {
    switch (file.status) {
      case "uploading":
        setFileList(fileList)
        break;
      case "done":
        setFileList(fileList.map(
          f => get(f, 'url') ? f : ({
            ...f, 
            url: get(f, 'response.url'),
            id: get(f, 'response.id'),
          })))
        break;
      case "removed":
        setFileList(fileList)
        break;
      default:
        break;
    }
  }

  const api = new Api()
  const handleUpload = async ({file, onSuccess }) => {
    const data = new FormData()
    data.append('file', file)
    const res = await api.image.uploadSingleImage(data)
    onSuccess(res)
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(`${t('ROOMS.YouCanOnlyUploadXFile')}`);
    }
    const isLt2M = file.size / 1024 / 1024 < MAX_IMG_SIZE_MB.ROOM_TYPE;
    if (!isLt2M) {
      message.error(`${t('ROOMS.ImageMustSmallerThan')} ${MAX_IMG_SIZE_MB.ROOM_TYPE}MB!`);
    }
    return isJpgOrPng && isLt2M;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if(!errors) {
        const data = {
          ...values,
          room_ids: newRoomsList.map(r => r.id),
          image_ids: fileList.map(f => f.id)
        }
        if (editView) {
          updateRoomType(roomTypeInfo.id, data)
        } else {
          createRoomType(data)
        }
      }
    })
  }

  const handleAddRooms = (rooms) => {
    setRoomsToAdd([...roomsToAdd, ...rooms])
    setOpenSelectRoomsModal(false)
  }

  const handleDeleteSelectedRooms = () => {
    setRoomsToDelete(newRoomsList.filter(r => selectedRoomIds.includes(r.id)))
    setSelectedRoomIds([])
  }

  return (
    <Container>
      {isGettingRoomTypeInfo ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Form onSubmit={handleSubmit} className={classes.formRoot}>
      <Grid container >
        {editView && 
        <Grid item xs={12} md={12} >
          <Typography variant='h5' component="div"  >
            <Link onClick={() => history.goBack()} className={classes.editViewTitle} >
              <ChevronLeftIcon /> {t('ROOMS.Edit')} {get(roomTypeInfo, 'room_type', '')}
            </Link>
          </Typography>
        </Grid>
        }
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={2} md={3}>
              <Typography variant='h6'>{t('ROOMS.RoomTypeInformation')}</Typography>
            </Grid>
            <Grid item xs={10} md={9} className={classes.roomInfoContent}>
              <Grid container>
                <Grid item xs={6} md={6} className={classes.padding15px}>
                  <Typography>{t('ROOMS.RoomTypeName')}</Typography>
                  <Form.Item>
                    {getFieldDecorator('room_type', {
                      rules: [
                        {
                          required: true,
                          message: `${t('ROOMS.RoomName')} ${t('ROOMS.IsRequired')}`,
                        },
                      ],
                    })(<TextField
                      className={classes.formField}
                      // id="outlined-error"
                      label=""
                    // variant="outlined"
                    />)}
                  </Form.Item>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} className={classes.padding15px}>
                  <Typography className={classes.descriptionLabel}>{t('ROOMS.Description')}</Typography>
                  {/* <Editor /> */}
                  <Form.Item>
                    {getFieldDecorator('description', {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(<Input.TextArea
                        placeholder={t('ROOMS.WritingRoomTypeDescription')}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />)}
                  </Form.Item>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} className={classes.padding15px}>
                  <Typography className={classes.descriptionLabel}>{t('ROOMS.PublishRoomType')}</Typography>
                  {/* <Editor /> */}
                  <Form.Item>
                    {getFieldDecorator('is_published', {
                      rules: [],
                    })(<Switch checked={getFieldValue('is_published')} />)}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.roomListContent}>
          <Grid item md={3} xs={2}>
            <Typography variant='h6'>{t('ROOMS.RoomsList')}</Typography>
          </Grid>
          <Grid item md={9} xs={10} className={classes.roomsSelectedContent}>
            <Grid container>
              <Grid item md={12} className={classes.roomListButtonGroup}>
                <Button
                  color="default"
                  disableElevation
                  variant="contained"
                  className={classes.createNewTypeButton}
                  onClick={() => setOpenSelectRoomsModal(true)}
                ><AddOutlined /> {t('ROOMS.AddRoom')}</Button>
              </Grid>
              <Grid item md={12}>
                <MaterialTableComponent
                  columns={roomHeadCells}
                  rows={newRoomsList}
                  onSelect={(ids) => setSelectedRoomIds(ids)}
                  onDelete={() => handleDeleteSelectedRooms()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.roomImagesContent}>
          <Grid item xs={2} md={3}>
            <Typography variant='h6'>{t('ROOMS.RoomTypeImage')}</Typography>
            <Typography variant='caption'>800x600 (px) Max {MAX_IMG_SIZE_MB.ROOM_TYPE}Mb</Typography>
          </Grid>
          <Grid item xs={10} md={9} className={classes.roomImagesUploadContent}>
            <Upload
              beforeUpload={beforeUpload}
              customRequest={handleUpload}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.footerContent}>
          <Button
            onClick={() => history.goBack()}
            className={classes.backButton}
            variant="contained" color="default"
            disableElevation
          >
            {isFormChanged() ? t('ROOMS.Discard'): t('ROOMS.Back')}
          </Button>
          <Button variant="contained" color="primary" disableElevation type="submit">
            {t('ROOMS.Publish')}
          </Button>
        </div>
      </Grid>
      <SelectRoomsModal
        isOpen={openSelectRoomsModal}
        closeModal={() => setOpenSelectRoomsModal(false)}
        rooms={roomsReadyToAdd.filter(room => 
          !newRoomsList.map(r => r.id).includes(room.id))} // filter room already added outside
        isLoading={isListingRoomReadyToAdd}
        onAddRooms={(rooms) => handleAddRooms(rooms)}
      />
      </Form>
    </Container >
  )
}

const mapState = ({roomReducer}) => ({
  isGettingRoomTypeInfo: roomReducer.isGettingRoomTypeInfo,
  roomTypeInfo: roomReducer.roomTypeInfo,
  isSavingRoomType: roomReducer.isSavingRoomType,
  roomRows: selectors.getRoomsInRoomType({roomReducer}),
  roomsReadyToAdd: selectors.getRoomsReadyToAdd({roomReducer}),
  isListingRoomReadyToAdd: roomReducer.isListingRoomReadyToAdd,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    createRoomType,
    updateRoomType,
    getRoomType,
    clearRoomTypeInfo,
    listRoomsReadyToAdd,
  },
  dispatch
)

CreateNewRoomType.propTypes = {
  name: PropTypes.string
}

CreateNewRoomType.defaultProps = {
  name: ''
}

export default Form.create({
  name: 'newRoomType'
})(withRouter(connect(mapState, mapDispatch)(withTranslation('common')(CreateNewRoomType))))
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import session from './sessionReducer'
import authReducer from './auth'
import userReducer from './user'
import bookingReducer from './booking'
import modalReducer from './modal'
import publicReducer from './public'
import roomReducer from './room'
import facilitiesReducer from './facilities'
import cateringsReducer from './caterings'
import discountReducer from './discount'

import discounts from "./discounts";
import bookings from "./bookings"
import users from "./users"
import rooms from "./rooms"
import facilities from "./exfacilities"
import caterings from "./caterings/index"

export default (history) => combineReducers({
  router: connectRouter(history),
  authReducer,
  session,
  userReducer,
  bookingReducer,
  modalReducer,
  publicReducer,
  roomReducer,
  facilitiesReducer,
  cateringsReducer,
  discountReducer,

  discounts,
  bookings,
  users,
  rooms,
  facilities,
  caterings,
})
import { bind } from 'decko'
import BaseApi from './baseApi';
import {c2s} from '../../helpers/helpers';
import {
  SearchListCustomer,
  CreateUser,
  ListUsers,
  UpdateUser,
  GetUserInfo,
  UserBookingAnalytics,
  GetProfile,
  RequestResetPassword,
  ChangePassword,
  ResetPassword
} from '../../constants/endpoints.constants'
export default class User extends BaseApi {
  @bind
  async searchListCustomer(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(SearchListCustomer(), data);
  }

  @bind
  async createUser(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(CreateUser(), c2s(data));
  }

  @bind
  async updateUser(id, data) {
    await this.checkAuthenticatedStatus()
    return await this.http.request.patch(UpdateUser(id), c2s(data));
  }

  @bind
  async deleteUser(id) {
    await this.checkAuthenticatedStatus()
    return await this.http.request.delete(UpdateUser(id));
  }

  @bind
  async updateProfile(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.request.patch(GetProfile(), c2s(data));
  }

  @bind
  async listUsers(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(ListUsers(), c2s(data));
  }

  @bind
  async getUserInfo(id) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(GetUserInfo(id));
  }

  @bind
  async getUserBookingAnalytics(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(UserBookingAnalytics(), c2s(data));
  }

  @bind
  async requestResetPassword(data) {
    return await this.http.post(RequestResetPassword(), c2s(data));
  }

  @bind
  async changePassword(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(ChangePassword(), c2s(data));
  }

  @bind
  async resetPasswordConfirm(data) {
    return await this.http.post(ResetPassword(), c2s(data));
  }

  @bind
  async retrieveExportToken(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(`/user/retrieve_export_token`, c2s(data));
  }
}

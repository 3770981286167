import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import {get} from 'lodash'
// import 
import {checkTokenExpiration} from '../actions/auth'
import {getProfile} from '../actions/public'
import * as paths from '../constants/paths.constants'

//Component
import LoginPage from '../containers/Auth/Login'
import ForgotPasswordPage from '../containers/Auth/ForgotPassword'
import VerifyPage from '../containers/Auth/Verify'
// import RegisterPage from '../containers/Auth/Register'
import ResetPasswordPage from '../containers/Auth/ResetPassword'
import App from '../containers/App/App'


class Router extends React.Component {

  UNSAFE_componentWillMount() {
    if(![
      paths.ResetPasswordPage, 
      paths.ForgotPasswordPage,
      paths.LoginPage,
    ].includes(get(this.props, 'location.pathname'))) {
      this.props.checkTokenExpiration()
    }
  }

  render() {
    const {myProfile} = this.props
    return (
      <Switch>
        <Route path={paths.ResetPasswordPage} component={ResetPasswordPage} />
        {/* <Route path={paths.RegisterPage} component={RegisterPage} /> */}
        <Route exact path={paths.Verifypage} component={VerifyPage} />
        <Route exact path={paths.ForgotPasswordPage}><ForgotPasswordPage /></Route>
        <Route exact path={paths.LoginPage} component={LoginPage} />
        {myProfile && <Route path={paths.HomePage} component={App}/>}
        {/* <Redirect to={paths.HomePage} /> */}
      </Switch>
    )
  }
}

const mapState = ({ publicReducer }) => ({
  myProfile: publicReducer.profile,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    checkTokenExpiration,

  },
  dispatch
)

Router.propTypes = {
  checkTokenExpiration: PropTypes.func
}

export default connect(mapState, mapDispatch) (withRouter(Router))

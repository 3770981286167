import { combineReducers } from 'redux'
import * as C from "../../actionTypes/discounts/edit";
import { DISCOUNT } from "../../constants/models";
import moment from 'moment'
import * as _ from 'lodash'

const { OBJECT_TYPE } = DISCOUNT

const data = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      return payload
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT:
      return true
    case C.RETRIEVE_DISCOUNT_COMPLETED:
    case C.RETRIEVE_DISCOUNT_FAILED:
      return false
    default:
      return state
  }
}

const name = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_DISCOUNT_NAME:
      return payload
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      return _.get(payload, 'name')
    default:
      return state
  }
}

const description = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_DISCOUNT_DESCRIPTION:
      return payload
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      return _.get(payload, 'description')
    default:
      return state
  }
}

const selectedApplyTo = (state = DISCOUNT.OBJECT_TYPE.CUSTOMER, action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      return _.get(payload, "object_type")
    case C.CHANGE_SELECTED_APPLY_TO:
      return payload
    default:
      return state;
  }
}

const selectedApplyType = (state = DISCOUNT.APPLY_TYPE.ALL, action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      return _.get(payload, "apply_type")
    case C.CHANGE_SELECTED_APPLY_TYPE:
      return payload
    case C.CHANGE_SELECTED_APPLY_TO:
      return DISCOUNT.APPLY_TYPE.ALL
    default:
      return state;
  }
}

const roomDiscount = (state = 0, action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      return _.get(payload, "discount_room")
    case C.CHANGE_ROOM_DISCOUNT:
      if (payload < 0) {
        return 0
      } else if (payload > 100) {
        return 100
      } else {
        return payload
      }
    default:
      return state;
  }
}

const facilityDiscount = (state = 0, action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      return _.get(payload, "discount_exfacility")
    case C.CHANGE_FACILITY_DISCOUNT:
      if (payload < 0) {
        return 0
      } else if (payload > 100) {
        return 100
      } else {
        return payload
      }
    default:
      return state;
  }
}

const cateringDiscount = (state = 0, action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      return _.get(payload, "discount_catering")
    case C.CHANGE_CATERING_DISCOUNT:
      if (payload < 0) {
        return 0
      } else if (payload > 100) {
        return 100
      } else {
        return payload
      }
    default:
      return state;
  }
}

const startDate = (state = moment(), action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      const startAt = _.get(payload, "start_at")
      return moment(startAt)
    case C.CHANGE_START_DATE:
      return payload
    default:
      return state;
  }
}

const endDate = (state = moment().add(1, "years"), action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      const endAt = _.get(payload, "end_at")
      return moment(endAt)
    case C.CHANGE_END_DATE:
      return payload
    default:
      return state;
  }
}

const isActive = (state = false, action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_DISCOUNT_COMPLETED:
      return _.get(payload, "is_active")
    case C.TOGGLE_IS_ACTIVATE:
      return !state
    default:
      return state;
  }
}

const searchCustomer = combineReducers({
  page: (state = 1, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER_COMPLETED:
        return _.get(payload, "page")
      case C.CHANGE_CUSTOMER_SEARCH_PAGE:
        return payload
      default:
        return state;
    }
  },
  pageSize: (state = 5, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER_COMPLETED:
        return _.get(payload, "page_size")
      default:
        return state;
    }
  },
  total: (state = 0, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER_COMPLETED:
        return _.get(payload, "count")
      default:
        return state;
    }
  },
  items: (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER_COMPLETED:
        return _.get(payload, "results")
      default:
        return state;
    }
  },
  searchText: (state = "", action) => {
    const { type, payload } = action
    switch (type) {
      case C.CHANGE_CUSTOMER_SEARCH_TEXT:
        return payload
      default:
        return state;
    }
  },
  isSearching: (state = false, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER:
      case C.CHANGE_CUSTOMER_SEARCH_PAGE:
        return true
      case C.SEARCH_CUSTOMER_COMPLETED:
      case C.SEARCH_CUSTOMER_FAILED:
        return false
      default:
        return state;
    }
  }
})

const searchRoom = combineReducers({
  page: (state = 1, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_ROOM_COMPLETED:
        return _.get(payload, "page")
      case C.CHANGE_ROOM_SEARCH_PAGE:
        return payload
      default:
        return state;
    }
  },
  pageSize: (state = 5, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER_COMPLETED:
        return _.get(payload, "page_size")
      default:
        return state;
    }
  },
  total: (state = 0, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_ROOM_COMPLETED:
        return _.get(payload, "count")
      default:
        return state;
    }
  },
  items: (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_ROOM_COMPLETED:
        return _.get(payload, "results")
      default:
        return state;
    }
  },
  searchText: (state = "", action) => {
    const { type, payload } = action
    switch (type) {
      case C.CHANGE_ROOM_SEARCH_TEXT:
        return payload
      default:
        return state;
    }
  },
  isSearching: (state = false, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_ROOM:
      case C.CHANGE_ROOM_SEARCH_PAGE:
        return true
      case C.SEARCH_ROOM_COMPLETED:
      case C.SEARCH_ROOM_FAILED:
        return false
      default:
        return state;
    }
  }
})

const selectedCustomers = combineReducers({
  ids: (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case C.RETRIEVE_DISCOUNT_COMPLETED:
        if (_.get(payload, "object_type") == OBJECT_TYPE.CUSTOMER)
          return _.get(payload, "apply_objects")
        return state
      case C.ADD_CUSTOMER:
        return [...state, _.get(payload, 'id')]
      case C.REMOVE_CUSTOMER:
        return _.filter(state, id => id !== _.get(payload, 'id'))
      default:
        return state;
    }
  },
  isFetching: (state = false, action) => {
    const { type, payload } = action
    switch (type) {
      case C.LIST_CUSTOMERS:
        return true
      case C.LIST_CUSTOMERS_COMPLETED:
      case C.LIST_CUSTOMERS_FAILED:
        return false
      default:
        return state
    }
  },
  items: (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case C.LIST_CUSTOMERS_COMPLETED:
        return _.get(payload, "results")
      default:
        return state
    }
  },
  page: (state = 1, action) => {
    const { type, payload } = action
    switch (type) {
      case C.CHANGE_CUSTOMERS_LIST_PAGE:
        return payload
      default:
        return state
    }
  },
  pageSize: (state=20, action) => {
    const { type, payload } = action
    switch (type) {
      default:
        return state
    }
  },
})

const selectedRooms = combineReducers({
  ids: (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case C.RETRIEVE_DISCOUNT_COMPLETED:
        if (_.get(payload, "object_type") == OBJECT_TYPE.ROOM)
          return _.get(payload, "apply_objects")
        return state
      case C.ADD_ROOM:
        return [...state, _.get(payload, 'id')]
      case C.REMOVE_ROOM:
        return _.filter(state, id => id !== _.get(payload, 'id'))
      default:
        return state;
    }
  },
  items: (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case C.LIST_ROOMS_COMPLETED:
        return _.get(payload, "results")
      default:
        return state
    }
  },
  page: (state = 1, action) => {
    const { type, payload } = action
    switch (type) {
      case C.CHANGE_ROOMS_LIST_PAGE:
        return payload
      default:
        return state
    }
  },
  pageSize: (state=20, action) => {
    const { type, payload } = action
    switch (type) {
      default:
        return state
    }
  },
})

const isSubmiting = (state = false, action) => {
  const {type, payload} = action
  switch (type) {
    case C.SUBMIT_SAVE:
      return true
    case C.SUBMIT_SAVE_COMPLETED:
    case C.SUBMIT_SAVE_FAILED:
      return false
    default:
      return state;
  }
}

const reducers = combineReducers({
  data,
  isFetching,
  name,
  description,
  selectedApplyTo,
  selectedApplyType,
  roomDiscount,
  facilityDiscount,
  cateringDiscount,
  startDate,
  endDate,
  isActive,
  searchCustomer,
  selectedCustomers,
  searchRoom,
  selectedRooms,
  isSubmiting,
})

export default reducers

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { Container, Grid, Typography, TextField, Button, IconButton } from '@material-ui/core'
import MaterialTableComponent from '../../../components/Table/MaterialTableHandlePaging'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { CreateOutlined, RemoveRedEyeOutlined } from '@material-ui/icons'

import { bindActionCreators } from 'redux'
import * as selectors from '../../../selectors/room'
import { listRoomTypes } from '../../../actions/room'
import LoadingIcon from 'mdi-react/LoadingIcon'
import { pathWithId } from '../../../helpers/helpers'
import { withTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
  root: {},
  formField: {
    borderRadius: '5px',
    width: '40%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  formFilterField: {
    borderRadius: '5px',
    width: '20%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px'
  },
  searchButton: {
    color: theme.colorAccent,
    marginRight: '20px'
  },
  newBookingButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '40px'
  }
}))

const ListRoomTypes = props => {
  const classes = useStyles()

  const {
    listRoomTypes,
    roomTypeRows,
    isListingRoomTypes,
    t,
  } = props
  
  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
    { key: 'name', numeric: true, disablePadding: false, label: t('ROOMS.RoomTypes') },
    { key: 'num', numeric: true, disablePadding: false, label: t('ROOMS.NoOfRoom') },
    { key: 'description', numeric: true, disablePadding: false, label: t('ROOMS.Description') },
    {
      key: 'action',
      label: t('ROOMS.Action'),
      render: (_, row) => {
        return (
          <>
            <Link to={pathWithId(paths.ViewRoomTypeDetail, row.id)}>
              <IconButton
                aria-label="toggle password visibility"
              // onClick={handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
              >
                <RemoveRedEyeOutlined />
              </IconButton>
            </Link>
            <Link to={pathWithId(paths.UpdateRoomType, row.id)}>
              <IconButton
                aria-label="toggle password visibility"
              // onClick={handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
              >
                <CreateOutlined />
              </IconButton>
            </Link>
          </>
        )
      }
    }
  ]

  useEffect(() => {
    listRoomTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      {isListingRoomTypes ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container>
        <Grid item md={12}>
          <Grid item md={4} className={classes.newBookingButtonContent}>
            <Link to={paths.NewRoomTypes}>
              <Button color="primary" variant="contained" disableElevation>
                {t("ROOMS.AddRoomType")}
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <MaterialTableComponent
            title={t('ROOMS.RoomTypes')}
            columns={headCells}
            rows={roomTypeRows}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

const mapState = ({ roomReducer }) => ({
  isListingRoomTypes: roomReducer.isListingRoomTypes,
  roomTypeRows: selectors.roomTypeRows({ roomReducer }),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    listRoomTypes
  },
  dispatch
)

ListRoomTypes.propTypes = {
  className: PropTypes.string,
  listRoomTypes: PropTypes.func,
}

ListRoomTypes.defaultProps = {
  orders: []
}

export default withRouter(connect(mapState, mapDispatch)(withTranslation('common')(ListRoomTypes)))

import React from 'react'
import PropTypes from 'prop-types'

const CheckBoxLabel = props => {
  
  const {label, width, backgroundColor, height, id, className, disabled, onChange, onShiftClick, onShiftHover, checked, removeHoverData } = props //onChange, value
  // document.addEventListener('keyup', (event) => {
  //   if(event.keyCode === 16) {
  //     console.log(event.key)
  //     // console.log(event.keyCode)
  //     // removeHoverData()
  //   }
  // })
  return (
    <div id="checkbox-label-content">
      <input type="checkbox" id={`mycheck-${label}-${id}`} disabled={disabled}
        // onChange={(e) => {
        //   if(!e.shiftKey) {
        //     console.log('ee')
        //     onChange()
        //   }
        // }}
        checked={checked}
        onClick={(e) => {
          onChange()
          if(e.shiftKey) {
            onShiftClick()
            // console.log('eeeee')
          }
        }}
      />
      <label id="checklabel" htmlFor={`mycheck-${label}-${id}`}
        style={{
          width,
          backgroundColor,
          height
        }}
        className={`${className} ${disabled && 'disabled'}`}
        onMouseOver={e => {
          if(e.shiftKey) {
            onShiftHover()
          } 
        }}
      >
        <span>
          {label}
        </span>
      </label>
    </div>
  )
}

CheckBoxLabel.propTypes = {
  label: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  id: PropTypes.any,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  onShiftHover: PropTypes.func,
  onShiftClick: PropTypes.func,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  removeHoverData: PropTypes.func,
}

CheckBoxLabel.defaultProps = {
  label: '',
  onChange: (value) => (value),
  value: false,
  width: '135px',
  height: '35px'
}

export default CheckBoxLabel
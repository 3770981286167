import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import BookingCalendarComponent from './components/BookingCalendar'
import { bindActionCreators } from 'redux'
import {getBookingSummary} from '../../actions/booking'
import moment from 'moment'
class BookingCalendar extends Component {

  UNSAFE_componentWillMount() {
    const {getBookingSummary} = this.props
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
    const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD')
    console.log('cang')
    getBookingSummary({
      date_range: [startOfMonth, endOfMonth]
    })
  }

  render() {
    const {listBookingSummaries} = this.props
    return (
      <div className="view-all-booking">
        <BookingCalendarComponent 
          listBookingSummaries={listBookingSummaries}
        />
      </div>
    )
  }
}

const mapState = ({bookingReducer}) => ({
  listBookingSummaries: bookingReducer.listBookingSummaries
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getBookingSummary
  },
  dispatch
)

BookingCalendar.propTypes = {
  getBookingSummary: PropTypes.func,
  listBookingSummaries: PropTypes.array
}

export default connect(mapState, mapDispatch) (BookingCalendar)
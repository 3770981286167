export const DISCOUNT = {
  OBJECT_TYPE: {
    ROOM: 1,
    CUSTOMER: 2,
  },

  APPLY_TYPE: {
    ALL: 1,
    NEW: 2,
    CUSTOM: 3,
  },

  DISCOUNT_TO: {
    ALL: 7
  },

  LIST_BY: {
    ACTIVE: 0,
    INACTIVE: 1,
  },

  STATUS: {
    ACTIVE: 0,
    INACTIVE: 1,
    EXPIRED: 2,
  }
}

export const BOOKING = {
  PAYMENT_METHOD: {
    STRIPE: 1,
    INVOICE: 2,
    OTHER: 3,
  },

  PAYMENT_STATUS: {
    OUTSTANDING: 1,
    DONE: 2,
    FAILED: 3,
    CANCELLED: 4,
    PARTIAL_REFUND: 5,
    FULL_REFUND: 6,
  },

  BOOKING_STATUS: {
    SCHEDULED: 1,
    CANCELLED: 2,
  },

  ITEM_TYPE: {
    ROOM: 1,
    FACILITY: 2,
    CATERING: 3
  },

  LIST_BY: {
    INCOMING: 0,
    HISTORY: 1,
  }
}

export const USER = {
  LIST_BY: {
    STAFF: 0,
    CUSTOMER: 1
  },

  ROLE: {
    CUSTOMER: 0,
    STAFF: 1,
    ADMIN: 2,
    UNKNOWN: 999,
  },
}

export const ROOM = {
  LIST_BY: {
    ROOM: 0,
    ROOM_TYPE: 1,
  }
}

export const CATERING = {
  LIST_BY: {
    ACTIVE: 0,
    INACTIVE: 1
  }
}

export const EXFACILITY = {
  LIST_BY: {
    ACTIVE: 0,
    INACTIVE: 1,
  }
}

export const VALIDATION_STATUS = {
  ERROR: "error",
  SUCCESS: "success",
}

export const FORMAT = {
  DATE: "DD.MM.YYYY"
}

import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import { message } from 'antd'
import * as C from '../../actionTypes/exfacilities/detail'
import { history } from '../../config/store'
import * as _ from 'lodash'

function* retrieveFacility({api}, {payload}) {
  try {
    const response = yield call(api.facilities.retrieveExtraFacility, payload)
    yield put({type: C.RETRIEVE_EXFACILITY_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.RETRIEVE_EXFACILITY_FAILED, payload: e})
    message.error("Error: ", _.get(e, 'data.detail'))
    console.error(e)
  }
}

function detailSaga(deps) {
  return [
      takeLeading(C.RETRIEVE_EXFACILITY, retrieveFacility, deps),

  ]
}

export default detailSaga

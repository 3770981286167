import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Grid, Typography, Button as MaterialButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Input, Button, Form, message } from 'antd'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import LoadingIcon from 'mdi-react/LoadingIcon'
import MaterialTableComponent from '../../../components/Table/MaterialTable'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import { attachSessionCustomer, searchListCustomerInBooking,
  onChangeBackupDateInSelectCustomerBooking
  } from '../../../actions/booking'
import {withTranslation} from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 0'
  },
  title: {
    padding: '30px 0 10px',
    marginBottom: '20px',
    color: theme.colorAccent,
    width: 'max-content',
    borderBottom: `2px solid ${theme.colorAccent}`
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchField: {
    width: '40%',
    margin: '0 10px'
  },
  searchButton: {
    display: 'flex',
    alignItems: 'center',
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  emptyText: {
    position: 'relative'
  }
}))

const SelectCustomer = props => {
  const classes = useStyles()
  const { history, searchListCustomerInBooking, isSearchingListCustomerInBooking, listCustomerInBooking,
    attachSessionCustomer, bookingSession, listCustomerInBookingMeta, isAttachingBookingCustomer,
    onChangeBackupDateInSelectCustomerBooking, backupDataInSelectCustomerBooking, t, i18n

  } = props
  const searchValue = get(backupDataInSelectCustomerBooking, 'searchValue', null)
  const selectedCustomer = get(backupDataInSelectCustomerBooking, 'selectedCustomer', null)
  const [searched, setSearched] = useState(false)
  // const [selectedCustomer, setSelectedCustomer] = useState({})
  const [loading, setLoading] = useState(false)
  const [getListUserStatus, setGetListUserStatus] = useState(false)
  useEffect(() => {
    if(!bookingSession.id) {
      history.push(paths.CreateNewBooking_FoundRoom)
    }
    if(!getListUserStatus) {
      let data = {
        page_size: 10,
        page: 1,
        filter: 'customer'
      }
      if(searchValue || searchValue !== '') {
        data.search = searchValue
      }
      searchListCustomerInBooking(data)
      setGetListUserStatus(true)
    }
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const handleSearch = (e) => {
    e.preventDefault()
    setSearched(true)
    // onChangeBackupDateInSelectCustomerBooking({selectedCustomer: null})
    searchListCustomerInBooking({
      page_size: get(listCustomerInBookingMeta, 'page_size', 5),
      page: 1,
      search: searchValue,
      filter: 'customer'
    })
  }

  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
    { key: 'customer_id', numeric: true, disablePadding: false, label: t('BOOKING.CustomerID') },
    // { key: 'full_name', numeric: true, disablePadding: false, label: t('BOOKING.FullName') },
    { key: 'first_name', numeric: true, disablePadding: false, label: t('BOOKING.FirstName') },
    { key: 'last_name', numeric: true, disablePadding: false, label: t('BOOKING.LastName') },
    { key: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { key: 'company', numeric: true, disablePadding: false, label: t('BOOKING.Company') },
  ]

  const handleNext = () => {
    if(get(selectedCustomer, 'id')) {
      attachSessionCustomer({customer_id: selectedCustomer.id})
    } else {
      message.info(t('BOOKING.PleaseSelectTheCustomerFirst'))
    }
  }

  const handleChangePage = page => {
    searchListCustomerInBooking({
      page_size: get(listCustomerInBookingMeta, 'page_size', 5),
      page,
      search: searchValue,
      filter: 'customer'
    })
  }

  const handleChangePageSize = size => {
    searchListCustomerInBooking({
      page_size: size,
      page: get(listCustomerInBookingMeta, 'page', 1),
      search: searchValue,
      filter: 'customer'
    })
  }

  return (
    <Container>
      {(loading || isSearchingListCustomerInBooking || isAttachingBookingCustomer) ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid className={classes.root}>
       
        <Grid item sm={12} xs={12} md={12} >
          <form onSubmit={handleSearch}
            style={{ width: '100%' }}
            className={classes.searchContent}
          >
            <Input
              value={searchValue}
              onChange={(e) => onChangeBackupDateInSelectCustomerBooking({searchValue: e.target.value})}
              className={classes.searchField}
              placeholder="Type something to search (email, company, name, ...)"
            />
            <Button
              type="primary"
              htmlType='submit'
              className={classes.searchButton}
              loading={isSearchingListCustomerInBooking}
            ><MagnifyIcon />
              &nbsp;{t('BOOKING.Search')}
            </Button>
          </form>
        </Grid>
        <Grid item sm={12} md={12}>
          <MaterialTableComponent
            singleSelect
            columns={headCells}
            rows={listCustomerInBooking || [{ id: 'empty' }]}
            selectedItem={get(selectedCustomer, 'id', null) ? [get(selectedCustomer, 'id', '')] : []}
            // title={`${listCustomerInBooking.length} customers found`}
            onSelect={(data) => onChangeBackupDateInSelectCustomerBooking({selectedCustomer: data})}
            emptyBool={searched && !isSearchingListCustomerInBooking && !listCustomerInBooking.length}
            emptyContent={() => {
              return (
                <Typography
                  className={classes.emptyText}
                  align="center"
                  variant="subtitle1"
                >
                  {t('BOOKING.CannotFoundCustomers')}
                  <Link to={paths.CreateNewBooking_CreateCustomer}>
                    <MaterialButton variant="contained" color="primary">
                      {t('BOOKING.CreateNewCustomer')}
                    </MaterialButton>
                  </Link>
                </Typography>
              )
            }}
            pageSize={get(listCustomerInBookingMeta, 'page_size')}
            page={get(listCustomerInBookingMeta, 'page')}
            total={get(listCustomerInBookingMeta, 'count')}
            onChangePage={(page) => handleChangePage(page)}
            onChangePageSize={(size) => handleChangePageSize(size)}
          />

        </Grid>
        <Grid item md={12}>
          <Typography variant="subtitle1">
            <Link to={paths.CreateNewBooking_CreateCustomer}>{t('BOOKING.ClickHere')}</Link> {t('BOOKING.ToCreateNewCustomerIfNoneAboveMatched')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.footerContent}>
            <MaterialButton
              onClick={() => history.goBack()}
              className={classes.backButton}
            >
              {t('BOOKING.Back')}
            </MaterialButton>
            <Button type="primary"
              onClick={() => handleNext()}
              loading={isAttachingBookingCustomer}
            >
              {t('BOOKING.Next')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

const mapState = ({ bookingReducer }) => ({
  isSearchingListCustomerInBooking: bookingReducer.isSearchingListCustomerInBooking,
  listCustomerInBooking: bookingReducer.listCustomerInBooking,
  listCustomerInBookingMeta: bookingReducer.listCustomerInBookingMeta,
  bookingSession: bookingReducer.bookingSession,
  isAttachingBookingCustomer: bookingReducer.isAttachingBookingCustomer,
  backupDataInSelectCustomerBooking: bookingReducer.backupDataInSelectCustomerBooking,

})

const mapDispatch = dispatch => bindActionCreators(
  {
    searchListCustomerInBooking,
    attachSessionCustomer,
    onChangeBackupDateInSelectCustomerBooking
  },
  dispatch
)

SelectCustomer.propTypes = {
  listCustomerInBooking: PropTypes.array,
  history: PropTypes.object,
  bookingSession: PropTypes.object,
  isSearchingListCustomerInBooking: PropTypes.bool,
  isAttachingBookingCustomer: PropTypes.bool,
  searchListCustomerInBooking: PropTypes.func,
  attachSessionCustomer: PropTypes.func,
  listCustomerInBookingMeta: PropTypes.object,
}

export default withRouter(connect(mapState, mapDispatch)( withTranslation('common') (SelectCustomer)))
import React, { Suspense, useState, useEffect } from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress, Container, Button } from '@material-ui/core'
import {Switch, Route, Redirect, withRouter} from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { NavBar, TopBar } from './components'
import routes from '../../../Routes/ListPrivateRoutes'
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux'
import {get} from 'lodash'
// Component view
import ProfileView from '../../../views/Profile'
import BookingCalendar from '../../../views/BookingCalendar'
import BookingTable from '../../../views/BookingTable'
import CreateNewBooking from '../../../views/CreateNewBooking'
import ViewAllBooking from '../../../views/ViewAllBooking'
import NewRoomTypes from '../../../views/ListingNew'
import NewRoom from '../../../views/ListingNew'
import RoomDetail from '../../../views/RoomDetail'
import ListRooms from '../../../views/ViewAllRoom'
import UpdateRoomType from '../../../views/UpdateRoomType'
import ViewRoomTypeDetail from '../../../views/RoomTypeDetail'
import ListRoomTypes from '../../../views/ViewAllRoom'
import ListFacilities from '../../../views/ListFacilities'
import NewFacility from '../../../views/NewFacility'
import CateringService from '../../../views/CateringService'
import NewService from '../../../views/NewService'
import NewUser from '../../../views/NewUser'
import ListUser from '../../../views/ListUsers'
import UserDetail from '../../../views/UserDetail'

import DiscountManagement from '../../discount/DiscountContainer'
import ListDiscounts from '../../../views/ListDiscounts'
import NewDiscount from '../../../views/NewDiscounts'
import BookingDetail from '../../../views/BookingDetail'
import {withTranslation} from 'react-i18next'
// action
import { getListCaterings, getListExtraFacilities, getCreatedBookingSummary } from '../../../actions/booking' 
import { getConfiguration } from '../../../actions/public' 

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100%',
    // width: '100%',
    display: 'flex',
    // flexDirection: 'column',
    // overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    // position: 'relative'
  },
  container: {
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto',
    position: 'fixed',
    backgroundColor: 'white'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '10px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '270px',
    },
    paddingTop: '80px',
    minHeight: '100vh',
  },
  containerMainContent: {
    // padding: '0 5%',
    // marginTop: '18px',
    // position: 'absolute',
    // width: '78%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '90%',
    },
    marginBottom: '5%',
    [theme.breakpoints.up('md')]: {
      padding: '0 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 0',
    },
  },
  continueBooking: {
    position: 'fixed',
    bottom: '20px',
    right: '20px'
  }
})
)

const Dashboard = props => {
  const { router, getConfiguration, getCreatedBookingSummary, bookingSession, history, t, i18n } = props



  const classes = useStyles()
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false)
  const [getConfigStatus, setGetConfigStatus] = useState(false)
  const [getBookingSessison, setGetBookingSessison] = useState(false)
  const [showContinueBookingButton, setShowContinueBookingButton] = useState(false)
  useEffect(() => {
    if(!getBookingSessison) {
      getCreatedBookingSummary()
      setGetBookingSessison(true)
    }
    if(!getConfigStatus) {
      getConfiguration()
      setGetConfigStatus(true)
    }
    // console.log('dashboard')
    if(!(router.pathname.indexOf(paths.CreateNewBooking) > -1)) {
      // console.log('ssss')
      if(get(bookingSession, 'state', 0) > 0) {
        setShowContinueBookingButton(true)
      } else {
        setShowContinueBookingButton(false)
      }
    } else {
      setShowContinueBookingButton(false)
    }
  }, [router, bookingSession, getBookingSessison, getConfigStatus, getConfiguration, getCreatedBookingSummary])
  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true)
  }

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false)
  }

  const handleContinueBooking = () => {
    switch (get(bookingSession, 'state', 0)) {
    case 1:
      history.push(paths.CreateNewBooking_SelectCustomer)
      break
    case 2:
      history.push(paths.CreateNewBooking_AddBookingDetail)
      break
    case 3:
      history.push(paths.CreateNewBooking_NewBookingCreated)
      break
    case 4:
      history.push(paths.CreateNewBooking_Payment)
      break
    case 5:
      history.push(paths.CreateNewBooking_BookingConfirm)
      break
    default:
      break
    }
  }

  return (
    <div className={classes.root}>
      <NavBar
        className={classes.navBar}
        onMobileClose={handleNavBarMobileClose}
        openMobile={openNavBarMobile}
      />
      <div className={classes.container}>
        <TopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
        <main className={classes.content}>
          <Container className={classes.containerMainContent}>
            {/* <Suspense fallback={<LinearProgress />}>
              {renderRoutes(route.routes)}
            </Suspense> */}
            <Switch>
              <Route path={paths.ProfilePage} component={ProfileView} />
              <Route path={paths.BookingCalendar} component={BookingCalendar} />
              <Route path={paths.BookingTable} component={BookingTable} />
              {/* <Route path={paths.CreateNewBooking_FoundRoom} component={ProfileView} />
              <Route path={paths.CreateNewBooking_SelectCustomer} component={ProfileView} />
              <Route path={paths.CreateNewBooking_AddBookingDetail} component={ProfileView} />
              <Route path={paths.CreateNewBooking_NewBookingCreated} component={ProfileView} />
              <Route path={paths.CreateNewBooking_Payment} component={ProfileView} /> */}

              <Route path={paths.CreateNewBooking} component={CreateNewBooking} />
              <Route path={paths.ViewAllBooking} component={ViewAllBooking} />
              <Route path={paths.UpdateRoom} component={NewRoom} />
              <Route path={paths.NewRoom} component={NewRoom} />
              <Route path={paths.NewRoomTypes} component={NewRoomTypes} />
              <Route path={paths.RoomDetail} component={RoomDetail} />
              <Route path={paths.ListRooms} component={ListRooms} />
              <Route path={paths.UpdateRoomType} component={UpdateRoomType} />
              <Route path={paths.ViewRoomTypeDetail} component={ViewRoomTypeDetail} />
              <Route path={paths.ListRoomTypes} component={ListRoomTypes} />
              <Route path={paths.ListCategory} component={ListFacilities} />
              <Route path={paths.ListFacilities} component={ListFacilities} />
              <Route path={paths.NewFacility} component={NewFacility} />
              <Route path={paths.CateringService} component={CateringService} />
              <Route path={paths.NewService} component={NewService} />
              <Route path={paths.NewUser} component={NewUser} />
              <Route path={paths.UpdateUser} component={NewUser} />
              <Route path={paths.UserDetail} component={UserDetail} />
              <Route path={paths.ListUser} component={ListUser} />
              <Route path={paths.ListDiscounts} component={DiscountManagement} />
              <Route path={paths.NewDiscount} component={NewDiscount} />
              <Redirect to={paths.BookingCalendar} />
              {/* {routes.map((item , index) => {
                return (
                )
              })} */}
              {/* <Route path={paths.} /> */}
            </Switch>
            {showContinueBookingButton && 
            <Button
              className={classes.continueBooking}
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => handleContinueBooking()}
            >
              {t('BOOKING.ContinueBooking')}
            </Button>}
          </Container>
        </main>
      </div>
    </div>
  )
}

const mapState = ({router, bookingReducer, publicReducer}) => ({
  router: router.location,
  configurationInfo: publicReducer.configurationInfo,
  listExtraFacilities: bookingReducer.listExtraFacilities,
  listCaterings: bookingReducer.listCaterings,
  bookingSession: bookingReducer.bookingSession
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getConfiguration,
    getListCaterings,
    getListExtraFacilities,
    getCreatedBookingSummary
  },
  dispatch
)

Dashboard.propTypes = {
  route: PropTypes.object,
  bookingSession: PropTypes.object,
  getCreatedBookingSummary: PropTypes.func,
  history: PropTypes.bool,
}

export default withRouter(connect(mapState, mapDispatch) ( withTranslation('common') (Dashboard)))

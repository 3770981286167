import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Grid, Button as MaterialButton, Typography } from '@material-ui/core'
import { Form, Input, Select, TreeSelect, Icon, Tooltip, Typography as AntdTypography } from 'antd'
import { roundToUnit } from '../../../helpers/helpers'
import { CURRENCY_MAPPING } from '../../../helpers/constant'
import { formatMoney } from '../../../helpers/bookings'
import { sum, get } from 'lodash'
import RemoveIcon from 'mdi-react/DeleteOutlineIcon'
import AddIcon from 'mdi-react/AddIcon'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { searchCaterings, removeSessionItems, addSessionCatering, retrieveCateringItemPricings } from '../../../actions/booking'
import { withTranslation  } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  roomImageGrid: {
    overflow: 'hidden',
    '& img': {
      maxHeight: '200px'
    }
  },
  justifyContentCenter: {
    justifyContent: 'center'
  },
  roomInfoContent: {
    padding: '0 40px'
  },
  priceContent: {
    borderTop: '1px solid #ddd',
    justifyContent: 'space-between',
    // position: 'relative',
    marginTop: '40px'
  },
  priceText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#cb4c4d'
  },
  priceAmount: {
    textTransform: 'uppercase',
    fontSize: '20px',
    color: '#cb4c4d'
  },
  roomName: {
    color: '#cb4c4d',
    padding: '0px 0 10px'
  },
  buttonGroup: {
    // position: 'relative',
    // bottom: '-20px',
    // right: '-55px',
    display: 'flex',
    justifyContent: 'space-between',
    // width: 'max-content',
    '& button': {
      margin: '0 10px'
    }
  },
  extraServices: {
    marginTop: '20px',
    padding: '0'
  },
  serviceContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0'
  },
  roomPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '10px'
  },
  formField: {
    borderRadius: '15px',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '15px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '15px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    }
  },
  serviceLabel: {
    fontWeight: 'bold'
  },
  detailInfo: {
    lineHeight: '25px',
    '& p': {
      marginBottom: 0,
    }
  },
  printButton: {
    position: 'relative',
    right: '70px',
    color: theme.colorAccent,
    '& svg': {
      position: 'relative',
      top: 5
    }
  },
  selectField: {
    '& .ant-select': {
      width: '80%',
    },
    '& .ant-form-item': {
      marginBottom: '0'
    }
  },
  faclityItem: {
    margin: '5px 0'
  },
  inputField: {
    '& .ant-input': {
      width: '80%',
    }
  },
  facilitiesServiceContent: {
    borderTop: '1px solid #ddd',
    padding: '20px'
  },
  cateringContent: {
    borderTop: '1px solid #ddd',
    padding: '20px'
  },
  buttonAddItem: {
    color: theme.colorAccent
  },
  faclityItemName: {
    display: 'flex',
    alignItems: 'center'
  },
  totalPrice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  totalPriceExample: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px'
  },
  formContent: {
    marginBottom: '20px'
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  textEllipsis: {
    textOverflow: 'ellipsis',
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '15px',
    color: '#9e9e9e'
  },
  discountInputContent: {
    display: 'flex',
  }
}))

const AddCateringForm = props => {
  const classes = useStyles()
  const { form: { validateFields, getFieldDecorator, resetFields, getFieldValue, setFieldsValue },
    bookingSession, listCaterings, addSessionCatering, isGettingCaterings, configurationInfo, bookingRequest,
    retrieveCateringItemPricings, cateringPricings, removeSessionItems, t, i18n
  } = props

  let listRoomSelecting = get(bookingSession, 'items', []).filter(item => item.item_type === 1)
  let listCateringsSelecting = get(bookingSession, 'items', []).filter(item => item.item_type === 3)
  listRoomSelecting = listRoomSelecting.filter(item => {
    if (!listCateringsSelecting.find(it => it.date === item.date && getFieldValue('catering') === get(it, 'item_id'))) {
      return item
    }
  })

  const addCaterings = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        const listData = []
        const { discount } = values
        const data = {
          item_id: values.catering,
          quantity: values.quantity,
          date: values.date,
          time: listRoomSelecting.find(item => item.date === values.date).timeslots,
          price: Number(values.price) * 100,
          // discount: Number(values.discount),
          item_type: 3
        }
        if (discount !== '') {
          data.discount = Number(discount)
        }
        listData.push(data)
        addSessionCatering({ items: listData })
        resetFields()
        // listCaterings = listCaterings.filter(item => item.id !== values.catering)
      }
    })
  }

  // console.log(listCaterings)
  let listAvailableCaterings = (listCaterings).filter(item => get(item, 'min_participants', 0) <= get(bookingSession, 'participants', 0))
  let listAvailableCateringsCombo = (listAvailableCaterings).filter(item => item.is_combo)
  let listAvailableCateringsSingle = (listAvailableCaterings).filter(item => !item.is_combo)
  // listCaterings.length > 0 && (bookingSession.items || []).filter(item => item.item_type === 3).map(item => {
  //   listAvailableCaterings = (listAvailableCaterings || []).filter(it => it.id !== item.item_id)
  // })

  const listCateringsSelected = _.chain(bookingSession.items || []).filter(item => item.item_type === 3).groupBy(item => {
    return item.date
  }).map((value, key) => ({
    name: key,
    items: _.chain(value || []).groupBy(it => {
      return it.item.name
    }).map((value2, key2) => ({ name: key2, items: value2 })).value()
  })).value()

  // console.log(listCateringsSelected)

  // _.chain(bookingSession.items || []).filter(item => item.item_type === 3).groupBy(item => {
  //   return item.item.name
  // }).map((value, key) => ({ name: key, items: value })).value()
  const getItemPriceDefault = data => {
    const listData = [{
      item_id: getFieldValue('catering'),
      quantity: Number(data),
      date: getFieldValue('date'),
      time: listRoomSelecting.find(item => item.date === getFieldValue('date')).timeslots,
      item_type: 3
    }]
    retrieveCateringItemPricings({ items: listData })
  }

  // const priceDefault = _.mean((cateringPricings || []).map(item => item.price)) / get(configurationInfo, 'unit', 100) || 0
  // const discountDefault = _.mean((cateringPricings || []).map(item => item.discount))

  useEffect(() => {
    const priceDefault = _.mean((cateringPricings || []).map(item => item.price)) / get(configurationInfo, 'unit', 100) || 0
    const discountDefault = _.mean((cateringPricings || []).map(item => item.discount))
    setFieldsValue({
      price: priceDefault,
      discount: discountDefault
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cateringPricings])

  const subTotal = Number(getFieldValue('price') || 0) * Number(getFieldValue('quantity') || 0)
  const total = subTotal - (subTotal * Number(getFieldValue('discount') || 0)) / 100
  return (
    <Grid item xs={12} md={12} className={classes.cateringContent} >
      <Form onSubmit={addCaterings}>
        <Grid container >
          <Grid item xs={3}>
            <Typography variant="h6" component="h6">
              {t('BOOKING.CateringService')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" component="h6">
              {t('BOOKING.Date')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" component="h6">
              {t('BOOKING.Quantity')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" component="h6">
              {t('BOOKING.Price')}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6" component="h6">
              {t('BOOKING.Discount')}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6" component="h6" align="center">
              {t('BOOKING.Total')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.formContent} >
          <Grid item xs={3} className={classes.selectField}>
            <Form.Item>
              {
                getFieldDecorator('catering', {
                  rules: [{ required: true, message: t('BOOKING.RequireCatering') }],
                  getValueFromEvent: (value) => {
                    if (value && getFieldValue('date')) {
                      setFieldsValue({
                        quantity: null,
                        price: 0,
                        discount: 0
                      })
                    }
                    return value
                  }
                })(
                  <TreeSelect
                    loading={isGettingCaterings}
                    treeDefaultExpandAll
                  >
                    
                    <TreeSelect.TreeNode selectable={false} title={t('BOOKING.DailyAllInclusive')}
                      treeNodeLabelProp={'content'}
                    >
                      {listAvailableCateringsCombo.map((item, index) => {
                        // console.log(listAvailableCateringsCombo)
                        return (
                          <TreeSelect.TreeNode key={index} value={item.id} title={
                            <Tooltip placement="right" title={item.description}>
                              {item.name}
                              <p className={classes.textEllipsis}>{item.description}</p>
                              {/* <AntdTypography.Paragraph ellipsis>{item.description}</AntdTypography.Paragraph> */}
                            </Tooltip>
                          }/>
                        )
                      })}
                    </TreeSelect.TreeNode>
                    <TreeSelect.TreeNode selectable={false} value={null} title={t('BOOKING.SingleOrder')}>
                      {listAvailableCateringsSingle.map((item, index) => {
                        // console.log(listAvailableCateringsSingle)
                        return(
                          <TreeSelect.TreeNode key={index} value={item.id} title={item.name} />
                        )
                      })}
                    </TreeSelect.TreeNode>
                    {/* {listAvailableCaterings.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                      )
                    })
                    } */}
                  </TreeSelect>
                )
              }
            </Form.Item>
          </Grid>
          <Grid item xs={2} className={classes.selectField}>
            <Form.Item>
              {
                getFieldDecorator('date', {
                  rules: [{ required: true, message: t('BOOKING.RequireDate' )}],
                })(
                  <Select
                    disabled={isGettingCaterings || !listRoomSelecting.length}
                    // placeholder={listQuantity.length < 1 && 'Not available'}
                    loading={isGettingCaterings}
                  >
                    {listRoomSelecting.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.date}>{item.date}</Select.Option>
                      )
                    })
                    }
                  </Select>
                )
              }
            </Form.Item>
          </Grid>
          <Grid item xs={2} className={classes.inputField}>
            <Form.Item>
              {
                getFieldDecorator('quantity', {
                  rules: [{ required: true, message: t('BOOKING.RequireQuantity') }],
                  getValueFromEvent: (e) => {
                    getItemPriceDefault(e.target.value)
                    return e.target.value
                  }
                })(
                  <Input
                    disabled={isGettingCaterings}
                    type='number'
                    min={1}
                  />
                )
              }
            </Form.Item>
          </Grid>

          <Grid item xs={2}>
            <Form.Item>
              {
                getFieldDecorator('price', {
                  // initialValue: priceDefault,
                  rules: [{ required: true, message: t('BOOKING.RequirePrice') }]
                })(
                  <Input 
                    placeholder="Price"
                    suffix={isGettingCaterings ? <Icon type="loading" /> : CURRENCY_MAPPING[configurationInfo.currency]}
                    type="number"
                    min="0"
                    step="0.01"
                    disabled={!getFieldValue('quantity') || isGettingCaterings}
                  />
                )
              }
            </Form.Item>
          </Grid>
          <Grid item xs={1} className={classes.discountInputContent}>
            <Form.Item>
              {
                getFieldDecorator('discount', {
                  // initialValue: discountDefault,
                  // rules: [{ required: true, message: 'Price is required' }]
                })(
                  <Input
                    // placeholder="0"
                    // defaultValue={0.00}
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                    disabled={!getFieldValue('quantity')}
                    suffix={isGettingCaterings ? <Icon type="loading" /> : '%'}
                  />
                )
              }
            </Form.Item>
          </Grid>
          <Grid item xs={1} className={classes.totalPriceExample}>
            {formatMoney((total || 0) * 100)}
          </Grid>
          <Grid item xs={1} >
            <MaterialButton className={classes.buttonAddItem}
              type="submit"
            ><AddIcon /></MaterialButton>
          </Grid>
        </Grid>
        {listCateringsSelected.map((item) => {
          // const { price } = item
          return (
            <>
              {item.items.map((it, index) => {
                const price = get(it, 'items[0].price', 0)
                const discount = get(it, 'items[0].discount_amount', 0)
                const quantity = get(it, 'items[0].quantity', 0)
                return (
                  <Grid key={index} container className={classes.faclityItem}>
                    <Grid item xs={3} className={classes.alignItemsCenter}>
                      {get(it, 'name', '')}
                    </Grid>
                    <Grid item xs={2} className={classes.alignItemsCenter}>
                      {get(item, 'name', '')}
                    </Grid>
                    <Grid item xs={2} className={classes.alignItemsCenter}>
                      {quantity}
                    </Grid>
                    <Grid item xs={2} className={classes.alignItemsCenter}>
                      {formatMoney(price)}
                    </Grid>
                    <Grid item xs={1} className={classes.alignItemsCenter}>
                      {discount}%
                    </Grid>
                    <Grid item xs={1} className={classes.totalPrice}>
                      {formatMoney((price * quantity) - ((price * discount * quantity)/100))}
                    </Grid>
                    <Grid item xs={1}>
                      <MaterialButton
                        onClick={() => {
                          // const temp = listCateringsSelecting.filter(it => get(it, 'item.name') == item.name && it.date == item.date)
                          removeSessionItems(get(it, 'items', []).map(it => it.id))
                        }}
                      ><RemoveIcon /></MaterialButton>
                    </Grid>

                  </Grid>
                )
              })}
            </>
          )
        })}
      </Form>
    </Grid>

  )
}

const mapState = ({ bookingReducer, publicReducer }) => ({
  bookingSession: bookingReducer.bookingSession,
  configurationInfo: publicReducer.configurationInfo,
  addSessionCatering: bookingReducer.addSessionCatering,
  searchCaterings: bookingReducer.searchCaterings,
  removeSessionItems: bookingReducer.removeSessionItems,
  listCaterings: bookingReducer.listCaterings,
  bookingRequest: bookingReducer.bookingRequest,
  isGettingCaterings: bookingReducer.isGettingCaterings,
  isGettingCateringPrice: bookingReducer.isGettingCateringPrice,
  cateringPricings: bookingReducer.cateringPricings
})

const mapDispatch = dispatch => bindActionCreators(
  {
    searchCaterings,
    removeSessionItems,
    addSessionCatering,
    retrieveCateringItemPricings
  },
  dispatch
)

AddCateringForm.propTypes = {
  form: PropTypes.object,
  bookingSession: PropTypes.object,
  listCaterings: PropTypes.array,
  addSessionCatering: PropTypes.func,
  isGettingCaterings: PropTypes.bool,
  configurationInfo: PropTypes.object,
  bookingRequest: PropTypes.object,
}

export default Form.create({
  name: 'addCateing'
})(connect(mapState, mapDispatch)( withTranslation('common') (AddCateringForm)))
import React, { Component } from 'react'
import { withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Input, Form, Switch, Select } from 'antd'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import ASSETS from '../../constants/assets'

import { USER } from '../../constants/models'

const { COUNTRIES } = ASSETS

const StaffView = ({
  t,

  email,
  firstName,
  lastName,
  isActive,
  isAdmin,

  isSubmitting,
  onSubmit,

  form,
}) => {
  const onFinish = (values) => {
    console.log('Success: ', values)
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const { getFieldDecorator, validateFields } = form

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onSubmit={e => {
        e.preventDefault()
        validateFields((errors, values) => {
          if (!errors) {
            // Do something
            console.log(values)
            onSubmit(values)
          }
        })
      }}
    >
      <Form.Item
        label={t("First name")}
        name="firstName"
      >
        {getFieldDecorator('first_name', {
          rules: [{ required: true, message: t('Please input First Name!') }],
          initialValue: firstName
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Last name")}
        name="lastName"
      >
        {getFieldDecorator('last_name', {
          rules: [{ required: true, message: t('Please input Last Name!') }],
          initialValue: lastName
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Email")}
        name="email"
      >
        {getFieldDecorator('email', {
          rules: [{ required: true, message: t('Please input Email!') }],
          initialValue: email
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Is Admin")}
        name="isAdmin"
        valuePropName="checked"
      >
        {getFieldDecorator('is_admin', {
          valuePropName: 'checked',
          initialValue: isAdmin,
        })(
          <Switch />
        )}
      </Form.Item>

      <Form.Item
        label={t("Is Active")}
        name="isActive"
        valuePropName="checked"
      >
        {getFieldDecorator('is_active', {
          valuePropName: 'checked',
          initialValue: isActive,
        })(
          <Switch />
        )}
      </Form.Item>

      <Form.Item labelCol={{ span: 4 }} wrapperCol={{span: 20}} style={{textAlign: "right"}}>
        <Button 
          variant="contained" 
          color="primary" 
          type="primary" 
          htmlType="submit"
          disabled={isSubmitting}
        >
          {t("Submit")}
        </Button>
      </Form.Item>
    </Form>
  )
}

const CustomerView = ({
  t,

  email,
  firstName,
  lastName,
  phone,
  street,
  houseNumber,
  postCode,
  city,
  country,
  company,
  customerNo,
  addition,
  isCompanyCustomer,
  isLongTermCustomer,
  isActive,
  adAccepted,

  isSubmitting,
  onSubmit,

  form,
}) => {
  const { getFieldDecorator, validateFields } = form

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 12 }}
      layout="horizontal"
      onSubmit={e => {
        e.preventDefault()
        validateFields((errors, values) => {
          if (!errors) {
            // Do something
            console.log(values)
            onSubmit(values)
          }
        })
      }}
    >
      <Form.Item
        label={t("Is Company Customer")}
        name="isCompanyCustomer"
        valuePropName="checked"
      >
        {getFieldDecorator('is_belong_to_company', {
          valuePropName: 'checked',
          initialValue: isCompanyCustomer
        })(
          <Switch />
        )}
      </Form.Item>

      <Form.Item
        label={t("Company")}
        name="company"
      >
        {getFieldDecorator('company', {
          initialValue: company
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("First name")}
        name="firstName"
      >
        {getFieldDecorator('first_name', {
          rules: [{ required: true, message: t('Please input First Name!') }],
          initialValue: firstName,
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Last name")}
        name="lastName"
      >
        {getFieldDecorator('last_name', {
          rules: [{ required: true, message: t('Please input Last Name!') }],
          initialValue: lastName,
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Email")}
        name="email"
      >
        {getFieldDecorator('email', {
          rules: [{ required: true, message: t('Please input Email!'), type: "email" }],
          initialValue: email,
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Phone")}
        name="phone"
      >
        {getFieldDecorator('phone', {
          initialValue: phone
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Street")}
        name="street"
      >
        {getFieldDecorator('street', {
          rules: [{ required: true, message: t('Please input Street name!') }],
          initialValue: street,
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("House Number")}
        name="houseNumber"
      >
        {getFieldDecorator('house_nbr', {
          rules: [{ required: true, message: t('Please input House Number!') }],
          initialValue: houseNumber
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Postcode")}
        name="postcode"
      >
        {getFieldDecorator('post_code', {
          rules: [{ required: true, message: t('Please input Postcode!') }],
          initialValue: postCode
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("City")}
        name="city"
      >
        {getFieldDecorator('city', {
          rules: [{ required: true, message: t('Please input City!') }],
          initialValue: city
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Country")}
        name="country"
      >
        {getFieldDecorator('country', {
          rules: [{ required: true, message: t('Please input Country!') }],
          initialValue: country
        })(
          // <Input />
          <Select
            showSearch
          >
            {_.map(COUNTRIES, c => (
              <Select.Option value={_.get(c, 'en')}>{_.get(c, 'en')}</Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item
        label={t("Customer No")}
        name="customerNo"
      >
        {getFieldDecorator('customer_id', {
          initialValue: customerNo
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Addition")}
        name="addition"
      >
        {getFieldDecorator('addition', {
          initialValue: addition
        })(
          <Input />
        )}
      </Form.Item>

      

      <Form.Item
        label={t("Is Long-Term Customer")}
        name="isLongTermCustomer"
        valuePropName="checked"
      >
        {getFieldDecorator('is_long_term_customer', {
          valuePropName: 'checked',
          initialValue: isLongTermCustomer,
        })(
          <Switch />
        )}
      </Form.Item>

      <Form.Item
        label={t("Is Active")}
        name="isActive"
        valuePropName="checked"
      >
        {getFieldDecorator('is_active', {
          valuePropName: 'checked',
          initialValue: isActive,
        })(
          <Switch />
        )}
      </Form.Item>

      <Form.Item
        label={t("Ad")}
        name="adAccepted"
        valuePropName="checked"
      >
        {getFieldDecorator('ad_accepted', {
          valuePropName: 'checked',
          initialValue: adAccepted,
        })(
          <Switch />
        )}
      </Form.Item>
        
      <Form.Item labelCol={{ span: 4 }} wrapperCol={{span: 20}} style={{textAlign: "right"}}>
        <Button 
          variant="contained" 
          color="primary" 
          type="primary" 
          htmlType="submit"
          disabled={isSubmitting}
        >
          {t("Submit")}
        </Button>
      </Form.Item>
    </Form>
  )
}

const UserEdit = (props) => {
  let { match: { params: { detailType } } } = props
  const forStaff = detailType == 'staff'

  const {
    t,
  } = props

  return (
    <Paper>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t(`EDIT ${forStaff ? "STAFF" : "CUSTOMER"}`)}
        subTitle={props.email}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        {forStaff ? <StaffView {...props} /> : <CustomerView {...props} />}
      </div>
    </Paper>
  )
}

export default Form.create({
  name: "editUserForm"
})(withRouter(withTranslation("user_management")(UserEdit)))

import { combineReducers } from 'redux'
import * as C from "../../actionTypes/caterings/edit";
import moment from 'moment'
import * as _ from 'lodash'


const data = (state = {}, action) => {
  const {type, payload} = action
  switch (type) {
    case C.RETRIEVE_CATERING_COMPLETED:
      return payload
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const {type, payload} = action
  switch (type) {
    case C.RETRIEVE_CATERING:
      return true
    case C.RETRIEVE_CATERING_COMPLETED:
    case C.RETRIEVE_CATERING_FAILED:
      return false
    default:
      return state
  }
}

const isSubmitting = (state = false, action) => {
  const {type, payload} = action
  switch (type) {
    case C.SUBMIT:
      return true
    case C.SUBMIT_COMPLETED:
    case C.SUBMIT_FAILED:
      return false
    default:
      return state
  }
}

const reducers = combineReducers({
  data,
  isFetching,
  isSubmitting,
})

export default reducers

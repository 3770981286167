import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Button as MaterialButton } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form, Select, Tag, Input } from 'antd'
import { roundToUnit, convertDiscountToSpecified } from '../../../helpers/helpers'
import { CURRENCY_MAPPING } from '../../../helpers/constant'
import { formatMoney } from '../../../helpers/bookings'
import { get, cloneDeep, mean, sum } from 'lodash'
import AddIcon from 'mdi-react/AddIcon'
import RemoveIcon from 'mdi-react/DeleteOutlineIcon'
import ContentSaveOutlineIcon from 'mdi-react/ContentSaveOutlineIcon'
import { makeStyles } from '@material-ui/core/styles'
import { changeBookingRoomPrice } from '../../../actions/booking'
import moment from 'moment'
import {withTranslation} from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  facilitiesServiceContent: {
    borderTop: '1px solid #ddd',
    padding: '20px'
  },
  selectField: {
    '& .ant-select': {
      width: '80%',
    },
    '& .ant-form-item': {
      marginBottom: '0'
    }
  },
  formField: {
    '& .ant-input': {
      width: '80%',
    }
  },
  cateringContent: {
    borderTop: '1px solid #ddd',
    padding: '20px'
  },
  buttonAddItem: {
    color: theme.colorAccent
  },
  faclityItemName: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px'
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  totalPrice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formContent: {
    marginBottom: '20px'
  },
  title: {
    color: theme.colorAccent,
    borderBottom: `2px solid ${theme.colorAccent}`
  }
}))

const RoomPriceContent = (props) => {
  const { item, classes, configurationInfo, updateRoomPrice } = props

  const [price, setPrice] = useState(get(item, 'price', 0) / 100)
  const [discount, setDiscount] = useState(get(item, 'discount_amount', 0))

  const handleSubmit = () => {
    const data = {
      id: get(item, 'id'),
      price: Number(price) * 100
    }
    if (discount !== '') {
      data.discount = Number(discount)
    }
    updateRoomPrice(data)
  }
  return (

    <Grid container className={classes.faclityItem}>
      <Grid item xs={3}>
        {get(item, 'item.name', '')}
      </Grid>
      <Grid item xs={2}>
        {get(item, 'date', '')}
      </Grid>
      <Grid item xs={2}>
        &nbsp;
      </Grid>
      <Grid item xs={2} className={classes.formField}>
        <Input placeholder="Warning" id="warning" prefix={CURRENCY_MAPPING[configurationInfo.currency]}
          defaultValue={get(item, 'price', 0) / get(configurationInfo, 'unit', 100)}
          type="number"
          min="0"
          max="10000"
          step="0.01"
          name="price"
          onChange={(e) => setPrice(e.target.value)}
        // onPressEnter={(e) => changePrice(item.date, e.target.value)}
        // onBlur={(e) => updateRoomPrice(item.date, e.target.value)}
        />
        {/* {formatMoney(get(item, 'avg_price', '')/get(configurationInfo, 'unit', 100))} */}
      </Grid>
      <Grid item xs={1}>

        <Input
          placeholder="0"
          defaultValue={get(item, 'discount_amount', 0)}
          type="number"
          min="0"
          max="100"
          step="1"
          name="discount"
          addonAfter={'%'}
          onChange={(e) => setDiscount(e.target.value)}
        />

        {get(item, 'discount_amount', 0)}

      </Grid>
      <Grid item xs={1} className={classes.totalPrice}>
        {/* <Typography align="center"> */}
        {formatMoney(price - ((price * discount) / 100))}
        {/* </Typography> */}
      </Grid>
      <Grid item xs={1}>
        <MaterialButton
          color="primary"
          onClick={() => handleSubmit()}
        ><ContentSaveOutlineIcon /></MaterialButton>
      </Grid>
    </Grid>

  )

}

RoomPriceContent.propTypes = {
  classes: PropTypes.object,
}

const RoomPricingTable = props => {
  const classes = useStyles()
  const { bookingSession, configurationInfo, changeBookingRoomPrice, t } = props
  // const listSelectedFacilities = get(bookingSession, 'extra_facilities', [])
  // const listSelectedCaterings = get(bookingSession, 'catering_services', [])

  let listRoomBooked = get(bookingSession, 'items', []).filter(item => item.item_type === 1)
  listRoomBooked.sort((a, b) => {
    if(moment(a.date) < moment(b.date)) {
      return -1
    }
    if(moment(a.date) < moment(b.date)) {
      return 1
    }
    return 0
  })

  // console.log(listSelectedFacilities)


  const [listPrice, setListPrice] = useState((listRoomBooked || []).map(item => ({id: item.id, price: item.price, discount: item.discount_amount})))
  const [discount, setDiscount] = useState(mean((listRoomBooked || []).map(item => item.discount_amount)))
  // const [priceDefault, setPriceDefault] = useState(get(listRoomBooked, '[0].price', 0) / get(configurationInfo, 'unit', 0))

  useEffect(() => {
    setListPrice((listRoomBooked || []).map(item => ({id: item.id, price: item.price, discount: item.discount_amount})))
    // let listRoomBooked = get(bookingSession, 'items', []).filter(item => item.item_type === 1)
    // if()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingSession])

  const changePrice = (id, price) => {
    let temp = cloneDeep(listPrice)
    // const rawNode = temp.find(item => item.id === id)
    temp = temp.filter(item => item.id !== id)
    temp.push({id, price: Number(price) * 100, discount})
    setListPrice(temp)
  }

  const changeDiscount = (value) => {
    setDiscount(value)
    let temp = cloneDeep(listPrice)
    temp = temp.map(item => ({...item, discount: value}))
    // console.log()
    setListPrice(temp)
  }

  const updateRoomPrice = () => {
    if(listPrice.length) {
      changeBookingRoomPrice({
        items: listPrice
      })
    }
    // const room = get(bookingSession, 'items', []).find(item => item.item_type === 1 && item.date == date)
  }

  // console.log(listPrice)
  // console.log(listRoomBooked)

  // const discountDefault = mean((listRoomBooked || []).map(item => item.discount_amount))
  const total = sum(listRoomBooked.map(item => get(item, 'price', 0))) / 100
  return (
    <Grid container>
      <Grid item xs={12} md={12} className={classes.facilitiesServiceContent} >
        {/* <Typography variant='h6' component="span" className={classes.title}>Discount</Typography> */}
        <Form >
          <Grid container style={{ marginBottom: '20px' }} >
            <Grid item xs={3}>
              <Typography variant="h6" component="h6">
                {t('BOOKING.Room')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" component="h6">
                {t('BOOKING.Date')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              &nbsp;
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" component="h6">
                {t('BOOKING.Price')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h6" component="h6">
                {t('BOOKING.Discount')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h6" component="h6" align="center">
                {t('BOOKING.Total')}
              </Typography>
            </Grid>
          </Grid>
          {listRoomBooked.map((item, index) => {
            const price = get(item, 'price', 0) / get(configurationInfo, 'unit', 100)
            console.log(price)
            return (
              // <RoomPriceContent
              //   {...props}
              //   updateRoomPrice={(data) => updateRoomPrice(data)}
              //   item={item}
              //   key={index}
              //   classes={classes}
              //   formatMoney={formatMoney}
              // />
              <Grid key={index} container spacing={1} style={{ marginBottom: '5px' }}>
                <Grid item xs={3}>
                  {get(item, 'item.name', '')}
                </Grid>
                <Grid item xs={2}>
                  {get(item, 'date', '')}
                </Grid>
                <Grid item xs={2}>
                  &nbsp;
                </Grid>
                <Grid item xs={2} >
                  <Input 
                    // placeholder="Warning" 
                    id="warning" 
                    suffix={CURRENCY_MAPPING[configurationInfo.currency]}
                    // defaultValue={price}
                    type="number"
                    min="0"
                    step="0.01"
                    value={((listPrice.find(it => it.id === item.id) || {}).price || 0) / 100}
                    onChange={(e) => changePrice(item.id, e.target.value)}
                  />
                  {/* {formatMoney(get(item, 'avg_price', '')/get(configurationInfo, 'unit', 100))} */}
                </Grid>
                <Grid item xs={1} >
                  {index === 0 ? <Input
                    placeholder="0"
                    // defaultValue={discountDefault}
                    value={discount}
                    onChange={(e) => changeDiscount(Number(e.target.value))}
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                    suffix={'%'}
                  />
                    :
                    <>
                      {/* {discount}%  */}
                    </>
                  }
                  {/* {formatMoney(get(item, 'discount', '')/get(configurationInfo, 'unit', 100))} */}
                </Grid>
                <Grid item xs={1} className={classes.totalPrice}>
                  {index === 0 && formatMoney((total - ((total * discount) / 100))*100)}
                  {/* <Typography align="center">
                  </Typography> */}
                </Grid>
                <Grid item xs={1}>
                  {index === 0 &&
                  <MaterialButton
                    color="primary"
                    onClick={() => updateRoomPrice()}
                  ><ContentSaveOutlineIcon /></MaterialButton>}
                </Grid>
              </Grid>
            )
          })}
        </Form>
      </Grid>

    </Grid>
  )
}

const mapState = ({ bookingReducer, publicReducer }) => ({
  bookingSession: bookingReducer.bookingSession,
  configurationInfo: publicReducer.configurationInfo,
  isChangingBookingRoomPrice: publicReducer.isChangingBookingRoomPrice,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    changeBookingRoomPrice
  },
  dispatch
)

RoomPricingTable.propTypes = {
  searchExtraPrice: PropTypes.func,
  removeExtraFacility: PropTypes.func,
  bookingSession: PropTypes.object,
  form: PropTypes.object,
  isSearchingExtraPrice: PropTypes.bool,
  configurationInfo: PropTypes.object,
  changeBookingRoomPrice: PropTypes.func,
}

export default Form.create({
  name: 'addExtraFacilities'
})(connect(mapState, mapDispatch)( withTranslation('common') (RoomPricingTable)))
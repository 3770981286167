import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'
import * as helpers from '../../helpers/room'
import * as bookingHelpers from '../../helpers/bookings'
import { slots2Timerange } from '../../helpers/timeslot'
import { formatMoney } from '../../helpers/bookings'
import { convertNumToMonthName } from '../../helpers/helpers'

import { BOOKING, FORMAT } from '../../constants/models'

const { LIST_BY } = BOOKING
const { DATE } = FORMAT

const getReducer = ({rooms}) => rooms.detail

export const room = {
  isFetching: createSelector(
    [getReducer],
    ({room: {isFetching}}) => isFetching
  ),

  getName: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'name')
  ),

  getDescription: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'description')
  ),

  getSize: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'size')
  ),

  getMaxParticipants: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'max_attendances')
  ),

  getLeadTime: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'lead_time')
  ),

  getFreeCancelDays: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'free_cancel_days')
  ),

  getCoverImage: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'images[0].url')
  ),

  getFacilities: createSelector(
    [getReducer],
    ({room: {data}}) => {
      const colors = ['cyan', 'blue', 'geekblue', 'purple']
      const facilities = _.map(_.get(data, 'permanent_facilities', []), it => ({
        id: it.id,
        name: it.name,
        color: colors[it.id%4]
      }))
      return facilities
    }
  ),

  getRoomType: createSelector(
    [getReducer],
    ({room: {data}}) => {
      return _.get(data, 'room_type_name')
    }
  ),

  getPublicTag: createSelector(
    [getReducer],
    ({room: {data}}) => helpers.getPublicTag(_.get(data, 'is_published'))
  ),

  getPricings: createSelector(
    [getReducer],
    ({room: {data}}) => {
      const pricings = _.map(_.get(data, 'pricings', []), pricing => {
        const [start, end] = slots2Timerange(_.get(pricing, 'apply_range_int'))
        return {
          id: pricing.id,
          blockHours: `${_.padStart(start, 2, '0')}:00 - ${_.padEnd(end, 2, '0')}:00`,
          price: formatMoney(_.get(pricing, 'price'))
        }
      })
      return pricings
    }
  ),

  getAnalyticsData: createSelector(
    [getReducer],
    ({room: {analytics: {items}}}) => {
      return {thisYear: {
        data: _.map(items, res => res.count),
        labels: _.map(items, res => convertNumToMonthName(res.month)),
      }}
    }
  ),

  getSelectedTab: createSelector(
    [getReducer],
    ({room: {bookings: {selectedTab}}}) => selectedTab
  ),

  getBookingItems: createSelector(
    [getReducer],
    ({room: {bookings: {items}}}) => {
      return _.map(items, it => ({
        id: it.id,
        confirmationNo: `${it.confirmation_prefix}${_.padStart(it.id, 4, '0')}`,
        room: it.room_name,
        customer: {
          name: `${_.get(it, "customer.first_name")} ${_.get(it, "customer.last_name")}`,
          email: _.get(it, "customer.email"),
          company: _.get(it, "customer.company"),
        },
        bookingDate: {
          startDate: moment(_.get(it, 'start_date')).format(DATE),
          startTime: bookingHelpers.slot2Time(_.get(it, 'start_slot_int'))[0],
          endDate: moment(_.get(it, 'end_date')).format(DATE),
          endTime: bookingHelpers.slot2Time(_.get(it, 'end_slot_int'))[1],
        },
        paymentStatus: bookingHelpers.getPaymentStatusTag(_.get(it, 'payment_status')),
        bookingStatus: bookingHelpers.getBookingStatusTag(_.get(it, 'status')),
      }))
    }
  ),
  
  getBookingPage: createSelector(
    [getReducer],
    ({room: {bookings: {page}}}) => page
  ),
  
  getBookingPageSize: createSelector(
    [getReducer],
    ({room: {bookings: {pageSize}}}) => pageSize
  ),
  
  getBookingTotal: createSelector(
    [getReducer],
    ({room: {bookings: {total}}}) => total
  ),
  
  isBookingFetching: createSelector(
    [getReducer],
    ({room: {bookings: {isFetching}}}) => isFetching
  ),

  generateBookingAnalyticsReqPayload: createSelector(
    [getReducer],
    ({room: {data: {id}}}) => {
      const year = moment().year()
      return {year, room_id: id}
    }
  ),

  generateListBookingReqPayload: createSelector(
    [getReducer],
    ({room: {bookings: {page, pageSize}, data: {id}}}) => {
      let payload = {page, page_size: pageSize, room_id: id}
      return payload
    }
  ),
}

export const roomType = {
  isFetching: createSelector(
    [getReducer],
    ({roomType: {isFetching}}) => isFetching
  ),

  getName: createSelector(
    [getReducer],
    ({roomType: {data}}) => _.get(data, 'room_type')
  ),

  getDescription: createSelector(
    [getReducer],
    ({roomType: {data}}) => _.get(data, 'description')
  ),

  getPublicTag: createSelector(
    [getReducer],
    ({roomType: {data}}) => helpers.getPublicTag(_.get(data, 'is_published'))
  ),

  getCoverImage: createSelector(
    [getReducer],
    ({roomType: {data}}) => {
      return _.get(data, 'images[0].url')
    }
  ),

  getRooms: createSelector(
    [getReducer],
    ({roomType: {data}}) => {
      return _.map(_.get(data, 'rooms', []), room => ({
        id: _.get(room, 'id'),
        name: _.get(room, 'name'),
        maxParticipants: _.get(room, 'max_attendances'),
        size: _.get(room, 'size'),
        leadTime: _.get(room, 'lead_time'),
        isPublic: helpers.getPublicTag(_.get(room, 'is_published')),
      }))
    }
  )
}

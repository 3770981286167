import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as C from '../../actionTypes/users/new'
import * as LIST_C from '../../actionTypes/users/list'
import { history } from '../../config/store'
import { message } from 'antd'
import { USER, BOOKING } from '../../constants/models'
import * as _ from 'lodash'

const { LIST_BY } = USER

function* submit({api}, {payload}) {
  payload = _.set(payload, 'is_active', true)
  try {
    const response = yield call(api.user.createUser, payload)
    yield put({type: C.SUBMIT_COMPLETED, payload: response})
    history.goBack()
  } catch (e) {
    yield put({type: C.SUBMIT_FAILED, payload: e})
    message.error(JSON.stringify(_.get(e, 'data')))
    console.error(e)
  }

  if (_.get(payload, 'is_staff')) {
    yield put({type: LIST_C.CHANGE_TAB, payload: LIST_BY.STAFF})
  } else {
    yield put({type: LIST_C.CHANGE_TAB, payload: LIST_BY.CUSTOMER})
  }
}

function detailSaga(deps) {
  return [
      takeLeading(C.SUBMIT, submit, deps),
  ]
}

export default detailSaga

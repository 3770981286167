import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import { message } from 'antd'
import * as C from '../../actionTypes/users/list'
import * as NEW_C from '../../actionTypes/users/new'
import * as EDIT_C from '../../actionTypes/users/edit'
import * as DETAIL_C from '../../actionTypes/users/detail'
import * as selectors from "../../selectors/users/list";
import { history } from '../../config/store'
import * as _ from 'lodash'

function* listUsers({api}) {
  const req = yield select(selectors.generateReqPayload)
  try {
    const response = yield call(api.user.listUsers, req)
    yield put({type: C.LIST_USER_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.LIST_USER_FAILED, payload: e})
    message.error(_.get(e, 'data.error'))
    console.error(e)
  }
}

function* retrieveExportToken({api}) {
  try {
    yield put({ type: C.RETRIEVE_EXPORT_TOKEN })
    const response = yield call(api.user.retrieveExportToken)
    yield put({ type: C.RETRIEVE_EXPORT_TOKEN_COMPLETED, payload: response })
  } catch (e) {
    yield put({ type: C.RETRIEVE_EXPORT_TOKEN_FAILED, payload: e })
    message.error(_.get(e, 'data.error'))
    console.error(e)
  }
}

function listSaga(deps) {
  return [
      takeLeading(C.LIST_USER, listUsers, deps),
      takeLatest(C.CHANGE_PAGE, listUsers, deps),
      takeLatest(C.CHANGE_TAB, listUsers, deps),
      takeLeading(C.SUBMIT_SEARCH, listUsers, deps),
      takeLatest(NEW_C.SUBMIT_COMPLETED, listUsers, deps),
      takeLatest(EDIT_C.SUBMIT_USER_COMPLETED, listUsers, deps),
      takeLeading(C.LIST_USER_COMPLETED, retrieveExportToken, deps),
      takeLatest(DETAIL_C.DELETE_USER_COMPLETED, listUsers, deps),
  ]
}

export default listSaga

import * as actionTypes from '../actionTypes/facilities.actionTypes'

export function getCategory(data) {
  return ({type: actionTypes.GET_CATEGORY, data})
}

export function getCategoryFail(data) {
  return ({type: actionTypes.GET_CATEGORY_FAIL, data})
}

export function getCategorySuccess(data) {
  return ({type: actionTypes.GET_CATEGORY_SUCCESS, data})
}

export function getFacilities(data) {
  return ({type: actionTypes.GET_FACILITIES, data})
}
export function getFacilitiesFail(data) {
  return ({type: actionTypes.GET_FACILITIES_FAIL, data})
}
export function getFacilitiesSuccess(data) {
  return ({type: actionTypes.GET_FACILITIES_SUCCESS, data})
}

export function createNewFacilityCategory(data) {
  return ({type: actionTypes.CREATE_NEW_FACILITY_CATEGORY, data})
}
export function createNewFacilityCategoryFail(data) {
  return ({type: actionTypes.CREATE_NEW_FACILITY_CATEGORY_FAIL, data})
}
export function createNewFacilityCategorySuccess(data) {
  return ({type: actionTypes.CREATE_NEW_FACILITY_CATEGORY_SUCCESS, data})
}

export function updateFacilityCategory(data) {
  return ({type: actionTypes.UPDATE_FACILITY_CATEGORY, data})
}
export function updateFacilityCategoryFail(data) {
  return ({type: actionTypes.UPDATE_FACILITY_CATEGORY_FAIL, data})
}
export function updateFacilityCategorySuccess(data) {
  return ({type: actionTypes.UPDATE_FACILITY_CATEGORY_SUCCESS, data})
}

export function increaseQuantityFacilityCategory(data) {
  return ({type: actionTypes.INCREASE_QUANTITY_FACILITY_CATEGORY, data})
}
export function increaseQuantityFacilityCategoryFail(data) {
  return ({type: actionTypes.INCREASE_QUANTITY_FACILITY_CATEGORY_FAIL, data})
}
export function increaseQuantityFacilityCategorySuccess(data) {
  return ({type: actionTypes.INCREASE_QUANTITY_FACILITY_CATEGORY_SUCCESS, data})
}

import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import RoomList from './List'
import NewRoomType from './NewRoomType'
import DetailRoomType from './DetailRoomType'
import EditRoomType from './EditRoomType'
import DetailRoom from './DetailRoom'
import NewRoom from './NewRoom'
import EditRoom from './EditRoom'

import * as actions from '../../actions/rooms/list'

const View = props => {
  let match = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/edit/room-type/:id`} render={() => <EditRoomType />} />
        <Route path={`${match.path}/detail/room-type/:id`} render={() => <DetailRoomType />} />
        <Route path={`${match.path}/new/room-type`} render={() => <NewRoomType />} />
        <Route path={`${match.path}/new/room`} render={() => <NewRoom />} />
        <Route path={`${match.path}/detail/room/:id`} render={() => <DetailRoom />} />
        <Route path={`${match.path}/edit/room/:id`} render={() => <EditRoom />} />
        <Route path={`${match.path}`} render={() => <RoomList />} />
      </Switch>
    </Container>
  )
}

class RoomManagement extends Component {
  componentDidMount() {
    this.props.loadData()
  }

  render() {
    return (
      <View {...this.props} />
    )
  }
}


const mapState = (state) => ({

})

const mapDispatch = dispatch => bindActionCreators(
  {
    loadData: actions.listRooms,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(RoomManagement))

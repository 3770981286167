import {combineReducers} from 'redux'
import * as actionTypes from '../actionTypes/auth.actionTypes'
import * as storage from '../services/storage'
import { LOCAL_STORAGE_KEY } from '../helpers/constant'
import moment from 'moment'
import jwtDecode from 'jwt-decode'

const token = (storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization) || {}).refresh || null

const isLogined = (state = token ? moment.unix(jwtDecode(token).exp) > moment() : false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LOGIN_SUCCESS:
    return true
  case actionTypes.LOGOUT:
    return false
  default:
    return state
  }
}

const isLogining = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LOGIN:
    return true
  case actionTypes.LOGIN_SUCCESS:
  case actionTypes.LOGIN_FAIL:
    return false
  default:
    return state
  }
}

const authReducer = combineReducers({
  isLogined,
  isLogining
})

export default authReducer
import {combineReducers} from 'redux'
import * as actionTypes from '../actionTypes/public.actionTypes'
import {UPDATE_PROFILE_SUCCESS} from '../actionTypes/user.actionTypes'
console.log(localStorage.getItem('language'))
const locale = (state = {lang: localStorage.getItem('language') || (navigator.language.slice(0,2) === 'en' ? 'en' : 'de')}, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.CHANGE_LANGUAGE_COMPLETE:
    state.lang = action.data
    return state
  default:
    return state
  }
}

const configurationInfo = (state = {}, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_CONFIGURATION_SUCCESS: 
    return action.data
  default:
    return state
  }
}

const isGettingProfile = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_PROFILE:
    return true
  case actionTypes.GET_PROFILE_SUCCESS:
  case actionTypes.GET_PROFILE_FAIL:
    return false
  default:
    return state
  }
}

const profile = (state=null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_PROFILE_SUCCESS:
  case UPDATE_PROFILE_SUCCESS:
    return action.data
  default:
    return state
  }
}

const publicReducer = combineReducers({
  locale,
  configurationInfo,
  profile,
  isGettingProfile,
})

export default publicReducer
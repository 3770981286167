import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from './components/Header'
import ListRooms from './components/ListRooms'
import ListRoomTypes from './components/ListRoomTypes'
import {Switch, Route} from 'react-router-dom'
import * as paths from '../../constants/paths.constants'
import RoomManagement from "../../containers/rooms/RoomManagement";
class ViewAllRoom extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path={paths.ListRooms} component={RoomManagement}/>
          {/* <Route path={paths.ListRoomTypes} component={ListRoomTypes}/> */}
        </Switch>
      </div>
    )
  }
}

ViewAllRoom.propTypes = {

}

export default ViewAllRoom
/* eslint-disable react/prop-types */
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'lodash'
import LoadingIcon from 'mdi-react/LoadingIcon'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { destroyBookingSession, downloadPdf } from '../../../actions/booking'

import * as paths from '../../../constants/paths.constants'

import { pathWithId } from '../../../helpers/helpers'

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: '20px'
  },
  title: {
    color: theme.colorAccent
  },
  textAreaAutoSize: {
    width: '100%',
    borderRadius: '5px'
  },
  bookingInfo: {
    padding: '5px 0'
  },
  roomImage: {
    width: '100px'
  },
  card: {
    height: '95%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f7f7f7',
    margin: '10px 0 10px 30px'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
    borderBottom: '1px solid #ddd',
  },
  justifyContentCenter: {
    justifyContent: 'center'
  },
  footerContent: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px'
  },
  detailRoom: {
    marginTop: '20px',
    '& p': {
      marginBottom: '5px'
    }
  },
  detailRoomContentFooter: {
    justifyContent: 'space-between'
  },
  detailRoomContentFooterLabel: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#797979',
    marginLeft: '10px',
    fontSize: '16px',
  },
  detailRoomContentFooterPrice: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.colorAccent,
    marginRight: '10px',
    fontSize: '16px',
  },
  iframeContent: {
    width: '100%',
    height: '1000px',
  }
}))
const BookingCompleted = props => {
  const classes = useStyles()
  
  const { t, bookingSession, createdBooking, history, downloadPdf, isDownloadingPDF } = props
  useEffect(() => {
    // console.log(bookingSession)
    if (!get(bookingSession, 'id', null)) {
      history.push(paths.ViewAllBooking)
    } 
  }, [history, bookingSession])

  const bookingDetailPath = get(createdBooking, 'id') ?
    pathWithId(paths.BookingDetail, get(createdBooking, 'id')) : paths.ViewAllBooking
    
  return (
    <div>
      {isDownloadingPDF ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container className={classes.mainGrid}>
        <Grid item xs={12}>
          <iframe srcDoc={get(createdBooking, 'html', '')} frameBorder='0' allowFullScreen className={classes.iframeContent}>
          </iframe>
          <div className={classes.footerContent}>
            <Button variant="contained" color="primary"
              onClick={() => history.push(bookingDetailPath)}>
              {t('BOOKING.ViewBookingDetail')}
            </Button>
            <div>
              <Button
                onClick={() => downloadPdf(get(createdBooking, 'id', ''))}
              // loading={}
              >
                {t('BOOKING.ExportPDF')}
              </Button>
              <Button variant="contained" color="primary" onClick={() => history.push(paths.ViewAllBooking)}>
                {t('BOOKING.GoHome')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const mapState = ({ publicReducer, bookingReducer }) => ({
  configurationInfo: publicReducer.configurationInfo,
  bookingSession: bookingReducer.bookingSession,
  createdBooking: bookingReducer.newCreatedBooking,
  isDownloadingPDF: bookingReducer.isDownloadingPDF,
  // newCreatedBooking: bookingReducer.newCreatedBooking,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    destroyBookingSession,
    downloadPdf
  },
  dispatch
)

export default withRouter(connect(mapState, mapDispatch)( withTranslation('common') (BookingCompleted)))
import React, { Component, useState } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Header from './component/Header'
import UpcomingTable from './component/UpcomingTable'
import AllBooking from '../../containers/booking/AllBooking'

import BookingModal from '../BookingTable/components/BookingModal'
import {closeSearchAvailableRooms} from '../../actions/modal'
import { bindActionCreators } from 'redux'
class ViewAllBooking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowBookingList: false,
      mode: false
    }
  }


  showBookingList = (data) => {
    this.setState({ isShowBookingList: true })
  }

  closeShowBookingList = (data) => {
    this.setState({ isShowBookingList: false })
  }

  handleModeChange = (mode) => {
    this.setState({mode})
  }

  render() {
    const { isShowBookingList, mode } = this.state
    const {isOpenSearchAvailableRoomModal} = this.props
    return (
      <div className="view-all-booking">
        {/* <Header
          handleModeChange={mode => this.handleModeChange(mode)}
        /> */}
        <AllBooking />
        <BookingModal 
            isOpen={isOpenSearchAvailableRoomModal}
            closeModal={() => this.props.closeSearchAvailableRooms()}
        />

      </div>
    )
  }
}

const mapState = ({modalReducer}) => ({
  isOpenSearchAvailableRoomModal: modalReducer.isOpenSearchAvailableRoomModal,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    closeSearchAvailableRooms
  },
  dispatch
)

ViewAllBooking.propTypes = {

}

export default connect(mapState, mapDispatch) (ViewAllBooking)
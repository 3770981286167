import React, { Component } from 'react'
import ForgotPasswordComponent from '../../../components/Auth/ForgotPassword'
class ForgotPassword extends Component {
  render() {
    return (
      <div>
        <ForgotPasswordComponent />
      </div>
    )
  }
}

export default ForgotPassword
import { combineReducers } from 'redux'
import * as C from "../../actionTypes/exfacilities/list";
import { EXFACILITY } from "../../constants/models";
import * as _ from 'lodash'

const { LIST_BY } = EXFACILITY

const items = (state = [], action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_EXFACILITY_COMPLETED:
      return payload
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const {type} = action
  switch (type) {
    case C.LIST_EXFACILITY:
      return true
    case C.LIST_EXFACILITY_COMPLETED:
    case C.LIST_EXFACILITY_FAILED:
      return false
    default:
      return state
  }
}


const selectedTab = (state = LIST_BY.ACTIVE, action) => {
  const {type, payload} = action
  switch (type) {
    case C.CHANGE_TAB:
      return payload
    default:
      return state
  }
}

const reducers = combineReducers({
  items,
  isFetching,
  selectedTab,
})

export default reducers

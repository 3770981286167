import React, { Component } from 'react'
import PropTypes from 'prop-types'
import UserDetailComponent from './components/UserDetail'
class UserDetail extends Component {
  render() {
    return (
      <div>
        <UserDetailComponent />
      </div>
    )
  }
}

UserDetail.propTypes = {

}

export default UserDetail
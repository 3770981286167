import React, { Component } from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import {Switch, Route, Redirect} from 'react-router-dom'
import AddCustomerInfoComponent from './components/AddCustomerInfo'
import RoomFoundComponent from './components/RoomFound'
import AddBookingDetailsComponent from './components/AddBookingDetails'
import NewBookingCreatedComponent from './components/NewBookingCreated'
import PaymentComponent from './components/Payment'
import SelectCustomerComponent from './components/SelectCustomer'
import BookingConfirm from './components/BookingConfirm'
import CompletedComponent from './components/BookingCompleted'
import Header from './components/Header'
import * as paths from '../../constants/paths.constants'
import { getConfiguration } from '../../actions/public' 
import { getListCaterings, getListExtraFacilities, getCreatedBookingSummary } from '../../actions/booking' 
class CreateNewBooking extends Component {

  UNSAFE_componentWillMount() {
    const {getConfiguration, getListCaterings, getListExtraFacilities, configurationInfo, listCaterings, listExtraFacilities, getCreatedBookingSummary, bookingSession} = this.props
    if(!configurationInfo.unit) {
      getConfiguration()
    }
    if(!listCaterings.length) {
      getListCaterings()
    }
    if(!listExtraFacilities.length) {
      getListExtraFacilities()
    }
    if(!listExtraFacilities.length) {
      getCreatedBookingSummary()
    }
    if(!bookingSession.id) {
      // getCreatedBookingSummary()
    }
  }

  render() {
    // console.log('cang')
    return (
      <div>
        <Header />
        <Switch>
          <Route path={paths.CreateNewBooking_Completed} component={CompletedComponent}/>
          <Route path={paths.CreateNewBooking_BookingConfirm} component={BookingConfirm}/>
          <Route path={paths.CreateNewBooking_Payment} component={PaymentComponent}/>
          <Route path={paths.CreateNewBooking_NewBookingCreated} component={NewBookingCreatedComponent}/>
          <Route path={paths.CreateNewBooking_AddBookingDetail} component={AddBookingDetailsComponent}/>
          <Route path={paths.CreateNewBooking_SelectCustomer} component={SelectCustomerComponent}/>
          <Route path={paths.CreateNewBooking_FoundRoom} component={RoomFoundComponent}/>
          <Route path={paths.CreateNewBooking_CreateCustomer} component={AddCustomerInfoComponent}/>
          <Redirect path={paths.CreateNewBooking} to={paths.CreateNewBooking_FoundRoom}/>
        </Switch>
      </div>
    )
  }
}

const mapState = ({bookingReducer, publicReducer}) => ({
  configurationInfo: publicReducer.configurationInfo,
  listExtraFacilities: bookingReducer.listExtraFacilities,
  listCaterings: bookingReducer.listCaterings,
  bookingSession: bookingReducer.bookingSession
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getConfiguration,
    getListCaterings,
    getListExtraFacilities,
    getCreatedBookingSummary
  },
  dispatch
)

CreateNewBooking.propTypes = {
  getConfiguration: PropTypes.func,
  getListCaterings: PropTypes.func,
  getListExtraFacilities: PropTypes.func,
  getCreatedBookingSummary: PropTypes.func,
  configurationInfo: PropTypes.object,
  listExtraFacilities: PropTypes.object,
  listCaterings: PropTypes.object,
  bookingSession: PropTypes.object,
}

export default connect(mapState, mapDispatch) (CreateNewBooking)

export const GET_FACILITIES = 'GET_FACILITIES'
export const GET_FACILITIES_SUCCESS = 'GET_FACILITIES_SUCCESS'
export const GET_FACILITIES_FAIL = 'GET_FACILITIES_FAIL'

export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL'

export const CREATE_NEW_FACILITY_CATEGORY = 'CREATE_NEW_FACILITY_CATEGORY'
export const CREATE_NEW_FACILITY_CATEGORY_SUCCESS = 'CREATE_NEW_FACILITY_CATEGORY_SUCCESS'
export const CREATE_NEW_FACILITY_CATEGORY_FAIL = 'CREATE_NEW_FACILITY_CATEGORY_FAIL'

export const UPDATE_FACILITY_CATEGORY = 'UPDATE_FACILITY_CATEGORY'
export const UPDATE_FACILITY_CATEGORY_SUCCESS = 'UPDATE_FACILITY_CATEGORY_SUCCESS'
export const UPDATE_FACILITY_CATEGORY_FAIL = 'UPDATE_FACILITY_CATEGORY_FAIL'

export const INCREASE_QUANTITY_FACILITY_CATEGORY = 'INCREASE_QUANTITY_FACILITY_CATEGORY'
export const INCREASE_QUANTITY_FACILITY_CATEGORY_SUCCESS = 'INCREASE_QUANTITY_FACILITY_CATEGORY_SUCCESS'
export const INCREASE_QUANTITY_FACILITY_CATEGORY_FAIL = 'INCREASE_QUANTITY_FACILITY_CATEGORY_FAIL'


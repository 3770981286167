import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Grid, Typography, Button } from '@material-ui/core'
import { PageHeader, Input, Table } from 'antd'
import { Card, Empty, Tag } from 'antd';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { ROOM } from '../../constants/models'
import * as URL from '../../constants/paths.constants'
import { ViewAllBooking } from '../../constants/paths.constants'
import PerformanceChart from '../../views/RoomDetail/components/PerformanceOverTime'
import parse from 'html-react-parser';

const { LIST_BY } = ROOM

const DetailRoom = (props) => {
  let { match: { params: { id } } } = props
  const {
    t,

    name,
    description,
    coverImage,
    facilities,
    maxParticipants,
    size,
    leadTime,
    freeCancelDays,
    roomType,
    publicTag,
    isFetching,
    pricings,
    analyticsData,

    bookingItems,
    bookingPage,
    bookingPageSize,
    bookingTotal,
    isBookingFetching,
    selectedTab,

    onChangeTab,
    onChangePage,

    match,
  } = props

  const bookingColumns = [
    {
      title: t('Confirmation No'),
      dataIndex: 'confirmationNo',
      key: 'confirmationNo',
      render: (text, record) => <Link to={`${ViewAllBooking}/detail/${record.id}`}>{text}</Link>,
    },
    {
      title: t('Customer'),
      dataIndex: 'customer',
      key: 'customer',
      render: (customer, record) => (
        <Grid container direction="column">
          <Grid item><Typography variant="subtitle1">{_.get(record, 'customer.name')}</Typography></Grid>
          <Grid item><Typography variant="body1">{_.get(record, 'customer.company')}</Typography></Grid>
          <Grid item><Typography variant="body2">{_.get(record, 'customer.email')}</Typography></Grid>
        </Grid>
      )
    },
    {
      title: t('Booking Date'),
      dataIndex: 'bookingDate',
      key: 'bookingDate',
      render: (bookingDate) => (
        <Grid container direction="column">
          <Grid item container justify="space-between">
            <Grid item><Typography variant="body1">{t("From")}</Typography></Grid>
            <Grid item><Typography variant="body1">{_.get(bookingDate, "startDate")}, {_.get(bookingDate, 'startTime')}</Typography></Grid>
          </Grid>
          <Grid item container justify="space-between">
            <Grid item><Typography variant="body1">{t("To")}</Typography></Grid>
            <Grid item><Typography variant="body1">{_.get(bookingDate, "endDate")}, {_.get(bookingDate, 'endTime')}</Typography></Grid>
          </Grid>
        </Grid>
      )
    },
    {
      title: t('Payment Status'),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (paymentStatus) => (
        <Tag color={_.get(paymentStatus, 'color')}>{_.get(paymentStatus, 'name')}</Tag>
      )
    },
    {
      title: t('Booking Status'),
      dataIndex: 'bookingStatus',
      key: 'bookingStatus',
      render: (bookingStatus) => (
        <Tag color={_.get(bookingStatus, 'color')}>{_.get(bookingStatus, 'name')}</Tag>
      )
    },
  ]

  return (
    <>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t("ROOM DETAIL")}
        subTitle={name || ""}
        extra={[
          <Link to={`${match.url.replace("detail", "edit")}`}>
            <Button
              color="primary"
              variant="contained"
              size="small"
            >
              {t("Edit")}
            </Button>
          </Link>
        ]}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        <Grid container spacing={4} direction="column">

          <Grid item container spacing={4}>
            <Grid xs={12} sm={4} item >
              <Card
                style={{ height: "100%" }}
                cover={
                  coverImage ?
                    <img src={coverImage} /> :
                    !isFetching && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                loading={isFetching}
              >
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h5">{name}</Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="body1">{parse((description || "").replace(/\n/gi, "<br />"))}</Typography>
                  </Grid>

                  <Grid item container spacing={1} direction="column">
                    <Grid item><Typography variant="subtitle1">{t("Room Type")}</Typography></Grid>
                    <Grid item>
                      <Typography variant="subtitle2"><b>{roomType}</b></Typography>
                    </Grid>
                  </Grid>

                  <Grid item container direction="column">
                    <Grid item><Typography variant="subtitle1">{t("Permanent Facilities")}</Typography></Grid>
                    <Grid item>
                      {_.map(facilities, fac => (
                        <Tag color={fac.color}>{fac.name}</Tag>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1}>
                    <Grid item><Typography variant="subtitle1">{t("Max Participants")}</Typography></Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><b>{maxParticipants} ppl</b></Typography>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1}>
                    <Grid item><Typography variant="subtitle1">{t("Size")}</Typography></Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><b>{size} m²</b></Typography>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1}>
                    <Grid item><Typography variant="subtitle1">{t("Lead time")}</Typography></Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><b>{leadTime} {t("days")}</b></Typography>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1}>
                    <Grid item><Typography variant="subtitle1">{t("Free cancel days")}</Typography></Grid>
                    <Grid item>
                      <Typography variant="subtitle1"><b>{freeCancelDays} {t("days")}</b></Typography>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1}>
                    <Grid item><Typography variant="subtitle1">{t("Is Public")}</Typography></Grid>
                    <Grid item>
                      {publicTag && <Tag color={publicTag.color}>{t(publicTag.name)}</Tag>}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid xs={12} sm={8} item container
              direction="column"
              justify="space-between"
            >
              <Grid item style={{ marginBottom: 32 }}>
                <Card loading={isFetching}>
                  <Grid container justify="space-between">
                    <Grid item><Typography variant="button">{t("total bookings")}</Typography></Grid>
                    <Grid item><Typography variant="body2">{t("This year")} </Typography></Grid>
                    <PerformanceChart style={{ width: "100%" }} data={analyticsData} period="thisYear" />
                  </Grid>
                </Card>
              </Grid>

              <Grid item >
                <Card loading={isFetching}>
                  <Typography variant="h6">{t("Pricings")}</Typography>
                  <Table
                    pagination={false}
                    columns={[
                      {
                        title: t("Block hours"),
                        dataIndex: 'blockHours',
                        key: "blockHours"
                      },
                      {
                        title: t("Price"),
                        dataIndex: 'price',
                        key: 'price',
                      }
                    ]}
                    dataSource={pricings}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} item container direction="column">
            <Grid item>
              <Card loading={isFetching}>
                <Tabs
                  value={selectedTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(e, value) => onChangeTab(value)}
                >
                  <Tab label={t("INCOMING")} value={0} />
                  <Tab label={t("HISTORY")} value={1} />
                </Tabs>

                <Table
                  style={{ width: "100%" }}
                  size="medium"
                  columns={bookingColumns}
                  dataSource={bookingItems || []}
                  loading={isBookingFetching}
                  pagination={{
                    current: bookingPage,
                    total: bookingTotal,
                    pageSize: bookingPageSize,
                    hideOnSinglePage: true,
                    onChange: (page, pageSize) => onChangePage(page)
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default withRouter(withTranslation("room_management")(DetailRoom))

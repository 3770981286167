import React, { useState } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography, TextField, Button, IconButton } from '@material-ui/core'
import MaterialTableComponent from '../../../components/Table/MaterialTable'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import { Link } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { RemoveRedEyeOutlined } from '@material-ui/icons'
import FacilityDetailModal from './modals/FacilityDetailModal'
import { bindActionCreators } from 'redux'
// import {}

const useStyles = makeStyles(theme => ({
  root: {},
  formField: {
    borderRadius: '5px',
    width: '40%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  formFilterField: {
    borderRadius: '5px',
    width: '20%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px'
  },
  searchButton: {
    color: theme.colorAccent,
    marginRight: '20px'
  },
  newBookingButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '40px'
  }
}))



function createData(id, extra, description) {
  return { id, extra, description }
}

const rows = [
  createData(12, 'Screen', 'Screen 41 inch'),
  createData(13, 'Mac', 'Macbook 2019 touch bar 15.6 inch'),
  createData(15, 'Bluetooth', 'Galaxy'),
  createData(16, 'Wifi', '5 Ghz'),
]

const ListCategory = props => {
  const classes = useStyles()
  const [openFacilityDetailModal, setOpenFacilityDetailModal] = useState(false)
  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
    { key: 'extra', numeric: true, disablePadding: false, label: 'Extra Facilities' },
    { key: 'description', numeric: true, disablePadding: false, label: 'Description' },
    {
      key: 'action',
      label: 'Action',
      render: (_, row) => {
        return (
          <>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setOpenFacilityDetailModal(true)}
            // onMouseDown={handleMouseDownPassword}
            >
              <RemoveRedEyeOutlined />
            </IconButton>
          </>
        )
      }
    }
  ]
  return (
    <Container>
      <Grid container>
        <Grid item md={12}>
          <Grid container>
            <Grid item md={8} className={classes.searchContent}>
              <Typography component='div'>Search</Typography>
              <TextField
                // error
                className={classes.formField}
                // id="outlined-error"
                label="Room, Room Types, Facilies"
                variant="outlined"
              />
              <Button className={classes.searchButton} color="default" variant="contained" disableElevation>
                <MagnifyIcon />
              </Button>
              <Typography component='div'>Filter by</Typography>
              <TextField
                // error
                className={classes.formFilterField}
                // id="outlined-error"
                label="Date"
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} className={classes.newBookingButtonContent}>
              <Link to={paths.NewFacility}>
                <Button color="primary" variant="contained" disableElevation>
                  Add Facility
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <MaterialTableComponent
            title='Rooms'
            columns={headCells}
            rows={rows}
          />
        </Grid>
      </Grid>
      <FacilityDetailModal 
        isOpen={openFacilityDetailModal}
        closeModal={() => setOpenFacilityDetailModal(false)}
      />
    </Container>
  )
}

const mapState = () => ({

})

const mapDispatch = dispatch => bindActionCreators(
  {

  },
  dispatch
)

ListCategory.propTypes = {
  className: PropTypes.string,
}

ListCategory.defaultProps = {
  orders: []
}

export default connect(mapState, mapDispatch) (ListCategory)

import React, { Component } from 'react'
import { withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Input, Upload, Icon, message } from 'antd'
import { Table, Tag, Form, Switch } from 'antd';
import { Card, Col, Row } from 'antd';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import QuantityPricingWall from "../pricings/QuantityPricingWall"
import * as helpers from '../../helpers/timeslot'
const { TextArea } = Input;

const EditCatering = (props) => {
  let { match: { params: { id } } } = props
  const {
    t,

    isFetching,
    name,
    description,
    minParticipants,
    isCombo,
    isActive,
    pricings,

    isSubmitting,
    onSubmit,

    form
  } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = form

  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        values = _.set(values, 'id', id)
        const pricing_objs = _.map(_.get(values, 'pricings'), p => ({
          min_quantity: p.minQuantity,
          price: _.floor(parseFloat(p.price)*100),
        }))
        values = _.set(values, 'pricings', pricing_objs)
        onSubmit(values)
      }
    })
  }

  return (
    <>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t("EDIT CATERING")}
        subTitle={name}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        <Form onSubmit={handleSubmit}>
          <Card style={{ marginBottom: 32 }} loading={isFetching}>
            <Form.Item
              label={t("Name")}
              name="name"
            >
              {getFieldDecorator('name', {
                rules: [{ required: true, message: t('Please input required fields!!') }],
                initialValue: name,
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label={t("Description")}
              name="description"
            >
              {getFieldDecorator('description', {
                rules: [{ required: true, message: t('Please input required fields!!') }],
                initialValue: description,
              })(
                <TextArea rows={4} />
              )}
            </Form.Item>

            <Form.Item
              label={t("Min participants")}
              name="minParticipants"
            >
              {getFieldDecorator('min_participants', {
                rules: [{ required: true, message: t('Please input required fields!!') }],
                initialValue: minParticipants
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label={t("Is Package")}
              name="isCombo"
            >
              {getFieldDecorator('is_combo', {
                initialValue: isCombo,
                valuePropName: 'checked',
              })(
                <Switch />
              )}
            </Form.Item>

            <Form.Item
              label={t("Is Active")}
              name="isActive"
              valuePropName="checked"
            >
              {getFieldDecorator('is_active', {
                valuePropName: 'checked',
                initialValue: isActive
              })(
                <Switch />
              )}
            </Form.Item>
          </Card>

          <Card style={{ marginBottom: 32 }} loading={isFetching}>
            <Form.Item
              label={t("Pricings")}
              name="pricings"
              valuePropName="pricings"
            >
              {getFieldDecorator('pricings', {
                valuePropName: 'pricings',
                rules: [{ required: true, message: t('Please input required fields!!')}],
                initialValue: pricings,
                onChange: (pricings) => setFieldsValue({"pricings": pricings})
              })(
                <QuantityPricingWall />
              )}
            </Form.Item>
          </Card>

          <Grid container justify="flex-end">
            <Grid item>
              <Form.Item>
                <Button
                  variant="contained"
                  color="primary"
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                >
                  {t("Submit")}
                </Button>
              </Form.Item>
            </Grid>
          </Grid>
        </Form>
      </div>
    </>
  )
}

export default Form.create({
  name: 'EditCatering'
})(withRouter(withTranslation("catering_management")(EditCatering)))

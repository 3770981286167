import { combineReducers } from 'redux'
import newDiscount from './new'
import listDiscount from './list'
import detailDiscount from './detail'
import editDiscount from './edit'


const reducers = combineReducers({
  new: newDiscount,
  list: listDiscount,
  detail: detailDiscount,
  edit: editDiscount,
});

export default reducers

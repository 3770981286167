import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Grid
} from '@material-ui/core'

import axios from '../../../../utils/axios'
import { GenericMoreButton } from '../../../../components'
import { Chart } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'unset'
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  chartContainer: {
    padding: theme.spacing(3)
  },
  chart: {
    height: 200
  },
  statsContainer: {
    // display: 'flex'
  },
  statsItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    // '&:not(:last-of-type)': {
    //   borderRight: `1px solid ${theme.palette.divider}`
    // }
  }
}))

const EarningsSegmentation = props => {
  const { className, data, ...rest } = props

  const classes = useStyles()

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={<GenericMoreButton />}
        title=""
      />
      <CardContent className={classes.content}>
        <Grid container>
          <Grid item md={8}>
            <div className={classes.chartContainer}>
              <Chart
                className={classes.chart}
                data={data}
              />
            </div>
          </Grid>
          <Grid item md={4}>
            <div className={classes.statsContainer}>
              {data.map(earning => (
                <div
                  className={classes.statsItem}
                  key={earning.id}
                >
                  <Typography
                    align="center"
                    component="h6"
                    gutterBottom
                    variant="overline"
                    style={{color: earning.color}}
                  >
                    {earning.label}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h6"
                  >
                    {earning.value}%
                  </Typography>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

EarningsSegmentation.propTypes = {
  className: PropTypes.string
}

export default EarningsSegmentation

import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import CateringList from './List'
import CateringDetail from './Detail'
import CateringEdit from './Edit'
import CateringNew from './New'
import * as actions from '../../actions/caterings/list'



const View = props => {
  let match = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/new`} render={() => <CateringNew />} />
        <Route path={`${match.path}/edit/:id`} render={() => <CateringEdit />} />
        <Route path={`${match.path}/detail/:id`} render={() => <CateringDetail />} />
        <Route path={`${match.path}`} render={() => <CateringList />} />
      </Switch>
    </Container>
  )
}

class CateringManagement extends Component {
  componentDidMount() {
    this.props.listCaterings()
  }

  render() {
    return (
      <View {...this.props} />
    )
  }
}


const mapState = (state) => ({

})

const mapDispatch = dispatch => bindActionCreators(
  {
    listCaterings: actions.listCaterings,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(CateringManagement))

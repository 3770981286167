import {combineReducers} from 'redux'
import * as actionTypes from '../actionTypes/catering.actionTypes'

const isGettingCaterings = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_LIST_ALL_CATERINGS:
    return true
  case actionTypes.GET_LIST_ALL_CATERINGS_FAIL:
  case actionTypes.GET_LIST_ALL_CATERINGS_SUCCESS:
    return false
  default:
    return state
  }
}

const listCaterings = (state = [], action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_LIST_ALL_CATERINGS_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isCreatingCatering = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.CREATE_NEW_CATERING:
    return true
  case actionTypes.CREATE_NEW_CATERING_FAIL:
  case actionTypes.CREATE_NEW_CATERING_SUCCESS:
    return false
  default:
    return state
  }
}

const isUpdatetingCatering = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.UPDATE_CATERING:
    return true
  case actionTypes.UPDATE_CATERING_FAIL:
  case actionTypes.UPDATE_CATERING_SUCCESS:
    return false
  default:
    return state
  }
}

const cateringsReducer = combineReducers({
  isGettingCaterings,
  listCaterings,
  isCreatingCatering,
  isUpdatetingCatering
})

export default cateringsReducer
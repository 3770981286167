import { LOCAL_STORAGE_KEY } from '../../helpers/constant'

export function setLocalStorageItem(key, data) {
  if (!data) {
    return null
  }
  localStorage.setItem(key, JSON.stringify(data))
}

export function getLocalStorageItem(key) {
  const storedData = localStorage.getItem(key)
  try {
    if (!storedData) {
      return null
    }
    return JSON.parse(storedData)
  } catch(e) {
    console.log(e)
    return {}
  }
}

export function removeLocalStorage(key) {
  if(key) {
    localStorage.removeItem(key)
  } else {
    Object.keys(LOCAL_STORAGE_KEY).forEach((key) => localStorage.removeItem(LOCAL_STORAGE_KEY[key]))
  }
}

export const LIST_PERM_FACILITIES = 'LIST_PERM_FACILITIES'
export const LIST_PERM_FACILITIES_SUCCESS = 'LIST_PERM_FACILITIES_SUCCESS'
export const LIST_PERM_FACILITIES_FAIL = 'LIST_PERM_FACILITIES_FAIL'

export const LIST_ROOMS = 'LIST_ROOMS'
export const LIST_ROOMS_SUCCESS = 'LIST_ROOMS_SUCCESS'
export const LIST_ROOMS_FAIL = 'LIST_ROOMS_FAIL'

export const CREATE_ROOM = 'CREATE_ROOM'
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS'
export const CREATE_ROOM_FAIL = 'CREATE_ROOM_FAIL'

export const UPDATE_ROOM = 'UPDATE_ROOM'
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS'
export const UPDATE_ROOM_FAIL = 'UPDATE_ROOM_FAIL'

export const GET_ROOM = 'GET_ROOM'
export const GET_ROOM_SUCCESS = 'GET_ROOM_SUCCESS'
export const GET_ROOM_FAIL = 'GET_ROOM_FAIL'

export const GET_ROOM_AVAIL_ANALYTICS = 'GET_ROOM_AVAIL_ANALYTICS'
export const GET_ROOM_AVAIL_ANALYTICS_SUCCESS = 'GET_ROOM_AVAIL_ANALYTICS_SUCCESS'
export const GET_ROOM_AVAIL_ANALYTICS_FAIL = 'GET_ROOM_AVAIL_ANALYTICS_FAIL'

export const GET_ROOM_BOOKING_ANALYTICS = 'GET_ROOM_BOOKING_ANALYTICS'
export const GET_ROOM_BOOKING_ANALYTICS_SUCCESS = 'GET_ROOM_BOOKING_ANALYTICS_SUCCESS'
export const GET_ROOM_BOOKING_ANALYTICS_FAIL = 'GET_ROOM_BOOKING_ANALYTICS_FAIL'

export const LIST_ROOM_TYPES = 'LIST_ROOM_TYPES'
export const LIST_ROOM_TYPES_SUCCESS = 'LIST_ROOM_TYPES_SUCCESS'
export const LIST_ROOM_TYPES_FAIL = 'LIST_ROOM_TYPES_FAIL'

export const CREATE_ROOM_TYPE = 'CREATE_ROOM_TYPE'
export const CREATE_ROOM_TYPE_SUCCESS = 'CREATE_ROOM_TYPE_SUCCESS'
export const CREATE_ROOM_TYPE_FAIL = 'CREATE_ROOM_TYPE_FAIL'

export const UPDATE_ROOM_TYPE = 'UPDATE_ROOM_TYPE'
export const UPDATE_ROOM_TYPE_SUCCESS = 'UPDATE_ROOM_TYPE_SUCCESS'
export const UPDATE_ROOM_TYPE_FAIL = 'UPDATE_ROOM_TYPE_FAIL'

export const GET_ROOM_TYPE = 'GET_ROOM_TYPE'
export const GET_ROOM_TYPE_SUCCESS = 'GET_ROOM_TYPE_SUCCESS'
export const GET_ROOM_TYPE_FAIL = 'GET_ROOM_TYPE_FAIL'

export const LIST_ROOMS_READY_TO_ADD = 'LIST_ROOMS_READY_TO_ADD'
export const LIST_ROOMS_READY_TO_ADD_SUCCESS = 'LIST_ROOMS_READY_TO_ADD_SUCCESS'
export const LIST_ROOMS_READY_TO_ADD_FAIL = 'LIST_ROOMS_READY_TO_ADD_FAIL'

export const GET_CREATED_BOOKING_SUMMARY = 'GET_CREATED_BOOKING_SUMMARY'
export const GET_CREATED_BOOKING_SUMMARY_SUCCESS = 'GET_CREATED_BOOKING_SUMMARY_SUCCESS'
export const GET_CREATED_BOOKING_SUMMARY_FAIL = 'GET_CREATED_BOOKING_SUMMARY_FAIL'

export const GET_BOOKING_SUMMARY = 'GET_BOOKING_SUMMARY'
export const GET_BOOKING_SUMMARY_SUCCESS = 'GET_BOOKING_SUMMARY_SUCCESS'
export const GET_BOOKING_SUMMARY_FAIL = 'GET_BOOKING_SUMMARY_FAIL'

export const SEARCH_AVAILABLE_ROOMS = 'SEARCH_AVAILABLE_ROOMS'
export const SEARCH_AVAILABLE_ROOMS_SUCCESS = 'SEARCH_AVAILABLE_ROOMS_SUCCESS'
export const SEARCH_AVAILABLE_ROOMS_FAIL = 'SEARCH_AVAILABLE_ROOMS_FAIL'

export const CREATE_BOOKING_SESSION = 'CREATE_BOOKING_SESSION'
export const CREATE_BOOKING_SESSION_SUCCESS = 'CREATE_BOOKING_SESSION_SUCCESS'
export const CREATE_BOOKING_SESSION_FAIL = 'CREATE_BOOKING_SESSION_FAIL'

export const SAVE_BOOKING_REQUEST = 'SAVE_BOOKING_REQUEST'

export const ATTACH_SESSION_CUSTOMER = 'ATTACH_SESSION_CUSTOMER'
export const ATTACH_SESSION_CUSTOMER_SUCCESS = 'ATTACH_SESSION_CUSTOMER_SUCCESS'
export const ATTACH_SESSION_CUSTOMER_FAIL = 'ATTACH_SESSION_CUSTOMER_FAIL'

export const GET_LIST_ROOMS_BOOKED = 'GET_LIST_ROOMS_BOOKED'
export const GET_LIST_ROOMS_BOOKED_SUCCESS = 'GET_LIST_ROOMS_BOOKED_SUCCESS'
export const GET_LIST_ROOMS_BOOKED_FAIL = 'GET_LIST_ROOMS_BOOKED_FAIL'

export const SEARCH_AVAILABLE_EXTRA_FACILITIES = 'SEARCH_AVAILABLE_EXTRA_FACILITIES'
export const SEARCH_AVAILABLE_EXTRA_FACILITIES_SUCCESS = 'SEARCH_AVAILABLE_EXTRA_FACILITIES_SUCCESS'
export const SEARCH_AVAILABLE_EXTRA_FACILITIES_FAIL = 'SEARCH_AVAILABLE_EXTRA_FACILITIES_FAIL'

export const REMOVE_SESSION_ITEMS = 'REMOVE_SESSION_ITEMS'
export const REMOVE_SESSION_ITEMS_SUCCESS = 'REMOVE_SESSION_ITEMS_SUCCESS'
export const REMOVE_SESSION_ITEMS_FAIL = 'REMOVE_SESSION_ITEMS_FAIL'

export const ADD_EXTRA_FACILITIES = 'ADD_EXTRA_FACILITIES'
export const ADD_EXTRA_FACILITIES_SUCCESS = 'ADD_EXTRA_FACILITIES_SUCCESS'
export const ADD_EXTRA_FACILITIES_FAIL = 'ADD_EXTRA_FACILITIES_FAIL'

export const SEARCH_AVAILABLE_CATERINGS = 'SEARCH_AVAILABLE_CATERINGS'
export const SEARCH_AVAILABLE_CATERINGS_SUCCESS = 'SEARCH_AVAILABLE_CATERINGS_SUCCESS'
export const SEARCH_AVAILABLE_CATERINGS_FAIL = 'SEARCH_AVAILABLE_CATERINGS_FAIL'

export const ADD_SESSION_CATERING = 'ADD_SESSION_CATERING'
export const ADD_SESSION_CATERING_SUCCESS = 'ADD_SESSION_CATERING_SUCCESS'
export const ADD_SESSION_CATERING_FAIL = 'ADD_SESSION_CATERING_FAIL'

export const GET_LIST_EXTRA_FACILITIES_CATEGORIES = 'GET_LIST_EXTRA_FACILITIES_CATEGORIES'
export const GET_LIST_EXTRA_FACILITIES_CATEGORIES_SUCCESS = 'GET_LIST_EXTRA_FACILITIES_CATEGORIES_SUCCESS'
export const GET_LIST_EXTRA_FACILITIES_CATEGORIES_FAIL = 'GET_LIST_EXTRA_FACILITIES_CATEGORIES_FAIL'

export const GET_LIST_CATERINGS = 'GET_LIST_CATERINGS'
export const GET_LIST_CATERINGS_SUCCESS = 'GET_LIST_CATERINGS_SUCCESS'
export const GET_LIST_CATERINGS_FAIL = 'GET_LIST_CATERINGS_FAIL'

export const SET_BOOKING_TABLE_DATE = 'SET_BOOKING_TABLE_DATE'

export const LIST_BOOKINGS_BY_DATE_RANGE = 'LIST_BOOKINGS_BY_DATE_RANGE'
export const LIST_BOOKINGS_BY_DATE_RANGE_SUCCESS = 'LIST_BOOKINGS_BY_DATE_RANGE_SUCCESS'
export const LIST_BOOKINGS_BY_DATE_RANGE_FAIL = 'LIST_BOOKINGS_BY_DATE_RANGE_FAIL'

export const UPDATE_BOOKING_ROOM = 'UPDATE_BOOKING_ROOM'
export const UPDATE_BOOKING_ROOM_SUCCESS = 'UPDATE_BOOKING_ROOM_SUCCESS'
export const UPDATE_BOOKING_ROOM_FAIL = 'UPDATE_BOOKING_ROOM_FAIL'

export const UPDATE_BOOKING_CATERINGS = 'UPDATE_BOOKING_CATERINGS'
export const UPDATE_BOOKING_CATERINGS_SUCCESS = 'UPDATE_BOOKING_CATERINGS_SUCCESS'
export const UPDATE_BOOKING_CATERINGS_FAIL = 'UPDATE_BOOKING_CATERINGS_FAIL'

export const UPDATE_BOOKING_EXTRA_FACILITIES = 'UPDATE_BOOKING_EXTRA_FACILITIES'
export const UPDATE_BOOKING_EXTRA_FACILITIES_SUCCESS = 'UPDATE_BOOKING_EXTRA_FACILITIES_SUCCESS'
export const UPDATE_BOOKING_EXTRA_FACILITIES_FAIL = 'UPDATE_BOOKING_EXTRA_FACILITIES_FAIL'

export const LINK_SESSION_PAYMENT = 'LINK_SESSION_PAYMENT'
export const LINK_SESSION_PAYMENT_SUCCESS = 'LINK_SESSION_PAYMENT_SUCCESS'
export const LINK_SESSION_PAYMENT_FAIL = 'LINK_SESSION_PAYMENT_FAIL'

export const CONFIRM_BOOKING = 'CONFIRM_BOOKING'
export const CONFIRM_BOOKING_SUCCESS = 'CONFIRM_BOOKING_SUCCESS'
export const CONFIRM_BOOKING_FAIL = 'CONFIRM_BOOKING_FAIL'

export const DESTROY_BOOKING_SESSION = 'DESTROY_BOOKING_SESSION'
export const DESTROY_BOOKING_SESSION_SUCCESS = 'DESTROY_BOOKING_SESSION_SUCCESS'
export const DESTROY_BOOKING_SESSION_FAIL = 'DESTROY_BOOKING_SESSION_FAIL'

export const GET_LIST_INCOMING_BOOKING = 'GET_LIST_INCOMING_BOOKING'
export const GET_LIST_INCOMING_BOOKING_SUCCESS = 'GET_LIST_INCOMING_BOOKING_SUCCESS'
export const GET_LIST_INCOMING_BOOKING_FAIL = 'GET_LIST_INCOMING_BOOKING_FAIL'

export const GET_LIST_HISTORY_BOOKING = 'GET_LIST_HISTORY_BOOKING'
export const GET_LIST_HISTORY_BOOKING_SUCCESS = 'GET_LIST_HISTORY_BOOKING_SUCCESS'
export const GET_LIST_HISTORY_BOOKING_FAIL = 'GET_LIST_HISTORY_BOOKING_FAIL'

export const UPDATE_BOOKING_STATE = 'UPDATE_BOOKING_STATE'
export const UPDATE_BOOKING_STATE_SUCCESS = 'UPDATE_BOOKING_STATE_SUCCESS'
export const UPDATE_BOOKING_STATE_FAIL = 'UPDATE_BOOKING_STATE_FAIL'

export const RETRIEVE_ITEM_PRICINGS = 'RETRIEVE_ITEM_PRICINGS'
export const RETRIEVE_ITEM_PRICINGS_SUCCESS = 'RETRIEVE_ITEM_PRICINGS_SUCCESS'
export const RETRIEVE_ITEM_PRICINGS_FAIL = 'RETRIEVE_ITEM_PRICINGS_FAIL'

export const SEARCH_LIST_CUSTOMER_IN_BOOKING = 'SEARCH_LIST_CUSTOMER_IN_BOOKING'
export const SEARCH_LIST_CUSTOMER_IN_BOOKING_SUCCESS = 'SEARCH_LIST_CUSTOMER_IN_BOOKING_SUCCESS'
export const SEARCH_LIST_CUSTOMER_IN_BOOKING_FAIL = 'SEARCH_LIST_CUSTOMER_IN_BOOKING_FAIL'

export const CHANGE_BOOKING_ROOM_PRICE = 'CHANGE_BOOKING_ROOM_PRICE'
export const CHANGE_BOOKING_ROOM_PRICE_SUCCESS = 'CHANGE_BOOKING_ROOM_PRICE_SUCCESS'
export const CHANGE_BOOKING_ROOM_PRICE_FAIL = 'CHANGE_BOOKING_ROOM_PRICE_FAIL'

export const GET_BOOKING_DETAIL = 'GET_BOOKING_DETAIL'
export const GET_BOOKING_DETAIL_SUCCESS = 'GET_BOOKING_DETAIL_SUCCESS'
export const GET_BOOKING_DETAIL_FAIL = 'GET_BOOKING_DETAIL_FAIL'

export const RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS = 'RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS'
export const RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_SUCCESS = 'RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_SUCCESS'
export const RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_FAIL = 'RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_FAIL'

export const RETRIEVE_CATERING_ITEM_PRICINGS = 'RETRIEVE_CATERING_ITEM_PRICINGS'
export const RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS = 'RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS'
export const RETRIEVE_CATERING_ITEM_PRICINGS_FAIL = 'RETRIEVE_CATERING_ITEM_PRICINGS_FAIL'

export const CHANGE_BACKUP_DATA_IN_SELECT_CUSTOMER_BOOKING = 'CHANGE_BACKUP_DATA_IN_SELECT_CUSTOMER_BOOKING'
export const CHANGE_BACKUP_DATA_IN_SELECT_ROOM_BOOKING = 'CHANGE_BACKUP_DATA_IN_SELECT_ROOM_BOOKING'
export const RESET_BOOKING_SESSION = 'RESET_BOOKING_SESSION'
export const REMOVE_BOOKING_STATE = 'REMOVE_BOOKING_STATE'

export const DOWNLOAD_PDF = 'DOWNLOAD_PDF'
export const DOWNLOAD_PDF_SUCCESS = 'DOWNLOAD_PDF_SUCCESS'
export const DOWNLOAD_PDF_FAIL = 'DOWNLOAD_PDF_FAIL'
import {createSelector} from 'reselect'
import { ROOM } from '../../constants/models'
import moment from 'moment'
import * as _ from 'lodash'
import * as helpers from '../../helpers/user'

const { LIST_BY } = ROOM

const getReducer = ({rooms}) => rooms.list

export const getSelectedTab = createSelector(
  [getReducer],
  ({selectedTab}) => selectedTab
)

export const getSelectableTabs = () => {
  return [
    {id: LIST_BY.ROOM, name: "Room"},
    {id: LIST_BY.ROOM_TYPE, name: "Room Type"}
  ]
}

export const getItems =createSelector(
  [getReducer],
  ({selectedTab, items}) => {
    if (selectedTab == LIST_BY.ROOM) {
      const colors = ['cyan', 'blue', 'geekblue', 'purple']
      return _.map(items, item => ({
        id: _.get(item, 'id'),
        name: _.get(item, 'name', ''),
        size: _.get(item, 'size', ''),
        maxParticipants: _.get(item, 'max_attendances'),
        roomType: _.get(item, 'room_type'),
        facilities: _.map(_.get(item, 'facilities', []), (fac, ix) => ({
          color: colors[ix%4],
          name: _.get(fac, 'name')
        })),
        isPublic: _.get(item, 'is_published') ? {color: "#2db7f5", name: "Yes"}: {color: "#f50", name: "No"},
        leadTime: _.get(item, 'lead_time'),
        freeCancelDays: _.get(item, 'free_cancel_days'),
      }))
    }

    return _.map(items, item => ({
      id: _.get(item, "id"),
      name: _.get(item, 'room_type'),
      description: _.get(item, 'description'),
      isPublic: _.get(item, 'is_published') ? {color: "#2db7f5", name: "Yes"}: {color: "#f50", name: "No"},
      maxParticipants: _.get(item, 'max_attendances'),
      roomCount: _.get(item, 'room_count'),
    }))
  }
)

export const getPage = createSelector(
  [getReducer],
  ({page}) => page
)

export const getPageSize = createSelector(
  [getReducer],
  ({pageSize}) => pageSize
)

export const getTotal = createSelector(
  [getReducer],
  ({total}) => total
)

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const generateReqPayload = createSelector(
  [getReducer],
  ({page, pageSize, }) => ({
    page, 
    page_size: pageSize
  })
)

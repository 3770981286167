import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import { message } from 'antd'
import * as C from '../../actionTypes/caterings/edit'
import { history } from '../../config/store'
import * as _ from 'lodash'

function* retrieveCatering({api}, {payload}) {
  try {
    const response = yield call(api.caterings.retrieveCatering, payload)
    yield put({type: C.RETRIEVE_CATERING_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.RETRIEVE_CATERING_FAILED, payload: e})
    message.error("Error: ", _.get(e, 'data.detail'))
    console.error(e)
  }
}

function* submitCatering({api}, {payload}) {
  try {
    const response = yield call(api.caterings.updateCatering, payload.id, payload)
    yield put({type: C.SUBMIT_COMPLETED, payload: response})
    history.goBack()
  } catch (e) {
    yield put({type: C.SUBMIT_FAILED, payload: e })
    message.error("Error: ", _.get(e, 'data.detail'))
    console.error(e)
  }
}

function editSaga(deps) {
  return [
      takeLeading(C.RETRIEVE_CATERING, retrieveCatering, deps),
      takeLeading(C.SUBMIT, submitCatering, deps),
  ]
}

export default editSaga

import {
  SEARCH_LIST_CUSTOMER,
  SEARCH_LIST_CUSTOMER_FAIL,
  SEARCH_LIST_CUSTOMER_SUCCESS,
  CREATE_USER_IN_BOOKING,
  CREATE_USER_IN_BOOKING_SUCCESS,
  CREATE_USER_IN_BOOKING_FAIL,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  LIST_USERS,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAIL,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAIL,
  SET_USER_FILTER,
  GET_USER_ANALYTICS,
  GET_USER_ANALYTICS_SUCCESS,
  GET_USER_ANALYTICS_FAIL,
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_FAIL,
  REQUEST_RESET_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS
} from '../actionTypes/user.actionTypes'

export function searchListCustomer(data) {
  return ({type: SEARCH_LIST_CUSTOMER, data})
}
export function searchListCustomerSuccess(data) {
  return ({type: SEARCH_LIST_CUSTOMER_SUCCESS, data})
}
export function searchListCustomerFail(data) {
  return ({type: SEARCH_LIST_CUSTOMER_FAIL, data})
}

export function createCustomerInBooking(data) {
  return ({type: CREATE_USER_IN_BOOKING, data})
}

export function createCustomerInBookingSuccess(data) {
  return ({type: CREATE_USER_IN_BOOKING_SUCCESS, data})
}
export function createCustomerInBookingFail(error) {
  return ({type: CREATE_USER_IN_BOOKING_FAIL, error})
}

export function createUser(data) {
  return ({type: CREATE_USER, data})
}

export function createUserSuccess(data) {
  return ({type: CREATE_USER_SUCCESS, data})
}

export function createUserFail(error) {
  return ({type: CREATE_USER_FAIL, error})
}

export function updateUser(id, data) {
  return ({type: UPDATE_USER, data: {id, ...data}})
}

export function updateUserSuccess(data) {
  return ({type: UPDATE_USER_SUCCESS, data})
}

export function updateUserFail(error) {
  return ({type: UPDATE_USER_FAIL, error})
}

export function listUsers(data) {
  return ({type: LIST_USERS, data})
}

export function listUsersSuccess(data) {
  return ({type: LIST_USERS_SUCCESS, data})
}

export function listUsersFail(error) {
  return ({type: LIST_USERS_FAIL, error})
}

export function getUserInfo(data) {
  return ({type: GET_USER_INFO, data})
}

export function getUserInfoSuccess(data) {
  return ({type: GET_USER_INFO_SUCCESS, data})
}

export function clearUserInfo() {
  return ({type: GET_USER_INFO_SUCCESS, data: null})
}

export function getUserInfoFail(error) {
  return ({type: GET_USER_INFO_FAIL, error})
}

export function setUserFilter(data) {
  return ({type: SET_USER_FILTER, data})
}

export function clearUserFilter() {
  return ({type: SET_USER_FILTER, data: {}})
}

export function getUserBookingAnalytics(data) {
  return ({type: GET_USER_ANALYTICS, data})
}

export function getUserBookingAnalyticsSuccess(data) {
  return ({type: GET_USER_ANALYTICS_SUCCESS, data})
}

export function getUserBookingAnalyticsFail(error) {
  return ({type: GET_USER_ANALYTICS_FAIL, error})
}

export function updateProfile(data) {
  return ({type: UPDATE_PROFILE, data})
}

export function updateProfileSuccess(data) {
  return ({type: UPDATE_PROFILE_SUCCESS, data})
}

export function updateProfileFail(error) {
  return ({type: UPDATE_PROFILE_FAIL, error})
}

export function requestResetPassword(data) {
  return ({type: REQUEST_RESET_PASSWORD, data})
}

export function requestResetPasswordSuccess() {
  return ({type: REQUEST_RESET_PASSWORD_SUCCESS})
}

export function requestResetPasswordFail() {
  return ({type: REQUEST_RESET_PASSWORD_FAIL})
}

export function changePassword(data) {
  return ({type: CHANGE_PASSWORD, data})
}

export function changePasswordSuccess() {
  return ({type: CHANGE_PASSWORD_SUCCESS})
}

export function changePasswordFail() {
  return ({type: CHANGE_PASSWORD_FAIL})
}

export function resetPasswordConfirm(data) {
  return ({type: RESET_PASSWORD, data})
}

export function resetPasswordConfirmSuccess() {
  return ({type: RESET_PASSWORD_SUCCESS})
}

export function resetPasswordConfirmFail() {
  return ({type: RESET_PASSWORD_FAIL})
}
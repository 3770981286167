import { createSelector } from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'
import { EXFACILITY } from "../../constants/models";

import * as helpers from "../../helpers/exfacilities"

const { LIST_BY } = EXFACILITY

const getReducer = ({ facilities }) => facilities.list

export const getItems = createSelector(
  [getReducer],
  ({ items, selectedTab }) => {
    items = _.filter(items, it => selectedTab == LIST_BY.ACTIVE ? it.is_active : !it.is_active)
    return _.map(items, item => ({
      ...item,
      description: _.truncate(item.description, { length: 50 }),
      activeTag: helpers.getActiveTag(item.is_active),
    }))
  }
)

export const isFetching = createSelector(
  [getReducer],
  ({ isFetching }) => isFetching
)

export const getSelectableTabs = () => [
  { id: LIST_BY.ACTIVE, name: "Active" },
  { id: LIST_BY.INACTIVE, name: "Inactive" }
]

export const getSelectedTab = createSelector(
  [getReducer],
  ({ selectedTab }) => selectedTab
)


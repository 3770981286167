import { combineReducers } from 'redux'
import * as C from "../../actionTypes/users/edit";
import moment from 'moment'
import * as _ from 'lodash'

const isFetching = (state=false, action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_USER:
      return true
    case C.RETRIEVE_USER_FAILED:
    case C.RETRIEVE_USER_COMPLETED:
      return false
    default: 
      return state
  }
}

const data = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case C.RETRIEVE_USER_COMPLETED:
      return payload
    default: 
      return state
  }
}

const isSubmitting = (state = false, action) => {
  const {type} = action
  switch (type) {
    case C.SUBMIT_USER:
      return true
    case C.SUBMIT_USER_COMPLETED:
    case C.SUBMIT_USER_FAILED:
      return false
    default:
      return state
  }
}

const reducers = combineReducers({
  isSubmitting,
  data,
  isFetching,
})

export default reducers


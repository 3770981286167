import { combineReducers } from 'redux'
import * as C from "../../actionTypes/caterings/list";
import * as EDIT_C from '../../actionTypes/caterings/edit'
import * as NEW_C from '../../actionTypes/caterings/new'
import { CATERING } from "../../constants/models";
import * as _ from 'lodash'

const { LIST_BY } = CATERING

const items = (state = [], action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_CATERING_COMPLETED:
      return payload
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const {type} = action
  switch (type) {
    case C.LIST_CATERING:
    case EDIT_C.SUBMIT_COMPLETED:
    case NEW_C.SUBMIT_COMPLETED:
      return true
    case C.LIST_CATERING_COMPLETED:
    case C.LIST_CATERING_FAILED:
      return false
    default:
      return state
  }
}


const selectedTab = (state = LIST_BY.ACTIVE, action) => {
  const {type, payload} = action
  switch (type) {
    case C.CHANGE_TAB:
      return payload
    default:
      return state
  }
}

const reducers = combineReducers({
  items,
  isFetching,
  selectedTab,
})

export default reducers

import { combineReducers } from 'redux'
import * as C from "../../actionTypes/rooms/list";
import * as _ from 'lodash'
import { ROOM } from '../../constants/models'

const { LIST_BY } = ROOM


const page = (state = 1, action) => {
  const {type, payload} = action
  switch (type) {
    case C.CHANGE_PAGE:
      return payload
    case C.LIST_ROOM_COMPLETED:
      return _.get(payload, 'page', 1)
    case C.CHANGE_TAB:
      return 1
    default:
      return state
  }
}

const total = (state = 0, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_ROOM_COMPLETED:
      return _.get(payload, 'count', 0)
    case C.CHANGE_TAB:
      return 0
    default:
      return state
  }
}

const pageSize = (state = 10, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_ROOM_COMPLETED:
      return _.get(payload, 'page_size', 10)
    default:
      return state
  }
}

const items = (state = [], action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_ROOM_COMPLETED:
      return _.get(payload, 'results', [])
    case C.CHANGE_TAB:
      return []
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const {type} = action
  switch (type) {
    case C.LIST_ROOM:
    case C.CHANGE_TAB:
    case C.CHANGE_PAGE:
      return true
    case C.LIST_ROOM_COMPLETED:
    case C.LIST_ROOM_FAILED:
      return false
    default:
      return state
  }
}

const selectedTab = (state = LIST_BY.ROOM, action) => {
  const {type, payload} = action
  switch (type) {
    case C.CHANGE_TAB:
      return payload
    default:
      return state
  }
}


const reducers = combineReducers({
  page,
  pageSize,
  total,
  items,
  isFetching,
  selectedTab,
})

export default reducers

const C = "BOOKING.DETAIL"

export const RETRIEVE_BOOKING = `${C}.RETRIEVE_BOOKING`
export const RETRIEVE_BOOKING_COMPLETED = `${C}.RETRIEVE_BOOKING_COMPLETED`
export const RETRIEVE_BOOKING_FAILED = `${C}.RETRIEVE_BOOKING_FAILED`

export const RETRIEVE_BOOKING_HTML = `${C}.RETRIEVE_BOOKING_HTML`
export const RETRIEVE_BOOKING_HTML_COMPLETED = `${C}.RETRIEVE_BOOKING_HTML_COMPLETED`
export const RETRIEVE_BOOKING_HTML_FAILED = `${C}.RETRIEVE_BOOKING_HTML_FAILED`

export const CHANGE_NOTES = `${C}.CHANGE_NOTES`
export const SAVE_NOTES = `${C}.SAVE_NOTES`
export const SAVE_NOTES_COMPLETED = `${C}.SAVE_NOTES_COMPLETED`
export const SAVE_NOTES_FAILED = `${C}.SAVE_NOTES_FAILED`

export const CANCEL_BOOKING = `${C}.CANCEL_BOOKING`
export const CANCEL_BOOKING_COMPLETED = `${C}.CANCEL_BOOKING_COMPLETED`
export const CANCEL_BOOKING_FAILED = `${C}.CANCEL_BOOKING_FAILED`

export const SHOW_REFUND_MODAL = `${C}.SHOW_REFUND_MODAL`
export const CLOSE_REFUND_MODAL = `${C}.CLOSE_REFUND_MODAL`
export const CHANGE_REFUND_AMOUNT = `${C}.CHANGE_REFUND_AMOUNT`
export const SUBMIT_REFUND = `${C}.SUBMIT_REFUND`
export const SUBMIT_REFUND_SUCCESS = `${C}.SUBMIT_REFUND_SUCCESS`
export const SUBMIT_REFUND_FAILED = `${C}.SUBMIT_REFUND_FAILED`

import { combineReducers } from 'redux'
import * as C from "../../actionTypes/bookings/detail";
import { BOOKING } from "../../constants/models";
import moment from 'moment'
import * as _ from 'lodash'


const {PAYMENT_METHOD, PAYMENT_STATUS, BOOKING_STATUS} = BOOKING

const data = (state = {}, action) => {
  const {type, payload} = action
  switch (type) {
    case C.RETRIEVE_BOOKING_COMPLETED:
      return payload
    case C.SAVE_NOTES_COMPLETED:
    case C.CANCEL_BOOKING_COMPLETED:
    case C.SUBMIT_REFUND_SUCCESS:
      return payload
    default:
      return state
  }
}

const notes = (state = null, action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_NOTES:
      return payload
    case C.SAVE_NOTES_COMPLETED:
      return null
    default:
      return state
  }
}

const savingNotes = (state=false, action) => {
  const { type, payload } = action
  switch (type) {
    case C.SAVE_NOTES:
      return true
    case C.SAVE_NOTES_COMPLETED:
    case C.SAVE_NOTES_FAILED:
      return false
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const {type} = action
  switch (type) {
    case C.RETRIEVE_BOOKING:
      return true
    case C.RETRIEVE_BOOKING_COMPLETED:
    case C.RETRIEVE_BOOKING_FAILED:
      return false
    default:
      return state
  }
}

const cancellingBooking = (state=false, action) => {
  const {type} = action
  switch (type) {
    case C.CANCEL_BOOKING:
      return true
    case C.CANCEL_BOOKING_COMPLETED:
    case C.CANCEL_BOOKING_FAILED:
      return false
    default:
      return state
  }
}

const refundModal = combineReducers({
  visible: (state=false, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SHOW_REFUND_MODAL:
        return true
      case C.CLOSE_REFUND_MODAL:
      case C.SUBMIT_REFUND_SUCCESS:
        return false
      default: 
        return state
    }
  },

  refundAmount: (state=0, action) => {
    const {type, payload} = action
    switch (type) {
      case C.CHANGE_REFUND_AMOUNT:
        if (payload < 0) {
          return 0
        }
        return payload
      case C.SUBMIT_REFUND_SUCCESS:
        return 0
      default:
        return state
    }
  },

  isSubmitting: (state=false, action) => {
    const {type, payload} = action
    switch (type) {
      case C.SUBMIT_REFUND:
        return true
      case C.SUBMIT_REFUND_SUCCESS:
      case C.SUBMIT_REFUND_FAILED:
        return false
      default:
        return state
    }
  }
})

const invoice = combineReducers({
  html: (state = "", action) => {
    const {type, payload} = action
    switch (type) {
      case C.RETRIEVE_BOOKING_HTML_COMPLETED:
        return _.get(payload, 'html')
      default:
        return state
    }
  },

  pdfUrl: (state = "", action) => {
    const {type, payload} = action
    switch (type) {
      case C.RETRIEVE_BOOKING_HTML_COMPLETED:
        return _.get(payload, 'pdfUrl')
      default:
        return state
    }
  },

  isFetching: (state = false, action) => {
    const {type} = action
    switch (type) {
      case C.RETRIEVE_BOOKING_HTML:
        return true
      case C.RETRIEVE_BOOKING_HTML_COMPLETED:
      case C.RETRIEVE_BOOKING_HTML_FAILED:
        return false
      default:
        return state
    }
  }
})


const reducers = combineReducers({
  data,
  notes,
  savingNotes,
  isFetching,
  cancellingBooking,
  invoice,
  refundModal,
})

export default reducers

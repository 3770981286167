import { combineReducers } from 'redux'
import * as C from "../../actionTypes/users/new";
import moment from 'moment'
import * as _ from 'lodash'


const isSubmitting = (state = false, action) => {
  const {type} = action
  switch (type) {
    case C.SUBMIT:
      return true
    case C.SUBMIT_COMPLETED:
    case C.SUBMIT_FAILED:
      return false
    default:
      return state
  }
}

const reducers = combineReducers({
  isSubmitting
})

export default reducers


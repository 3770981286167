import { combineReducers } from 'redux'
import * as C from "../../actionTypes/bookings/list";
import { BOOKING } from "../../constants/models";
import moment from 'moment'
import * as _ from 'lodash'

const { LIST_BY } = BOOKING

const selectedTab = (state = LIST_BY.INCOMING, action) => {
  const {type, payload} = action
  switch (type) {
    case C.CHANGE_TAB:
      return payload
    default:
      return state
  }
}

const page = (state = 1, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_BOOKING_COMPLETED:
      return _.get(payload, "page", 1)
    case C.CHANGE_PAGE:
      return payload
    case C.CHANGE_TAB:
      return 1
    default:
      return state
  }
}

const total = (state = 0, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_BOOKING_COMPLETED:
      return _.get(payload, "count", 0)
    default:
      return state
  }
}

const pageSize = (state = 10, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_BOOKING_COMPLETED:
      return _.get(payload, "page_size", 10)
    case C.CHANGE_TAB:
      return 10
    default:
      return state
  }
}

const items = (state = 1, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_BOOKING_COMPLETED:
      return _.get(payload, "results")
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const {type} = action
  switch (type) {
    case C.LIST_BOOKING:
    case C.CHANGE_TAB:
    case C.CHANGE_PAGE:
      return true
    case C.LIST_BOOKING_COMPLETED:
    case C.LIST_BOOKING_FAILED:
      return false
    default:
      return state
  }
}

const filter = combineReducers({
  selectedPaymentStatuses: (state = [], action) => {
    const {type, payload} = action
    switch (type) {
      case C.CHANGE_PAYMENT_STATUSES_FILTER:
        return payload
      default:
        return state
    }
  },
  selectedBookingStatuses: (state = [], action) => {
    const {type, payload} = action
    switch (type) {
      case C.CHANGE_BOOKING_STATUSES_FILTER:
        return payload
      default:
        return state
    }
  },
  isSubmitting: (state=false, action) => {
    const {type} = action
    switch (type) {
      case C.SUBMIT_FILTER:
        return true
      case C.SUBMIT_FILTER_COMPLETED:
      case C.SUBMIT_FILTER_FAILED:
        return false
      default:
        return state
    }
  }
})

const reducers = combineReducers({
  selectedTab,
  page,
  pageSize,
  total,
  items,
  isFetching,
  filter,
})

export default reducers

import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { message } from 'antd'
import {s2c} from '../helpers/helpers'
import {history} from '../config/store'
import * as paths from '../constants/paths.constants'
import * as actionTypes from '../actionTypes/room.actionTypes'
import * as actions from '../actions/room'

function* createRoom({api}, {data}) {
  try {
    const room = yield call(api.room.createRoom, data)
    if(room) {
      message.success('Create room successfully')
      yield put(actions.createRoomSuccess(s2c(room)))
      history.push(paths.ListRooms)
    }
  } catch (e) {
    message.error('Cannot create room')
    yield put(actions.createRoomFail())
  }
}

function* listRooms({api}, {data}) {
  try {
    const listRoom = yield call(api.room.listRooms, data)
    if(listRoom) {
      yield put(actions.listRoomsSuccess(listRoom))
    }
  } catch (e) {
    yield put(actions.listRoomsFail(e))
  }
}

function* getRoom({api}, {data}) {
  try {
    const room = yield call(api.room.getRoom, data)
    if(room) {
      yield put(actions.getRoomSuccess(room))
    }
  } catch (e) {
    yield put(actions.getRoomFail(e))
  }
}

function* updateRoom({api}, {data}) {
  try {
    const {id} = data
    const room = yield call(api.room.updateRoom, id, data)
    if(room) {
      message.success('Update room successfully')
      yield put(actions.updateRoomSuccess(room))
    }
  } catch (e) {
    message.error('Cannot update room')
    yield put(actions.updateRoomFail(e))
  }
}

function* getRoomAvailAnalytics({api}, {data}) {
  try {
    const analytics = yield call(api.analytics.roomAvailability, data)
    if(analytics) {
      yield put(actions.getRoomAvailAnalyticsSuccess(analytics))
    }
  } catch (e) {
    yield put(actions.getRoomAvailAnalyticsFail(e))
  }
}

function* getRoomBookingAnalytics({api}, {data}) {
  try {
    const analytics = yield call(api.analytics.roomBooking, data)
    if(analytics) {
      yield put(actions.getRoomBookingAnalyticsSuccess(analytics))
    }
  } catch (e) {
    yield put(actions.getRoomBookingAnalyticsFail(e))
  }
}

function* listPermFacs({api}, {data}) {
  try {
    const pfs = yield call(api.room.listPermFacs, data)
    if(pfs) {
      yield put(actions.listPermFacsSuccess(pfs))
    }
  } catch (e) {
    yield put(actions.listPermFacsFail(e))
  }
}

// room type

function* listRoomTypes({api}, {data}) {
  try {
    const roomTypes = yield call(api.room.listRoomTypes, data)
    if(roomTypes) {
      yield put(actions.listRoomTypesSuccess(roomTypes))
    }
  } catch (e) {
    yield put(actions.listRoomTypesFail(e))
  }
}

function* createRoomType({api}, {data}) {
  try {
    const room = yield call(api.room.createRoomType, data)
    if(room) {
      message.success('Create room type successfully')
      yield put(actions.createRoomTypeSuccess(s2c(room)))
      history.push(paths.ListRoomTypes)
    }
  } catch (e) {
    message.error('Cannot create room type')
    yield put(actions.createRoomTypeFail())
  }
}

function* getRoomType({api}, {data}) {
  try {
    const room = yield call(api.room.getRoomType, data)
    if(room) {
      yield put(actions.getRoomTypeSuccess(room))
    }
  } catch (e) {
    yield put(actions.getRoomTypeFail(e))
  }
}

function* updateRoomType({api}, {data}) {
  try {
    const {id} = data
    const room = yield call(api.room.updateRoomType, id, data)
    if(room) {
      message.success('Update room type successfully')
      yield put(actions.updateRoomTypeSuccess(room))
    }
  } catch (e) {
    message.error('Cannot update room type')
    yield put(actions.updateRoomTypeFail(e))
  }
}

function* listRoomsReadyToAdd({api}, {data}) {
  try {
    const listRoom = yield call(api.room.listRoomsReadyToAdd, data)
    if(listRoom) {
      yield put(actions.listRoomsReadyToAddSuccess(listRoom))
    }
  } catch (e) {
    yield put(actions.listRoomsReadyToAddFail(e))
  }
}


function roomSaga(deps) {
  return function* saga() {
    yield all([
      takeLatest(actionTypes.GET_ROOM, getRoom, deps),
      takeLatest(actionTypes.GET_ROOM_BOOKING_ANALYTICS, getRoomBookingAnalytics, deps),
      takeLatest(actionTypes.GET_ROOM_AVAIL_ANALYTICS, getRoomAvailAnalytics, deps),
      takeLatest(actionTypes.LIST_ROOMS, listRooms, deps),
      takeLatest(actionTypes.CREATE_ROOM, createRoom, deps),
      takeLatest(actionTypes.UPDATE_ROOM, updateRoom, deps),
      takeLatest(actionTypes.LIST_PERM_FACILITIES, listPermFacs, deps),
      takeLatest(actionTypes.LIST_ROOM_TYPES, listRoomTypes, deps),
      takeLatest(actionTypes.GET_ROOM_TYPE, getRoomType, deps),
      takeLatest(actionTypes.CREATE_ROOM_TYPE, createRoomType, deps),
      takeLatest(actionTypes.UPDATE_ROOM_TYPE, updateRoomType, deps),
      takeLatest(actionTypes.LIST_ROOMS_READY_TO_ADD, listRoomsReadyToAdd, deps),
    ])
  }
}

export default roomSaga
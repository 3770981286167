import React, { Component } from 'react'
import { withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Input, Upload, Icon, message } from 'antd'
import { Table, Tag, Form, Switch } from 'antd';
import { Card, Col, Row } from 'antd';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import PictureWall from "../images/PicturesWall";
import { ROOM } from '../../constants/models'
const { TextArea } = Input;

const { LIST_BY } = ROOM

const NewRoomType = (props) => {
  let match = useRouteMatch();
  const {
    t,
    isSubmitting,
    onSubmit,

    form
  } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = form

  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        const image_ids = _.map(_.get(values, 'images'), im => im.id)
        values = _.set(values, 'image_ids', image_ids)
        values = _.omit(values, ['images'])
        onSubmit(values)
      }
    })
  }

  return (
    <>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t("NEW ROOM TYPE")}
        subTitle={t("Create a new room type")}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        <Form onSubmit={handleSubmit}>
          <Card style={{ marginBottom: 32 }}>
            <Form.Item
              label={t("Name")}
              name="name"
            >
              {getFieldDecorator('room_type', {
                rules: [{ required: true, message: t('Please input name!') }]
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label={t("Description")}
              name="description"
            >
              {getFieldDecorator('description', {
                rules: [{ required: true, message: t('Please input a short description!') }]
              })(
                <TextArea rows={4} />
              )}
            </Form.Item>

            <Form.Item
              label={t("Is Public")}
              name="isPublic"
              valuePropName="checked"
            >
              {getFieldDecorator('is_published', {
                valuePropName: 'checked',
                initialValue: false
              })(
                <Switch />
              )}
            </Form.Item>
          </Card>

          <Card style={{ marginBottom: 32 }}>
            <Form.Item
              label={t("Images")}
              name="images"
              valuePropName="images"
            >
              {getFieldDecorator('images', {
                valuePropName: 'images',
                onChange: (images) => setFieldsValue({"images": images})
              })(
                <PictureWall />
              )}
            </Form.Item>
          </Card>

          <Grid container justify="flex-end">
            <Grid item>
              <Form.Item>
                <Button
                  variant="contained"
                  color="primary"
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                >
                  {t("Submit")}
                </Button>
              </Form.Item>
            </Grid>
          </Grid>
        </Form>
      </div>
    </>
  )
}

export default Form.create({
  name: 'newRoomType'
})(withRouter(withTranslation("room_management")(NewRoomType)))

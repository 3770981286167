export const GetSession = () => '/'

export const Login = () => '/auth/jwt/create'
export const RefreshToken = () => '/auth/jwt/refresh'
export const SignUp = () => '/auth/users/'
export const ActivateAccount = () => '/auth/users/activation/'
export const ResendActivation = () => '/auth/users/resend_activation/'
export const GetProfile = () => '/auth/users/me/'
export const RequestResetPassword = () => '/auth/users/reset_password/'
export const ChangePassword = () =>  '/auth/users/set_password/'
export const ResetPassword = () =>  '/auth/users/reset_password_confirm/'

export const GetRoomTypes = () => '/room-types'
export const GetListExtraFacilityCategories = () => '/ex-facility-categories'
export const GetListCaterings = () => '/booking-session/find_available_caterings'
export const GetListPermanentFacilities = () => '/permanent-facilities'
export const GetConfiguration = () => '/get_configuration'
export const GetListExtraFacilities = () => '/extra-facilities'

// search available room
export const SearchAvailableRoom = () => '/booking-session/find_available_rooms'

// Search available extra facilities
export const SearchExtraFacilities = () => '/booking-session/find_available_exfacilities'
export const AddExtraFacilities = () => '/booking-session/add_session_exfacility_items'

// Search available catering
export const SearchAvailableCaterings = () => '/booking-session/find_available_caterings'
export const AddCatering = () => '/booking-session/add_session_catering_items'

//Booking room: attach customer id to booking session
export const AttachSessionCustomer = () => '/booking-session/attach_session_customer'

export const CreateBookingSession = () => '/booking-session/create_booking_session'
export const GetListRoomsBooked = () => '/booking-session/list_room_availabilities'

// Update a few item of booking session
export const UpdateBookingRoom = () => '/booking-session/update_room'
export const UpdateBookingCaterings = () => '/booking-session/update_or_create_catering_items'
export const UpdateBookingExtraFacilities = () => '/booking-session/update_or_create_exfacilities_items'
export const AddSessionItems = () => '/booking-session/add_session_items'
export const ChangeBookingRoomPrice = () => '/booking-session/change_session_item_pricings'

// Payment
export const LinkSessionPayment = () => '/booking-session/link_session_payment'
export const ConfirmBooking = () => '/booking-session/confirm_booking'

export const PrintInvoice = () => '/booking-session/download_invoice_pdf'

export const UpdateBookingState = () => '/booking-session/update_session_info'

export const RemoveSessionItems = () => '/booking-session/remove_session_items'

export const GetBookingSession = () => '/booking-session/retrieve_booking_session'

export const DestroyBookingSession = () => '/booking-session/destroy_booking_session'

export const GetBookings = () => '/bookings'
export const GetBookingDetail = (id) => `/bookings/${id}`
export const DownloadBookingInvoiceHTML = () => '/booking/download_invoice_html'

export const SendContactRequest = () => '/contacts'

/**
 * Portal section
 */
export const SearchListCustomer = () => '/users'

// Users
export const CreateUser = () => '/users'
export const UpdateUser = (id) => '/users/' + id
export const ListUsers = () => '/users'
export const GetUserInfo = (id) => '/users/' + id

// Booking
export const GetBookingSummary = () => '/booking_summary'
export const ListBookingsByDateRange = () => '/list_room_bookings_by_date'
export const GetListIncomingBookings = () => '/list_incoming_bookings'
export const GetListHistoryBookings = () => '/list_history_bookings'
export const RetrieveItemPricings = () => '/booking-session/retrieve_item_pricings'

export const GetBookingToken = () => '/booking/get_booking_token'
export const DownloadBookingInvoce = () => '/booking/download_invoice_pdf'

// Room mngt
export const CreateRoom = () => '/rooms'
export const UpdateRoom = (id) => '/rooms/' + id
export const ListRooms = () => '/rooms'
export const GetRoom = (id) => '/rooms/' + id

export const ListPermFacs = () => '/permanent-facilities'
export const ListRoomTypes = () => '/room-types'
export const CreateRoomType = () => '/room-types'
export const UpdateRoomType = (id) => '/room-types/' + id
export const GetRoomType = (id) => '/room-types/' + id
export const RoomTypeListRoomsReadyToAdd = () => '/room_type_get_ready_rooms_to_add'

// Analytics
const analytics_prefix = '/analytics'
export const RoomBookingAnalytics = () => analytics_prefix + '/retrieve_room_booking_analytics'
export const RoomAvailAnalytics = () => analytics_prefix + '/retrieve_room_availability_analytics'
export const UserBookingAnalytics = () => analytics_prefix + '/retrieve_user_booking_analytics'

// Facilities
export const CreateNewFacilityCategory = () => '/ex-facility-categories'
export const UpdateFacilityCategory = () => '/ex-facility-categories'
export const IncreaseQuantityFacilityCategory = (id) => `/ex-facility-categories/${id}/increase_quantity`

// Caterings
export const GetListAllCaterings = () => '/caterings'
export const CreateNewCatering = () => '/caterings'
export const UpdateCatering = (id) => '/caterings' + id

// Discount
export const GetListDiscount = () => '/discounts'
export const CreateNewDiscount = () => '/discounts'

// Upload images
export const UploadSingleImage = () => '/upload_single_image'
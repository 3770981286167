import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'

import { DISCOUNT } from '../../constants/models'
const { OBJECT_TYPE, APPLY_TYPE } = DISCOUNT

const FMT = 'DD.MM.YYYY'

const getReducer = ({discounts}) => discounts.edit

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const getName = createSelector(
  [getReducer],
  ({name}) => name
)

export const getDescription = createSelector(
  [getReducer],
  ({description}) => description
)


export const getSelectableApplyToes = () => [
  { id: DISCOUNT.OBJECT_TYPE.CUSTOMER, name: "Customer" },
  { id: DISCOUNT.OBJECT_TYPE.ROOM, name: "Room" }
]

export const getSelectedApplyTo = createSelector(
  [getReducer],
  ({selectedApplyTo}) => selectedApplyTo
)


export const getSelectableApplyTypes = createSelector(
  [getReducer],
  ({selectedApplyTo}) => {
    return [
      {id: DISCOUNT.APPLY_TYPE.ALL, name: "All"},
      ...(selectedApplyTo == DISCOUNT.OBJECT_TYPE.CUSTOMER ? [{id: DISCOUNT.APPLY_TYPE.NEW, name: "New"}]: []),
      {id: DISCOUNT.APPLY_TYPE.CUSTOM, name: "Custom"}
    ]
  }
)

export const getSelectedApplyType = createSelector(
  [getReducer],
  ({selectedApplyType}) => selectedApplyType
)


export const getRoomDiscount = createSelector(
  [getReducer],
  ({roomDiscount}) => roomDiscount
)

export const getFacilityDiscount = createSelector(
  [getReducer],
  ({facilityDiscount}) => facilityDiscount
)

export const getCateringDiscount = createSelector(
  [getReducer],
  ({cateringDiscount}) => cateringDiscount
)

export const getStartDate = createSelector(
  [getReducer],
  ({startDate}) => startDate
)

export const getEndDate = createSelector(
  [getReducer],
  ({endDate}) => endDate
)

export const isActive = createSelector(
  [getReducer],
  ({isActive}) => isActive
)


export const isSelectedCustomersVisiable = createSelector(
  [getReducer],
  ({selectedApplyType, selectedApplyTo}) => {
    return selectedApplyTo == DISCOUNT.OBJECT_TYPE.CUSTOMER && selectedApplyType == DISCOUNT.APPLY_TYPE.CUSTOM
  }
)

export const getSelectableCustomers = createSelector(
  [getReducer],
  ({searchCustomer: {items}, selectedCustomers: {ids}}) => {

    return _.map(items, it => ({
      id: it.id,
      name: `${it.first_name} ${it.last_name}`,
      email: it.email,
      company: it.company,
      customerNo: it.customer_id,
      selected: !!_.find(ids, id => id == it.id),
    }))
  }
)

export const getSearchCustomerPagination = createSelector(
  [getReducer],
  ({searchCustomer: {page, pageSize, total}}) => ({page, pageSize, total})
)

export const getSearchCustomerText = createSelector(
  [getReducer],
  ({searchCustomer: {searchText}}) => searchText
)

export const isSearchingCustomer = createSelector(
  [getReducer],
  ({searchCustomer: {isSearching}}) => isSearching
)

export const isSelectedRoomsVisible = createSelector(
  [getReducer],
  ({selectedApplyType, selectedApplyTo}) => {
    return selectedApplyTo == DISCOUNT.OBJECT_TYPE.ROOM && selectedApplyType == DISCOUNT.APPLY_TYPE.CUSTOM
  }
)

export const getSelectedCustomersPagination = createSelector(
  [getReducer],
  ({selectedCustomers: {ids, page, pageSize}}) => {
    return {page, pageSize, total: _.size(ids)}
  }
)

export const getVisibleSelectedCustomers = createSelector(
  [getReducer],
  ({selectedCustomers: {items}}) => {
    return _.map(items, it => ({
      ..._.pick(it, ['id', 'email']),
      name: `${it.first_name} ${it.last_name}`,
      customerNo: _.get(it, "customer_id", ""),
      company: _.get(it, "company", "")
    }))
  }
)

export const isFechingSelectedCustomers = createSelector(
  [getReducer],
  ({selectedCustomers: {isFetching}}) => isFetching
)

export const getSelectableRooms = createSelector(
  [getReducer],
  ({searchRoom: {items}, selectedRooms: {ids}}) => _.map(items, it => ({
    id: it.id,
    name: it.name,
    size: it.size,
    maxParticipants: it.max_attendances,
    roomType: it.room_type,
    selected: !!_.find(ids, id => id === it.id)
  }))
)

export const getSelectedRoomsPagination = createSelector(
  [getReducer],
  ({selectedRooms: {ids, page, pageSize}}) => {
    return {page, pageSize, total: _.size(ids)}
  }
)

export const getVisibleSelectedRooms = createSelector(
  [getReducer],
  ({selectedRooms: {items, ids}}) => {
    return _.map(items, it => ({
      id: it.id,
      name: it.name,
      size: it.size,
      maxParticipants: it.max_attendances,
      roomType: it.room_type,
      selected: !!_.find(ids, id => id === it.id)
    }))
  }
)

export const getRoomSearchText = createSelector(
  [getReducer],
  ({searchRoom: {searchText}}) => searchText
)

export const getSearchRoomPagination = createSelector(
  [getReducer],
  ({searchRoom: {page, pageSize, total}}) => ({page, pageSize, total})
)

export const isSearchingRooms = createSelector(
  [getReducer],
  ({searchRoom: {isSearching}}) => isSearching
)

export const isSaving = createSelector(
  [getReducer],
  ({isSubmiting}) => isSubmiting
)

/**
 * GENERATE REQUEST PAYLOAD
 * ========================
 */
export const generateListCustomersReq = createSelector(
  [getReducer],
  ({selectedCustomers: {page, pageSize, ids}}) => {
    const maxPage = _.ceil(_.size(ids)/pageSize)
    if (page > maxPage) {
      page = maxPage
    }

    ids = _.slice(ids, (page-1)*pageSize, page*pageSize).join(',')
    return {
      page_size: pageSize,
      ids,
    }
  }
)

export const generateListRoomsReq = createSelector(
  [getReducer],
  ({selectedRooms: {page, pageSize, ids}}) => {
    const maxPage = _.ceil(_.size(ids)/pageSize)
    if (page > maxPage) {
      page = maxPage
    }

    ids = _.slice(ids, (page-1)*pageSize, page*pageSize)
    return {
      page_size: pageSize,
      ids,
    }
  }
)

export const generateSearchCustomersReq = createSelector(
  [getReducer],
  ({searchCustomer: {page, searchText}}) => {
    return { filter: "customer", page, page_size: 5, search: searchText }
  }
)


export const generateSearchRoomsReq = createSelector(
  [getReducer],
  ({searchRoom: {page, searchText}}) => {
    return {q: searchText, page, page_size: 5}
  }
)


export const generateCreateReq = createSelector(
  [getReducer],
  ({
    data,
    name, 
    description, 
    selectedApplyTo, 
    selectedApplyType, 
    selectedCustomers, 
    selectedRooms,
    roomDiscount,
    facilityDiscount,
    cateringDiscount,
    startDate,
    endDate,
    isActive,
  }) => {
    let apply_objects = selectedApplyTo == DISCOUNT.OBJECT_TYPE.CUSTOMER ? selectedCustomers : selectedRooms
    apply_objects = _.get(apply_objects, 'ids')

    return {
      id: _.get(data, 'id'),
      name, 
      description, 
      object_type: selectedApplyTo,
      apply_type: selectedApplyType,
      apply_objects,
      discount_room: roomDiscount,
      discount_exfacility: facilityDiscount,
      discount_catering: cateringDiscount,
      start_at: startDate,
      end_at: endDate,
      is_active: isActive
    }
  }
)

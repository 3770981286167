import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {makeStyles} from '@material-ui/core/styles'
import { Container, Grid, Typography, Select , Card, CardMedia, CardContent, CardActions, Button, TextField } from '@material-ui/core'
import {Link } from 'react-router-dom'
import Image from '../../../assets/images/Raum_18.jpg'
import * as paths from '../../../constants/paths.constants'
import {history} from '../../../config/store'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {get} from 'lodash'
import { roundToUnit } from '../../../helpers/helpers'
import { CURRENCY_MAPPING } from '../../../helpers/constant'
import { formatMoney } from '../../../helpers/bookings'
import LoadingIcon from 'mdi-react/LoadingIcon'
import CheckoutForm from './CheckoutForm'
import {updateBookingState} from '../../../actions/booking'
import {bindActionCreators} from 'redux'
import {withTranslation} from 'react-i18next'

import { BOOKING } from "../../../constants/models";
const { PAYMENT_METHOD } = BOOKING

const {REACT_APP_STRIPE_TOKEN} = process.env
const stripePromise = loadStripe(REACT_APP_STRIPE_TOKEN)

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: '20px'
  },
  textAreaAutoSize: {
    width: '100%',
    borderRadius: '5px'
  },
  bookingInfo: {
    padding: '5px 0'
  },
  roomImage: {
    width: '100px'
  },
  justifyContentCenter: {
    justifyContent: 'center'
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    height: '95%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f7f7f7',
    margin: '10px 0 10px 30px'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
    borderBottom: '1px solid #ddd',
  },
  detailRoom: {
    marginTop: '20px',
    '& p': {
      marginBottom: '5px'
    }
  },
  detailRoomContentFooter: {
    justifyContent: 'space-between'
  },
  detailRoomContentFooterLabel: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#797979',
    marginLeft: '10px',
    fontSize: '16px',
  },
  detailRoomContentFooterPrice: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.colorAccent,
    marginRight: '10px',
    fontSize: '16px',
  },
  formRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50%',
    },
  },
  formField: {
    borderRadius: '15px',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '15px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '15px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    }
  },
  skipButton: {
    margin: '0 10px'
  }
}))
const Payment = props => {
  const classes = useStyles()
  const {t, bookingSession, configurationInfo, isUpdatingBookingState, updateBookingState} = props
  const selectedRoom = get(bookingSession, 'items', []).find(item => item.item_type === 1)
  return (
    <Container>
      {isUpdatingBookingState ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container className={classes.mainGrid}>
        <Grid item xs={8}>
          <Grid item xs={12}>
            <Typography variant="h6" align="left">
            {get(bookingSession, 'payment_method') == PAYMENT_METHOD.STRIPE ?
              t('BOOKING.AddCreditCardDetails'):
              t('Customer will be charged later!!')
            }
            </Typography>
          </Grid>
          <form className={classes.formRoot} noValidate autoComplete="off">
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>

          </form>
          <Grid item xs={12} className={classes.bookingInfo}>
          {get(bookingSession, 'payment_method') == PAYMENT_METHOD.STRIPE ?
            <span>{t('BOOKING.NoteYouWillBeChargedAfterUsingTheReservedMeetingRoom')}</span>:
            <span>{`Note: ${t('Customer will receive INVOICE after the end of the event')}`}</span>
          }
          </Grid>
          <div className={classes.footerContent}>
            <Button
              onClick={() => {
                updateBookingState({state: 3})
                history.goBack()
              }}
              className={classes.backButton}
            >
              {t('BOOKING.Back')}
            </Button>
            {/* <div>
              <Link to={paths.ViewAllBooking}>
                <Button variant="contained" color="default" disableElevation className={classes.skipButton}>
                  {'Skip'}
                </Button>
              </Link>
              <Link to={paths.ViewAllBooking}>
                <Button variant="contained" color="primary" disableElevation>
                  {'Complele'}
                </Button>
              </Link>

            </div> */}
          </div>
        </Grid>
        {/* <Grid item xs={1}>

        </Grid> */}
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              image={Image}
              title="Image title"
            />
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h6" component="h2" align="left">
                {get(bookingSession, 'invoice.1[0].name', '')}
              </Typography>
              <Typography variant="caption" align="left">
                {/* Located two hours south of Sydney in the Southem Highlands of New South Wales */}
              </Typography>
              <Typography component="h6" variant="caption" align="left" className={classes.detailRoom}>
                <p>{t('BOOKING.MaxParticipant')}: {get(selectedRoom, 'item.max_attendances', 0)}</p>
                <p>{t('BOOKING.RoomSize')}: {get(selectedRoom, 'item.size', 0)}</p>
                <p>{t('BOOKING.Permanent')}: {get(selectedRoom, 'item.permanent_facilities', []).map((item, index) => {
                  if(index > 0) return `, ${item.name}`
                  return item.name
                })}</p>
              </Typography>
            </CardContent>
            <CardActions className={classes.detailRoomContentFooter}>
              <span className={classes.detailRoomContentFooterLabel}>
                {t('BOOKING.GRANDTOTAL')}
              </span>
              <span className={classes.detailRoomContentFooterPrice}>
                {formatMoney(get(bookingSession, 'grand_total', 0))}
              </span>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

const mapState = ({bookingReducer, publicReducer}) => ({
  bookingSession: bookingReducer.bookingSession,
  configurationInfo: publicReducer.configurationInfo,
  isUpdatingBookingState: bookingReducer.isUpdatingBookingState,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    updateBookingState
  },
  dispatch
)

Payment.propTypes = {
  bookingSession: PropTypes.object,
  configurationInfo: PropTypes.object,
  isUpdatingBookingState: PropTypes.bool,
}

export default connect(mapState, mapDispatch) ( withTranslation('common') (Payment))
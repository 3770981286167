import * as C from '../../actionTypes/rooms/edit'

export const retrieveRoomType = (payload) => ({type: C.RETRIEVE_ROOM_TYPE, payload })
export const submitRoomType = (payload) => ({type: C.SUBMIT_ROOM_TYPE, payload })

export const retrieveRoom = (payload) => ({type: C.RETRIEVE_ROOM, payload })
export const submitRoom = (payload) => ({type: C.SUBMIT_ROOM, payload })

export const searchRoomTypes = (payload) => ({type: C.SEARCH_ROOM_TYPES, payload})
export const searchCombinableRooms = (payload) => ({type: C.SEARCH_COMBINABLE_ROOM, payload})
export const listPermanentFacilities = () => ({type: C.LIST_PERMANENT_FACILITY})

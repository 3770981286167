import React, { Component } from 'react'
import ResetPasswordComponent from '../../../components/Auth/ResetPassword'
class ResetPassword extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <div>
        <ResetPasswordComponent/>
      </div>
    )
  }
}

export default ResetPassword
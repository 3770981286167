import { all, takeLatest, put, call } from 'redux-saga/effects'
import * as actions from '../actions/caterings'
import * as actionTypes from '../actionTypes/catering.actionTypes'
import { history } from '../config/store'
import * as paths from '../constants/paths.constants'
import { message } from 'antd'

function* getListAllCaterings({api}, {data}) {
  try {
    // yield delay(2000)
    const response = yield call(api.caterings.getListAllCaterings, data)
    yield put(actions.getListAllCateringsSuccess(response))
    // history.push(paths.ListFacilities)
  } catch (e) {
    yield put(actions.getListAllCateringsFail())
  }
}

function* createNewCatering({api}, {data}) {
  try {
    // yield delay(2000)
    const response = yield call(api.caterings.createNewCatering, data)
    yield put(actions.createNewCateringSuccess(response))
    message.success('Create successful')
    history.push(paths.CateringService)
  } catch (e) {
    yield put(actions.createNewCateringFail())
  }
}

function* updateCatering({api}, {data}) {
  try {
    // yield delay(2000)
    const response = yield call(api.caterings.updateCatering, data)
    yield put(actions.updateCateringSuccess(response))
  } catch (e) {
    yield put(actions.updateCateringFail())
  }
}

function publicSaga(deps) {
  return function* saga() {
    yield all([
      takeLatest(actionTypes.GET_LIST_ALL_CATERINGS, getListAllCaterings, deps),
      takeLatest(actionTypes.CREATE_NEW_CATERING, createNewCatering, deps),
      takeLatest(actionTypes.UPDATE_CATERING, updateCatering, deps),
      // takeLatest(actionTypes.GET_CATEGORY, getCategory, deps)
    ])
  }
}

export default publicSaga
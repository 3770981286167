import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'

const getReducer = ({facilities}) => facilities.new

export const isSubmitting = createSelector(
  [getReducer],
  ({isSubmitting}) => isSubmitting
)

import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Grid, Typography, Button } from '@material-ui/core'
import { PageHeader, Input, Table } from 'antd'
import { Card, Empty, Tag } from 'antd';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { ROOM } from '../../constants/models'
import * as URL from '../../constants/paths.constants'
import parse from 'html-react-parser';

const { LIST_BY } = ROOM

const DetailRoomType = (props) => {
  let { match: { params: { id } } } = props
  const {
    t,

    name,
    description,
    publicTag,
    coverImage,
    isFetching,
    rooms,

    match,
  } = props

  const roomColumns = [
    {
      title: t('Room Name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={`${URL.ROOM_DETAIL.replace(":id", record.id)}`}>{text}</Link>,
    },
    {
      title: t('Max Participants'),
      dataIndex: 'maxParticipants',
      key: 'maxParticipants',
    },
    {
      title: t('Size (m2)'),
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: t('Lead time'),
      dataIndex: 'leadTime',
      key: 'leadTime',
    },
    {
      title: t("Is Public"),
      dataIndex: 'isPublic',
      key: 'isPublic',
      render: (publicTag) => <Tag color={publicTag.color}>{publicTag.name}</Tag>
    },
  ]

  return (
    <>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t("ROOM TYPE DETAIL")}
        subTitle={name || ""}
        extra={[
          <Link to={`${match.url.replace("detail", "edit")}`}>
            <Button
              color="primary"
              variant="contained"
              size="small"
            >
              {t("Edit")}
            </Button>
          </Link>
        ]}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        <Grid container spacing={4}>
          <Grid xs={12} sm={4} item>
            <Card
              style={{ height: "100%" }}
              loading={isFetching}
              cover={
                coverImage ? 
                <img src={coverImage} /> : 
                !isFetching && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }
            >
              <Grid container direction="column" spacing={2}>
                <Grid item><Typography variant="h5">{name}</Typography></Grid>

                <Grid item container direction="column">
                  <Grid item>
                    <Typography variant="subtitle1">{t("Description")}</Typography>
                  </Grid>
                  <Grid item><Typography variant="body1">{parse((description || "").replace(/\n/gi, "<br />"))}</Typography></Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography variant="subtitle1">{t("Is Public")}</Typography>
                  </Grid>
                  <Grid item><Tag color={publicTag.color}>{t(publicTag.name)}</Tag></Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={12} sm={8} item>
            <Card
              style={{ height: "100%" }}
              loading={isFetching}
            >
              <Table 
                columns={roomColumns}
                dataSource={rooms}
                pagination={{hideOnSinglePage:true}}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default withRouter(withTranslation("room_management")(DetailRoomType))

import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import ExfacilityList from './List'
import ExfacilityDetail from './Detail'
import ExfacilityEdit from './Edit'
import ExfacilityNew from './New'

import * as actions from '../../actions/exfacilities/list'


const View = props => {
  let match = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/new`} render={() => <ExfacilityNew />} />
        <Route path={`${match.path}/edit/:id`} render={() => <ExfacilityEdit />} />
        <Route path={`${match.path}/detail/:id`} render={() => <ExfacilityDetail />} />
        <Route path={`${match.path}`} render={() => <ExfacilityList />} />
      </Switch>
    </Container>
  )
}

class FacilityManagement extends Component {
  componentDidMount() {
    this.props.listFacilities()
  }

  render() {
    return (
      <View {...this.props} />
    )
  }
}


const mapState = (state) => ({

})

const mapDispatch = dispatch => bindActionCreators(
  {
    listFacilities: actions.listExfacilities,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(FacilityManagement))

import { combineReducers } from 'redux'
import listCaterings from './list'
import detailCatering from './detail'
import editCatering from './edit'
import newCatering from './new'


const reducers = combineReducers({
  list: listCaterings,
  detail: detailCatering,
  edit: editCatering,
  new: newCatering,
});

export default reducers

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListDiscountsComponent from './components/ListDiscounts'
class ListDiscounts extends Component {
  render() {
    return (
      <div>
        <ListDiscountsComponent />
      </div>
    )
  }
}

ListDiscounts.propTypes = {

}

export default ListDiscounts
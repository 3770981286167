const C = "BOOKING.LIST"

export const LIST_BOOKING = `${C}.LIST_BOOKING`
export const LIST_BOOKING_COMPLETED = `${C}.LIST_BOOKING_COMPLETED`
export const LIST_BOOKING_FAILED = `${C}.LIST_BOOKING_FAILED`
export const CHANGE_PAGE = `${C}.CHANGE_PAGE`
export const CHANGE_TAB = `${C}.CHANGE_TAB`

export const CHANGE_PAYMENT_STATUSES_FILTER = `${C}.CHANGE_PAYMENT_STATUSES_FILTER`
export const CHANGE_BOOKING_STATUSES_FILTER = `${C}.CHANGE_BOOKING_STATUSES_FILTER`

export const SUBMIT_FILTER = `${C}.SUBMIT_FILTER`
export const SUBMIT_FILTER_COMPLETED = `${C}.SUBMIT_FILTER_COMPLETED`
export const SUBMIT_FILTER_FAILED = `${C}.SUBMIT_FILTER_FAILED`
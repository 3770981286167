import { combineReducers } from 'redux'
import * as C from "../../actionTypes/discounts/new";
import { DISCOUNT, VALIDATION_STATUS } from "../../constants/models";
import moment from 'moment'
import * as _ from 'lodash'

const { ERROR, SUCCESS } = VALIDATION_STATUS

const name = (state = "", action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_DISCOUNT_NAME:
      return payload
    case C.SUBMIT_CREATE_COMPLETED:
      return ""
    default:
      return state;
  }
}

const description = (state = "", action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_DISCOUNT_DESCRIPTION:
      return payload
    case C.SUBMIT_CREATE_COMPLETED:
      return ""
    default:
      return state;
  }
}

const selectedApplyTo = (state = DISCOUNT.OBJECT_TYPE.CUSTOMER, action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_SELECTED_APPLY_TO:
      return payload
    case C.SUBMIT_CREATE_COMPLETED:
      return DISCOUNT.OBJECT_TYPE.CUSTOMER
    default:
      return state;
  }
}


const selectedApplyType = (state = DISCOUNT.APPLY_TYPE.ALL, action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_SELECTED_APPLY_TYPE:
      return payload
    case C.CHANGE_SELECTED_APPLY_TO:
      return DISCOUNT.APPLY_TYPE.ALL
    case C.SUBMIT_CREATE_COMPLETED:
      return DISCOUNT.APPLY_TYPE.ALL
    default:
      return state;
  }
}

const searchCustomer = combineReducers({
  page: (state = 1, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER_COMPLETED:
        return _.get(payload, "page")
      case C.CHANGE_CUSTOMER_SEARCH_PAGE:
        return payload
      default:
        return state;
    }
  },
  pageSize: (state=5, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER_COMPLETED:
        return _.get(payload, "page_size")
      default:
        return state;
    }
  },
  total: (state = 0, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER_COMPLETED:
        return _.get(payload, "count")
      default:
        return state;
    }
  },
  items: (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER_COMPLETED:
        return _.get(payload, "results")
      default:
        return state;
    }
  },
  searchText: (state = "", action) => {
    const { type, payload } = action
    switch (type) {
      case C.CHANGE_CUSTOMER_SEARCH_TEXT:
        return payload
      default:
        return state;
    }
  },
  isSearching: (state = false, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER:
      case C.CHANGE_CUSTOMER_SEARCH_PAGE:
        return true
      case C.SEARCH_CUSTOMER_COMPLETED:
      case C.SEARCH_CUSTOMER_FAILED:
        return false
      default:
        return state;
    }
  }
})

const selectedCustomers = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case C.ADD_CUSTOMER:
      return [...state, payload]
    case C.ADD_SUGGESTED_CUSTOMERS:
      return [...state, ...payload]
    case C.REMOVE_CUSTOMER:
      return _.filter(state, it => it.id !== payload.id)
    case C.SUBMIT_CREATE_COMPLETED:
      return []
    default:
      return state;
  }
}

const searchRoom = combineReducers({
  page: (state = 1, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_ROOM_COMPLETED:
        return _.get(payload, "page")
      case C.CHANGE_ROOM_SEARCH_PAGE:
        return payload
      default:
        return state;
    }
  },
  pageSize: (state=5, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_CUSTOMER_COMPLETED:
        return _.get(payload, "page_size")
      default:
        return state;
    }
  },
  total: (state = 0, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_ROOM_COMPLETED:
        return _.get(payload, "count")
      default:
        return state;
    }
  },
  items: (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_ROOM_COMPLETED:
        return _.get(payload, "results")
      default:
        return state;
    }
  },
  searchText: (state = "", action) => {
    const { type, payload } = action
    switch (type) {
      case C.CHANGE_ROOM_SEARCH_TEXT:
        return payload
      default:
        return state;
    }
  },
  isSearching: (state = false, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_ROOM:
      case C.CHANGE_ROOM_SEARCH_PAGE:
        return true
      case C.SEARCH_ROOM_COMPLETED:
      case C.SEARCH_ROOM_FAILED:
      case C.SUBMIT_CREATE_COMPLETED:
        return false
      default:
        return state;
    }
  }
})

const selectedRooms = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case C.ADD_ROOM:
      return [...state, payload]
    case C.REMOVE_ROOM:
      return _.filter(state, it => it.id !== payload.id)
    case C.SUBMIT_CREATE_COMPLETED:
      return []
    default:
      return state;
  }
}

const roomDiscount = (state = 0, action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_ROOM_DISCOUNT:
      if (payload < 0) {
        return 0
      } else if (payload > 100) {
        return 100
      } else {
        return payload
      }
    case C.SUBMIT_CREATE_COMPLETED:
      return 0
    default:
      return state;
  }
}

const facilityDiscount = (state = 0, action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_FACILITY_DISCOUNT:
      if (payload < 0) {
        return 0
      } else if (payload > 100) {
        return 100
      } else {
        return payload
      }
    case C.SUBMIT_CREATE_COMPLETED:
      return 0
    default:
      return state;
  }
}

const cateringDiscount = (state = 0, action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_CATERING_DISCOUNT:
      if (payload < 0) {
        return 0
      } else if (payload > 100) {
        return 100
      } else {
        return payload
      }
    case C.SUBMIT_CREATE_COMPLETED:
      return 0
    default:
      return state;
  }
}

const startDate = (state = moment(), action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_START_DATE:
      return payload
    case C.SUBMIT_CREATE_COMPLETED:
      return moment()
    default:
      return state;
  }
}

const endDate = (state = moment().add(1, "years"), action) => {
  const { type, payload } = action
  switch (type) {
    case C.CHANGE_END_DATE:
      return payload
    case C.SUBMIT_CREATE_COMPLETED:
      return moment().add(1, "years")
    default:
      return state;
  }
}

const isActive = (state = false, action) => {
  const { type } = action
  switch (type) {
    case C.TOGGLE_IS_ACTIVATE:
      return !state
    case C.SUBMIT_CREATE_COMPLETED:
      return false
    default:
      return state;
  }
}

const isLoadingSuggestCustomers = (state = false, action) => {
  const {type} = action
  switch (type) {
    case C.LOAD_SUGGEST_CUSTOMERS:
      return true
    case C.LOAD_SUGGEST_CUSTOMERS_COMPLETED:
    case C.LOAD_SUGGEST_CUSTOMERS_FAILED:
      return false
    default:
      return state;
  }
}

const suggestedCustomers = (state = [], action) => {
  const {type, payload} = action
  switch (type) {
    case C.LOAD_SUGGEST_CUSTOMERS_COMPLETED:
      return payload
    case C.LOAD_SUGGEST_CUSTOMERS_FAILED:
    case C.ADD_SUGGESTED_CUSTOMERS:
      return []
    default:
      return state;
  }
}

const isCreating = (state = false, action) => {
  const {type, payload} = action
  switch (type) {
    case C.SUBMIT_CREATE:
      return true
    case C.SUBMIT_CREATE_COMPLETED:
    case C.SUBMIT_CREATE_FAILED:
      return false
    default:
      return state;
  }
}

const validation = combineReducers({
  name: (state=null, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SUBMIT_CREATE:
        return state || ERROR
      case C.CHANGE_DISCOUNT_NAME:
        return payload ? SUCCESS : ERROR
      case C.SUBMIT_CREATE_COMPLETED:
          return null
      default:
        return state;
    }
  },
  description: (state=null, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SUBMIT_CREATE:
        return state || ERROR
      case C.CHANGE_DISCOUNT_DESCRIPTION:
        return payload ? SUCCESS : ERROR
      case C.SUBMIT_CREATE_COMPLETED:
        return null
      default:
        return state;
    }
  }
})

const reducers = combineReducers({
  name,
  description,
  selectedApplyTo,
  selectedApplyType,
  searchCustomer,
  selectedCustomers,
  searchRoom,
  selectedRooms,
  roomDiscount,
  facilityDiscount,
  cateringDiscount,
  startDate,
  endDate,
  isActive,
  isCreating,
  isLoadingSuggestCustomers,
  suggestedCustomers,
  validation,
})

export default reducers;

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PencilIcon from 'mdi-react/PencilIcon'
import {get, replace} from 'lodash'
import {history} from '../../../config/store'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography, TextField, Button, IconButton, Toolbar } from '@material-ui/core'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as selectos from '../../../selectors/user'
import { Form, Checkbox } from 'antd'
import {updateProfile} from '../../../actions/user'
import { withTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    backgroundColor: '#840810'
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  formContent: {
    justifyContent: 'center',
    marginBottom: '20px'
  },
  formRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
      '& .MuiInputBase-input': {
        padding: '10.5px 14px'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ddd !important',
        color: '#ddd !important'
      }
    },
  },
  formField: {
    borderRadius: '15px',
    margin: '8px 0',
    minWidth: '150px',
    width: '90%',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '15px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '15px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
    '& .Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0
      }
    }
  },
  justifyContentCenter: {
    justifyContent: 'center'
  },
  pageTitle: {
    color: 'white',
    padding: '15px 0'
  },
  searchTitle: {
    margin: 'auto 0'
  },
  searchContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {

  },
  searchButton: {
    margin: '8px'
  },
  subTitle: {
    borderBottom: '1px solid',
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between'
  },
  actionsContent: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    '& svg': {
      width: '18px',
      height: '18px',
    }
  },
  container: {
    maxWidth: '75%',
  },
  buttonGroup: {
    marginBottom: '50px'
  },
  confirmChangeButton: {
    marginRight: '30px'
  },
  formLabel: {
    alignItems: 'center',
    display: 'flex',
  },
  changeInfoButtonGroup: {
    textAlign: 'center'
  }
}))

const AccountComponent = props => {
  const classes = useStyles()
  const [editableInfo, setEditableInfo] = useState(false)
  const { myProfile, updateProfile, t } = props
  const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form

  useEffect(() => {
    // fetched user info
    if (get(myProfile , 'id')) {
      setFieldsValue({...myProfile })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfile])


  const handleSubmit = async(e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        console.log(values)
        updateProfile(values)
      }
    })
    setEditableInfo(false)
  }

  return (
    <Toolbar component='nav' variant='dense' className={classes.root}>
      <Grid container className={classes.justifyContentCenter}>
        <Grid item xs={12} >
          <Typography variant='h6' align="left" className={classes.subTitle} component="div" >
            <span>{t('USERS.GeneralInfo')}</span>
            <div className={classes.actionsContent} onClick={() => setEditableInfo(true) }>
              <PencilIcon />&nbsp;&nbsp;<span>{t('USERS.Edit')}</span>
            </div>
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <Form onSubmit={handleSubmit} className={classes.formRoot}>
          <Grid container>
            <Grid item sm={5} xs={12} md={5} className={classes.formContent}>
              <Grid container>
                <Grid item xs={3} className={classes.formLabel}>
                  <Typography variant="body2" align="left" component="div">
                    {t('USERS.Surname')}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.formValue}>
                  <Form.Item>
                    {getFieldDecorator('first_name', {
                      rules: [
                        {
                          required: true,
                          message: `${t('USERS.Surname')} ${t('USERS.IsRequired')}`,
                        },
                      ],
                    })(
                    <TextField
                      className={classes.formField}
                      variant="outlined"
                      disabled={!editableInfo}
                    />
                  )}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={5} xs={12} md={5} className={classes.formContent}>
              <Grid container>
                <Grid item xs={3} className={classes.formLabel}>
                  <Typography variant="body2" align="left" component="div">
                    {t('USERS.GivenName')}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.formValue}>
                  <Form.Item>
                    {getFieldDecorator('last_name', {
                      rules: [
                        {
                          required: true,
                          message: `${t('USERS.GivenName')} ${t('USERS.IsRequired')}`,
                        },
                      ],
                    })(
                    <TextField
                      className={classes.formField}
                      variant="outlined"
                      disabled={!editableInfo}
                    />
                  )}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={5} xs={12} md={5} className={classes.formContent}>
              <Grid container>
                <Grid item xs={3} className={classes.formLabel}>
                  <Typography variant="body2" align="left" component="div">
                    {t('USERS.StaffID')}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.formValue}>
                  <Form.Item>
                    {getFieldDecorator('customer_nbr', {})(
                    <TextField
                      className={classes.formField}
                      variant="outlined"
                      disabled={!editableInfo}
                    />
                  )}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={5} xs={12} md={5} className={classes.formContent}>
              <Grid container>
                <Grid item xs={3} className={classes.formLabel}>
                  <Typography variant="body2" align="left" component="div">
                    {t('USERS.Email')}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.formValue}>
                  <Form.Item>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          required: true,
                          message: `${t('USERS.Email')} ${t('USERS.IsRequired')}`,
                        },
                      ],
                    })(
                    <TextField
                      className={classes.formField}
                      variant="outlined"
                      disabled={true}
                    />
                  )}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={5} xs={12} md={5} className={classes.formContent}>
              <Grid container>
                <Grid item xs={3} className={classes.formLabel}>
                  <Typography variant="body2" align="left" component="div">
                    {t('USERS.Street')}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.formValue}>
                  <Form.Item>
                    {getFieldDecorator('street', {
                      rules: [
                        {
                          required: true,
                          message: `${t('USERS.Street')} ${t('USERS.IsRequired')}`,
                        },
                      ],
                    })(
                    <TextField
                      className={classes.formField}
                      variant="outlined"
                      disabled={!editableInfo}
                    />
                  )}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={5} xs={12} md={5} className={classes.formContent}>
              <Grid container>
                <Grid item xs={3} className={classes.formLabel}>
                  <Typography variant="body2" align="left" component="div">
                  {t('USERS.HouseNumber')}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.formValue}>
                  <Form.Item>
                    {getFieldDecorator('house_nbr', {
                      rules: [
                        {
                          required: true,
                          message: `${t('USERS.HouseNumber')} ${t('USERS.IsRequired')}`,
                        },
                      ],
                    })(
                    <TextField
                      className={classes.formField}
                      variant="outlined"
                      disabled={!editableInfo}
                    />
                  )}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={5} xs={12} md={5} className={classes.formContent}>
              <Grid container>
                <Grid item xs={3} className={classes.formLabel}>
                  <Typography variant="body2" align="left" component="div">
                    {t('USERS.City')}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.formValue}>
                  <Form.Item>
                    {getFieldDecorator('city', {
                      rules: [
                        {
                          required: true,
                          message: `${t('USERS.City')} ${t('USERS.IsRequired')}`,
                        },
                      ],
                    })(
                    <TextField
                      className={classes.formField}
                      variant="outlined"
                      disabled={!editableInfo}
                    />
                  )}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={5} xs={12} md={5} className={classes.formContent}>
              <Grid container>
                <Grid item xs={3} className={classes.formLabel}>
                  <Typography variant="body2" align="left" component="div">
                    {t('USERS.Country')}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.formValue}>
                  <Form.Item>
                    {getFieldDecorator('country', {
                      rules: [
                        {
                          required: true,
                          message: `${t('USERS.Country')} ${t('USERS.IsRequired')}`,
                        },
                      ],
                    })(
                    <TextField
                      className={classes.formField}
                      variant="outlined"
                      disabled={!editableInfo}
                    />
                  )}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={5} xs={12} md={5} className={classes.formContent}>
              <Grid container>
                <Grid item xs={3} className={classes.formLabel}>
                  <Typography variant="body2" align="left" component="div">
                    {t('USERS.PostalZipcode')}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.formValue}>
                  <Form.Item>
                    {getFieldDecorator('post_code', {
                      rules: [
                        {
                          required: true,
                          message: `${t('USERS.PostalZipcode')} ${t('USERS.IsRequired')}`,
                        },
                      ],
                    })(
                    <TextField
                      className={classes.formField}
                      variant="outlined"
                      disabled={!editableInfo}
                    />
                  )}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
            {editableInfo && 
            <Grid item sm={10} xs={12} md={10} className={classes.changeInfoButtonGroup}>
              <Button color="primary" variant="contained" 
                className={classes.confirmChangeButton} type="submit">
                {t('USERS.SaveChanges')}
              </Button>
              <Button color="" variant="contained" className={classes.cancelButton}
                onClick={() => setEditableInfo(false)}
              >
                {t('USERS.Cancel')}
              </Button>
            </Grid>}
          </Grid>
          </Form>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

const mapState = ({ userReducer, publicReducer }) => ({
  sortOfUser: selectos.getSortOfUsers({userReducer}),
  myProfile: publicReducer.profile,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    updateProfile,
  },
  dispatch
)

AccountComponent.propTypes = {
  className: PropTypes.string,
  myProfile: PropTypes.object,
}

AccountComponent.defaultProps = {
}

export default  Form.create({
  name: 'userProfile'
})(withRouter(connect(mapState, mapDispatch)(withTranslation('common')(AccountComponent))))

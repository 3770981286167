import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CateringServiceComponent from './components/CateringService'
import CateringManagement from '../../containers/caterings/Management'
class CateringService extends Component {
  render() {
    return (
      <div>
        <CateringManagement />
      </div>
    )
  }
}

CateringService.propTypes = {

}

export default CateringService
import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as C from '../../actionTypes/discounts/new'
import * as selector from '../../selectors/discounts/new'
import { history } from '../../config/store'
import * as _ from 'lodash'
import { message } from 'antd'


function* searchCustomers({api}) {
  const reqPayload = yield select(selector.generateSearchCustomersReq)

  try {
    const response = yield call(api.user.searchListCustomer, reqPayload)
    yield put({type: C.SEARCH_CUSTOMER_COMPLETED, payload: response })
  } catch (e) {
    yield put({type: C.SEARCH_CUSTOMER_FAILED, payload: e})
    console.error(e)
  }
}

function* changeSearchText({api}, {payload}) {
  if (payload == "") {
    // Only on reset the text
    yield call(searchCustomers, {api})
  }
}

function* searchRooms({api}) {
  const reqPayload = yield select(selector.generateSearchRoomsReq)
  try {
    const response = yield call(api.room.searchRooms, reqPayload)
    yield put({type: C.SEARCH_ROOM_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.SEARCH_ROOM_FAILED, payload: e})
    console.error(e)
  }
}

function* changeRoomSearchText({api}, {payload}) {
  if (payload == "") {
    // Only on reset the text
    yield call(searchRooms, {api})
  }
}

function* loadSuggestCustomers({api}) {
  const reqPayload = yield select(selector.generateSuggestCustomersReq)
  if (!reqPayload) {
    yield put({type: C.LOAD_SUGGEST_CUSTOMERS_COMPLETED, payload: []})
  } else {
    try {
      const response = yield call(api.user.searchListCustomer, reqPayload)
      const selectedCustomers = yield select(selector.getSelectedCustomers)
      const excludeIds = _.map(selectedCustomers, it => it.id)
      const payload = _.filter(_.get(response, 'results'), it => !_.includes(excludeIds, it.id))
      yield put({type: C.LOAD_SUGGEST_CUSTOMERS_COMPLETED, payload})
    } catch (e) {
      yield put({type: C.LOAD_SUGGEST_CUSTOMERS_FAILED, payload: e})
      console.error(e)
    }
  }
}

function* submitCreate({api}) {
  const reqPayload = yield select(selector.generateCreateReq)
  try {
    const response = yield call(api.discount.createNewDiscount, reqPayload)
    yield put({type: C.SUBMIT_CREATE_COMPLETED, payload: response})
    history.goBack()
  } catch (e) {
    yield put({type: C.SUBMIT_CREATE_FAILED, payload: e})
    message.error("Cannot create. Please check for required fields!!")
    console.error(e)
  }
}

function newSaga(deps) {
  return [
      takeLeading(C.SEARCH_CUSTOMER, searchCustomers, deps),
      takeLeading(C.CHANGE_CUSTOMER_SEARCH_PAGE, searchCustomers, deps),
      takeLatest(C.CHANGE_CUSTOMER_SEARCH_TEXT, changeSearchText, deps),

      takeLeading(C.SEARCH_ROOM, searchRooms, deps),
      takeLeading(C.CHANGE_ROOM_SEARCH_PAGE, searchRooms, deps),
      takeLatest(C.CHANGE_ROOM_SEARCH_TEXT, changeRoomSearchText, deps),
      takeLeading(C.LOAD_SUGGEST_CUSTOMERS, loadSuggestCustomers, deps),

      takeLeading(C.SUBMIT_CREATE, submitCreate, deps),
  ]
}

export default newSaga

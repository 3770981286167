import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NewFacilityComponent from './components/NewFacility'
class NewFacility extends Component {
  render() {
    return (
      <div>
        <NewFacilityComponent />
      </div>
    )
  }
}

NewFacility.propTypes = {

}

export default NewFacility
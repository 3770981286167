import {
  ACTIVATE_USER,
  ACTIVATE_USER_FAIL,
  ACTIVATE_USER_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_NOT_REDIRECT,
  LOGOUT,
  REGISTER,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESEND_ACTIVATION_CODE,
  RESEND_ACTIVATION_CODE_FAIL,
  RESEND_ACTIVATION_CODE_SUCCESS,
  CHECK_TOKEN_EXPIRATION
} from '../actionTypes/auth.actionTypes'


// data: {email, password}
export function login(data) {
  return ({type: LOGIN, data})
}

// data: {email, password}
export function loginNotRedirect(data) {
  return ({type: LOGIN_NOT_REDIRECT, data})
}

export function loginSuccess(data) {
  return ({type: LOGIN_SUCCESS, data})
}
export function loginFail(error) {
  return ({type: LOGIN_FAIL, error})
}

export function register(data) {
  return ({type: REGISTER, data})
}
export function registerSuccess() {
  return ({type: REGISTER_SUCCESS})
}
export function registerFail(error) {
  return ({type: REGISTER_FAIL, error})
}

export function activateUser(data) {
  return ({type: ACTIVATE_USER, data})
}
export function activateUserSuccess(data) {
  return ({type: ACTIVATE_USER_SUCCESS, data})
}
export function activateUserFail(error) {
  return ({type: ACTIVATE_USER_FAIL, error})
}

export function resendActivationCode(data) {
  return ({type: RESEND_ACTIVATION_CODE, data})
}
export function resendActivationCodeSuccess() {
  return ({type: RESEND_ACTIVATION_CODE_SUCCESS})
}
export function resendActivationCodeFail(error) {
  return ({type: RESEND_ACTIVATION_CODE_FAIL, error})
}

export function forgotPassword(data) {
  return ({type: FORGOT_PASSWORD, data})
}
export function forgotPasswordSuccess() {
  return ({type: FORGOT_PASSWORD_SUCCESS})
}
export function forgotPasswordFail(error) {
  return ({type: FORGOT_PASSWORD_FAIL, error})
}

export function logout() {
  return ({type: LOGOUT})
}

export function checkTokenExpiration() {
  return ({type: CHECK_TOKEN_EXPIRATION})
}
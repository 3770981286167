import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import UserList from './List'
import UserDetail from './Detail'
import UserNew from './New'
import UserEdit from './Edit'

import * as actions from '../../actions/users/list'


const View = props => {
  let match = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/new/:detailType`}>
          <UserNew />
        </Route>

        <Route path={`${match.path}/detail/:detailType/:id`} render={() => <UserDetail />} />
        <Route path={`${match.path}/edit/:detailType/:id`} render={() => <UserEdit />} />
        <Route path={`${match.path}`} render={() => <UserList />} />
      </Switch>
    </Container>
  )
}

class UserManagement extends Component {
  componentDidMount() {
    this.props.listUsers()
  }

  render() {
    return (
      <View {...this.props} />
    )
  }
}


const mapState = (state) => ({

})

const mapDispatch = dispatch => bindActionCreators(
  {
    listUsers: actions.listUsers,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(UserManagement))

import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Input } from 'antd'
import { Table, Tag } from 'antd';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'


const ExFacilityList = (props) => {
  let match = useRouteMatch();
  const {
    t,

    items,
    isFetching,

    selectedTab,
    selectableTabs = [],
    onChangeTab,
  } = props

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      render: (name, record) => <Link to={`${match.path}/detail/${record.id}`}>{t(name)}</Link>,
    },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity"
    },
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description"
    },
    {
      title: t("Is Active"),
      dataIndex: "activeTag",
      key: "activeTag",
      render: (tag) => <Tag color={tag.color}>{t(tag.name)}</Tag>
    },
  ]

  return (
    <>
      <PageHeader
        backIcon={false}
        title={t("FACILITY MANAGEMENT")}
        subTitle={t("Manage all extra facilities")}
        extra={[
          <Link to={`${match.path}/new/`}>
            <Button
              color="primary"
              variant="contained"
              size="small"
            >
              {t("NEW EXTRA FACILITY")}
            </Button>
          </Link>,
        ]}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(e, value) => onChangeTab(value)}
        >
          {_.map(selectableTabs, tab => (
            <Tab label={t(tab.name)} value={tab.value} />
          ))}
        </Tabs>

        <Table
          columns={columns}
          dataSource={items}
          pagination={{ hideOnSinglePage: true }}
          loading={isFetching}
        />
      </div>
    </>
  )
}

export default withRouter(withTranslation("exfacility_management")(ExFacilityList))

import axios from 'axios'
import * as storage from '../services/storage'
import { LOCAL_STORAGE_KEY } from '../helpers/constant'
import {get} from 'lodash'
const BASE_URL =  process.env.REACT_APP_BASE_URL

export default class HttpClient {
  constructor() {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    instance.interceptors.request.use(
      (config) => {
        const authorization = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization)
        const session = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.session)
        if(session) {
          config.headers.Session = session
        }
        if (authorization) {
          config.headers.Authorization = `JWT ${authorization.access}`
        }
        return config
      },
      (error) => { 
        throw new Error(error) 
      },
    )
    instance.interceptors.response.use(
      (res) => {
        if(get(res, 'data.meta.session')) {
          storage.setLocalStorageItem(LOCAL_STORAGE_KEY.session, get(res, 'data.meta.session'))
        }
        return res.data
      },
      (error) => {
        if (error.response) {
          throw error.response
        }
      },
    )
    this.request = instance
  }

  get(url, params, options) {
    return this.request.get(url, {params, ...options})
  }

  post(url, data, options) {
    return this.request.post(url, data, options)
  }

  put(url, data, params, options) {
    return this.request.put(url, data, {params, ...options})
  }

  patch(url, data, params, options) {
    return this.request.patch(url, data, {params, ...options})
  }

  del(url, data, params, options) {
    return this.request.delete(url, {data, params, ...options})
  }

  getSource(url) {
    const config = {
      baseURL: window.location.origin,
      withCredentials: true,
    }
    return axios.get(url, config)
  }
}

import { takeLatest, put, call, all, select } from 'redux-saga/effects'

import listSaga from './list'
import detailSaga from './detail'
import newSaga from './new'
import editSaga from './edit'

function userSaga(deps) {
  return function* saga() {
    yield all([
      ...listSaga(deps),
      ...detailSaga(deps),
      ...newSaga(deps),
      ...editSaga(deps),
    ])
  }
}

export default userSaga

import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'

const getReducer = ({rooms}) => rooms.new

export const room = {
  isSubmitting: createSelector(
    [getReducer],
    ({room: {isSubmitting}}) => isSubmitting
  ),

  selectableRoomTypes: createSelector(
    [getReducer],
    ({room: {selectableRoomTypes}}) => _.map(selectableRoomTypes, it => ({
      id: it.id,
      name: _.get(it, 'room_type'),
    }))
  ),

  selectableCombinedRooms: createSelector(
    [getReducer],
    ({room: {selectableCombinedRooms}}) => _.map(selectableCombinedRooms, it => ({
      id: it.id,
      name: it.name,
    }))
  ),

  selectablePermanentFacilities: createSelector(
    [getReducer],
    ({room: {selectablePermanentFacilities}}) => _.map(selectablePermanentFacilities, it => ({
      id: it.id,
      name: it.name
    }))
  )
}

export const roomType = {
  isSubmitting: createSelector(
    [getReducer],
    ({roomType: {isSubmitting}}) => isSubmitting
  )
}

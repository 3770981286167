import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { message } from 'antd'
import {s2c} from '../helpers/helpers'
import {history} from '../config/store'
import * as paths from '../constants/paths.constants'
import * as actionTypes from '../actionTypes/user.actionTypes'
import * as actions from '../actions/user'
import * as bookingActions from '../actions/booking'

function* searchListCustomer({api}, {data}) {
  try {
    const listUser = yield call(api.user.searchListCustomer, {search: data})
    if(listUser) {
      yield put(actions.searchListCustomerSuccess(listUser))
    }
  } catch (e) {
    yield put(actions.searchListCustomerFail())
  }
}

function* createCustomerInBooking({api}, {data}) {
  try {
    const user = yield call(api.user.createUser, data)
    if(user) {
      message.success('Create customer info successfully')
      yield put(actions.createCustomerInBookingSuccess(s2c(user)))
      yield put(bookingActions.attachSessionCustomer({customerId: user['id']}))
      // history.push(paths.CreateNewBooking_AddBookingDetail)
    }
  } catch (e) {
    message.error('This Email has been used')
    yield put(actions.createCustomerInBookingFail())
  }
}

function* createUser({api}, {data}) {
  try {
    const user = yield call(api.user.createUser, data)
    if(user) {
      message.success('Create user info successfully')
      yield put(actions.createUserSuccess(s2c(user)))
      history.push(paths.ListUser)
    }
  } catch (e) {
    message.error('This Email has been used')
    yield put(actions.createUserFail())
  }
}

function* listUsers({api}, {data}) {
  try {
    const resp = yield call(api.user.listUsers, data)
    if(resp) {
      yield put(actions.listUsersSuccess(resp))
    }
  } catch (e) {
    yield put(actions.listUsersFail(e))
  }
}

function* getUserInfo({api}, {data}) {
  try {
    const user = yield call(api.user.getUserInfo, data)
    if(user) {
      yield put(actions.getUserInfoSuccess(user))
    }
  } catch (e) {
    yield put(actions.getUserInfoFail(e))
  }
}

function* updateUser({api}, {data}) {
  try {
    const {id} = data
    const user = yield call(api.user.updateUser, id, data)
    if(user) {
      message.success('Update user info successfully')
      yield put(actions.updateUserSuccess(user))
    }
  } catch (e) {
    message.error('Cannot update user info')
    yield put(actions.updateUserFail(e))
  }
}

function* getUserBookingAnalytics({api}, {data}) {
  try {
    const user = yield call(api.analytics.userBooking, data)
    if(user) {
      yield put(actions.getUserBookingAnalyticsSuccess(user))
    }
  } catch (e) {
    yield put(actions.getUserBookingAnalyticsFail(e))
  }
}

function* updateProfile({api}, {data}) {
  try {
    const user = yield call(api.user.updateProfile, data)
    if(user) {
      message.success('Update profile successfully')
      yield put(actions.updateProfileSuccess(user))
    }
  } catch (e) {
    message.error('Cannot update profile info')
    yield put(actions.updateProfileFail(e))
  }
}

function* requestResetPassword({api}, {data}) {
  try {
    const res = yield call(api.user.requestResetPassword, data)
    message.success('Request reset password successfully, please check your email')
    // yield put(actions.requestResetPasswordSuccess())
  } catch (e) {
    message.error('Cannot request reset password')
    // yield put(actions.requestResetPasswordFail())
  }
}

function* changePassword({api}, {data}) {
  try {
    const res = yield call(api.user.changePassword, data)
    message.success('Changed password successfully')
    yield put(actions.changePasswordSuccess())
  } catch (e) {
    message.error('Current password is invalid or password too short')
    yield put(actions.changePasswordFail())
  }
}

function* resetPasswordConfirm({api}, {data}) {
  try {
    const res = yield call(api.user.resetPasswordConfirm, data)
    message.success('Changed password successfully')
    yield put(actions.resetPasswordConfirmSuccess())
  } catch (e) {
    message.error('Your new password is insecure!')
    yield put(actions.resetPasswordConfirmFail())
  }
}

function userSaga(deps) {
  return function* saga() {
    yield all([
      takeLatest(actionTypes.SEARCH_LIST_CUSTOMER, searchListCustomer, deps),
      takeLatest(actionTypes.GET_USER_INFO, getUserInfo, deps),
      takeLatest(actionTypes.GET_USER_ANALYTICS, getUserBookingAnalytics, deps),
      takeLatest(actionTypes.LIST_USERS, listUsers, deps),
      takeLatest(actionTypes.CREATE_USER, createUser, deps),
      takeLatest(actionTypes.UPDATE_USER, updateUser, deps),
      takeLatest(actionTypes.CREATE_USER_IN_BOOKING, createCustomerInBooking, deps),
      takeLatest(actionTypes.UPDATE_PROFILE, updateProfile, deps),
      takeLatest(actionTypes.REQUEST_RESET_PASSWORD, requestResetPassword, deps),
      takeLatest(actionTypes.CHANGE_PASSWORD, changePassword, deps),
      takeLatest(actionTypes.RESET_PASSWORD, resetPasswordConfirm, deps),
    ])
  }
}

export default userSaga
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, Card, CardMedia, CardContent,
  CardActions, TextField, Button, AppBar, Tabs, Tab, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {get, camelCase} from 'lodash'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import {getImage} from '../../../helpers/helpers'
import {CURRENCY_MAPPING, BOOKING_STATUS} from '../../../helpers/constant'
import {timeslotsToBinary, pathWithId} from '../../../helpers/helpers'
import { formatMoney } from '../../../helpers/bookings'
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles'
import Image from '../../../assets/images/Kopenhagen_1-1.jpg'
import { history } from '../../../config/store'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { ArrowDropDown, RemoveRedEyeOutlined } from '@material-ui/icons'
import PerformanceChart from './PerformanceOverTime'
import EarningsSegmentation from './EarningsSegmentation'
import MaterialTable from '../../../components/Table/MaterialTable'
import {
  getRoom,
  getRoomBookingAnalytics,
  getRoomAvailAnalytics,
  clearRoomInfo,
} from '../../../actions/room'
import * as selectors from '../../../selectors/room'
import LoadingIcon from 'mdi-react/LoadingIcon'
import {getListIncomingBookings, getListHistoryBookings} from '../../../actions/booking'
import { withTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  rootTitleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `2px solid ${theme.colorAccent}`,
    paddingBottom: '10px',
    marginBottom: '20px',
    marginTop: '30px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorAccent,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f7f7f7'
  },
  cardMedia: {
    paddingTop: '80%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
    borderBottom: '1px solid #ddd',
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold'
  },
  footerPrice: {
    color: theme.colorAccent,
    fontWeight: 'bold'
  },
  roomTypeInfoContent: {
    paddingRight: '20px'
  },
  topBookedContent: {
    paddingLeft: '20px',
    border: '1px solid #ddd',
    padding: '10px',
    borderRadius: '5px'
  },
  bookedTable: {
    paddingLeft: '20px',
    border: '1px solid #ddd',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '40px'
  },
  doughnutChart: {
    paddingLeft: '20px',
    border: '1px solid #ddd',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '40px'
  },
  topBookedAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chooseDateContent: {
    display: 'flex',
    alignItems: 'center',
  },
  timeSelected: {
    color: theme.colorAccent,
  },
  roomCard: {
    // height: '100%',
    display: 'flex',
    // flexDirection: 'column',
    backgroundColor: '#f7f7f7',
    margin: '10px'
  },
  facilitiesContent: {
    display: 'flex',
    alignItems: 'center'
  },
  showMoreGrid: {
    padding: '20px'
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  editRoomTypeButton: {
    color: theme.colorAccent,
    marginLeft: '10px'
  },
  tabsContent: {
    width: '50%',
    boxShadow: 'unset',
    backgroundColor: '#fff',
    borderBottom: '1px solid #ddd',
    zIndex: 'unset'
  },
  totalBookingTitle: {
    fontSize: '18px'
  },
  timeAvailability: {
    fontSize: '18px'
  }
}))

const RoomInfo = (props) => {
  const classes = useStyles()

  const { roomInfo, t } = props

  const columns = [
    { title: t('ROOMS.BlockHours'), field: 'hour' },
    { title: t('ROOMS.Price'), field: 'price' },
  ]

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={get(roomInfo, 'mainImageUrl')}
        title="Image title"
      />
      <CardContent className={classes.cardContent}>
        {/* <Typography gutterBottom variant="h6" component="h2" align="left">
          {roomInfo.title}
        </Typography> */}
        <Typography variant="subtitle1" align="left">
          {get(roomInfo, 'description', '')}
        </Typography>
        <Typography variant="subtitle1" align="left" className={classes.roomExtraServiceContent}>
          <p>- {t('ROOMS.MaxPeoples')}: {get(roomInfo, 'capacity', '')}</p>
          <p>- {t('ROOMS.Facilities')}: {get(roomInfo, 'facilities', '')}</p>
          <p>- {t('ROOMS.Orthers')}:</p>
        </Typography>
        <Grid item xs={6} md={6} className={classes.padding15px}>
          <Typography variant="subtitle1" align="left">- {t('ROOMS.PriceList')}</Typography>
          <TableContainer >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((item, index) => {
                    return (
                      <TableCell key={index}>{item.title}</TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {get(roomInfo, 'pricings', []).map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((item, idx) => {
                      return (
                        <TableCell key={idx} align="left">{row[item.field]}</TableCell>
                      )
                    })}

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </Card>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const ViewRoomDetail = props => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
    props.history.push(newValue)
  }

  const {
    // actions
    getRoom,
    getRoomAvailAnalytics,
    getRoomBookingAnalytics,
    clearRoomInfo,
    match,

    // data
    roomInfo,
    roomBookingAnalytics,
    availAnalytics,
    isFetching,

    // booking list
    getListIncomingBookings,
    getListHistoryBookings,

    isGettingListIncomingBookings,
    listIncomingBookings,
    listIncomingBookingsMeta,

    configurationInfo,
    t,
    i18n
  } = props

  moment.locale(i18n.language === 'en' ? 'en-gb' : 'de')

  const isViewingRoomDetail = match.path == paths.RoomDetail
  const room_id = match.params.id
  const [tab, setTab] = useState('upcoming');
  const isViewUpcoming = tab == 'upcoming'  
  const thisYear = moment().year()
  useEffect(() => {
    // first load
    clearRoomInfo()
    if (isViewingRoomDetail) {
      getRoom(room_id)
      getRoomAvailAnalytics({room_id, year: thisYear})
      getRoomBookingAnalytics({room_id, year: thisYear})
    }

    if(isViewUpcoming) {
      getListIncomingBookings({
        page_size: 5,
        page: 1,
        room_id,
      })
    } else {
      getListHistoryBookings({
        page_size: 5,
        page: 1, 
        room_id
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // booking 
  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID'},
    { key: 'room_name', numeric: true, disablePadding: false, label: 'Room' },
    { key: 'customer', numeric: true, disablePadding: false, label: 'Customer',
      render: (customer) => `${customer.first_name} ${customer.last_name}`
    },
    { key: 'paid_total', numeric: true, disablePadding: false, label: 'Paid',
      render: (paid_total) => formatMoney(paid_total/configurationInfo.unit)
    },
    { key: 'start_date', numeric: true, disablePadding: false, label: 'Start At',
      render: (start_date, row) => {
        const binary = timeslotsToBinary(get(row, 'start_slot_int', 0))
        const startTime = binary.indexOf('1') + 8
        return `${ moment(start_date).format('YYYY-MM-DD')} -- ${startTime}:00`
      }
    },
    { key: 'end_date', numeric: true, disablePadding: false, label: 'End At',
      render: (end_date, row) => {
        const binary = timeslotsToBinary(get(row, 'end_slot_int', 0))
        const endTime = binary.lastIndexOf('1') + 9
        return `${moment(end_date).format('YYYY-MM-DD')} -- ${endTime}:00`
      }
    },
    { key: 'status', numeric: true, disablePadding: false, label: 'Status',
      render: (status) => `${BOOKING_STATUS[status]}`
    },
    { key: 'action', numeric: true, disablePadding: false, label: 'Action',
      render: (_, row) => {
        return (
          <>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => history.push(pathWithId(paths.BookingDetail, row.id))}
            // onMouseDown={handleMouseDownPassword}
            >
              <RemoveRedEyeOutlined />
            </IconButton>
          </>
        )
      }
    },
  ]

  useEffect(() => {
    if(isViewUpcoming) {
      getListIncomingBookings({
        page_size: 5,
        page: 1,
        room_id,
      })
    } else {
      getListHistoryBookings({
        page_size: 5,
        page: 1, 
        room_id
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewUpcoming])

  const handleChangePage = page => {
    if(!isViewUpcoming) {
      getListHistoryBookings({
        page_size: get(listIncomingBookingsMeta, 'page_size', 5),
        page: page,
        room_id
      })
    } else {
      getListIncomingBookings({
        page_size: get(listIncomingBookingsMeta, 'page_size', 5),
        page: page,
        room_id
      })
    }
  }

  const handleChangePageSize = size => {
    if(!isViewUpcoming) {
      getListHistoryBookings({
        page_size: size,
        page: 1,
        room_id,
      })
    } else {
      getListIncomingBookings({
        page_size: size,
        page: 1,
        room_id,
      })
    }
  }

  const period = "This Year"

  return (
    <Container>
      {isFetching ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container >
        <Grid item xs={12} md={12} >
          <Typography variant='h5' component="div" className={classes.rootTitleContent}  >
            <Link to={paths.ListRooms} className={classes.title}>
              <ChevronLeftIcon /> {get(roomInfo, 'name')}
            </Link>
            <div>
              <Link to={pathWithId(paths.UpdateRoom, get(roomInfo, 'id'))}>
                <Button color="default" variant="contained" disableElevation
                  className={classes.editRoomTypeButton}
                >{t('ROOMS.EditRoom')}</Button>
              </Link>
            </div>
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container >
            <Grid item md={5} xs={12} className={classes.roomTypeInfoContent}>
              <RoomInfo
                roomInfo={roomInfo}
                t={t}
              />
            </Grid>
            <Grid item md={7} xs={12} >
              <Grid container >
                <Grid item md={12} className={classes.topBookedContent}>
                  <Typography component="div" className={classes.topBookedAction}>
                    <span className={classes.totalBookingTitle}>{t('ROOMS.TotalBookings')}</span>
                    <div className={classes.chooseDateContent}>
                      <span>{t('ROOMS.Period')}: </span> 
                      <span className={classes.timeSelected}>{period} </span>
                    </div>
                  </Typography>
                  <PerformanceChart data={roomBookingAnalytics} period={camelCase(period)}/>
                </Grid>
                <Grid item md={12} className={classes.doughnutChart}>
                  <Typography component="div" className={classes.topBookedAction}>
                    <span className={classes.timeAvailability}>{t('ROOMS.TimeAvailability')}</span>
                    <div className={classes.chooseDateContent}>
                      <span>{t('ROOMS.Period')}: </span> 
                      <span className={classes.timeSelected}> {period} </span>
                    </div>
                  </Typography>
                  <EarningsSegmentation data={availAnalytics}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.bookedTable} >
        <Grid container>
          <Grid item xs={12}>
            <Typography component="div" className={classes.topBookedAction}>
              {/* <div> */}
              <AppBar position="static" color="default" className={classes.tabsContent}>
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label={t("ROOMS.UpcomingBooking")} {...a11yProps(0)} onClick={() => setTab('upcoming')}/>
                  <Tab label={t("ROOMS.History")} {...a11yProps(1)} onClick={() => setTab('history')}/>
                </Tabs>
              </AppBar>
              {/* </div> */}
              <div className={classes.chooseDateContent}><span>Show:</span> <span className={classes.timeSelected}>{period}</span></div>
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.facilitiesContent}>
            <MaterialTable
              title='Room Booking'
              columns={headCells}
              rows={listIncomingBookings}
              pageSize={get(listIncomingBookingsMeta, 'page_size')}
              page={get(listIncomingBookingsMeta, 'page')}
              total={get(listIncomingBookingsMeta, 'count')}
              onChangePage={(page) => handleChangePage(page)}
              onChangePageSize={(size) => handleChangePageSize(size)}
            />
          </Grid>

        </Grid>
      </Grid>
      {/* </Grid> */}
    </Container>
  )
}

const mapState = (state) => ({
  isFetching: selectors.isFetchingRoomDetail(state),
  availAnalytics: selectors.roomAvailabilityAnalytics(state),
  roomInfo: selectors.roomInfo(state),
  roomBookingAnalytics: selectors.roomBookingAnalytics(state),

  // booking list
  isGettingListIncomingBookings: state.bookingReducer.isGettingListIncomingBookings,
  listIncomingBookings: state.bookingReducer.listIncomingBookings,
  listIncomingBookingsMeta: state.bookingReducer.listIncomingBookingsMeta,

  configurationInfo: state.publicReducer.configurationInfo,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getRoom,
    getRoomBookingAnalytics,
    getRoomAvailAnalytics,
    clearRoomInfo,
    
    // booking list
    getListIncomingBookings,
    getListHistoryBookings,
  },
  dispatch
)

ViewRoomDetail.propTypes = {
  className: PropTypes.string,
  isGettingListIncomingBookings: PropTypes.bool,
  listIncomingBookings: PropTypes.array,
  listIncomingBookingsMeta: PropTypes.object,
  listRooms: PropTypes.func,
  getListIncomingBookings: PropTypes.func,
  getListHistoryBookings: PropTypes.func,
}

ViewRoomDetail.defaultProps = {
  orders: []
}

export default withRouter(connect(mapState, mapDispatch)(withTranslation('common')(ViewRoomDetail)))

import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as C from '../../actionTypes/users/detail'
import { history } from '../../config/store'
import { message } from 'antd';
import { USER, BOOKING } from '../../constants/models'
import * as selectors from '../../selectors/users/detail'
import * as _ from 'lodash'

const { LIST_BY } = BOOKING

function* retrieveUser({api}, {payload}) {
  try {
    const response = yield call(api.user.getUserInfo, payload)
    yield put({type: C.RETRIEVE_USER_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.RETRIEVE_USER_FAILED, payload: e})
    console.error(e)
  }

  yield call(retrieveAnalytics, {api})
  yield call(listBookings, {api})
}

function* retrieveAnalytics({api}) {
  const analyticsReq = yield select(selectors.generateBookingAnalyticsReqPayload)
  try {
    const analytics = yield call(api.user.getUserBookingAnalytics, analyticsReq)
    yield put({type: C.LIST_BOOKING_ANALYTICS_COMPLETED, payload: analytics})
  } catch (e) {
    yield put({type: C.LIST_BOOKING_ANALYTICS_FAILED, payload: e})
    console.error(e)
  }
}

function* deleteUser({api}, {payload}) {
  try {
    const response = yield call(api.user.deleteUser, payload)
    yield put({type: C.DELETE_USER_COMPLETED, payload: response})
    message.success("Deleted")
    history.goBack()
  } catch (e) {
    yield put({ type: C.DELETE_USER_FAILED, payload: e })
    message.error("Error: ", _.get(e, 'data.detail'))
  }
}

function* listBookings({api}) {
  yield put({type: C.LIST_BOOKINGS})
  const reqPayload = yield select(selectors.generateListBookingReqPayload)
  const selectedTab = yield select(selectors.getSelectedTab)

  try {
    let response = null
    if (selectedTab == LIST_BY.INCOMING) {
      response = yield call(api.booking.getListIncomingBookings, reqPayload)
    } else {
      response = yield call(api.booking.getListHistoryBookings, reqPayload)
    }
    
    yield put({type: C.LIST_BOOKINGS_COMPLETED, payload: response})

  } catch (e) {
    yield put({type: C.LIST_BOOKINGS_FAILED, payload: e})
    console.error(e)
  }
  
}

function detailSaga(deps) {
  return [
      takeLeading(C.RETRIEVE_USER, retrieveUser, deps),
      takeLatest(C.CHANGE_TAB, listBookings, deps),
      takeLatest(C.CHANGE_PAGE, listBookings, deps),
      takeLeading(C.DELETE_USER, deleteUser, deps),
  ]
}

export default detailSaga

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Prototype from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Typography, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Button, Paper } from '@material-ui/core'
import { Link, withRouter } from 'react-router-dom'
import { CURRENCY_MAPPING, PAYMENT_METHOD } from '../../../helpers/constant'
import { history } from '../../../config/store'
import * as paths from '../../../constants/paths.constants'
import * as selectors from '../../../selectors/booking'
import LoadingIcon from 'mdi-react/LoadingIcon'
import { getBookingDetail, downloadPdf } from '../../../actions/booking'
import { get } from 'lodash'
import { Form } from 'antd'
import {withTranslation} from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: '20px'
  },
  textAreaAutoSize: {
    width: '100%',
    borderRadius: '5px'
  },
  bookingInfo: {
    padding: '5px 0',
    paddingLeft: '0'
  },
  roomImage: {
    width: '100px'
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f7f7f7'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
    borderBottom: '1px solid #ddd',
  },
  justifyContentCenter: {
    justifyContent: 'center'
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  bookingInfoDetail: {
    margin: '5px 0'
  },
  table: {
    // minWidth: 700,
    '& .MuiTableCell-head': {
      fontWeight: 'bold'
    }
  },
  tableContainer: {
    // width: 'max-content'
  },
  title: {
    paddingBottom: '20px',
    borderBottom: '1px solid #ddd',
  },
  bookingId: {
    fontWeight: 'bold'
  },
  iframeContent: {
    width: '100%',
    height: '1000px',
  }
}))

const BookingDetail = props => {
  const classes = useStyles()
  const {
    booking,
    isGettingBookingDetail,
    invoiceRows,
    match,
    grandTotalInvoice,
    downloadPdf,
    // actions
    getBookingDetail,
    t, i18n
  } = props
  useEffect(() => {
    getBookingDetail(match.params.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cc = get(booking, 'currency', 'EUR')
  const ccyFormat = (num) => `${CURRENCY_MAPPING[cc]} ${(num || 0).toFixed(2)}`
  // const {handleBack, confirm} = props
  console.log(booking)
  return (
    <Container>
      {isGettingBookingDetail ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Form>

        <Grid container className={classes.mainGrid}>
          
          <Grid item xs={12}>
            <Grid container>
              {/* <Grid item xs={12} md={6} className={classes.bookingInfo}>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12} >
                    <Typography variant="h6" className={classes.bookingId} >Booking {booking.name}</Typography>
                  </Grid>
                  
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Date: {booking.date}</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Number of attendants: {booking.participants} ppl</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>-</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Facilities include: {booking.extraFacilityCategories.join(', ')}</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Catering Services: {booking.cateringNames.join(', ')}</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Payment Method: {PAYMENT_METHOD[booking.paymentMethod]}</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>-</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className={classes.bookingInfo}>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12} >
                    <Typography variant="h6" className={classes.bookingId} >Customer&apos;s Info</Typography>
                  </Grid>
                  
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Full name: {booking.customerName}</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Email: {booking.customerEmail}</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>-</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>Additional requirements / Notes: {booking.additional_requirement}</span>
                  </Grid>
                </Grid>
                <Grid container className={classes.bookingInfoDetail}>
                  <Grid item xs={12} md={12}>
                    <span>-</span>
                  </Grid>
                </Grid>
              </Grid>
               */}

              {/* <Grid item xs={12} md={12} className={classes.bookingInfo}>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Booking Items</TableCell>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Price ({cc})</TableCell>
                        <TableCell align="left">Quantity</TableCell>
                        <TableCell align="left">Discount</TableCell>
                        <TableCell align="left">Total Price ({cc})</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.date}</TableCell>
                          <TableCell align="left">{ccyFormat(row.avg_price)}</TableCell>
                          <TableCell align="left">{row.quantity}</TableCell>
                          <TableCell align="left">{row.avg_discount_pct} %</TableCell>
                          <TableCell align="left">{ccyFormat(row.total_price)}</TableCell>
                        </TableRow>
                      ))}

                      <TableRow>
                        <TableCell rowSpan={3} />
                        <TableCell rowSpan={3} />
                        <TableCell rowSpan={3} />
                        <TableCell rowSpan={3} />
                        <TableCell >Subtotal</TableCell>
                        <TableCell align="left">{ccyFormat(get(grandTotalInvoice, 'subTotal'))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tax</TableCell>
                        <TableCell align="left">{ccyFormat(get(grandTotalInvoice, 'tax'))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell >Total</TableCell>
                        <TableCell align="left">{ccyFormat(get(grandTotalInvoice, 'grandTotal'))}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>type="text/html"
            */}
              <Grid item xs={12}>
                <iframe srcDoc={booking.html} frameborder='0' allowfullscreen className={classes.iframeContent}>
                  
                </iframe>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.footerContent}>
              <Button
                onClick={() => history.push(paths.ViewAllBooking)}
                className={classes.backButton}
              >
                {t('BOOKING.ViewAllBookings')}
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={() => downloadPdf(booking.id)}
                className={classes.backButton}
              >
                {t('BOOKING.ExportPDF')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Form>
    </Container>
  )
}

const mapState = ({ bookingReducer }) => ({
  isGettingBookingDetail: bookingReducer.isGettingBookingDetail,
  booking: selectors.getBookingDetail({ bookingReducer }),
  invoiceRows: selectors.getBookingDetailInvoiceRows({ bookingReducer }),
  grandTotalInvoice: selectors.getGrandTotalBookingDetail({ bookingReducer })
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getBookingDetail,
    downloadPdf
  },
  dispatch
)

BookingDetail.propTypes = {
  booking: Prototype.object,
  history: Prototype.object,
  invoiceRows: Prototype.array,
}

export default withRouter(connect(mapState, mapDispatch) (withTranslation('common') (BookingDetail)))

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UpdateRoomTypeComponent from './components/UpdateRoomType'
class UpdateRoomType extends Component {
  render() {
    return (
      <div>
        <UpdateRoomTypeComponent />
      </div>
    );
  }
}

UpdateRoomType.propTypes = {

};

export default UpdateRoomType;
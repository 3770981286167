import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'

import { DISCOUNT } from '../../constants/models'
const { OBJECT_TYPE, APPLY_TYPE } = DISCOUNT

const FMT = 'DD.MM.YYYY'

const getReducer = ({discounts}) => discounts.detail

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const getName = createSelector(
  [getReducer],
  ({data: {name}}) => name
)

export const getDescription = createSelector(
  [getReducer],
  ({data: {description}}) => description
)

export const getApplyTo = createSelector(
  [getReducer],
  ({data: {object_type, apply_type}}) => {
    const objectType = object_type == OBJECT_TYPE.ROOM? "ROOMS" : "CUSTOMERS"
    const applyType = apply_type == APPLY_TYPE.ALL? "ALL": object_type == APPLY_TYPE.NEW? "NEW": ""
    return apply_type == APPLY_TYPE.CUSTOM ? objectType : `${applyType} ${objectType}`
  }
)

export const isCustomersVisible = createSelector(
  [getReducer],
  ({data: {object_type, apply_type}}) => object_type == OBJECT_TYPE.CUSTOMER && apply_type == APPLY_TYPE.CUSTOM
)

export const isRoomsVisible = createSelector(
  [getReducer],
  ({data: {object_type, apply_type}}) => object_type == OBJECT_TYPE.ROOM && apply_type == APPLY_TYPE.CUSTOM
)

export const getDiscounts = createSelector(
  [getReducer],
  ({data: {discount_room, discount_exfacility, discount_catering}}) => {
    return [`Room: ${discount_room}%`, `Facility: ${discount_exfacility}%`, `Catering: ${discount_catering}%`]
  }
)

export const getStartDate = createSelector(
  [getReducer],
  ({data: {start_at}}) => moment(start_at)
)

export const getStartDateBeautify = createSelector(
  [getStartDate],
  (date) => date.format(FMT)
)


export const getEndDate = createSelector(
  [getReducer],
  ({data: {end_at}}) => moment(end_at)
)

export const getEndDateBeautify = createSelector(
  [getEndDate],
  (date) => date.format(FMT)
)

export const isActive = createSelector(
  [getReducer],
  ({data: {is_active}}) => is_active
)

export const getCustomers = createSelector(
  [getReducer],
  ({customersList: {items}}) => {
    return _.map(items, it => ({
      ..._.pick(it, ['id', 'email']),
      name: `${it.first_name} ${it.last_name}`,
      customerNo: _.get(it, "customer_id", ""),
      company: _.get(it, "company", "")
    }))
  }
)

export const isFechingCustomers = createSelector(
  [getReducer],
  ({customersList: {isFetching}}) => isFetching
)

export const getCustomersPagination = createSelector(
  [getReducer],
  ({customersList}) => _.pick(customersList, ['page', 'pageSize', 'total'])
)

export const getRooms = createSelector(
  [getReducer],
  ({roomsList: {items}}) => {
    return _.map(items, it => ({
      ..._.pick(it, ['id', 'name', 'size']),
      roomType: it.room_type,
      maxParticipants: it.max_attendances
    }))
  }
)

export const isFechingRooms = createSelector(
  [getReducer],
  ({roomsList: {isFetching}}) => isFetching
)

export const getRoomsPagination = createSelector(
  [getReducer],
  ({roomsList}) => _.pick(roomsList, ['page', 'pageSize', 'total'])
)

/**
 * GENERATE REQUEST PAYLOAD
 * ========================
 */
export const generateListCustomersReq = createSelector(
  [getReducer],
  ({customersList: {page, pageSize}, data}) => {
    const ids = _.get(data, "apply_objects", []).join(',')
    return {
      page,
      page_size: pageSize,
      ids
    }
  }
)

export const generateListRoomsReq = createSelector(
  [getReducer],
  ({roomsList: {page, pageSize}, data}) => {
    const ids = _.get(data, "apply_objects", [])
    return {
      page,
      page_size: pageSize,
      ids
    }
  }
)

import * as actionTypes from '../actionTypes/discount.actionTypes'

export function getListDiscount(data) {
  return ({type: actionTypes.GET_LIST_DISCOUNT, data})
}
export function getListDiscountFail(data) {
  return ({type: actionTypes.GET_LIST_DISCOUNT_FAIL, data})
}
export function getListDiscountSuccess(data) {
  return ({type: actionTypes.GET_LIST_DISCOUNT_SUCCESS, data})
}

export function createNewDiscount(data) {
  return ({type: actionTypes.CREATE_NEW_DISCOUNT, data})
}
export function createNewDiscountFail(data) {
  return ({type: actionTypes.CREATE_NEW_DISCOUNT_FAIL, data})
}
export function createNewDiscountSuccess(data) {
  return ({type: actionTypes.CREATE_NEW_DISCOUNT_SUCCESS, data})
}
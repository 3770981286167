import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Edit from '../../components/rooms/EditRoomType'
import * as actions from '../../actions/rooms/edit'
import {roomType as selectors} from '../../selectors/rooms/edit'


class RoomTypeEdit extends Component {
  componentDidMount() {
    let { match: { params: { id } } } = this.props
    this.props.retrieveRoomType(id)
  }

  render() {

    return (
      <Edit {...this.props} />
    )
  }
}


const mapState = (state) => ({
  isFetching: selectors.isFetching(state),
  name: selectors.getName(state),
  description: selectors.getDescription(state),
  isPublic: selectors.isPublic(state),
  images: selectors.getImages(state),
  isSubmitting: selectors.isSubmitting(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveRoomType: actions.retrieveRoomType,
    onSubmit: actions.submitRoomType,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(RoomTypeEdit))

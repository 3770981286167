import { all, takeLatest, put, call } from 'redux-saga/effects'
import * as actions from '../actions/facilities'
import * as actionTypes from '../actionTypes/facilities.actionTypes'
import { history } from '../config/store'
import * as paths from '../constants/paths.constants'

function* getFacilities({api}, {data}) {
  try {
    // yield delay(2000)
    const response = yield call(api.facilities.getListExtraFacilityCategories)
    yield put(actions.getFacilitiesSuccess(response))
  } catch (e) {
    yield put(actions.getFacilitiesFail())
  }
}

function* createNewFacilityCategory({api}, {data}) {
  try {
    // yield delay(2000)
    const response = yield call(api.facilities.createNewFacilityCategory, data)
    yield put(actions.createNewFacilityCategorySuccess(response))
    history.push(paths.ListFacilities)
  } catch (e) {
    yield put(actions.createNewFacilityCategoryFail())
  }
}

function* updateFacilityCategory({api}, {data}) {
  try {
    // yield delay(2000)
    const response = yield call(api.facilities.updateFacilityCategory, data)
    yield put(actions.updateFacilityCategorySuccess(response))
  } catch (e) {
    yield put(actions.updateFacilityCategoryFail())
  }
}

function* increaseQuantityFacilityCategory({api}, {data}) {
  try {
    // yield delay(2000)
    const response = yield call(api.facilities.increaseQuantityFacilityCategory, data)
    yield put(actions.increaseQuantityFacilityCategorySuccess(response))
  } catch (e) {
    yield put(actions.increaseQuantityFacilityCategoryFail())
  }
}

function publicSaga(deps) {
  return function* saga() {
    yield all([
      takeLatest(actionTypes.GET_FACILITIES, getFacilities, deps),
      takeLatest(actionTypes.CREATE_NEW_FACILITY_CATEGORY, createNewFacilityCategory, deps),
      takeLatest(actionTypes.UPDATE_FACILITY_CATEGORY, updateFacilityCategory, deps),
      takeLatest(actionTypes.INCREASE_QUANTITY_FACILITY_CATEGORY, increaseQuantityFacilityCategory, deps),
      // takeLatest(actionTypes.GET_CATEGORY, getCategory, deps)
    ])
  }
}

export default publicSaga
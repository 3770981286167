import React, { useState, Component } from 'react'
import { Table, Input, Select, Modal, Icon, message } from 'antd';
import { Grid, IconButton, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { MIN_H, MAX_H } from "../../helpers/timeslot";

class TimeslotPricingWall extends React.Component {
  state = {
    pricings: [
      { start: 8, end: 13, price: 0, slots: 5, blockHours: "08:00 - 13:00" },
      { start: 13, end: 18, price: 0, slots: 5, blockHours: "13:00 - 18:00" },
      { start: 8, end: 18, price: 0, slots: 10, blockHours: "08:00 - 18:00" },
    ],

    start: MIN_H,
    end: 13,
    price: 0,
  };

  componentDidMount() {
    const { pricings = [], preLoadPricings } = this.props
    if (pricings && pricings.length > 0) {
      this.setState({pricings: _.map(pricings, p => ({
        ...p,
        blockHours: `${_.padStart(p.start, 2, '0')}:00 - ${_.padStart(p.end, 2, '0')}:00`
      }))})
    } 
    else {
      // to allow this changes parent component props at first mount
      if (preLoadPricings) {
        const { onChange = f => f } = this.props
        onChange(this.state.pricings)
      }
    }
  }

  handleChangePrice(pricing, newValue) {
    const { onChange = f => f } = this.props
    const clonePricings = _.clone(this.state.pricings)
    const item = _.find(clonePricings, it => it.blockHours == pricing.blockHours)
    _.set(item, 'price', newValue)
    this.setState({ pricings: clonePricings })
    onChange(clonePricings)
  }

  handleDelete(value) {
    const { onChange = f => f } = this.props

    const newItems = _.filter(this.state.pricings, it => it.blockHours !== value.blockHours)
    this.setState({ pricings: newItems })
    onChange(newItems)
  }

  handleAdd() {
    const { onChange = f => f } = this.props

    let item = _.pick(this.state, ['start', 'end', 'price'])
    const {start, end} = item
    item.blockHours = `${_.padStart(start, 2, '0')}:00 - ${_.padStart(end, 2, '0')}:00`
    item.slots = end - start
    const pricings = [..._.filter(this.state.pricings, it=>it.blockHours != item.blockHours), item]
    this.setState({pricings})
    onChange(pricings)
  }

  getSelectableStartTimes() {
    return _.range(MIN_H, MAX_H - 1)
  }

  getSelectableEndTimes() {
    return _.range(this.state.start+1, MAX_H)
  }

  render() {
    const { t } = this.props

    const columns = [
      {
        title: t("Block hours"),
        dataIndex: 'blockHours',
        key: "blockHours"
      },
      {
        title: t("Price"),
        dataIndex: 'price',
        key: 'price',
        render: (price, record) => (
          <Input
            prefix="€"
            type="number"
            value={price}
            onChange={e => this.handleChangePrice(record, e.target.value)}
          />
        )
      },
      {
        title: t("Action"),
        key: 'action',
        render: (record) => (
          <IconButton onClick={() => this.handleDelete(record)}>
            <DeleteIcon />
          </IconButton>
        )
      }
    ]
    return (
      <Grid>
        <Table
          columns={columns}
          dataSource={this.state.pricings}
          pagination={false}
          footer={() => (
            <Grid container spacing={2} alignItems="flex-end">
              <Grid xs={4} sm={2} item>
              <Typography variant="body1">{t("Start")}</Typography>
                <Select
                  value={this.state.start}
                  onChange={value => {
                    this.setState({ start: value })
                    if (this.state.end <= value) {
                      this.setState({end: value + 1})
                    }
                  }}
                  placeholder={t("Start Time")}
                >
                  {_.map(this.getSelectableStartTimes(), time => (
                    <Select.Option value={time}>{`${_.padStart(time, 2, '0')}:00`}</Select.Option>
                  ))}
                </Select>
              </Grid>
              
              <Grid item xs={4} sm={2}>
                <Typography variant="body1">{t("End")}</Typography>
                <Select
                  value={this.state.end}
                  onChange={value => this.setState({ end: value })}
                  placeholder={t("End Time")}
                >
                  {_.map(this.getSelectableEndTimes(), time => (
                    <Select.Option value={time}>{`${_.padStart(time, 2, '0')}:00`}</Select.Option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={4} sm={4}>
              <Typography variant="body1">{t("Price")}</Typography>
                <Input 
                  type="number" 
                  value={this.state.price} 
                  prefix="€" 
                  onChange={(e) => this.setState({price: e.target.value})}
                />
              </Grid>

              <Grid item xs={4} sm={2}>
                <IconButton size="small" onClick={() => this.handleAdd()} ><AddIcon /></IconButton>
              </Grid>
            </Grid>
          )}
        />

      </Grid>
    )
  }
}

export default withTranslation("common")(TimeslotPricingWall)

import * as _ from 'lodash'


export const getActiveTag = (isActive) => {
  if (isActive) {
    return { color: "green", name: "Yes" }
  }
  return { color: "red", name: "No" }
}

export const getTypeTag = (isCombo) => {
  if (isCombo) {
    return { color: "green", name: "Package" }
  }
  return { color: "violet", name: "Single" }
}



const C = "DISCOUNT.NEW"

export const CHANGE_DISCOUNT_NAME = `${C}.CHANGE_DISCOUNT_NAME`
export const CHANGE_DISCOUNT_DESCRIPTION = `${C}.CHANGE_DISCOUNT_DESCRIPTION`
export const CHANGE_SELECTED_APPLY_TO = `${C}.CHANGE_SELECTED_APPLY_TO`
export const CHANGE_SELECTED_APPLY_TYPE = `${C}.CHANGE_SELECTED_APPLY_TYPE`

export const CHANGE_CUSTOMER_SEARCH_TEXT = `${C}.CHANGE_CUSTOMER_SEARCH_TEXT`
export const CHANGE_CUSTOMER_SEARCH_PAGE = `${C}.CHANGE_CUSTOMER_SEARCH_PAGE`
export const SEARCH_CUSTOMER = `${C}.SEARCH_CUSTOMER`
export const SEARCH_CUSTOMER_COMPLETED = `${C}.SEARCH_CUSTOMER_COMPLETED`
export const SEARCH_CUSTOMER_FAILED = `${C}.SEARCH_CUSTOMER_FAILED`
export const ADD_CUSTOMER = `${C}.ADD_CUSTOMER`
export const REMOVE_CUSTOMER = `${C}.REMOVE_CUSTOMER`

export const CHANGE_ROOM_SEARCH_TEXT = `${C}.CHANGE_ROOM_SEARCH_TEXT`
export const CHANGE_ROOM_SEARCH_PAGE = `${C}.CHANGE_ROOM_SEARCH_PAGE`
export const SEARCH_ROOM = `${C}.SEARCH_ROOM`
export const SEARCH_ROOM_COMPLETED = `${C}.SEARCH_ROOM_COMPLETED`
export const SEARCH_ROOM_FAILED = `${C}.SEARCH_ROOM_FAILED`
export const ADD_ROOM = `${C}.ADD_ROOM`
export const REMOVE_ROOM = `${C}.REMOVE_ROOM`

export const CHANGE_ROOM_DISCOUNT = `${C}.CHANGE_ROOM_DISCOUNT`
export const CHANGE_FACILITY_DISCOUNT = `${C}.CHANGE_FACILITY_DISCOUNT`
export const CHANGE_CATERING_DISCOUNT = `${C}.CHANGE_CATERING_DISCOUNT`

export const CHANGE_START_DATE = `${C}.CHANGE_START_DATE`
export const CHANGE_END_DATE = `${C}.CHANGE_END_DATE`
export const TOGGLE_IS_ACTIVATE = `${C}.TOGGLE_IS_ACTIVATE`

export const LOAD_SUGGEST_CUSTOMERS = `${C}.LOAD_SUGGEST_CUSTOMERS`
export const LOAD_SUGGEST_CUSTOMERS_COMPLETED = `${C}.LOAD_SUGGEST_CUSTOMERS_COMPLETED`
export const LOAD_SUGGEST_CUSTOMERS_FAILED = `${C}.LOAD_SUGGEST_CUSTOMERS_FAILED`

export const ADD_SUGGESTED_CUSTOMERS = `${C}.ADD_SUGGESTED_CUSTOMERS`

export const SUBMIT_CREATE = `${C}.SUBMIT_CREATE`
export const SUBMIT_CREATE_COMPLETED = `${C}.SUBMIT_CREATE_COMPLETED`
export const SUBMIT_CREATE_FAILED = `${C}.SUBMIT_CREATE_FAILED`

import * as C from '../../actionTypes/discounts/new'


export const changeName = (payload) => ({type: C.CHANGE_DISCOUNT_NAME, payload })
export const changeDescription = (payload) => ({type: C.CHANGE_DISCOUNT_DESCRIPTION, payload})
export const changeSelectedApplyTo = (payload) => ({type: C.CHANGE_SELECTED_APPLY_TO, payload})
export const changeSelectedApplyType = (payload) => ({type: C.CHANGE_SELECTED_APPLY_TYPE, payload})
export const changeCustomerSearchText = (payload) => ({type: C.CHANGE_CUSTOMER_SEARCH_TEXT, payload})
export const changeCustomerSearchPage = (payload) => ({type: C.CHANGE_CUSTOMER_SEARCH_PAGE, payload})
export const searchCustomers = () =>({type: C.SEARCH_CUSTOMER })
export const addCustomer = (payload) => ({type: C.ADD_CUSTOMER, payload })
export const removeCustomer = (payload) => ({type: C.REMOVE_CUSTOMER, payload })

export const changeRoomSearchText = (payload) => ({ type: C.CHANGE_ROOM_SEARCH_TEXT, payload })
export const changeRoomSearchPage = (payload) => ({ type: C.CHANGE_ROOM_SEARCH_PAGE, payload })
export const searchRooms = () => ({type: C.SEARCH_ROOM })
export const addRoom = (payload) => ({type: C.ADD_ROOM, payload })
export const removeRoom = (payload) => ({type: C.REMOVE_ROOM, payload })

export const changeRoomDiscount = (payload) => ({type: C.CHANGE_ROOM_DISCOUNT, payload})
export const changeFacilityDiscount = (payload) => ({type: C.CHANGE_FACILITY_DISCOUNT, payload})
export const changeCateringDiscount = (payload) => ({type: C.CHANGE_CATERING_DISCOUNT, payload})

export const changeStartDate = (payload) => ({type: C.CHANGE_START_DATE, payload})
export const changeEndDate = (payload) => ({type: C.CHANGE_END_DATE, payload})
export const toggleIsActive = () => ({type: C.TOGGLE_IS_ACTIVATE})

export const loadSuggestCustomers = () => ({type: C.LOAD_SUGGEST_CUSTOMERS})
export const addSuggestedCustomers = (payload) => ({type: C.ADD_SUGGESTED_CUSTOMERS, payload})

export const submitCreate = () => ({type: C.SUBMIT_CREATE})

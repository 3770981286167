import * as storage from '../storage'
import {
  LOCAL_STORAGE_KEY
} from '../../helpers/constant'
import moment from 'moment'
import * as _ from 'lodash'
import jwtDecode from 'jwt-decode'
import { history } from '../../config/store'

const isTokenExpired = (token) => {
  const { exp } = jwtDecode(token)
  return (moment.unix(exp) - moment()) <= 0
}

class UnauthorizedError extends Error {
  message = "Unauthenticated"
}

export default class BaseApi {
  http;
  constructor(http) {
    this.http = http;
  }

  async checkAuthenticatedStatus() {
    let authorization = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization)
        const accessToken = _.get(authorization, 'access')
        const refreshToken = _.get(authorization, 'refresh')

        if (accessToken) {
            if (isTokenExpired(accessToken)) {
                console.log("Access token expired.")
                if (isTokenExpired(refreshToken)) {
                    console.log("Refresh token expired.")
                    storage.setLocalStorageItem(LOCAL_STORAGE_KEY.authorization,{})
                    history.push('/')
                    history.go(0)
                    throw new UnauthorizedError()
                }

                // Try to refresh token
                const data = {
                     refresh: refreshToken
                }

                console.log("Refresh access token")
                const response = await this.http.post('/auth/jwt/refresh', data)
                const access = _.get(response, 'access')
                authorization['access'] = access
                storage.setLocalStorageItem(LOCAL_STORAGE_KEY.authorization, authorization)
            }
            
            return true
        }

        history.push('/')
        history.go(0)
        throw new UnauthorizedError()
  }

}

import React from 'react'
import PropTypes from 'prop-types'
import EditNewRoomTypes from '../../ListingNew/components/CreateNewRoomTypes'
const UpdateRoomType = props => {
  return (
    <div>
      <EditNewRoomTypes
        editView
        roomType={'VIP'}
      />
    </div>
  )
}

UpdateRoomType.propTypes = {
  
}

export default UpdateRoomType
import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import { message } from 'antd'
import * as C from '../../actionTypes/rooms/list'
import * as NEW_C from '../../actionTypes/rooms/new'
import * as EDIT_C from '../../actionTypes/rooms/edit'
import * as selectors from '../../selectors/rooms/list'
import { history } from '../../config/store'
import * as _ from 'lodash'
import { ROOM } from '../../constants/models'

const { LIST_BY } = ROOM

function* listRooms({api}) {
  const tab = yield select(selectors.getSelectedTab)
  const fnApi = tab == LIST_BY.ROOM ? api.room.searchRooms : api.room.searchRoomTypes
  const req = yield select(selectors.generateReqPayload)

  try {
    const response = yield call(fnApi, req)
    yield put({type: C.LIST_ROOM_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.LIST_ROOM_FAILED, payload: e})
    message.error(_.get(e, 'data.detail'))
    console.error(e)
  }
}

function* loadRoomTypes(deps) {
  yield (put({type: C.CHANGE_TAB, payload: LIST_BY.ROOM_TYPE}))
}

function* loadRooms(deps) {
  yield put(({type: C.CHANGE_TAB, payload: LIST_BY.ROOM}))
}

function listSaga(deps) {
  return [
      takeLeading(C.LIST_ROOM, listRooms, deps),
      takeLatest(C.CHANGE_TAB, listRooms, deps),
      takeLatest(C.CHANGE_PAGE, listRooms, deps),
      
      takeLatest(EDIT_C.SUBMIT_ROOM_COMPLETED, loadRooms, deps),
      takeLatest(NEW_C.SUBMIT_ROOM_COMPLETED, loadRooms, deps),

      takeLatest(EDIT_C.SUBMIT_ROOM_TYPE_COMPLETED, loadRoomTypes, deps),
      takeLatest(NEW_C.SUBMIT_ROOM_TYPE_COMPLETED, loadRoomTypes, deps),
  ]
}

export default listSaga

import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Input } from 'antd'
import { Table, Tag } from 'antd';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { ROOM } from '../../constants/models'

const {LIST_BY} = ROOM


const RoomList = (props) => {
  let match = useRouteMatch();
  const {
    t,
    
    selectedTab,
    selectableTabs,
    items,
    isFetching,
    page,
    pageSize,
    total,

    onChangeTab,
    onChangePage,
  } = props

  const roomColumns = [
    {
      title: t('Room Name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={`${match.path}/detail/room/${record.id}`}>{text}</Link>,
    },
    {
      title: t('Permanent Facilities'),
      dataIndex: 'facilities',
      key: 'facilities',
      render: (facilities) => _.map(facilities, fac => (
        <Tag color={fac.color}>{t(fac.name)}</Tag>
      ))
    },
    {
      title: t('Room Type'),
      dataIndex: 'roomType',
      key: 'roomType',
    },
    {
      title: t('Max Participants'),
      dataIndex: 'maxParticipants',
      key: 'maxParticipants',
    },
    {
      title: t('Size') + " (m²)",
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: t('Lead time'),
      dataIndex: 'leadTime',
      key: 'leadTime',
    },
    {
      title: t('Free cancel days'),
      dataIndex: 'freeCancelDays',
      key: 'freeCancelDays',
    },
    {
      title: t("Is Public"),
      dataIndex: 'isPublic',
      key: 'isPublic',
      render: (publicTag) => <Tag color={publicTag.color}>{t(publicTag.name)}</Tag>
    },
  ]

  const roomTypeColumns = [
    {
      title: t('Room Type'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={`${match.path}/detail/room-type/${record.id}`}>{text}</Link>,
    },
    {
      title: t("Number of rooms"),
      dataIndex: 'roomCount',
      key: 'roomCount',
    },
    {
      title: t("Max Participants"),
      dataIndex: "maxParticipants",
      key: "maxParticipants"
    },
    {
      title: t("Description"),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t("Is Public"),
      dataIndex: 'isPublic',
      key: 'isPublic',
      render: (publicTag) => <Tag color={publicTag.color}>{t(publicTag.name)}</Tag>
    },
  ]

  const columns = selectedTab == LIST_BY.ROOM ? roomColumns: roomTypeColumns

  const forRoom = selectedTab == LIST_BY.ROOM
  return (
    <>
      <PageHeader
        backIcon={false}
        title={t("ROOM MANAGEMENT")}
        subTitle={t("Manage all rooms")}
        extra={[
          <Link to={`${match.path}/new/${forRoom?"room":"room-type"}/`}>
            <Button
              color="primary"
              variant="contained"
              size="small"
            >
              {t(`NEW ${forRoom ? "ROOM": "ROOM TYPE"}`)}
            </Button>
          </Link>,
        ]}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          value={selectedTab}
          onChange={(e, value) => onChangeTab(value)}
        >
          {_.map(selectableTabs, tab => (
            <Tab label={t(tab.name)} value={tab.id} />
          ))}
        </Tabs>

        <Table
          columns={columns}
          dataSource={items}
          loading={isFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page,
            pageSize,
            total,
            onChange: (page, pageSize) => onChangePage(page)
          }}
        />
      </div>
    </>
  )
}

export default withRouter(withTranslation("room_management")(RoomList))

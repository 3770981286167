import React, { useState, Component } from "react";
import { Table, Input, Select, Modal, Icon, message } from "antd";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import * as _ from "lodash";
import NumberFormat from "react-number-format";

class QuantityPricingWall extends React.Component {
  state = {
    pricings: [{ minQuantity: 1, price: 0 }],
    minQuantity: 10,
    price: 1,
  };

  componentDidMount() {
    const { pricings = [] } = this.props;
    if (pricings && pricings.length > 0) {
      this.setState({ pricings: pricings });
    } else {
      const { onChange = (f) => f } = this.props;
      onChange(this.state.pricings);
    }
  }

  handleChangePrice(pricing, newValue) {
    const { onChange = (f) => f } = this.props;
    const clonePricings = _.clone(this.state.pricings);
    const item = _.find(
      clonePricings,
      (it) => it.minQuantity == pricing.minQuantity
    );
    _.set(item, "price", newValue);
    this.setState({ pricings: clonePricings });
    onChange(clonePricings);
  }

  handleDelete(value) {
    const { onChange = (f) => f } = this.props;

    const newItems = _.filter(
      this.state.pricings,
      (it) => it.minQuantity !== value.minQuantity
    );
    this.setState({ pricings: newItems });
    onChange(newItems);
  }

  handleAdd() {
    const { onChange = (f) => f } = this.props;

    let item = _.pick(this.state, ["minQuantity", "price"]);
    const pricings = [
      ..._.filter(
        this.state.pricings,
        (it) => it.minQuantity != item.minQuantity
      ),
      item,
    ];
    this.setState({ pricings });
    onChange(pricings);
  }

  render() {
    const { t } = this.props;

    const columns = [
      {
        title: t("Min Quantity"),
        dataIndex: "minQuantity",
        key: "minQuantity",
      },
      {
        title: t("Price"),
        dataIndex: "price",
        key: "price",
        render: (price, record) => (
          <span class="ant-input-affix-wrapper" data-children-count="1">
            <NumberFormat
              className="ant-input"
              thousandSeparator="."
              decimalSeparator=","
              value={price}
              onValueChange={(value) => {
                this.handleChangePrice(record, value.floatValue);
              }}
            />
            <span class="ant-input-suffix">€</span>
          </span>
        ),
      },
      {
        title: t("Action"),
        key: "action",
        render: (record) => (
          <IconButton onClick={() => this.handleDelete(record)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
    ];
    return (
      <Grid>
        <Table
          columns={columns}
          dataSource={this.state.pricings}
          pagination={false}
          footer={() => (
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={4} sm={4}>
                <Typography variant="body1">{t("Min Quantity")}</Typography>
                <Input
                  type="number"
                  value={this.state.minQuantity}
                  min={1}
                  onChange={(e) =>
                    this.setState({ minQuantity: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={4} sm={4}>
                <Typography variant="body1">{t("Price")}</Typography>
                <span class="ant-input-affix-wrapper" data-children-count="1">
                  <NumberFormat
                    className="ant-input"
                    thousandSeparator="."
                    decimalSeparator=","
                    value={this.state.price}
                    onValueChange={(value) => {
                      this.setState({ price: value.floatValue });
                    }}
                  />
                  <span class="ant-input-suffix">€</span>
                </span>
              </Grid>

              <Grid item xs={4} sm={2}>
                <IconButton size="small" onClick={() => this.handleAdd()}>
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
        />
      </Grid>
    );
  }
}

export default withTranslation("common")(QuantityPricingWall);

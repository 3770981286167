import { bind } from 'decko'
import {c2s} from '../../helpers/helpers';
import BaseApi from './baseApi'
import {
  RoomBookingAnalytics,
  RoomAvailAnalytics,
  UserBookingAnalytics,
} from '../../constants/endpoints.constants'
export default class Analytics extends BaseApi {
  @bind
  async roomBooking(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(RoomBookingAnalytics(), c2s(data));
  }

  @bind
  async roomAvailability(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(RoomAvailAnalytics(), c2s(data));
  }

  @bind
  async userBooking(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(UserBookingAnalytics(), c2s(data));
  }
}

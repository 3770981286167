import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as C from '../../actionTypes/discounts/edit'
import * as selectors from '../../selectors/discounts/edit'
import { history } from '../../config/store'
import { DISCOUNT } from '../../constants/models'
import * as _ from 'lodash'

const { APPLY_TYPE, OBJECT_TYPE } = DISCOUNT

function* retrieveDiscount({ api }, { payload }) {
  try {
    const response = yield call(api.discount.retrieveDiscount, payload)
    yield put({ type: C.RETRIEVE_DISCOUNT_COMPLETED, payload: response })
  } catch (e) {
    yield put({ type: C.RETRIEVE_DISCOUNT_FAILED, payload: e })
    console.error(e)
  }
}


function* listApplyObjects({ api }, { payload }) {

  if (_.get(payload, 'apply_type') == APPLY_TYPE.CUSTOM) {
    if (_.get(payload, 'object_type') == OBJECT_TYPE.ROOM) {

      yield put({ type: C.LIST_ROOMS })
      try {
        const req = yield select(selectors.generateListRoomsReq)
        const response = yield call(api.room.searchRooms, req)
        yield put({ type: C.LIST_ROOMS_COMPLETED, payload: response })
      } catch (e) {
        yield put({ type: C.LIST_ROOMS_FAILED, payload: e })
        console.error(e)
      }

    } else {

      yield put({ type: C.LIST_CUSTOMERS })
      try {
        const req = yield select(selectors.generateListCustomersReq)
        const response = yield call(api.user.searchListCustomer, req)
        yield put({ type: C.LIST_CUSTOMERS_COMPLETED, payload: response })
      } catch (e) {
        yield put({ type: C.LIST_CUSTOMERS_FAILED, payload: e })
        console.error(e)
      }

    }
  }
}

function* listRooms({ api }) {
  yield put({ type: C.LIST_ROOMS })
  try {
    const req = yield select(selectors.generateListRoomsReq)
    const response = yield call(api.room.searchRooms, req)
    yield put({ type: C.LIST_ROOMS_COMPLETED, payload: response })
  } catch (e) {
    yield put({ type: C.LIST_ROOMS_FAILED, payload: e })
    console.error(e)
  }
}

function* listCustomers({ api }) {
  yield put({ type: C.LIST_CUSTOMERS })
  try {
    const req = yield select(selectors.generateListCustomersReq)
    const response = yield call(api.user.searchListCustomer, req)
    yield put({ type: C.LIST_CUSTOMERS_COMPLETED, payload: response })
  } catch (e) {
    yield put({ type: C.LIST_CUSTOMERS_FAILED, payload: e })
    console.error(e)
  }
}


function* searchCustomers({api}) {
  const reqPayload = yield select(selectors.generateSearchCustomersReq)

  try {
    const response = yield call(api.user.searchListCustomer, reqPayload)
    yield put({type: C.SEARCH_CUSTOMER_COMPLETED, payload: response })
  } catch (e) {
    yield put({type: C.SEARCH_CUSTOMER_FAILED, payload: e})
    console.error(e)
  }
}

function* changeSearchText({api}, {payload}) {
  if (payload == "") {
    // Only on reset the text
    yield call(searchCustomers, {api})
  }
}

function* searchRooms({api}) {
  const reqPayload = yield select(selectors.generateSearchRoomsReq)
  try {
    const response = yield call(api.room.searchRooms, reqPayload)
    yield put({type: C.SEARCH_ROOM_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.SEARCH_ROOM_FAILED, payload: e})
    console.error(e)
  }
}

function* changeRoomSearchText({api}, {payload}) {
  if (payload == "") {
    // Only on reset the text
    yield call(searchRooms, {api})
  }
}


function* submitSave({api}) {
  const reqPayload = yield select(selectors.generateCreateReq)
  console.log(reqPayload)
  try {
    const response = yield call(api.discount.updateDiscount, reqPayload)
    console.log(response)
    yield put({type: C.SUBMIT_SAVE_COMPLETED, payload: response})
    history.goBack()
  } catch (e) {
    yield put({type: C.SUBMIT_SAVE_FAILED, payload: e})
    console.error(e)
  }
}

function editSaga(deps) {
  return [
    takeLeading(C.RETRIEVE_DISCOUNT, retrieveDiscount, deps),
    takeLatest(C.RETRIEVE_DISCOUNT_COMPLETED, listApplyObjects, deps),
    takeLatest(C.CHANGE_ROOMS_LIST_PAGE, listRooms, deps),
    takeLatest(C.CHANGE_CUSTOMERS_LIST_PAGE, listCustomers, deps),
    takeLatest(C.ADD_CUSTOMER, listCustomers, deps),
    takeLatest(C.REMOVE_CUSTOMER, listCustomers, deps),

    takeLeading(C.SEARCH_CUSTOMER, searchCustomers, deps),
    takeLeading(C.CHANGE_CUSTOMER_SEARCH_PAGE, searchCustomers, deps),
    takeLatest(C.CHANGE_CUSTOMER_SEARCH_TEXT, changeSearchText, deps),

    takeLeading(C.SEARCH_ROOM, searchRooms, deps),
    takeLeading(C.CHANGE_ROOM_SEARCH_PAGE, searchRooms, deps),
    takeLatest(C.CHANGE_ROOM_SEARCH_TEXT, changeRoomSearchText, deps),
    takeLatest(C.ADD_ROOM, listRooms, deps),
    takeLatest(C.REMOVE_ROOM, listRooms, deps),

    takeLeading(C.SUBMIT_SAVE, submitSave, deps),
  ]
}

export default editSaga

import * as actionTypes from '../actionTypes/catering.actionTypes'

export function getListAllCaterings(data) {
  return ({type: actionTypes.GET_LIST_ALL_CATERINGS, data})
}
export function getListAllCateringsFail(data) {
  return ({type: actionTypes.GET_LIST_ALL_CATERINGS_FAIL, data})
}
export function getListAllCateringsSuccess(data) {
  return ({type: actionTypes.GET_LIST_ALL_CATERINGS_SUCCESS, data})
}

export function createNewCatering(data) {
  return ({type: actionTypes.CREATE_NEW_CATERING, data})
}
export function createNewCateringFail(data) {
  return ({type: actionTypes.CREATE_NEW_CATERING_FAIL, data})
}
export function createNewCateringSuccess(data) {
  return ({type: actionTypes.CREATE_NEW_CATERING_SUCCESS, data})
}

export function updateCatering(data) {
  return ({type: actionTypes.UPDATE_CATERING, data})
}
export function updateCateringFail(data) {
  return ({type: actionTypes.UPDATE_CATERING_FAIL, data})
}
export function updateCateringSuccess(data) {
  return ({type: actionTypes.UPDATE_CATERING_SUCCESS, data})
}

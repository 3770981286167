import { combineReducers } from 'redux'
import * as C from "../../actionTypes/users/detail";
import { USER, BOOKING } from "../../constants/models";
import moment from 'moment'
import * as _ from 'lodash'

const { LIST_BY } = BOOKING

const data = (state = {}, action) => {
  const {type, payload} = action
  switch (type) {
    case C.RETRIEVE_USER_COMPLETED:
      return payload
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const {type, payload} = action
  switch (type) {
    case C.RETRIEVE_USER:
      return true
    case C.RETRIEVE_USER_COMPLETED:
    case C.RETRIEVE_USER_FAILED:
      return false
    default:
      return state
  }
}

const bookings = combineReducers({
  page: (state=1, action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_BOOKINGS_COMPLETED:
        return _.get(payload, 'page', 1)
      case C.CHANGE_PAGE:
        return payload
      case C.CHANGE_TAB:
      case C.RETRIEVE_USER:
        return 1
      default:
        return state
    }
  },
  pageSize: (state=10, action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_BOOKINGS_COMPLETED:
        return _.get(payload, 'page_size', 10)
      case C.RETRIEVE_USER:
        return 10
      default:
        return state
    }
  },
  total: (state=0, action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_BOOKINGS_COMPLETED:
        return _.get(payload, 'count', 0)
      case C.RETRIEVE_USER:
        return 0
      default:
        return state
    }
  },
  isFetching: (state=false, action) => {
    const {type, payload} = action
    switch (type) {
      case C.CHANGE_TAB:
      case C.CHANGE_PAGE:
      case C.LIST_BOOKINGS:
        return true
      case C.LIST_BOOKINGS_FAILED:
      case C.LIST_BOOKINGS_COMPLETED:
        return false
      default:
        return state
    }
  },
  items: (state=[], action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_BOOKINGS_COMPLETED:
        return _.get(payload, 'results', [])
      case C.RETRIEVE_USER:
        return []
      default:
        return state
    }
  },
  selectedTab: (state=LIST_BY.INCOMING, action) => {
    const {type, payload} = action
    switch (type) {
      case C.CHANGE_TAB:
        return payload
      default:
        return state
    }
  },
})

const analytics = combineReducers({
  items: (state=[], action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_BOOKING_ANALYTICS_COMPLETED:
        return _.orderBy(_.get(payload, "results"), it => it.month)
      case C.RETRIEVE_USER:
        return []
      default:
        return state
    }
  },
  isFetching: (state=false, action) => {
    const {type, payload} = action
    switch (type) {
      case C.LIST_BOOKING_ANALYTICS:
        return true
      case C.LIST_BOOKING_ANALYTICS_COMPLETED:
      case C.LIST_BOOKING_ANALYTICS_FAILED:
        return false
      default:
        return state
    }
  },
})

const reducers = combineReducers({
  data,
  isFetching,
  bookings,
  analytics,
})

export default reducers

const C = "ROOMS.LIST"

export const LIST_ROOM = `${C}.LIST_ROOM`
export const LIST_ROOM_COMPLETED = `${C}.LIST_ROOM_COMPLETED`
export const LIST_ROOM_FAILED = `${C}.LIST_ROOM_FAILED`

export const SUBMIT_SEARCH = `${C}.SUBMIT_SEARCH`
export const CHANGE_TAB = `${C}.CHANGE_TAB`
export const CHANGE_PAGE = `${C}.CHANGE_PAGE`


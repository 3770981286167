import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Button, Pagination, Skeleton } from 'antd'
import MdButton from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Table, Tag } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import DiscountEditor from '../../components/Discount/DiscountEditor'

import * as actions from '../../actions/discounts/edit'
import * as selectors from '../../selectors/discounts/edit'


const View = props => {
  let match = useRouteMatch();
  const {
    t,
    isFetching = false,
    isSaving = false,

    onSave = f => f,
  } = props

  return (
    <Paper>
      <PageHeader
        onBack={() => window.history.back()}
        title={t("EDIT DISCOUNT")}
        subTitle={t("Update discount information")}
      />


      <div style={{ padding: 24 }}>
        {isFetching ?
          <Skeleton active /> :
          [
            <DiscountEditor {...props} />,

            <Grid container justify="flex-end">
              <Grid item>
                <MdButton
                  color="primary"
                  variant="contained"
                  onClick={onSave}
                  disabled={isSaving}
                >
                  {isSaving && <CircularProgress size={16} />}
                  {isSaving ? t("Saving") + '...' : t("Save")}
                </MdButton>
              </Grid>
            </Grid>
          ]
        }
      </div>
    </Paper>
  )
}

class DiscountEdit extends Component {
  componentDidMount() {
    let { match: { params: { id } } } = this.props
    this.props.retrieveDetail(id)
    this.props.onSearchCustomers()
    this.props.onSearchRooms()
  }

  render() {
    return (
      <View {...this.props} />
    )
  }
}


const mapState = (state) => ({
  isFetching: selectors.isFetching(state),
  name: selectors.getName(state),
  description: selectors.getDescription(state),
  selectableApplyToes: selectors.getSelectableApplyToes(state),
  selectedApplyTo: selectors.getSelectedApplyTo(state),
  selectableApplyTypes: selectors.getSelectableApplyTypes(state),
  selectedApplyType: selectors.getSelectedApplyType(state),

  roomDiscount: selectors.getRoomDiscount(state),
  facilityDiscount: selectors.getFacilityDiscount(state),
  cateringDiscount: selectors.getCateringDiscount(state),

  startDate: selectors.getStartDate(state),
  endDate: selectors.getEndDate(state),
  isActive: selectors.isActive(state),

  isSelectedCustomersVisible: selectors.isSelectedCustomersVisiable(state),
  selectableCustomers: selectors.getSelectableCustomers(state),
  customerSearchPagination: selectors.getSearchCustomerPagination(state),
  customerSearchText: selectors.getSearchCustomerText(state),
  isSearchingCustomer: selectors.isSearchingCustomer(state),
  selectedCustomersPagination: selectors.getSelectedCustomersPagination(state),
  selectedCustomers: selectors.getVisibleSelectedCustomers(state),
  isFechingSelectedCustomers: selectors.isFechingSelectedCustomers(state),

  isSelectedRoomsVisible: selectors.isSelectedRoomsVisible(state),
  selectableRooms: selectors.getSelectableRooms(state),
  roomSearchText: selectors.getRoomSearchText(state),
  roomSearchPagination: selectors.getSearchRoomPagination(state),
  isSearchingRooms: selectors.isSearchingRooms(state),
  selectedRooms: selectors.getVisibleSelectedRooms(state),
  selectedRoomsPagination: selectors.getSelectedRoomsPagination(state),

  isSaving: selectors.isSaving(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveDetail: actions.retrieveDiscount,

    onChangeName: actions.changeName,
    onChangeDescription: actions.changeDescription,
    onChangeSelectedApplyTo: actions.changeSelectedApplyTo,
    onChangeSelectedApplyType: actions.changeSelectedApplyType,
    onSearchCustomers: actions.searchCustomers,
    onChangeCustomerSearchText: actions.changeCustomerSearchText,
    onChangeCustomerSearchPage: actions.changeCustomerSearchPage,
    onAddCustomer: actions.addCustomer,
    onRemoveCustomer: actions.removeCustomer,
    onChangeCustomersPage: actions.changeCustomersPage,

    onSearchRooms: actions.searchRooms,
    onChangeRoomSearchText: actions.changeRoomSearchText,
    onChangeRoomSearchPage: actions.changeRoomSearchPage,
    onAddRoom: actions.addRoom,
    onRemoveRoom: actions.removeRoom,
    onChangeRoomsPage: actions.changeRoomsPage,

    onChangeRoomDiscount: actions.changeRoomDiscount,
    onChangeFacilityDiscount: actions.changeFacilityDiscount,
    onChangeCateringDiscount: actions.changeCateringDiscount,

    onChangeStartDate: actions.changeStartDate,
    onChangeEndDate: actions.changeEndDate,
    onChangeIsActive: actions.toggleIsActive,
    onSave: actions.submitSave,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(withTranslation("discount_management")(DiscountEdit)))

import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'
import * as helpers from '../../helpers/user'

import { BOOKING, FORMAT } from '../../constants/models'

const { LIST_BY } = BOOKING
const { DATE } = FORMAT

const getReducer = ({users}) => users.edit

export const getUserId = createSelector(
  [getReducer],
  ({data: {id}}) => id
)

export const getFirstName = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'first_name', "")
)

export const getLastName = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'last_name', "")
)

export const getEmail = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'email', "")
)

export const getCustomerNo = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'customer_id')
)

export const getCompany = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'company')
)

export const getStreet = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'street')
)

export const getHouseNumber = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'house_nbr')
)

export const getPostCode = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'post_code')
)

export const getCity = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'city')
)

export const getCountry = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'country')
)

export const getPhone = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'phone')
)

export const getAddition = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'addition')
)

export const isAdmin = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'is_admin')
)

export const isActive = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'is_active')
)

export const isLongTermCustomer = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'is_long_term_customer')
)

export const isCompanyCustomer = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'is_belong_to_company')
)

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const adAccepted = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'ad_accepted')
)

export const isSubmitting = createSelector(
  [getReducer],
  ({isSubmitting}) => isSubmitting
)


import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Tabs, Grid, FormControl, InputLabel, Select, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckBoxLabel from '../../../components/Form/CheckBoxLabel'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import LoadingIcon from 'mdi-react/LoadingIcon'
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import {withTranslation} from 'react-i18next'
import { sortBy, get, cloneDeep, pick } from 'lodash'
import { timeslotsToBinary, convertTimeLineToBinary, timeslotsToTimeline, binaryToTimeline } from '../../../helpers/helpers'
import { getListRoomsBooked, updateBookingRoom, updateBookingCaterings, updateBookingExtraFacilities } from '../../../actions/booking'
const useStyles = makeStyles((theme) => ({
  scrollContent: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      position: 'relative',
      top: '-210px',
    }
  },
  dayDate: {
    padding: '0 1px',
    textAlign: 'center'
  },
  formControl: {
    width: '100%',
    // minWidth: '100px',
    '& .MuiInputLabel-outlined': {
      top: '-12px'
    }
  },
  formField: {
    borderRadius: '15px',
    width: '100%',
    margin: '8px -',
    // minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '15px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '15px'
    },
    '& .MuiInputBase-input': {
      padding: '5px 10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-2px'
    },

  },
  inputWeekLabel: {
    fontSize: '14px'
  },
  day: {
    margin: '5px 0'
  },
  date: {
    margin: '5px 0',
    marginBottom: '20px'
  },
  currentDate: {
    backgroundColor: '#cb4c4d',
    color: 'white',
    borderRadius: '50%',
    padding: '2px 0',
    width: '30px',
    margin: '0 auto',
    marginBottom: '2px'
  },
  chooseWeekContent: {
    textAlign: 'center',
    padding: '5px 0px'
  },
  typoraphyMonthYear: {
    padding: '5px 0',
    marginBottom: '10px'
  },
  checkTableRow: {
    padding: '0 40px'
  },
  sideBar: {
    textAlign: 'center'
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'center'
  },
  checkbox: {
    marginBottom: '3px !important'
  },
  hoursToBooking: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: "3px",
    height: "20px"
  },
  listTime: {
    marginTop: '25px'
  },
  nextWeek: {
    position: 'relative',
    top: '-170px',
    cursor: 'pointer'
  }
}))

const getAllDateWeek = (startDate) => {
  const weekStart = startDate.clone().startOf('isoWeek')
  // const weekEnd = startDate.clone().endOf('isoWeek')
  let days = []
  for (var i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days'))
  }
  return days
}

const RenderDayOfWeek = (props) => {
  const { selectingRoom, changeWeek, updateBookingRoom, bookingSession,
    updateBookingCaterings, updateBookingExtraFacilities
  } = props
  const classes = useStyles()
  const [startDateOfWeek, setStartDateOfWeek] = useState(moment(get(selectingRoom, '[0]', {}).date))

  useEffect(() => {
    setStartDateOfWeek(moment(get(selectingRoom, '[0]', {}).date))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectingRoom])

  const days = ['Mon', 'Tue', 'Web', 'Thu', 'Fri', 'Sat', 'Sun']
  const listDateOfWeek = getAllDateWeek(startDateOfWeek)

  const handleNextWeek = () => {
    setStartDateOfWeek(moment(startDateOfWeek.format()).add(7, 'days'))
    changeWeek(moment(startDateOfWeek.format()).add(7, 'days'))
  }

  const handlePreviousWeek = () => {
    setStartDateOfWeek(moment(startDateOfWeek.format()).subtract(7, 'days'))
    changeWeek(moment(startDateOfWeek.format()).subtract(7, 'days'))
  }

  const [currentSelectData, setCurrentSelectData] = useState([])

  const handleChooseSlot = (date, currentTimeline, time) => {
    let chooseSlotsTemp = selectingRoom.find(item => item.date === date.format('YYYY-MM-DD'))
    let temp = get(chooseSlotsTemp, 'time', null) ? chooseSlotsTemp.time : currentTimeline
    let tempArray = temp.split('')
    if (temp.substring(Number(time) - 8, Number(time) - 7) === '1') {
      tempArray[Number(time) - 8] = '0'
    } else {
      tempArray[Number(time) - 8] = '1'
    }
    chooseSlotsTemp = chooseSlotsTemp || selectingRoom[selectingRoom.length - 1]
    const data = [{
      ...pick(chooseSlotsTemp, 'item_id'),
      date: date.format('YYYY-MM-DD'),
      time: parseInt(tempArray.toString().split(',').join(''), 2),
      quantity: 1
    }]
    updateBookingRoom(data)
    let listCateringsSelecting = get(bookingSession, 'items', []).filter(item => item.item_type === 3)
    let listFacilitiesSelecting = get(bookingSession, 'items', []).filter(item => item.item_type === 2)
    if(listFacilitiesSelecting.length > 0) {
      const listFacilitiesOfDate = listFacilitiesSelecting.filter(item => item.date == date.format('YYYY-MM-DD'))
      // console.log(date.format('YYYY-MM-DD'))
      // console.log(listFacilitiesSelecting)
      // console.log(listFacilitiesOfDate)
      updateBookingExtraFacilities(listFacilitiesOfDate.map(item => ({
        ...pick(item, ['item_id', 'date', 'quantity']),
        time: parseInt(tempArray.toString().split(',').join(''), 2),
      })))
    }
    if(listCateringsSelecting.length > 0) {
      const listCateringsOfDate = listCateringsSelecting.filter(item => item.date == date.format('YYYY-MM-DD'))
      updateBookingCaterings(listCateringsOfDate.map(item => ({
        ...pick(item, ['item_id', 'date', 'quantity']),
        time: parseInt(tempArray.toString().split(',').join(''), 2),
      })))
    }
  }

  const [shiftHoverData, setShiftHoverData] = useState([])

  const handleShiftHover = (date, currentTimeline, time) => {
    setShiftHoverData([])
    let selectedRoomBefore = [...selectingRoom, ...currentSelectData]
    selectedRoomBefore = sortBy(selectedRoomBefore, ['date'])
    console.log(selectedRoomBefore)
    if (selectedRoomBefore.length > 0) {
      let startSlots = cloneDeep(selectedRoomBefore)[selectedRoomBefore.length - 1]
      startSlots.time = binaryToTimeline(get(startSlots, 'time', '0'))[1]
      let tempData = cloneDeep([startSlots])
      if(date.unix() < moment(tempData[0].date).unix()) return
      tempData.push({
        date: date.format('YYYY-MM-DD'),
        time: time
      })
      tempData = sortBy(tempData, ['date'])
      let collectData = []
      if (moment(tempData[1].date).dates() - moment(tempData[0].date).dates() === 0) {
        tempData.sort((a, b) => {
          var timeA = Number(a.time)
          var timeB = Number(b.time)
          if (timeA < timeB) {
            return -1
          }
          if (timeA > timeB) {
            return 1
          }
          return 0
        })
        collectData.push({
          date: tempData[0].date,
          time: convertTimeLineToBinary(Number(tempData[0].time), Number(Number(tempData[1].time)))
        })
        setShiftHoverData(collectData)
      } else if(moment(tempData[1].date).dates() - moment(tempData[0].date).dates() > 0) {
        const duration = moment(tempData[1].date).dates() - moment(tempData[0].date).dates()
        console.log(duration)
        for (let i = 0; i <= duration; i++) {
          if (i === 0) {
            collectData.push({
              date: tempData[0].date,
              time: convertTimeLineToBinary(Number(tempData[0].time) - 1, 22)
            })
          } else if (i === duration) {
            collectData.push({
              date: tempData[1].date,
              time: convertTimeLineToBinary(8, Number(Number(tempData[1].time) + 1))
            })
          } else {
            collectData.push({
              date: moment(tempData[0].date).add(i, 'days'),
              time: convertTimeLineToBinary(8, 22)
            })
          }
        }
        setShiftHoverData(collectData)
      }
    }
  }

  const handleShiftClick = (date, currentTimeline, time) => {
    setCurrentSelectData(shiftHoverData)

  }
  return (
    <>
      <div className={classes.nextWeek} >
        <ChevronLeftIcon
          onClick={() => handlePreviousWeek()}
        />
      </div>
      {listDateOfWeek.map((date, index) => {
        const selecting = selectingRoom.find(item => moment(item.date).date() === date.date())
        const booked = get(props, 'listRoomsBooked', []).find(item => moment(item.date).date() === date.date())
        const currentSelected = currentSelectData.find(item => moment(item.date).date() === date.date())
        const shiftHoverSelect = shiftHoverData.find(item => moment(item.date).date() === date.date())
        const selectingTimeline = timeslotsToBinary(get(selecting, 'timeslots', 0))
        const bookedTimeline = timeslotsToBinary(get(booked, 'booked_slots_int', 0))
        return (
          <div key={index} className={classes.dayDate}>
            <p className={classes.day}>{days[index]}</p>
            <p className={`${classes.day} ${(date.date() === new Date().getDate()) && classes.currentDate}`}>{date.date()}</p>
            {['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21'].map((time, index) => {
              const selected = Number(selectingTimeline[index]) === 1
              const booked = Number(bookedTimeline[index]) === 1
              const notAvailable = false
              const shiftHover = Number(get(shiftHoverSelect, 'time', '0')[index]) === 1
              const current = Number(get(currentSelected, 'time', '0')[index]) === 1
              let backgroundColor = 'white'
              if (selected) {
                backgroundColor = '#70bbfd'
              }
              if (booked) {
                backgroundColor = '#cb4b4d'
              }
              if (notAvailable) {
                backgroundColor = 'rgb(118, 121, 132)'
              }
              if (shiftHover) {
                backgroundColor = '#a1c8ea'
              }
              return (
                <CheckBoxLabel
                  key={index}
                  id={`${date.date()}-${time}`}
                  label=''
                  width='42px'
                  height='20px'
                  disabled={notAvailable || booked}
                  backgroundColor={backgroundColor}
                  className={classes.checkbox}
                  onChange={() => handleChooseSlot(date, selectingTimeline, time)}
                  onShiftHover={() => {
                    handleShiftHover(date, selectingTimeline, time)
                  }}
                  onShiftClick={() => handleShiftClick(date, selectingTimeline, time)}
                  checked={selected}
                />
              )
            })}
          </div>
        )
      })}
      <div className={classes.nextWeek} >
        <ChevronRightIcon
          onClick={() => handleNextWeek()}
        />
      </div>
    </>
  )
}

RenderDayOfWeek.propTypes = {
  selectingRoom: PropTypes.array,
  changeWeek: PropTypes.func,
  listRoomsBooked: PropTypes.array,
  bookingSession: PropTypes.object,
  updateBookingRoom: PropTypes.func,
  updateBookingCaterings: PropTypes.func,
  updateBookingExtraFacilities: PropTypes.func,
}

const RoomTimetable = props => {
  const {getListRoomsBooked, listRoomsBooked, updateBookingRoom, isUpdatingBookingRoom,
    bookingSession, updateBookingCaterings, updateBookingExtraFacilities, history, t, i18n
  } = props
  moment.locale(i18n.language === 'en' ? 'en-gb' : 'de')
  const classes = useStyles()
  const roomBooked = get(bookingSession, 'items', []).filter(item => item.item_type === 1)

  const [startDateOfWeek, setStartDateOfWeek] = useState(moment())
  const [listBooked, setListbooked] = useState([])

  useEffect(() => {
    let roomBookedSorted = sortBy(roomBooked, ['date'])
    setStartDateOfWeek(moment(get(roomBookedSorted, '[0]', {}).date))
    const weekStart = moment(get(roomBookedSorted, '[0]', {}).date).clone().startOf('isoWeek').format('YYYY-MM-DD')
    const weekEnd = moment(get(roomBookedSorted, '[0]', {}).date).clone().endOf('isoWeek').format('YYYY-MM-DD')

    getListRoomsBooked({
      room_id: get(roomBookedSorted, '[0]', {}).item_id,
      date_range: [
        weekStart,
        weekEnd
      ]
    })
    setListbooked(roomBookedSorted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingSession])

  const handleChangeWeek = date => {
    setStartDateOfWeek(date)
    const weekStart = date.clone().startOf('isoWeek').format('YYYY-MM-DD')
    const weekEnd = date.clone().endOf('isoWeek').format('YYYY-MM-DD')
    getListRoomsBooked({
      room_id: get(roomBooked, '[0]', {}).item_id,
      date_range: [
        weekStart,
        weekEnd
      ]
    })
  }

  return (
    <div>
      <Grid container style={{ userSelect: 'none' }}>
        {isUpdatingBookingRoom ? <div className="loading-in-small-area"><LoadingIcon /></div> : ''}
        <Grid item md={2} xs={2} className={classes.chooseWeekContent}>
          <Typography className={classes.typoraphyMonthYear}>{startDateOfWeek.format('MMM, YYYY')}</Typography>
          <div className={classes.listTime}>
            {['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21'].map((item, index) => {
              return (
                <Typography key={index} align="right" className={classes.hoursToBooking}>{item}</Typography>
              )
            })}

          </div>
        </Grid>
        <Grid item md={10} xs={10}>
          <div
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            aria-label="scrollable force tabs example"
            className={classes.scrollContent}

          >   
            <RenderDayOfWeek 
              classes={classes}
              selectingRoom={listBooked}
              changeWeek={date => handleChangeWeek(date)}
              listRoomsBooked={listRoomsBooked}
              updateBookingRoom={updateBookingRoom}
              bookingSession={bookingSession}
              updateBookingCaterings={updateBookingCaterings}
              updateBookingExtraFacilities={updateBookingExtraFacilities}
              t={t}
            />
          </div>
        </Grid>
        <Grid container className={classes.checkTableRow}>
        </Grid>
      </Grid>
    </div>
  )
}

const mapState = ({ bookingReducer }) => ({
  isGettingListRoomsBooked: bookingReducer.isGettingListRoomsBooked,
  listRoomsBooked: bookingReducer.listRoomsBooked,
  isUpdatingBookingRoom: bookingReducer.isUpdatingBookingRoom,
  bookingSession: bookingReducer.bookingSession
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getListRoomsBooked,
    updateBookingRoom,
    updateBookingCaterings,
    updateBookingExtraFacilities
  },
  dispatch
)

RoomTimetable.propTypes = {
  roomBooked: PropTypes.array,
  isGettingListRoomsBooked: PropTypes.bool,
  listRoomsBooked: PropTypes.array,
  getListRoomsBooked: PropTypes.array,
  isUpdatingBookingRoom: PropTypes.bool,
  bookingSession: PropTypes.object,
  updateBookingRoom: PropTypes.func,
  updateBookingCaterings: PropTypes.func,
  updateBookingExtraFacilities: PropTypes.func,
}

export default withRouter(connect(mapState, mapDispatch)( withTranslation('common') (RoomTimetable)))
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, TextField, Toolbar, Button } from '@material-ui/core'
import Image from '../../../assets/images/Stockholm_1.jpg'
import * as paths from '../../../constants/paths.constants'
import {history} from '../../../config/store' 
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import ASSETS from '../../../constants/assets'

const useStyles = makeStyles((theme) => {
  return ({
    logo: {
      marginTop: '50px',
      // marginBottom: '50px',
      color: theme.colorAccent,
      width: '20%',
      cursor: 'pointer',
      position: 'absolute',
      top: '0',
      left: '40%',
      // width: '300px',
    },
    background: {
      minHeight: '230px',
      height: '25%',
      backgroundImage: `url(${Image})`,
      '& img': {
        // height: '55%',
        width: '20%'
      },
      backgroundSize: '100%',
      backgroundPositionY: '455px',
      opacity: 0.2
    },
    title: {
      marginTop: '50px'
    },
    checkIcon: {
      marginTop: '50px',
      '& svg': {
        width: '50px',
        height: '50px',
        color: theme.colorAccent,
      }
    },
    formRoot: {
      textAlign: 'center',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 300,
      },
    },
    formField: {
      '& .MuiInputBase-root': {
        // backgroundColor: 'white',
        borderRadius: '15px'
      }
    },
    fontBold: {
      fontWeight: 'bold'
    },
    textWelcome: {
      marginTop: '50px',
      padding: '20px 0',
      color: theme.colorGray
    },
    verifySuccessText: {
      padding: '0px 0 10px',
      color: theme.colorGray
    },
    titleSubText: {
      color: theme.colorGray,
      padding: '0 20%'
    },
    buttonGroup: {
      justifyContent: 'center',
      '& button': {
        width: 300,
        padding: '10px 0',
        margin: '25px'
      }
    },
    forgotPassword: {
      cursor: 'pointer'
    },
    registerContent: {
      marginTop: '30px'
    },
    registerLink: {
      cursor: 'pointer'
    }
  })
})

const Verify = props => {
  const classes = useStyles()
  const { verifyAccount, isVerified } = props
  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid item md={12} sm={12} xs={12}>
        <Typography variant='h2' align='center' className={classes.background}>
        </Typography>
        <img src={ASSETS.LOGO} className={classes.logo} onClick={() => history.push(paths.HomePage)}
          alt=""
        />
        {!isVerified ?
          <>
            <Typography variant='h5' align='center' className={classes.textWelcome}>
              Account Verification
            </Typography>
            <Typography variant='body2' align='center' className={classes.titleSubText}>
              Please enter the verification code just sent to your registered email to complete activating your account.
            </Typography>
            <form className={classes.formRoot} noValidate autoComplete="off">
              <Grid container >
                <Grid item xs={12}>
                  <TextField
                    // error
                    className={classes.formField}
                    // id="outlined-error"
                    id="standard-basic"
                    label="Verification code"
                  />
                </Grid>
                <Grid item xs={12} className={classes.registerContent}>
                  <Typography variant='h6' className="cursor-pointer">
                    Resend code
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Toolbar className={classes.buttonGroup}>
                    <Button autoFocus color="primary" variant="contained" onClick={() => verifyAccount()} >
                      Verify
                    </Button>
                  </Toolbar>
                </Grid>
              </Grid>
            </form>
          </> :
          <>
            <Typography variant='h5' align='center' className={classes.checkIcon}>
              <CheckCircleIcon />
            </Typography>
            <Typography variant='h5' align='center' className={classes.verifySuccessText}>
              Successfully Verified
            </Typography>
            <Typography variant='body2' align='center' className={classes.titleSubText}>
              Your account is now fully activated! <br/>
              You can log in ti search rooms and make booking requests!
            </Typography>
            <Grid item xs={12}>
              <Toolbar className={classes.buttonGroup}>
                <Button autoFocus color="primary" variant="contained" onClick={() => history.push(paths.LoginPage)} >
                  Go to SIGN IN
                </Button>
              </Toolbar>
            </Grid>
          </>
        }
      </Grid>
      {/* <Grid item md={8} sm={8} xs={8} className={classes.background}>
        <img src={Image}  alt=""/>
      </Grid> */}
    </Grid>
  )
}

Verify.propTypes = {
  verifyAccount: PropTypes.func,
  isVerified: PropTypes.bool
}

export default Verify
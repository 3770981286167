import { bind } from 'decko'
import BaseApi from './baseApi'
import {
  UploadSingleImage
} from '../../constants/endpoints.constants'
export default class ImageApi extends BaseApi {
  @bind
  async uploadSingleImage(data) {
    console.log(data)
    return await this.http.post(UploadSingleImage(), data, {headers: {"Content-Type": "multipart/form-data"}});
  }
}

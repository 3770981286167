import { ROOM } from '../constants/models'
import moment from 'moment'
import * as _ from 'lodash'


export const getPublicTag = (isPublic) => {
  if (isPublic) {
    return { color: "green", name: "Yes" }
  }
  return { color: "red", name: "No" }
}

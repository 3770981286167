import uuid from 'uuid/v1'
import {createSelector} from 'reselect'
import * as _ from 'lodash'
import { colors } from '@material-ui/core'
import {
  timeslotsToTimeline,
  convertNumToMonthName,
  convert24To12HoursClock,
  s2c,
  getDisplayedPrice
} from '../helpers/helpers'
import {getCcySymbol, getUnit} from './public'
import Image from '../assets/images/Kopenhagen_1-1.jpg'

export const rooms = ({roomReducer}) => roomReducer.listRooms

// transform room to room row as table schema
const _transformRoomToTableRow = (room) => ({
  id: _.get(room, 'id'),
  roomName: _.get(room, 'name'),
  facility: _.get(room, 'permanent_facilities', []).map(pf => pf.name).join(', '),
  roomType: _.get(room, 'room_type'),
  capacity: _.get(room, 'max_attendances'),
  size: _.get(room, 'size')
})

export const roomRows = createSelector(
  [rooms],
  (rooms) => rooms.map(u => _transformRoomToTableRow(u))
)

const _get_room_pricings = (room, ccy, unit) => _.get(room, 'pricings', [])
  .map(p => ({
    "hour": timeslotsToTimeline(p.apply_range_int).map(
      h => convert24To12HoursClock(h)).join(' - '),
    "price": getDisplayedPrice(p.price, ccy, unit)
  }))

const _transformToDisplayedRoom = (room, ccy, unit) => ({
  id: _.get(room, 'id'),
  name: _.get(room, 'name'),
  facilities: _.get(room, 'permanent_facilities', []).map(pf => pf.name).join(', '),
  roomType: _.get(room, 'room_type'),
  capacity: _.get(room, 'max_attendances'),
  size: _.get(room, 'size'),
  pricings: _get_room_pricings(room, ccy, unit),
  description: _.get(room, 'description', 'N/A'),
  mainImageUrl: _.get(room, 'images[0].url', Image),
})

export const roomInfo = ({roomReducer, publicReducer}) => 
  _transformToDisplayedRoom(
    roomReducer.roomInfo, 
    getCcySymbol({publicReducer}),
    getUnit({publicReducer})
  )

const _get_booked_rate = ({roomReducer}) => Number(parseFloat(
  _.get(roomReducer, 'roomAvailAnalytics.filled_rate')))*100

export const roomAvailabilityAnalytics = createSelector(
  [_get_booked_rate],
  (booked) => (
    [
      {
        id: uuid(),
        label: 'Booked',
        value: booked.toFixed(2),
        color: colors.green['A700']
      },
      {
        id: uuid(),
        label: 'Available',
        value: (100 - booked).toFixed(2),
        color: colors.amber[700]
      }
    ]
  )
)

const _transformToTotalBookingChart = (bookingAnalyticsResult) => ({
  thisYear: {
    data: bookingAnalyticsResult.map(res => res.count),
    labels: bookingAnalyticsResult.map(res => convertNumToMonthName(res.month)),
  }
})

export const roomBookingAnalytics = ({roomReducer}) => 
  _transformToTotalBookingChart(_.get(roomReducer, 'roomBookingAnalytics.results', []))

export const isFetchingRoomDetail = ({roomReducer}) => 
  _.get(roomReducer, 'isFetchingRoomAvailAnalytics', false) 
  && _.get(roomReducer, 'isFetchingRoomBookingAnalytics', false)
  && _.get(roomReducer, 'isGettingRoomInfo', false)


// Room types
export const isFetchingRoomTypeDetail = ({roomReducer}) => _.get(roomReducer, 'isGettingRoomTypeInfo', false)
export const roomTypes = ({roomReducer}) => roomReducer.listRoomTypes.map(rt => s2c(rt))

// transform room to room row as table schema
const _transformRoomTypeToTableRow = (roomType) => ({
  id: _.get(roomType, 'id'),
  name: _.get(roomType, 'roomType'),
  num: _.get(roomType, 'rooms', []).length,
  description: _.get(roomType, 'description'),
})

export const roomTypeRows = createSelector(
  [roomTypes],
  (roomTypes) => roomTypes.map(r => _transformRoomTypeToTableRow(r))
)

const _transformRoomsInRoomTypeToTableRow = (room) => ({
  id: _.get(room, 'id'),
  name: _.get(room, 'name'),
  capacity: _.get(room, 'max_attendances'),
})

export const getRoomsInRoomType = ({roomReducer}) => {
  return _.get(roomReducer, 'roomTypeInfo.rooms', []).map(r => _transformRoomsInRoomTypeToTableRow(r))
}

export const getRoomsReadyToAdd = ({roomReducer}) => {
  return _.get(roomReducer, 'roomsReadyToAdd', []).map(r => _transformRoomsInRoomTypeToTableRow(r))
}

const roomTypeInfo = ({roomReducer}) => _.get(roomReducer, 'roomTypeInfo')
const ccySymbol = ({publicReducer}) =>  getCcySymbol({publicReducer})
const unit = ({publicReducer}) =>  getUnit({publicReducer})

export const getRoomTypeInfo = createSelector(
  [
    roomTypeInfo,
    ccySymbol,
    unit
  ],
  (
    roomTypeInfo, 
    ccySymbol,
    unit
  ) => ({
    ...roomTypeInfo,
    rooms: _.get(roomTypeInfo, 'rooms', []).map(r => _transformToDisplayedRoom(
      r,
      ccySymbol,
      unit
    )),
    mainImageUrl: _.get(roomTypeInfo, 'images[0].url', Image)
  })
)

import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Button, Pagination } from 'antd'
import MdButton from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Table, Tag } from 'antd';
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import DiscountEditor from '../../components/Discount/DiscountEditor'
import * as selectors from "../../selectors/discounts/new";
import * as actions from "../../actions/discounts/new";


const View = props => {
  let match = useRouteMatch();
  const {t} = props
  
  return (
    <Paper>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t("NEW DISCOUNT")}
        subTitle={t("Create a new discount")}
      />

      <div style={{ padding: 24 }}>
        <DiscountEditor {...props} />

        <Grid container justify="flex-end">
          <Grid item>
            <MdButton 
              color="primary" 
              variant="contained"
              onClick={props.onCreate}
              disabled={props.isCreating}
            >
              {props.isCreating && <CircularProgress size={16}/>}
              {props.isCreating ? t("Creating") + '...' : t("Create")}
            </MdButton>
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}

class DiscountNewContainer extends Component {
  componentDidMount() {
    this.props.onSearchCustomers()
    this.props.onSearchRooms()
  }

  render() {
    return (
      <View {...this.props}  />
    )
  }
}


const mapState = (state) => ({
  name: selectors.getName(state),
  description: selectors.getDescription(state),
  selectableApplyToes: selectors.getSelectableApplyToes(state),
  selectedApplyTo: selectors.getSelectedApplyTo(state),
  selectableApplyTypes: selectors.getSelectableApplyTypes(state),
  selectedApplyType: selectors.getSelectedApplyType(state),
  
  isSelectedCustomersVisible: selectors.isSelectedCustomersVisiable(state),
  selectableCustomers: selectors.getSelectableCustomers(state),
  selectedCustomers: selectors.getSelectedCustomers(state),
  customerSearchPagination: selectors.getSearchCustomerPagination(state),
  customerSearchText: selectors.getSearchCustomerText(state),
  isSearchingCustomer: selectors.isSearchingCustomer(state),

  selectedRooms: selectors.getSelectedRooms(state),
  isSelectedRoomsVisible: selectors.isSelectedRoomsVisible(state),
  selectableRooms: selectors.getSelectableRooms(state),
  roomSearchText: selectors.getRoomSearchText(state),
  roomSearchPagination: selectors.getSearchRoomPagination(state),
  isSearchingRooms: selectors.isSearchingRooms(state),

  roomDiscount: selectors.getRoomDiscount(state),
  facilityDiscount: selectors.getFacilityDiscount(state),
  cateringDiscount: selectors.getCateringDiscount(state),

  startDate: selectors.getStartDate(state),
  endDate: selectors.getEndDate(state),
  isActive: selectors.isActive(state),

  loadingSuggestCustomers: selectors.isLoadingSuggestCustomers(state),
  suggestedCustomers: selectors.getSuggestCustomers(state),

  isCreating: selectors.isCreating(state),
  validation: selectors.getValidation(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    onChangeName: actions.changeName,
    onChangeDescription: actions.changeDescription,
    onChangeSelectedApplyTo: actions.changeSelectedApplyTo,
    onChangeSelectedApplyType: actions.changeSelectedApplyType,
    onSearchCustomers: actions.searchCustomers,
    onChangeCustomerSearchText: actions.changeCustomerSearchText,
    onChangeCustomerSearchPage: actions.changeCustomerSearchPage,
    onAddCustomer: actions.addCustomer,
    onRemoveCustomer: actions.removeCustomer,

    onSearchRooms: actions.searchRooms,
    onChangeRoomSearchText: actions.changeRoomSearchText,
    onChangeRoomSearchPage: actions.changeRoomSearchPage,
    onAddRoom: actions.addRoom,
    onRemoveRoom: actions.removeRoom,

    onChangeRoomDiscount: actions.changeRoomDiscount,
    onChangeFacilityDiscount: actions.changeFacilityDiscount,
    onChangeCateringDiscount: actions.changeCateringDiscount,

    onChangeStartDate: actions.changeStartDate,
    onChangeEndDate: actions.changeEndDate,
    onChangeIsActive: actions.toggleIsActive,

    onCreate: actions.submitCreate,
    onClosePickCustomer: actions.loadSuggestCustomers,
    onAddSuggestedCustomers: actions.addSuggestedCustomers,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(withTranslation("discount_management")(DiscountNewContainer)))

import { takeLatest, put, call, all, select } from 'redux-saga/effects'

import newSaga from './new'
import listSaga from './list'
import detailSaga from './detail'
import editSaga from './edit'


function discountSaga(deps) {
  return function* saga() {
    yield all([
      ...newSaga(deps),
      ...listSaga(deps),
      ...detailSaga(deps),
      ...editSaga(deps),
    ])
  }
}

export default discountSaga

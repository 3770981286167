import { BOOKING, FORMAT } from '../constants/models'
import moment from 'moment'
import * as _ from 'lodash'
import numeral from 'numeral'

numeral.register('locale', 'de', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  currency: {
    symbol: '€'
  }
})
numeral.locale('de')

const { DATE } = FORMAT
const { PAYMENT_STATUS, BOOKING_STATUS, PAYMENT_METHOD } = BOOKING

export const getPaymentStatusTag = (status) => {
  switch (status) {
    case PAYMENT_STATUS.OUTSTANDING:
      return {color: "orange", name: "OUTSTANDING"}
    case PAYMENT_STATUS.DONE:
      return {color: "green", name: "DONE"}
    case PAYMENT_STATUS.FAILED:
      return {color: "red", name: "FAILED"}
    case PAYMENT_STATUS.CANCELLED:
      return {color: "red", name: "CANCELLED"}
    case PAYMENT_STATUS.PARTIAL_REFUND:
      return {color: "blue", name: "PARTIAL_REFUND"}
    case PAYMENT_STATUS.FULL_REFUND:
      return {color: "blue", name: "FULL_REFUND"}
    default:
      return {color: "orange", name: "NULL"}
  }
}

export const getBookingStatusTag = (status) => {
  switch (status) {
    case BOOKING_STATUS.SCHEDULED:
      return {color: "green", name: "SCHEDULED"}
    case BOOKING_STATUS.CANCELLED:
      return {color: "red", name: "CANCELLED"}
    default:
      return {color: "orange", name: "NULL"}
  }
}

export const getPaymentMethodTag = (method) => {
  switch (method) {
    case PAYMENT_METHOD.STRIPE:
      return {color: "blue", name: "CREDIT CARD"}
    case PAYMENT_METHOD.INVOICE:
      return {color: "green", name: "INVOICE"}
    default:
      return {color: "orange", name: "OTHER"}
  }
}

export const slot2Time = (slots) => {
  const binary = _.padStart(slots.toString(2), 14, '0')
  const firstTime = binary.indexOf('1') + 8
  const lastTime = binary.lastIndexOf('1') + 9
  return [`${_.padStart(firstTime, 2, '0')}:00`, `${_.padStart(lastTime, 2, '0')}:00`]
}

export const formatMoney = (price) => numeral(price / 100 || 0).format('0,0.00 $')

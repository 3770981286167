import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import LoginComponent from '../../../components/Auth/Login'
import * as authActions from '../../../actions/auth'
class Login extends Component {

  login = () => {
    const {login} = this.props
    login()
  }

  render() {
    return (
      <div>
        <LoginComponent 
          login={this.login}
        />
      </div>
    )
  }
}

const mapState = state => ({
  
})

const mapDispatch = dispatch => ({
  login() {
    dispatch(authActions.login())
  }
})

Login.propTypes = {
  login: PropTypes.func
}

export default connect(mapState, mapDispatch) (Login)
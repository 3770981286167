import {createSelector} from 'reselect'
import { USER } from '../../constants/models'
import moment from 'moment'
import * as _ from 'lodash'
import * as helpers from '../../helpers/user'

const { LIST_BY, ROLE } = USER

const getReducer = ({users}) => users.list

export const currentIsAdmin = ({publicReducer: {profile}}) => _.get(profile, 'is_admin')

export const getSelectableTabs = () => {
  return [
    {id: LIST_BY.STAFF, name: "Staff"},
    {id: LIST_BY.CUSTOMER, name: "Customer"}
  ]
}

export const getSelectedTab = createSelector(
  [getReducer],
  ({selectedTab}) => {
    return selectedTab
  }
)

export const getPage = createSelector(
  [getReducer],
  ({page}) => {
    return page
  }
)

export const getPageSize = createSelector(
  [getReducer],
  ({pageSize}) => {
    return pageSize
  }
)

export const getTotal = createSelector(
  [getReducer],
  ({total}) => {
    return total
  }
)

export const getItems = createSelector(
  [getReducer],
  ({items}) => {
    return _.map(items, it => {
      return {
        id: it.id,
        fullName: `${it.first_name} ${it.last_name}`,
        email: it.email,
        role: helpers.getRoleTag(helpers.getRole(it)),
        status: helpers.getStatusTag(_.get(it, "is_active")),
        term: helpers.getTermTag(_.get(it, "is_long_term_customer")),
        customerNo: _.get(it, 'customer_id'),
        company: _.get(it, 'company'),
        customerType: helpers.getCustomerTypeTag(_.get(it, 'is_belong_to_company')),
        ad: helpers.getAdTag(_.get(it, "ad_accepted"), false),
      }
    })
  }
)

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const getSearchText = createSelector(
  [getReducer],
  ({searchText}) => searchText
)

export const isRetrievingExportToken = createSelector(
  [getReducer],
  ({ exportUser }) => _.get(exportUser, 'retrieving', false)
)

export const getExportToken = createSelector(
  [getReducer],
  ({ exportUser }) => _.get(exportUser, 'token')
)

export const generateReqPayload = createSelector(
  [getReducer],
  ({page, pageSize, selectedTab, searchText}) => ({
    filter: selectedTab == LIST_BY.STAFF? "staff": "customer",
    ordering: "-created_at",
    page, 
    page_size: pageSize,
    search: searchText || ""
  })
)

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import configStore, { history } from './config/store'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import './index.less'
import 'antd/dist/antd.css'
import './assets/styles/app.scss'
import { ToastContainer } from 'react-toastify'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import themeDefault from './theme'
import { deDE } from '@material-ui/core/locale'
import { enUS } from '@material-ui/core/locale'
import Router from './Routes'
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next'
import './config/i18next' 

import './mixins/chartjs'
import './mixins/moment'
import './mixins/validate'
import './mixins/prismjs'

import './mock'

const store = configStore()

const langTheme = {
  en: enUS,
  de: deDE
}

const theme = createMuiTheme(themeDefault, langTheme[i18next.language])

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <Router />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            style={{ zIndex: 19999 }}
          />
        </ThemeProvider>
      </ConnectedRouter>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister() 

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGIN_NOT_REDIRECT = 'LOGIN_NOT_REDIRECT'

export const REGISTER = 'REGISTER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'

export const ACTIVATE_USER = 'ACTIVATE_USER'
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS'
export const ACTIVATE_USER_FAIL = 'ACTIVATE_USER_FAIL'

export const RESEND_ACTIVATION_CODE = 'RESEND_ACTIVATION_CODE'
export const RESEND_ACTIVATION_CODE_SUCCESS = 'RESEND_ACTIVATION_CODE_SUCCESS'
export const RESEND_ACTIVATION_CODE_FAIL = 'RESEND_ACTIVATION_CODE_FAIL'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const LOGOUT = 'LOGOUT'

export const CHECK_TOKEN_EXPIRATION = 'CHECK_TOKEN_EXPIRATION'

export const SESSION_LOGIN = 'SESSION_LOGIN'
export const SESSION_LOGOUT = 'SESSION_LOGOUT'

export const login = () => dispatch =>
  dispatch({
    type: SESSION_LOGIN
  })

export const logout = () => dispatch =>
  dispatch({
    type: SESSION_LOGOUT
  })
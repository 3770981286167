import { bind } from 'decko'
import BaseApi from './baseApi';
import {GetBookingSummary, SearchAvailableRoom, CreateBookingSession, AttachSessionCustomer,
  GetListRoomsBooked, SearchExtraFacilities, AddExtraFacilities, SearchAvailableCaterings,
  AddCatering, RemoveSessionItems, GetListCaterings, GetListExtraFacilityCategories, 
  ListBookingsByDateRange, UpdateBookingRoom, GetBookingSession, UpdateBookingCaterings, UpdateBookingExtraFacilities,
  LinkSessionPayment, ConfirmBooking, DestroyBookingSession, GetListIncomingBookings, GetListHistoryBookings, UpdateBookingState,
  RetrieveItemPricings, AddSessionItems, ChangeBookingRoomPrice, GetBookingDetail, GetBookingToken, DownloadBookingInvoce,
  DownloadBookingInvoiceHTML

} from '../../constants/endpoints.constants'

import {c2s} from '../../helpers/helpers';

export default class Booking extends BaseApi {
  // the value of `this` is always the object from which getBookingSummay() was referenced.
  @bind
  async getBookingSummary(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(GetBookingSummary(), data);
  }

  @bind
  async searchAvailableRoom(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(SearchAvailableRoom(), data);
  }

  @bind
  async createBookingSession(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(CreateBookingSession(), data);
  }

  @bind
  async attachSessionCustomer(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(AttachSessionCustomer(), c2s(data));
  }

  @bind
  async listBookingsByDateRange(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(ListBookingsByDateRange(), c2s(data))
  }

  @bind
  async getBookingSession(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(GetBookingSession(), data)
  }
  
  @bind
  async getListRoomsBooked(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(GetListRoomsBooked(), data);
  }

  @bind
  async searchExtraFacilities(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(SearchExtraFacilities(), data);
  }

  @bind
  async addExtraFacilities(data) {
    // console.log(data)
    await this.checkAuthenticatedStatus()
    return await this.http.post(AddExtraFacilities(), data);
  }

  @bind
  async searchAvailableCaterings(data) {
    // const {bookingSessionId, ...rest} = data
    await this.checkAuthenticatedStatus()
    return await this.http.post(SearchAvailableCaterings(), data);
  }

  @bind
  async addCatering(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(AddCatering(), data);
  }

  @bind
  async removeSessionItems(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(RemoveSessionItems(), data);
  }

  @bind
  async getListExtraFacilityCategories(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(GetListExtraFacilityCategories(), data);
  }

  @bind
  async getListCaterings(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(GetListCaterings(), data);
  }

  @bind
  async updateBookingRoom(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(UpdateBookingRoom(), data);
  }

  @bind
  async updateBookingCaterings(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(UpdateBookingCaterings(), data);
  }

  @bind
  async updateBookingExtraFacilities(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(UpdateBookingExtraFacilities(), data);
  }

  @bind
  async linkSessionPayment(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(LinkSessionPayment(), data);
  }

  @bind
  async confirmBooking(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(ConfirmBooking(), data);
  }

  @bind
  async destroyBookingSession(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(DestroyBookingSession(), data);
  }

  @bind
  async getListIncomingBookings(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(GetListIncomingBookings(), c2s(data));
  }

  @bind
  async getListHistoryBookings(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(GetListHistoryBookings(), c2s(data));
  }

  @bind
  async updateBookingState(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(UpdateBookingState(), data);
  }

  @bind
  async retrieveItemPricings(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(RetrieveItemPricings(), data);
  }

  @bind
  async addSessionItems(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(AddSessionItems(), data);
  }

  @bind
  async changeBookingRoomPrice(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(ChangeBookingRoomPrice(), data);
  }

  @bind
  async getBookingDetail(id) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(GetBookingDetail(id));
  }

  @bind
  async getBookingToken() {
    await this.checkAuthenticatedStatus()
    return await this.http.post(GetBookingToken());
  }

  @bind
  async downloadBookingInvoce() {
    await this.checkAuthenticatedStatus()
    return await this.http.get(DownloadBookingInvoce());
  }

  @bind
  async downloadBookingInvoiceHTML(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(DownloadBookingInvoiceHTML(), data);
  }

  @bind
  async updateNotes(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post('/booking/internal/update_notes', data)
  }

  @bind
  async cancelBooking(data, lang='en') {
    await this.checkAuthenticatedStatus()
    return await this.http.post(`/booking/internal/cancel_booking?lang=${lang}`, data)
  }

  @bind
  async refundBooking(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post('/booking/internal/refund_booking', data)
  }
}

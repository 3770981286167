import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import BookingTableComponent from './components/BookingTable'
import BookingModal from './components/BookingModal'
import {openSearchAvailableRooms, closeSearchAvailableRooms} from '../../actions/modal'

class BookingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenBookingModal: false
    }
  }

  closeBookingModal = () => {
    // console.log('aaa')
    this.setState({isOpenBookingModal: false})
  }
  
  render() {
    const {isOpenSearchAvailableRoomModal} = this.props
    return (
      <div>
        <BookingTableComponent
          booking={() => this.props.openSearchAvailableRooms()}
        />
        <BookingModal 
          isOpen={isOpenSearchAvailableRoomModal}
          closeModal={() => this.props.closeSearchAvailableRooms()}
        />
      </div>
    )
  }
}

const mapState = ({modalReducer}) => ({
  isOpenSearchAvailableRoomModal: modalReducer.isOpenSearchAvailableRoomModal
})

const mapDispatch = dispatch => bindActionCreators(
  {
    openSearchAvailableRooms,
    closeSearchAvailableRooms
  },
  dispatch
)

BookingTable.propTypes = {
  isOpenSearchAvailableRoomModal: PropTypes.bool,
  openSearchAvailableRooms: PropTypes.func,
  closeSearchAvailableRooms: PropTypes.func
}

export default connect(mapState, mapDispatch) (BookingTable)
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, TextField, Button
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import { get } from 'lodash'
import { Upload, Icon, Modal } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { history } from '../../../config/store'
import { Form, Checkbox } from 'antd'
import { s2c } from '../../../helpers/helpers'

//Room imge
import Image1 from '../../../assets/images/Kopenhagen_1-2.jpg'
import Image2 from '../../../assets/images/Raum_19_Coaching.jpg'
import Image3 from '../../../assets/images/Stockholm_1.jpg'

import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import {
  createUser,
  clearUserInfo,
  getUserInfo,
  updateUser,
} from '../../../actions/user'
import {getSortOfUsers} from '../../../selectors/user'
import LoadingIcon from 'mdi-react/LoadingIcon'

const useStyles = makeStyles((theme) => ({
  formField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  formRoot: {
    '& .ant-form-item': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  roomInfoContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
    // '& .MuiGrid-item ': {
    //   padding: '15px'
    // }
  },
  roomCombinedContent: {
    display: 'block',
    minHeight: '225px',
    borderLeft: '1px solid #ddd'
  },
  chip: {
    minWidth: '120px',
    margin: '5px',
    justifyContent: 'space-between'
  },
  chip2: {
    padding: '5px 5px',
    margin: '5px',
  },
  addRoomCombinedIcon: {
    border: '1px solid',
    borderColor: theme.colorAccent,
    borderRadius: '50%',
    color: theme.colorAccent,
    width: '18px',
    height: '18px',
  },
  addRoomCombinedContent: {
    width: '100%'
  },
  padding15px: {
    padding: '15px'
  },
  roomCategoryLabelContent: {
    padding: '10px 0'
  },
  descriptionLabel: {
    padding: '10px 0'
  },
  addMoreRow: {
    '& .MuiTableCell-root': {
      color: 'rgba(162, 162, 162, 0.87)',
    },
    '& .MuiInput-underline:before': {
      border: 'unset'
    }
  },
  addMoreRowButtonContent: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      width: '80px'
    }
  },
  roomImagesContent: {
    margin: '20px 0'
  },
  roomImagesUploadContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
  },
  formSelectField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '200px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  roomsSelectedContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',

  },
  createNewTypeButton: {
    color: theme.colorAccent
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  roomListContent: {
    margin: '20px 0'
  },
  roomListButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  editViewTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorAccent,
    paddingBottom: '10px',
    borderBottom: `2px solid ${theme.colorAccent}`,
    marginBottom: '20px',
    marginTop: '30px',

  }
}))

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const NewUser = props => {
  const {
    editView,
    roomType,
    createUser,
    match,
    getUserInfo,
    clearUserInfo,
    userInfo,
    updateUser,
    isSavingUser,
    isGettingUserInfo,
    sortOfUser,
    form: { getFieldDecorator, validateFields, setFieldsValue, getFieldValue },
    t
  } = props
  const classes = useStyles()

  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: Image1,
    },
    {
      uid: '-2',
      name: 'image.png',
      status: 'done',
      url: Image2,
    },
    {
      uid: '-3',
      name: 'image.png',
      status: 'done',
      url: Image3,
    },
  ])

  const isUpdatingUser = match.path == paths.UpdateUser

  useEffect(() => {
    // first load
    clearUserInfo()
    if (isUpdatingUser) {
      getUserInfo(match.params.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // fetched user info
    console.log(userInfo)
    if (isUpdatingUser && get(userInfo, 'id')) {
      setFieldsValue({
        ...s2c(userInfo),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isUpdatingUser])

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">{t('USERS.Upload')}</div>
    </div>
  )

  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }

  const handleChange = ({ fileList }) => setFileList(fileList)

  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        const userData = {
          fullName: get(values, 'firstName') + ' ' + get(values, 'lastName'),
          ...values
        }
        if (isUpdatingUser) {
          updateUser(userInfo.id, userData)
        } else {
          createUser(userData)
        }
      }
    })

  }

  const isAddCustomer = () => {
    if (isUpdatingUser) {
      return !(get(userInfo, 'is_admin') | get(userInfo, 'is_staff'))
    } 
    return sortOfUser == 'customer'
  }

  return (
    <Container>
      {isGettingUserInfo ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Form onSubmit={handleSubmit} className={classes.formRoot}>
        <Grid container >
          {editView &&
            <Grid item xs={12} md={12} >
              <Typography variant='h5' component="div"  >
                <Link onClick={() => history.goBack()} className={classes.editViewTitle} >
                  <ChevronLeftIcon /> {t('USERS.Edit')} {roomType}
                </Link>
              </Typography>
            </Grid>
          }
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={2} md={3}>
                <Typography variant='h6'>{t('USERS.UserInformation')}</Typography>
              </Grid>
              <Grid item xs={10} md={9} className={classes.roomInfoContent}>
                <Grid container>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Typography>* {t('USERS.Surname')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('firstName', {
                        rules: [
                          {
                            required: true,
                            message: `${t('USERS.Surname')} ${t('USERS.IsRequired')}`,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>

                  </Grid>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Typography>* {t('USERS.GivenName')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('lastName', {
                        rules: [
                          {
                            required: true,
                            message: `${t('USERS.GivenName')} ${t('USERS.IsRequired')}`,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Typography>* {t('USERS.Company')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('company', {
                        rules: [
                          {
                            required: true,
                            message: `${t('USERS.Company')} ${t('USERS.IsRequired')}`,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Typography>* {t('USERS.Email')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            type: 'email',
                            message: t('USERS.TheInputIsNotValidEmail'),
                          },
                          {
                            required: true,
                            message: `${t('USERS.Email')} ${t('USERS.IsRequired')}`,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Typography>{t('USERS.Addition')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('addition', {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                  {isAddCustomer() && 
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    <Form.Item>
                      {getFieldDecorator("isLongTermCustomer", {})
                        (<Checkbox 
                          checked={getFieldValue('isLongTermCustomer')} 
                          onChange={(e) => setFieldsValue({ isLongTermCustomer: e.target.checked})}
                        >{t('USERS.IsLongTermCustomer')}</Checkbox>)}
                    </Form.Item>
                  </Grid>}
                  {!isAddCustomer() && 
                  <>
                  <Grid item xs={3} md={3} className={classes.padding15px}>
                    <Form.Item>
                      {getFieldDecorator("isStaff", {})
                        (<Checkbox 
                          checked={getFieldValue('isStaff')} 
                          onChange={(e) => setFieldsValue({
                          isStaff:
                            e.target.checked
                        })}>{t('USERS.IsStaff')}</Checkbox>)}
                    </Form.Item>
                  </Grid>
                  <Grid item xs={3} md={3} className={classes.padding15px}>
                    <Form.Item>
                      {getFieldDecorator("isAdmin", {})
                        (<Checkbox 
                          checked={getFieldValue('isAdmin')} 
                          onChange={(e) => setFieldsValue({
                          isAdmin:
                            e.target.checked
                        })}>{t('USERS.IsAdmin')}</Checkbox>)}
                    </Form.Item>
                  </Grid>
                  </>}
                </Grid>
                <Grid container>
                  <Grid item xs={4} md={4} className={classes.padding15px}>
                    <Typography>* {t('USERS.Street')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('street', {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.padding15px}>
                    <Typography>* {t('USERS.HouseNumber')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('houseNbr', {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.padding15px}>
                    <Typography>* {t('USERS.PostalZipcode')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('postCode', {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} md={4} className={classes.padding15px}>
                    <Typography>* {t('USERS.City')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('city', {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.padding15px}>
                    <Typography>* {t('USERS.Country')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('country', {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.padding15px}>
                    <Typography>{t('USERS.ContactPerson')}</Typography>
                    <Form.Item>
                      {getFieldDecorator('contactPerson', {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(<TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                      // variant="outlined"
                      />)}
                    </Form.Item>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} md={6} className={classes.padding15px}>
                    {isAddCustomer() && <Form.Item>
                      {getFieldDecorator("isBelongToCompany", {})
                        (<Checkbox 
                          checked={getFieldValue('isBelongToCompany')} 
                          onChange={(e) => setFieldsValue({
                          isBelongToCompany: e.target.checked
                        })}>{t('USERS.IsCompanyCustomer')}</Checkbox>)}
                    </Form.Item>}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container className={classes.roomImagesContent}>
            <Grid item xs={2} md={3}>
              <Typography variant='h6'>User Avatar</Typography>
              <Typography variant='caption'>600x600 (px) Max 1Mb</Typography>
            </Grid>
            <Grid item xs={10} md={9} className={classes.roomImagesUploadContent}>
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.footerContent}>
            <Button
              onClick={() => history.push(paths.ListUser)}
              className={classes.backButton}
              variant="contained" color="default"
              disableElevation
              loading={isSavingUser}
            >
              {t('USERS.Discard')}
          </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
            >
              {isUpdatingUser ? t('USERS.Save') : t('USERS.Publish')}
            </Button>
          </div>
        </Grid>
      </Form>
    </Container >
  )
}

const mapState = ({ userReducer }) => ({
  isGettingUserInfo: userReducer.isGettingUserInfo,
  userInfo: userReducer.userInfo,
  isSavingUser: userReducer.isSavingUser,
  sortOfUser: getSortOfUsers({userReducer}),

})

const mapDispatch = dispatch => bindActionCreators(
  {
    createUser,
    updateUser,
    getUserInfo,
    clearUserInfo,
  },
  dispatch
)

NewUser.propTypes = {
  editView: PropTypes.bool,
  roomType: PropTypes.string
}

NewUser.defaultProps = {
  editView: false,
  roomType: ''
}

export default Form.create({
  name: 'newUser'
})(withRouter(connect(mapState, mapDispatch)(withTranslation('common')(NewUser))))
import {combineReducers} from 'redux'
import * as actionTypes from '../actionTypes/room.actionTypes'
// import * as storage from '../services/storage'
// import { LOCAL_STORAGE_KEY } from '../helpers/constant'

const isListingPermFacilities = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LIST_PERM_FACILITIES:
    return true
  case actionTypes.LIST_PERM_FACILITIES_FAIL:
  case actionTypes.LIST_PERM_FACILITIES_SUCCESS:
    return false
  default:
    return state
  }
}

const listPermFacilities = (state = [], action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LIST_PERM_FACILITIES_SUCCESS:
    return action.data
  default:
    return state
  }
}


const isListingRoom = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LIST_ROOMS:
    return true
  case actionTypes.LIST_ROOMS_FAIL:
  case actionTypes.LIST_ROOMS_SUCCESS:
    return false
  default:
    return state
  }
}

const listRooms = (state = [], action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LIST_ROOMS_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isGettingRoomInfo = (state = null, action) => {
  const {type} = action
  switch (type) {
    case actionTypes.GET_ROOM:
      return true
    case actionTypes.GET_ROOM_FAIL:
    case actionTypes.GET_ROOM_SUCCESS:
      return false
    default:
      return state
  }
}

const roomInfo = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.UPDATE_ROOM_SUCCESS:
  case actionTypes.GET_ROOM_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isSavingRoom = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.UPDATE_ROOM:
  case actionTypes.CREATE_ROOM:
    return true
  case actionTypes.UPDATE_ROOM_FAIL:
  case actionTypes.UPDATE_ROOM_SUCCESS:
  case actionTypes.CREATE_ROOM_FAIL:
  case actionTypes.CREATE_ROOM_SUCCESS:
    return false
  default:
    return state
  }
}

const updatedRoom = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.UPDATE_ROOM_SUCCESS:
    return action.data
  default:
    return state
  }
}

const createdRoom = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.CREATE_ROOM_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isFetchingRoomAvailAnalytics = (state = null, action) => {
  const {type} = action
  switch (type) {
    case actionTypes.GET_ROOM_AVAIL_ANALYTICS:
      return true
    case actionTypes.GET_ROOM_AVAIL_ANALYTICS_FAIL:
    case actionTypes.GET_ROOM_AVAIL_ANALYTICS_SUCCESS:
      return false
    default:
      return state
  }
}

const roomAvailAnalytics = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_ROOM_AVAIL_ANALYTICS_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isFetchingRoomBookingAnalytics = (state = null, action) => {
  const {type} = action
  switch (type) {
    case actionTypes.GET_ROOM_BOOKING_ANALYTICS:
      return true
    case actionTypes.GET_ROOM_BOOKING_ANALYTICS_FAIL:
    case actionTypes.GET_ROOM_BOOKING_ANALYTICS_SUCCESS:
      return false
    default:
      return state
  }
}

const roomBookingAnalytics = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_ROOM_BOOKING_ANALYTICS_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isListingRoomTypes = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LIST_ROOM_TYPES:
    return true
  case actionTypes.LIST_ROOM_TYPES_FAIL:
  case actionTypes.LIST_ROOM_TYPES_SUCCESS:
    return false
  default:
    return state
  }
}

const listRoomTypes = (state = [], action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LIST_ROOM_TYPES_SUCCESS:
    return action.data
  default:
    return state
  }
}


const isGettingRoomTypeInfo = (state = null, action) => {
  const {type} = action
  switch (type) {
    case actionTypes.GET_ROOM_TYPE:
      return true
    case actionTypes.GET_ROOM_TYPE_FAIL:
    case actionTypes.GET_ROOM_TYPE_SUCCESS:
      return false
    default:
      return state
  }
}

const roomTypeInfo = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.UPDATE_ROOM_TYPE_SUCCESS:
  case actionTypes.GET_ROOM_TYPE_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isSavingRoomType = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.UPDATE_ROOM_TYPE:
  case actionTypes.CREATE_ROOM_TYPE:
    return true
  case actionTypes.UPDATE_ROOM_TYPE_FAIL:
  case actionTypes.UPDATE_ROOM_TYPE_SUCCESS:
  case actionTypes.CREATE_ROOM_TYPE_FAIL:
  case actionTypes.CREATE_ROOM_TYPE_SUCCESS:
    return false
  default:
    return state
  }
}

const updatedRoomType = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.UPDATE_ROOM_TYPE_SUCCESS:
    return action.data
  default:
    return state
  }
}

const createdRoomType = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.CREATE_ROOM_TYPE_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isListingRoomReadyToAdd = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LIST_ROOMS_READY_TO_ADD:
    return true
  case actionTypes.LIST_ROOMS_READY_TO_ADD_FAIL:
  case actionTypes.LIST_ROOMS_READY_TO_ADD_SUCCESS:
    return false
  default:
    return state
  }
}

const roomsReadyToAdd = (state = [], action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LIST_ROOMS_READY_TO_ADD_SUCCESS:
    return action.data
  default:
    return state
  }
}

const roomReducer = combineReducers({
  isListingPermFacilities,
  listPermFacilities,
  isListingRoom,
  listRooms,
  isGettingRoomInfo,
  roomInfo,
  createdRoom,
  updatedRoom,
  isSavingRoom,
  isFetchingRoomAvailAnalytics,
  roomAvailAnalytics,
  isFetchingRoomBookingAnalytics,
  roomBookingAnalytics,
  isListingRoomTypes,
  listRoomTypes,
  isGettingRoomTypeInfo,
  roomTypeInfo,
  createdRoomType,
  updatedRoomType,
  isSavingRoomType,
  roomsReadyToAdd,
  isListingRoomReadyToAdd
})

export default roomReducer
import {get} from 'lodash'
import { message } from 'antd'
import moment from 'moment'
import jwtDecode from 'jwt-decode'
import { takeLatest, put, call, all } from 'redux-saga/effects'

import {LOGIN, LOGOUT, CHECK_TOKEN_EXPIRATION } from '../actionTypes/auth.actionTypes'
import * as actions from '../actions/auth'
import {getProfile, getProfileSuccess} from '../actions/public'

import {history} from '../config/store'
import * as paths from '../constants/paths.constants'

import { LOCAL_STORAGE_KEY } from '../helpers/constant'

import * as storage from '../services/storage'

function* login({api}, {data}) { // object: {type, data}
  try {
    console.log(data)
    const response = yield call(api.auth.login, data)
    if(response) {
      storage.setLocalStorageItem(LOCAL_STORAGE_KEY.authorization, response)
      const userInfo = yield call(api.auth.getProfile)
      
      if(userInfo.is_staff) {
        yield put(getProfileSuccess(userInfo))
        yield put(actions.loginSuccess())
        // yield put(actions.loginSuccess())
        message.success('Login successfully!')
        history.push(paths.BookingCalendar)
      } else {
        storage.removeLocalStorage(LOCAL_STORAGE_KEY.authorization)
        message.error('Please use the staff account to login this website')
        yield put(actions.loginFail())
      }
    }
    // yield put(userActions.getProfile())
  } catch (e) {
    console.log(e)
    message.error(e.data.detail)
    yield put(actions.loginFail())
  }
}


async function _getValidAccessToken({ api }, curAuthToken) {
  if (!get(curAuthToken, 'access')) return null
  console.log('câlllled')
  const accessTokenExp = jwtDecode(curAuthToken.access).exp
  const refresTokenExp = jwtDecode(curAuthToken.refresh).exp
  const currentUnix = moment().unix()

  if (refresTokenExp < currentUnix) {
    return null
  }
  
  if (accessTokenExp > currentUnix) {
    return curAuthToken.access
  } else {
    try {
      // token expired, refresh token
      const resp = await api.auth.refreshToken({refresh: curAuthToken.refresh })
      return resp.access
    } catch(e) {
      throw(e)
    }
  }
}

function* checkTokenExpiration({ api }) {
  try {
    const session = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.session)
    if(!session) {
      yield call(api.auth.getSession)
    }

    const authorization = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization)
    const accessToken = yield call(_getValidAccessToken, { api }, authorization)
    console.log("valid token: ", accessToken)
    
    if (accessToken) {
      storage.setLocalStorageItem(LOCAL_STORAGE_KEY.authorization, {...authorization, access: accessToken})
      yield put(getProfile())
    } else {
      storage.removeLocalStorage(LOCAL_STORAGE_KEY.authorization)
      history.push(paths.LoginPage)
      yield put(actions.logout())
    }

  } catch (e) {
    console.log(e)
    
    if(e.data.meta) {
      storage.setLocalStorageItem(LOCAL_STORAGE_KEY.session, e.data.meta.session)
    }
    history.push(paths.LoginPage)
    message.error(e.data.detail)
    yield put(actions.logout())
  }
}

function logout({api}) {
  try {
    storage.removeLocalStorage(LOCAL_STORAGE_KEY.authorization)
  } catch(e) {
    storage.removeLocalStorage(LOCAL_STORAGE_KEY.authorization)
  }
}


function authSaga(deps) {
  return function* saga() {
    yield all([
      takeLatest(LOGIN, login, deps),
      takeLatest(CHECK_TOKEN_EXPIRATION, checkTokenExpiration, deps),
      takeLatest(LOGOUT, logout, deps),

    ])
  }
}

export default authSaga

export const LOCAL_STORAGE_KEY = {
  authorization: 'AUTHORIZATION',
  session: 'Session'
};

export const ITEM_TYPES = {
  ROOM: 1,
  EXTRA_FACILITY: 2,
  CATERING_SERVICE: 3
}

export const CURRENCY_MAPPING = {
  EUR: '€',
  USD: '$'
}

export const DISCOUNT_TO = [
  'Caterings',  // 100
  'Extra Facitities',  // 010
  'Room',  // 001
  // ALL: 7,  // 111
]

export const ObjectType = {
  1: 'Room', // ROOM = 1
  2: 'Customer' // CUSTOMER = 2
}

export const CustomerApplyType = {
  1: 'All', // ALL = 1
  2: 'New', // NEW = 2
  3: 'Custom' //  CUSTOM = 3
}

export const RoomApplyType = {
  1: 'All', // ALL = 1
  3: 'Custom' // CUSTOM = 3
}

export const DiscountType = {
  1: 'PERCENT', // PERCENT = 1
  2: 'FIXED' // FIXED = 2
}

export const BOOKING_STATUS = {
  1: 'SCHEDULED',
  2: 'ACTIVE',
  3: 'DONE',
  4: 'CUSTOMERREQUESTCANCEL',
  5: 'CANCELED',
}

export const PAYMENT_METHOD = {
  1: 'STRIPE',
  2: 'INVOICE',
  3: 'OTHER',
}

export const MAX_IMG_SIZE_MB = {
  ROOM: 10,
  ROOM_TYPE: 10,
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NewDiscountComponent from './components/NewDiscount'
import CustomDiscountModal from './components/CustomDiscountModal'
class NewDiscount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenCustomDiscountModal: false,
      useCustom: false
    }
  }
  

  openCustomDiscountModal = () => {
    this.setState({isOpenCustomDiscountModal: true})
  }

  closeCustomDiscountModal = () => {
    this.setState({isOpenCustomDiscountModal: false})
  }

  confirmOffer = () => {
    this.setState({useCustom: true})
    this.closeCustomDiscountModal()
  }

  render() {
    const {isOpenCustomDiscountModal, useCustom} = this.state
    return (
      <div>
        <NewDiscountComponent
          openCustomDiscountModal={this.openCustomDiscountModal}
          useCustom={useCustom}
        />
        <CustomDiscountModal 
          isOpen={isOpenCustomDiscountModal}
          closeModal={this.closeCustomDiscountModal}
          confirmOffer={this.confirmOffer}
        />
      </div>
    )
  }
}

NewDiscount.propTypes = {

}

export default NewDiscount
const C = "ROOM.DETAIL"

export const RETRIEVE_ROOM_TYPE = `${C}.RETRIEVE_ROOM_TYPE`
export const RETRIEVE_ROOM_TYPE_COMPLETED = `${C}.RETRIEVE_ROOM_TYPE_COMPLETED`
export const RETRIEVE_ROOM_TYPE_FAILED = `${C}.RETRIEVE_ROOM_TYPE_FAILED`

export const RETRIEVE_ROOM = `${C}.RETRIEVE_ROOM`
export const RETRIEVE_ROOM_COMPLETED = `${C}.RETRIEVE_ROOM_COMPLETED`
export const RETRIEVE_ROOM_FAILED = `${C}.RETRIEVE_ROOM_FAILED`

export const LIST_BOOKING_ANALYTICS = `${C}.LIST_BOOKING_ANALYTICS`
export const LIST_BOOKING_ANALYTICS_COMPLETED = `${C}.LIST_BOOKING_ANALYTICS_COMPLETED`
export const LIST_BOOKING_ANALYTICS_FAILED = `${C}.LIST_BOOKING_ANALYTICS_FAILED`

export const LIST_BOOKINGS = `${C}.LIST_BOOKINGS`
export const LIST_BOOKINGS_COMPLETED = `${C}.LIST_BOOKINGS_COMPLETED`
export const LIST_BOOKINGS_FAILED = `${C}.LIST_BOOKINGS_FAILED`
export const CHANGE_TAB = `${C}.CHANGE_TAB`
export const CHANGE_PAGE = `${C}.CHANGE_PAGE`

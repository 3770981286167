import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import List from '../../components/caterings/List'
import * as selectors from '../../selectors/caterings/list'
import * as actions from '../../actions/caterings/list'


class CateringList extends Component {
  render() {

    return (
      <List {...this.props} />
    )
  }
}


const mapState = (state) => ({
  items: selectors.getItems(state),
  isFetching: selectors.isFetching(state),
  selectableTabs: selectors.getSelectableTabs(state),
  selectedTab: selectors.getSelectedTab(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    onChangeTab: actions.changeTab,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(CateringList))

import { combineReducers } from 'redux'
import * as C from "../../actionTypes/rooms/new";
import moment from 'moment'
import * as _ from 'lodash'


const room = combineReducers({
  isSubmitting: (state = false, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SUBMIT_ROOM:
        return true
      case C.SUBMIT_ROOM_COMPLETED:
      case C.SUBMIT_ROOM_FAILED:
        return false
      default:
        return state
    }
  },
  selectableRoomTypes: (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_ROOM_TYPES_COMPLETED:
        return _.get(payload, 'results')
      default:
        return state
    }
  },
  selectableCombinedRooms: (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case C.SEARCH_COMBINABLE_ROOM_COMPLETED:
        return _.get(payload, 'results')
      default:
        return state
    }
  },
  selectablePermanentFacilities: (state=[], action) => {
    const { type, payload } = action
    switch (type) {
      case C.LIST_PERMANENT_FACILITY_COMPLETED:
        return payload
      default:
        return state
    }
  }
})

const roomType = combineReducers({
  isSubmitting: (state = false, action) => {
    const { type, payload } = action
    switch (type) {
      case C.SUBMIT_ROOM_TYPE:
        return true
      case C.SUBMIT_ROOM_TYPE_COMPLETED:
      case C.SUBMIT_ROOM_TYPE_FAILED:
        return false
      default:
        return state
    }
  }
})

const reducers = combineReducers({
  room,
  roomType,
})

export default reducers


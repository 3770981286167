/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener
} from '@material-ui/core'
import LockIcon from '@material-ui/icons/LockOutlined'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import { Popover } from '@material-ui/core'
import axios from '../../../../../utils/axios'
import useRouter from '../../../../../utils/useRouter'
import { PricingModal, NotificationsPopover } from '../../../../../components'
import * as actions from '../../../../../actions/auth'
import {changeLanguage} from '../../../../../actions/public'
import * as paths from '../../../../../constants/paths.constants'
import { withTranslation } from 'react-i18next'
import TopbarMenuLinks from './TopbarMenuLink'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    // width: '84%',
    // left: '16%'
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}))

const TopBar = props => {
  const { onOpenNavBarMobile, className, logout, t, i18n, changeLanguage, ...rest } = props

  const classes = useStyles()
  const { history } = useRouter()
  const searchRef = useRef(null)
  const dispatch = useDispatch()
  const notificationsRef = useRef(null)
  const languageRef = useRef(null)
  const [pricingModalOpen, setPricingModalOpen] = useState(false)
  const [openSearchPopover, setOpenSearchPopover] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [notifications, setNotifications] = useState([])
  const [openLanguage, setOpenLanguage] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)

  useEffect(() => {
    let mounted = true

    // const fetchNotifications = () => {
    //   axios.get('/api/account/notifications').then(response => {
    //     if (mounted) {
    //       setNotifications(response.data.notifications)
    //     }
    //   })
    // }

    // fetchNotifications()

    return () => {
      mounted = false
    }
  }, [])

  const handleLogout = () => {
    // history.push(paths.LoginPage)

    dispatch(logout())
  }

  const handlePricingOpen = () => {
    setPricingModalOpen(true)
  }

  const handlePricingClose = () => {
    setPricingModalOpen(false)
  }

  const handleNotificationsOpen = () => {
    setOpenNotifications(true)
  }

  const handleNotificationsClose = () => {
    setOpenNotifications(false)
  }

  const handleLanguageOpen = () => {
    setOpenLanguage(true)
  }

  const handleCloseChangeLanguge = () => {
    setOpenLanguage(false)
  }

  const handleSearchChange = event => {
    setSearchValue(event.target.value)

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true)
      }
    } else {
      setOpenSearchPopover(false)
    }
  }

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false)
  }

  const popularSearches = [
    'Devias React Dashboard',
    'Devias',
    'Admin Pannel',
    'Project',
    'Pages'
  ]

  const handleChangeLanguage = (value) => {
    console.log('called')
    changeLanguage(value)
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        {/* <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo--white.svg"
          />
        </RouterLink> */}
        <div className={classes.flexGrow} />
        {/* <Hidden smDown>
          <div
            className={classes.search}
            ref={searchRef}
          >
            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              placeholder="Search people &amp; places"
              value={searchValue}
            />
          </div>
          <Popper
            anchorEl={searchRef.current}
            className={classes.searchPopper}
            open={openSearchPopover}
            transition
          >
            <ClickAwayListener onClickAway={handleSearchPopverClose}>
              <Paper
                className={classes.searchPopperContent}
                elevation={3}
              >
                <List>
                  {popularSearches.map(search => (
                    <ListItem
                      button
                      key={search}
                      onClick={handleSearchPopverClose}
                    >
                      <ListItemIcon>
                        <SearchIcon />
                      </ListItemIcon>
                      <ListItemText primary={search} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </Hidden>
         */}
        <Hidden mdDown={false} >
          {/* <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notifications.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleLanguageOpen}
            ref={languageRef}
          >
            <Badge
              badgeContent={notifications.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <img className='' src={require(`../../../../../assets/images/flags/${i18n.language || 'en'}-flag.png`)} alt='flag' />
            </Badge>
          </IconButton>
          <Popover
            id='simple-popover'
            open={openLanguage}
            anchorEl={languageRef.current}
            onClose={handleCloseChangeLanguge}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <TopbarMenuLinks
              title={<><img className='' src={require('../../../../../assets/images/flags/en-flag.png')} alt='flag' />&nbsp;English</>}
              // path={paths.LoginPage}
              onClick={() => {
                handleChangeLanguage('en')
                // setAnchorEl(null)
              }}
            />
            <TopbarMenuLinks
              title={<><img className='' src={require('../../../../../assets/images/flags/de-flag.png')} alt='flag' />&nbsp;Deutsch</>}
              // path={paths.LoginPage}
              onClick={() => {
                handleChangeLanguage('de')
                // setAnchorEl(null)
              }}
            />

            {/* <Typography className={classes.typography}>The content of the Popover.</Typography> */}
          </Popover>
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon className={classes.logoutIcon} />
            {t('AUTH.SignOut')}
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <PricingModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      />
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  )
}

const mapState = state => ({

})

const mapDispatch = dispatch => bindActionCreators({
  logout: actions.logout,
  changeLanguage
}, dispatch)

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  logout: PropTypes.func
}

export default React.memo(connect(mapState, mapDispatch)(withTranslation('common')(TopBar)))

import {combineReducers} from 'redux'
import * as actionTypes from '../actionTypes/discount.actionTypes'

const isGettingDiscount = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_LIST_DISCOUNT:
    return true
  case actionTypes.GET_LIST_DISCOUNT_FAIL:
  case actionTypes.GET_LIST_DISCOUNT_SUCCESS:
    return false
  default:
    return state
  }
}

const listDiscount = (state = [], action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_LIST_DISCOUNT_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isCreatingDiscount = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.CREATE_NEW_DISCOUNT:
    return true
  case actionTypes.CREATE_NEW_DISCOUNT_FAIL:
  case actionTypes.CREATE_NEW_DISCOUNT_SUCCESS:
    return false
  default:
    return state
  }
}


const discountReducer = combineReducers({
  isGettingDiscount,
  listDiscount,
  isCreatingDiscount,
})

export default discountReducer
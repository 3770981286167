import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Edit from '../../components/users/Edit'
import * as actions from '../../actions/users/edit'
import * as selectors from '../../selectors/users/edit'


class UserEdit extends Component {
  componentDidMount() {
    let { match: { params: { detailType, id } } } = this.props
    this.props.retrieveUser(id)
  }

  render() {

    return (
      <Edit {...this.props} />
    )
  }
}


const mapState = (state) => ({
  firstName: selectors.getFirstName(state),
  lastName: selectors.getLastName(state),
  email: selectors.getEmail(state),
  isAdmin: selectors.isAdmin(state),
  isActive: selectors.isActive(state),
  phone: selectors.getPhone(state),
  street: selectors.getStreet(state),
  houseNumber: selectors.getHouseNumber(state),
  postCode: selectors.getPostCode(state),
  city: selectors.getCity(state),
  country: selectors.getCountry(state),
  customerNo: selectors.getCustomerNo(state),
  company: selectors.getCompany(state),
  addition: selectors.getAddition(state),
  isCompanyCustomer: selectors.isCompanyCustomer(state),
  isLongTermCustomer: selectors.isLongTermCustomer(state),
  adAccepted: selectors.adAccepted(state),
  isSubmitting: selectors.isSubmitting(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveUser: actions.retrieveUser,
    onSubmit: actions.submit,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(UserEdit))

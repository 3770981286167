import { combineReducers } from 'redux'
import * as C from "../../actionTypes/discounts/list";
import { DISCOUNT } from "../../constants/models";
import moment from 'moment'
import * as _ from 'lodash'

const { LIST_BY } = DISCOUNT

const page = (state = 1, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_DISCOUNT_COMPLETED:
      return _.get(payload, 'page')
    case C.CHANGE_PAGE:
      return payload
    case C.CHANGE_TAB:
      return 1
    default:
      return state
  }
}

const total = (state = 0, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_DISCOUNT_COMPLETED:
      return _.get(payload, 'count')
    default:
      return state
  }
}

const pageSize = (state = 10, action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_DISCOUNT_COMPLETED:
      return _.get(payload, 'page_size')
    default:
      return state
  }
}

const items = (state = [], action) => {
  const {type, payload} = action
  switch (type) {
    case C.LIST_DISCOUNT_COMPLETED:
      return _.get(payload, 'results')
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  const {type} = action
  switch (type) {
    case C.LIST_DISCOUNT:
    case C.CHANGE_TAB:
    case C.CHANGE_PAGE:
      return true
    case C.LIST_DISCOUNT_COMPLETED:
    case C.LIST_DISCOUNT_FAILED:
      return false
    default:
      return state
  }
}

const selectedTab = (state = LIST_BY.ACTIVE, action) => {
  const {type, payload} = action
  switch (type) {
    case C.CHANGE_TAB:
      return payload
    default:
      return state
  }
}

const reducers = combineReducers({
  page,
  pageSize,
  total,
  items,
  isFetching,
  selectedTab,
})

export default reducers

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography, TextField, Button, IconButton } from '@material-ui/core'
import MaterialTableComponent from '../../../components/Table/MaterialTable'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { RemoveRedEyeOutlined } from '@material-ui/icons'
import { bindActionCreators } from 'redux'
import { getListDiscount } from '../../../actions/discount'
import { convertDiscountToSpecified, roundToUnit } from '../../../helpers/helpers'
import { CURRENCY_MAPPING } from '../../../helpers/constant'
import { Tag } from 'antd'
import LoadingIcon from 'mdi-react/LoadingIcon'
import moment from 'moment'
import {get} from 'lodash'
import {withTranslation} from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {},
  formField: {
    borderRadius: '5px',
    width: '40%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  formFilterField: {
    borderRadius: '5px',
    width: '20%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px'
  },
  searchButton: {
    color: theme.colorAccent,
    marginRight: '20px'
  },
  newBookingButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '40px'
  }
}))



function createData(id, discount_offer, starting, ending, applied_to, offer, status) {
  return { id, discount_offer, starting, ending, applied_to, offer, status }
}

const rows = [
  createData(12, 'New Year', '1/1/2020', '1/1/2020', 'All', '-15%', 'Expired'),
  createData(13, 'Black Friday', '1/1/2020', '1/1/2020', 'All', '-30%', 'Expired'),
  createData(14, 'End Year', '1/1/2020', '1/1/2020', 'Custom', '-10%', 'Stop'),
  createData(15, 'Opening', '30/04/2020', '5/5/2020', 'Custom', '-50%', 'Active'),
]

const ListDiscounts = props => {
  const classes = useStyles()
  const { t, history, isGettingDiscount, getListDiscount, listDiscount, configurationInfo } = props
  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
    { key: 'name', numeric: true, disablePadding: false, label: t('DISCOUNT.DiscountOffers') },
    { key: 'start_at', numeric: true, disablePadding: false, label: t('DISCOUNT.Starting'),
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm')
    },
    { key: 'end_at', numeric: true, disablePadding: false, label: t('DISCOUNT.Ending'),
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm')
    },
    {
      key: 'discount_to', numeric: true, disablePadding: false, label: t('DISCOUNT.AppliedTo'),
      render: (value) => <>
        {convertDiscountToSpecified(value).map((item, index) => {
          const color = ['blue', 'volcano', 'purple']
          return (
            <Tag key={index} color={color[index]}>{item}</Tag>
          )
        })}
      </>
    },
    {key: 'discount_amount', label: t('DISCOUNT.Discount'), 
      render: (value, row) => get(row, 'discount_type', 1) === 1 ? `-${row.discount_amount}%` : `${CURRENCY_MAPPING[configurationInfo.currency]}${roundToUnit(row.discount_amount, configurationInfo.unit)}`
    },
    { key: 'is_active', numeric: true, disablePadding: false, label: t('DISCOUNT.Status'),
      render: value => value ? 'Active' : 'Inactive'
    },
  ]

  useEffect(() => {
    getListDiscount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  return (
    <Container>
      {isGettingDiscount ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container>
        <Grid item md={12}>
          <Grid container>
            <Grid item md={8} className={classes.searchContent}>
              {/* <Typography component='div'>Search</Typography>
              <TextField
                // error
                className={classes.formField}
                // id="outlined-error"
                label="Room, Room Types, Facilies"
                variant="outlined"
              />
              <Button className={classes.searchButton} color="default" variant="contained" disableElevation>
                <MagnifyIcon />
              </Button>
              <Typography component='div'>Filter by</Typography>
              <TextField
                // error
                className={classes.formFilterField}
                // id="outlined-error"
                label="Date"
                variant="outlined"
              /> */}
            </Grid>
            <Grid item md={4} className={classes.newBookingButtonContent}>
              <Link to={paths.NewDiscount}>
                <Button color="primary" variant="contained" disableElevation>
                  {t('DISCOUNT.AddDiscount')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <MaterialTableComponent
            title={t('DISCOUNT.Discounts')}
            columns={headCells}
            rows={listDiscount}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

const mapState = ({ discountReducer, publicReducer }) => ({
  isGettingDiscount: discountReducer.isGettingDiscount,
  listDiscount: discountReducer.listDiscount,
  configurationInfo: publicReducer.configurationInfo,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getListDiscount
  },
  dispatch
)

ListDiscounts.propTypes = {
  className: PropTypes.string,
}

ListDiscounts.defaultProps = {
  orders: []
}

export default connect(mapState, mapDispatch) (withTranslation('common') (withRouter(ListDiscounts)))

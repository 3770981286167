import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { PageHeader, Button } from 'antd'

import DiscountList from './DiscountList'
import DiscountNew from './DiscountNew'
import DiscountDetail from './DiscountDetail'
import DiscountEdit from './DiscountEdit'


const View = props => {
  let match = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/new`}>
          <DiscountNew />
        </Route>

        <Route path={`${match.path}/detail/:id`} render={() => <DiscountDetail />} />
        <Route path={`${match.path}/edit/:id`} render={() => <DiscountEdit />} />
        <Route path={`${match.path}`} render={() => <DiscountList />} />
      </Switch>
    </Container>
  )
}

class DiscountContainer extends Component {
  render() {

    return (
      <View {...this.props} />
    )
  }
}


const mapState = (state) => ({

})

const mapDispatch = dispatch => bindActionCreators(
  {

  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(withTranslation("discount_management")(DiscountContainer)))

import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, Button, AppBar, Tabs, Tab, IconButton
} from '@material-ui/core'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import {get, camelCase} from 'lodash'

import { makeStyles } from '@material-ui/core/styles'
import Image from '../../../assets/images/Kopenhagen_1-1.jpg'
import { history } from '../../../config/store'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import PerformanceChart from '../../RoomDetail/components/PerformanceOverTime'
import MaterialTable from '../../../components/Table/MaterialTable'
import { RemoveRedEyeOutlined } from '@material-ui/icons'
import {
  getUserInfo,
  getUserBookingAnalytics,
  clearUserInfo,
} from '../../../actions/user'
import * as selectors from '../../../selectors/user'
import LoadingIcon from 'mdi-react/LoadingIcon'
import {getListIncomingBookings, getListHistoryBookings} from '../../../actions/booking'
import { pathWithId } from '../../../helpers/helpers'
import { withTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  rootTitleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `2px solid ${theme.colorAccent}`,
    paddingBottom: '10px',
    marginBottom: '20px',
    marginTop: '30px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorAccent,
  },
  footerPrice: {
    color: theme.colorAccent,
    fontWeight: 'bold'
  },
  roomTypeInfoContent: {
    border: '1px solid #ddd',
    padding: '10px',
    borderRadius: '5px',
    paddingRight: '20px',
  },
  topBookedContent: {
    paddingLeft: '20px',
    border: '1px solid #ddd',
    padding: '10px',
    borderRadius: '5px'
  },
  bookedTable: {
    paddingLeft: '20px',
    border: '1px solid #ddd',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '40px'
  },
  doughnutChart: {
    paddingLeft: '20px',
    border: '1px solid #ddd',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '40px',
  },
  topBookedAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chooseDateContent: {
    display: 'flex',
    alignItems: 'center',
  },
  timeSelected: {
    color: theme.colorAccent,
  },
  facilitiesContent: {
    display: 'flex',
    alignItems: 'center'
  },
  showMoreGrid: {
    padding: '20px'
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  editRoomTypeButton: {
    color: theme.colorAccent,
    marginLeft: '10px'
  },
  tabsContent: {
    width: '50%',
    boxShadow: 'unset',
    backgroundColor: '#fff',
    borderBottom: '1px solid #ddd',
    zIndex: 'unset'
  },
  totalBookingTitle: {
    fontSize: '18px'
  },
  timeAvailability: {
    fontSize: '18px'
  },
  avatar: {
    width: '85%',
    borderRadius: '10px'
  },
  userFullName: {
    color: theme.colorAccent
  }
}))

const headCells = [
  { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
  { key: 'room_name', numeric: true, disablePadding: false, label: 'Room' },
  { key: 'start', numeric: true, disablePadding: false, label: 'Start Time' },
  { key: 'end', numeric: true, disablePadding: false, label: 'End Time' },
  { key: 'status', numeric: true, disablePadding: false, label: 'Status' },
  { key: 'invoice', numeric: true, disablePadding: false, label: 'Invoice',
      render: (_, row) => {
        return (
          <>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => history.push(pathWithId(paths.BookingDetail, row.id))}
            // onMouseDown={handleMouseDownPassword}
            >
              <RemoveRedEyeOutlined />
            </IconButton>
          </>
        )
      }
    },
]

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const UserDetail = props => {
  const classes = useStyles()
  const { 
    getUserInfo, 
    clearUserInfo,
    getUserBookingAnalytics,
    getListIncomingBookings,
    getListHistoryBookings,
    match,

    isFetching,
    userInfo,
    userBookingAnalytics,

    isGettingListIncomingBookings,
    listIncomingBookings,
    listIncomingBookingsMeta,
    t,
    i18n,
    locale
  } = props
  moment.locale(i18n.language === 'en' ? 'en-gb' : 'de')

  const userId = match.params.id
  const [value, setValue] = React.useState(0)
  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
    props.history.push(newValue)
  }

  const period = "This Year"
  const thisYear = moment().year()

  const [tab, setTab] = useState('upcoming');
  const isViewUpcoming = tab == 'upcoming'
  useEffect(() => {
    // first load
    clearUserInfo()
    getUserInfo(userId)
    getUserBookingAnalytics({userId: parseInt(userId), year: thisYear})

    if(isViewUpcoming) {
      getListIncomingBookings({
        pageSize: 5,
        page: 1,
        userId,
      })
    } else {
      getListHistoryBookings({
        pageSize: 5,
        page: 1, 
        userId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(isViewUpcoming) {
      getListIncomingBookings({
        pageSize: 5,
        page: 1,
        userId,
      })
    } else {
      getListHistoryBookings({
        pageSize: 5,
        page: 1, 
        userId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewUpcoming])

  const handleChangePage = page => {
    if(!isViewUpcoming) {
      getListHistoryBookings({
        pageSize: get(listIncomingBookingsMeta, 'page_size', 5),
        page: page,
        userId
      })
    } else {
      getListIncomingBookings({
        pageSize: get(listIncomingBookingsMeta, 'page_size', 5),
        page: page,
        userId
      })
    }
  }

  const handleChangePageSize = size => {
    if(!isViewUpcoming) {
      getListHistoryBookings({
        pageSize: size,
        page: 1,
        userId,
      })
    } else {
      getListIncomingBookings({
        pageSize: size,
        page: 1,
        userId,
      })
    }
  }

  return (
    <Container>
      {isFetching ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container >
        <Grid item xs={12} md={12} >
          <Typography variant='h5' component="div" className={classes.rootTitleContent}  >
            <Link to={paths.ListUser} className={classes.title}>
              <ChevronLeftIcon /> USER #{get(userInfo, 'id')}
            </Link>
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container >
            <Grid item md={5} xs={12} className={classes.roomTypeInfoContent}>
              <Grid container>
                <Grid item md={8}>
                  <Typography component="div">
                    {get(userInfo, 'role')}
                  </Typography>
                  <Typography variant="h6" component="div" >
                    <span className={classes.userFullName}>{get(userInfo, 'fullName')}</span>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <img className={classes.avatar} src={Image} alt="" />
                </Grid>
                <Grid item md={12} >
                  <Typography>
                    <p>{t('USERS.Email')}: {get(userInfo, 'email')}</p>
                    <p>{t('USERS.Company')}: {get(userInfo, 'company')}</p>
                    <p>{t('USERS.Address')}: {get(userInfo, 'address')}</p>
                    <p>{t('USERS.ContactPerson')}: {get(userInfo, 'contactPerson')}</p>
                    <p>{t('USERS.MemberSince')}: {get(userInfo, 'createdAt')}</p>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1} />
            <Grid item md={6} xs={12} className={classes.topBookedContent} >
              <Typography component="div" className={classes.topBookedAction}>
                <span className={classes.totalBookingTitle}>Total Bookings</span>
                <div className={classes.chooseDateContent}><span>Show:</span> <span className={classes.timeSelected}>{period}</span></div>
              </Typography>
              <PerformanceChart data={userBookingAnalytics} period={camelCase(period)}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.bookedTable} >
        <Grid container>
          <Grid item xs={12}>
            <Typography component="div" className={classes.topBookedAction}>
              {/* <div> */}
              <AppBar position="static" color="default" className={classes.tabsContent}>
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label={t("USERS.UpcomingBooking")} {...a11yProps(0)} onClick={() => setTab('upcoming')}/>
                  <Tab label={t("USERS.History")} {...a11yProps(1)} onClick={() => setTab('history')}/>
                </Tabs>
              </AppBar>
              {/* </div> */}
              <div className={classes.chooseDateContent}><span>Show:</span> <span className={classes.timeSelected}>{period}</span></div>
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.facilitiesContent}>
          <MaterialTable
              title='Room Booking'
              columns={headCells}
              rows={listIncomingBookings}
              pageSize={get(listIncomingBookingsMeta, 'page_size')}
              page={get(listIncomingBookingsMeta, 'page')}
              total={get(listIncomingBookingsMeta, 'count')}
              onChangePage={(page) => handleChangePage(page)}
              onChangePageSize={(size) => handleChangePageSize(size)}
            />
          </Grid>

        </Grid>
      </Grid>
      {/* </Grid> */}
    </Container>
  )
}

const mapState = (state) => ({
  isFetching: selectors.isFetchingUserDetail(state),
  userInfo: selectors.getUserInfo(state),
  userBookingAnalytics: selectors.userBookingAnalytics(state),

  // booking list
  isGettingListIncomingBookings: state.bookingReducer.isGettingListIncomingBookings,
  listIncomingBookings: selectors.getListIncomingBookings(state),
  listIncomingBookingsMeta: state.bookingReducer.listIncomingBookingsMeta,

  configurationInfo: state.publicReducer.configurationInfo,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getUserInfo,
    clearUserInfo,
    getUserBookingAnalytics,

    // booking list
    getListIncomingBookings,
    getListHistoryBookings,
  },
  dispatch
)

UserDetail.propTypes = {
  className: PropTypes.string,
}

UserDetail.defaultProps = {
  orders: []
}

export default withRouter(connect(mapState, mapDispatch)(withTranslation('common')(UserDetail)))
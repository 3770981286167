import {combineReducers} from 'redux'
import * as actionTypes from '../actionTypes/modal.actionTypes'

const isOpenSearchAvailableRoomModal = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.OPEN_SEARCH_AVAILABLE_ROOMS_MODAL:
    return true
  case actionTypes.CLOSE_SEARCH_AVAILABLE_ROOMS_MODAL:
    return false
  default:
    return state
  }
}

const modalReducer = combineReducers({
  isOpenSearchAvailableRoomModal
})

export default modalReducer
const C = "DISCOUNT.DETAIL"

export const RETRIEVE_DISCOUNT = `${C}.RETRIEVE_DISCOUNT`
export const RETRIEVE_DISCOUNT_COMPLETED = `${C}.RETRIEVE_DISCOUNT_COMPLETED`
export const RETRIEVE_DISCOUNT_FAILED = `${C}.RETRIEVE_DISCOUNT_FAILED`

export const LIST_CUSTOMERS = `${C}.LIST_CUSTOMERS`
export const LIST_CUSTOMERS_COMPLETED = `${C}.LIST_CUSTOMERS_COMPLETED`
export const LIST_CUSTOMERS_FAILED = `${C}.LIST_CUSTOMERS_FAILED`
export const CHANGE_CUSTOMERS_LIST_PAGE = `${C}.CHANGE_CUSTOMERS_LIST_PAGE`

export const LIST_ROOMS = `${C}.LIST_ROOMS`
export const LIST_ROOMS_COMPLETED = `${C}.LIST_ROOMS_COMPLETED`
export const LIST_ROOMS_FAILED = `${C}.LIST_ROOMS_FAILED`
export const CHANGE_ROOMS_LIST_PAGE = `${C}.CHANGE_ROOMS_LIST_PAGE`


import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Input } from 'antd'
import { Table, Tag, Skeleton, Popconfirm, message } from 'antd';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { ViewAllBooking } from '../../constants/paths.constants'
import PerformanceChart from '../../views/RoomDetail/components/PerformanceOverTime'

import { USER } from '../../constants/models'

const { LIST_BY } = USER

const StaffView = ({
  t,

  isFetching,
  fullName,
  email,
  status,
  role,
}) => (
    <Grid container direction="column" style={{ border: "solid 1px #ddd", borderRadius: 5, padding: 24 }}>
      {isFetching ? <Skeleton active /> :
        [
          <Grid item>
            <Typography variant="h6">{fullName}</Typography>
          </Grid>,
          <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
            <Grid item><Typography variant="subtitle2">{t("Email")}</Typography></Grid>
            <Grid item><Typography variant="subtitle2"><b>{email}</b></Typography></Grid>
          </Grid>,
          <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
            <Grid item><Typography variant="subtitle2">{t("Status")}</Typography></Grid>
            <Grid item><Tag color={status.color}>{status.name}</Tag></Grid>
          </Grid>,
          <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
            <Grid item><Typography variant="subtitle2">{t("Role")}</Typography></Grid>
            <Grid item><Tag color={role.color}>{role.name}</Tag></Grid>
          </Grid>,
        ]}
    </Grid>
  )

const CustomerView = ({
  t,

  isFetching,
  fullName,
  customerNo,
  phone,
  email,
  company,
  address,
  term,
  accountType,
  status,
  ad,

  bookingItems,
  bookingPage,
  bookingPageSize,
  bookingTotal,
  isBookingFetching,
  selectedTab,

  isAnalyticsFetching,
  analyticsData,

  onChangeTab,
  onChangePage,

}) => {

  const columns = [
    {
      title: t('Confirmation No'),
      dataIndex: 'confirmationNo',
      key: 'confirmationNo',
      render: (text, record) => <Link to={`${ViewAllBooking}/detail/${record.id}`}>{text}</Link>,
    },
    {
      title: t('Room'),
      dataIndex: 'room',
      key: 'room',
    },
    {
      title: t('Booking Date'),
      dataIndex: 'bookingDate',
      key: 'bookingDate',
      render: (bookingDate) => (
        <Grid container direction="column">
          <Grid item container justify="space-between">
            <Grid item><Typography variant="body1">{t("From")}</Typography></Grid>
            <Grid item><Typography variant="body1">{_.get(bookingDate, "startDate")}, {_.get(bookingDate, 'startTime')}</Typography></Grid>
          </Grid>
          <Grid item container justify="space-between">
            <Grid item><Typography variant="body1">{t("To")}</Typography></Grid>
            <Grid item><Typography variant="body1">{_.get(bookingDate, "endDate")}, {_.get(bookingDate, 'endTime')}</Typography></Grid>
          </Grid>
        </Grid>
      )
    },
    {
      title: t('Payment Status'),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (paymentStatus) => (
        <Tag color={_.get(paymentStatus, 'color')}>{t(_.get(paymentStatus, 'name'))}</Tag>
      )
    },
    {
      title: t('Booking Status'),
      dataIndex: 'bookingStatus',
      key: 'bookingStatus',
      render: (bookingStatus) => (
        <Tag color={_.get(bookingStatus, 'color')}>{t(_.get(bookingStatus, 'name'))}</Tag>
      )
    },
  ]
  return (
    <Grid container justify="space-between">
      <Grid sm={5} xs={12} item container direction="column" style={{ border: "solid 1px #ddd", borderRadius: 5, padding: 24 }}>
        {isFetching ? <Skeleton active /> :
          [
            <Grid item>
              <Typography variant="h6">{fullName}</Typography>
            </Grid>,
            <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
              <Grid item><Typography variant="subtitle2">{t("Email")}</Typography></Grid>
              <Grid item><Typography variant="subtitle2"><b>{email}</b></Typography></Grid>
            </Grid>,
            <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
              <Grid item><Typography variant="subtitle2">{t("Phone")}</Typography></Grid>
              <Grid item><Typography variant="subtitle2"><b>{phone}</b></Typography></Grid>
            </Grid>,
            <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
              <Grid item><Typography variant="subtitle2">{t("Customer No")}</Typography></Grid>
              <Grid item><Typography variant="subtitle2"><b>{customerNo}</b></Typography></Grid>
            </Grid>,
            <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
              <Grid item><Typography variant="subtitle2">{t("Company")}</Typography></Grid>
              <Grid item><Typography variant="subtitle2"><b>{company}</b></Typography></Grid>
            </Grid>,
            <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
              <Grid item><Typography variant="subtitle2">{t("Address")}</Typography></Grid>
              <Grid item><Typography variant="subtitle2"><b>{address}</b></Typography></Grid>
            </Grid>,
            <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
              <Grid item><Typography variant="subtitle2">{t("Term")}</Typography></Grid>
              <Grid item><Tag color={term.color}>{term.name}</Tag></Grid>
            </Grid>,
            <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
              <Grid item><Typography variant="subtitle2">{t("Account Type")}</Typography></Grid>
              <Grid item><Tag color={accountType.color}>{accountType.name}</Tag></Grid>
            </Grid>,
            <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
              <Grid item><Typography variant="subtitle2">{t("Status")}</Typography></Grid>
              <Grid item><Tag color={status.color}>{status.name}</Tag></Grid>
            </Grid>,
            <Grid item container spacing={1} alignItems="flex-end" style={{ marginTop: 12 }}>
              <Grid item><Typography variant="subtitle2">{t("Ad")}</Typography></Grid>
              <Grid item><Tag color={ad.color}>{ad.name}</Tag></Grid>
            </Grid>,
          ]
        }

      </Grid>

      <Grid sm={6} xs={12} item container style={{ border: "solid 1px #ddd", borderRadius: 5, padding: 24 }} justify="space-between">
        {isFetching || isAnalyticsFetching ? <Skeleton active /> :
          [
            <Grid item><Typography variant="button">{t("total bookings")}</Typography></Grid>,
            <Grid item><Typography variant="body2">{t("This year")} </Typography></Grid>,
            <PerformanceChart style={{ width: "100%" }} data={analyticsData} period="thisYear" />
          ]
        }
      </Grid>

      <Grid xs={12} item container style={{ border: "solid 1px #ddd", borderRadius: 5, padding: 16, marginTop: 48 }}>
        {isFetching ? <Skeleton active /> :
          [
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => onChangeTab(value)}
            >
              <Tab label={t("INCOMING")} value={0} />
              <Tab label={t("HISTORY")} value={1} />
            </Tabs>,

            <Table
              style={{ width: "100%" }}
              size="medium"
              columns={columns}
              dataSource={bookingItems || []}
              loading={isBookingFetching}
              pagination={{
                current: bookingPage,
                total: bookingTotal,
                pageSize: bookingPageSize,
                hideOnSinglePage: true,
                onChange: (page, pageSize) => onChangePage(page)
              }}
            />
          ]
        }
      </Grid>
    </Grid>
  )
}

const UserList = (props) => {
  let { match: { params: { detailType, id } } } = props
  const forStaff = detailType == 'staff'

  const {
    t,
    match,
    currentIsAdmin,

    onDeleteUser=f=>f,
  } = props

  return (
    <Paper>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t(`${forStaff ? "STAFF" : "CUSTOMER"} DETAIL`)}
        subTitle={props.email}
        extra={[
          <Popconfirm
            placement="bottomRight"
            title={t("The user data cannot be recovered after deletion Do you still want to delete")}
            onConfirm={() => onDeleteUser(id)}
            okText={t("Yes")}
            cancelText={t("No")}
          >
            <Button
              color="primary"
              variant="outlined"
              size="small"
              disabled={!currentIsAdmin}
            >
              {t("Delete")}
            </Button>
          </Popconfirm>,
          <Link
            style={{ marginLeft: 8 }}
            to={`${match.url.replace('detail', 'edit')}`}
            disabled={forStaff && !currentIsAdmin}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={forStaff && !currentIsAdmin}
            >
              {t("Edit")}
            </Button>
          </Link>,
        ]}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        {forStaff ? <StaffView {...props} /> : <CustomerView {...props} />}
      </div>
    </Paper>
  )
}

export default withRouter(withTranslation("user_management")(UserList))

import * as actionTypes from '../actionTypes/public.actionTypes'

export function getConfiguration() {
  return ({type: actionTypes.GET_CONFIGURATION})
}

export function getConfigurationSuccess(data) {
  return ({type: actionTypes.GET_CONFIGURATION_SUCCESS, data})
}

export function getConfigurationFail() {
  return ({type: actionTypes.GET_CONFIGURATION_FAIL})
}

export function getProfile() {
  return ({type: actionTypes.GET_PROFILE})
}

export function getProfileSuccess(data) {
  return ({type: actionTypes.GET_PROFILE_SUCCESS, data})
}

export function getProfileFail(data) {
  return ({type: actionTypes.GET_PROFILE_FAIL, data})
}

export function changeLanguage(data) {
  return ({type: actionTypes.CHANGE_LANGUAGE, data})
}

export function changeLanguageComplete(data) {
  return ({type: actionTypes.CHANGE_LANGUAGE_COMPLETE, data})
}
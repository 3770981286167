import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Button as MaterialButton } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form, Select, Tag } from 'antd'
import { roundToUnit, convertDiscountToSpecified } from '../../../helpers/helpers'
import { CURRENCY_MAPPING } from '../../../helpers/constant'
import {get} from 'lodash'
import AddIcon from 'mdi-react/AddIcon'
import RemoveIcon from 'mdi-react/DeleteOutlineIcon'
import {makeStyles} from '@material-ui/core/styles'
import {withTranslation} from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  facilitiesServiceContent: {
    borderTop: '1px solid #ddd',
    padding: '20px'
  },
  selectField: {
    '& .ant-select': {
      width: '80%',
    },
    '& .ant-form-item': {
      marginBottom: '0'
    }
  },
  cateringContent: {
    borderTop: '1px solid #ddd',
    padding: '20px'
  },
  buttonAddItem: {
    color: theme.colorAccent
  },
  faclityItemName: {
    display: 'flex',
    alignItems: 'center'
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  formContent: {
    marginBottom: '20px' 
  },
  title: {
    color: theme.colorAccent,
    borderBottom: `2px solid ${theme.colorAccent}`
  }
}))

const AddDiscountForm = props => {
  const classes = useStyles()
  const { t, form: { getFieldDecorator, validateFields, getFieldValue }, isSearchingExtraPrice, bookingSession, configurationInfo  } = props
  // const listSelectedFacilities = get(bookingSession, 'extra_facilities', [])
  // const listSelectedCaterings = get(bookingSession, 'catering_services', [])
  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if(!errors) {

      }
    })
  }
  // console.log(listSelectedFacilities)
  return (
    <Grid container>
      <Grid item xs={12} md={12} className={classes.facilitiesServiceContent} >
        {/* <Typography variant='h6' component="span" className={classes.title}>Discount</Typography> */}
        <Form
          onSubmit={handleSubmit}
        >
          <Grid container style={{marginBottom: '20px'}} >
            <Grid item xs={4}>
              <Typography variant="h6" component="h6">
                {t('Discount Name')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" component="h6">
                {t('Discount Amount')}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container className={classes.formContent} >
            <Grid item xs={5} className={classes.selectField}>
              <Form.Item>
                {
                  getFieldDecorator('applied', {
                    rules: [{required: true, message: 'Facility is required'}]
                  })(
                    <Select
                      loading={isSearchingExtraPrice}
                    >
                      <Select.Option value={1}>Rooms</Select.Option>
                      <Select.Option value={2}>Catering Service</Select.Option>
                      <Select.Option value={2}>Facilities</Select.Option>
                    </Select>
                  )
                }
              </Form.Item>

            </Grid>
            <Grid item xs={3} className={classes.selectField}>
              <Form.Item>
                {
                  getFieldDecorator('offer', {
                    rules: [{required: true, message: 'Quantity is required'}]
                  })(
                    <Select>
                      <Select.Option value={1}>Back Friday</Select.Option>
                      <Select.Option value={2}>New Year</Select.Option>
                      <Select.Option value={3}>Optional</Select.Option>
                    </Select>
                  )
                }
              </Form.Item>
            </Grid>
            <Grid item xs={3} className={classes.selectField}>
              <Form.Item>
                {
                  getFieldDecorator('rate', {
                    rules: [{required: true, message: 'Quantity is required'}]
                  })(
                    <Select>
                      <Select.Option value={1}>-10%</Select.Option>
                      <Select.Option value={2}>-20%</Select.Option>
                      <Select.Option value={3}>-50%</Select.Option>
                      <Select.Option value={3}>...</Select.Option>
                    </Select>
                  )
                }
              </Form.Item>
            </Grid>
            <Grid item xs={1}>
              <MaterialButton className={classes.buttonAddItem}
                type="submit"
              ><AddIcon /></MaterialButton>
            </Grid>
          </Grid>
           */}
          {get(bookingSession, 'discounts', []).map((item, index) => {
            return (
              <Grid key={index} container className={classes.faclityItem}>
                <Grid item xs={4}>
                  {item.name}
                </Grid>
                
                <Grid item xs={4}>
                  <Tag color="blue">Room: {item.discount_room}%</Tag>
                  <Tag color="volcano">Facility: {item.discount_exfacility}%</Tag>
                  <Tag color="purple">Catering: {item.discount_catering}%</Tag>
                </Grid>
              </Grid>
            )
          })}
        </Form>
      </Grid>

    </Grid>
  )
}

const mapState = ({bookingReducer, publicReducer}) => ({
  bookingSession: bookingReducer.bookingSession,
  configurationInfo: publicReducer.configurationInfo,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    
  },
  dispatch
)

AddDiscountForm.propTypes = {
  searchExtraPrice: PropTypes.func,
  removeExtraFacility: PropTypes.func,
  bookingSession: PropTypes.object,
  form: PropTypes.object,
  isSearchingExtraPrice: PropTypes.bool,
  configurationInfo: PropTypes.object,
}

export default Form.create({
  name: 'addExtraFacilities'
})(connect(mapState, mapDispatch)(withTranslation('common') (AddDiscountForm)))
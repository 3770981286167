import React, { useEffect, useMemo, useState, useCallback, forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, TextField, Button,
  Toolbar
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Editor from '../../../components/RichEditor'
import { Upload, Icon, Modal, Radio, DatePicker, TimePicker, Select } from 'antd'
import { Link } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { history } from '../../../config/store'
import MaterialTableComponent from '../../../components/Table/MaterialTable'

//Room imge
import Image1 from '../../../assets/images/Kopenhagen_1-2.jpg'
import Image2 from '../../../assets/images/Raum_19_Coaching.jpg'
import Image3 from '../../../assets/images/Stockholm_1.jpg'

import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'

const useStyles = makeStyles((theme) => ({
  formField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  roomInfoContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
    // '& .MuiGrid-item ': {
    //   padding: '15px'
    // }
  },
  roomCombinedContent: {
    display: 'block',
    minHeight: '225px',
    borderLeft: '1px solid #ddd'
  },
  chip: {
    minWidth: '120px',
    margin: '5px',
    justifyContent: 'space-between'
  },
  chip2: {
    padding: '5px 5px',
    margin: '5px',
  },
  addRoomCombinedIcon: {
    border: '1px solid',
    borderColor: theme.colorAccent,
    borderRadius: '50%',
    color: theme.colorAccent,
    width: '18px',
    height: '18px',
  },
  addRoomCombinedContent: {
    width: '100%'
  },
  padding15px: {
    padding: '15px'
  },
  roomCategoryLabelContent: {
    padding: '10px 0'
  },
  descriptionLabel: {
    padding: '10px 0'
  },
  addMoreRow: {
    '& .MuiTableCell-root': {
      color: 'rgba(162, 162, 162, 0.87)',
    },
    '& .MuiInput-underline:before': {
      border: 'unset'
    }
  },
  addMoreRowButtonContent: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      width: '80px'
    }
  },
  roomImagesContent: {
    margin: '20px 0'
  },
  roomImagesUploadContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
  },
  formSelectField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '200px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  roomsSelectedContent: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',

  },
  createNewTypeButton: {
    color: theme.colorAccent
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  roomListContent: {
    margin: '20px 0'
  },
  roomListButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  editViewTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorAccent,
    paddingBottom: '10px',
    borderBottom: `2px solid ${theme.colorAccent}`,
    marginBottom: '20px',
    marginTop: '30px',

  },
  radioContent: {
    padding: '10px 0',
    '& .ant-radio-wrapper': {
      marginBottom: '20px'
    }
  },
  selectContent: {
    '& .ant-select': {
      width: '80%'
    }
  }
}))

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

function createData(id, customer, email, company) {
  return { id, customer, email, company }
}

const rows = [
  createData(12, 'Alice', 'alice@email.com', 'ABC'),
  createData(13, 'Angelina', 'agelina@email.com', 'DEF'),
  createData(14, 'Micky Mouse', 'mouse.micky@email.com', 'GHI'),
]

const NewDiscount = props => {
  const { editView, roomType, openCustomDiscountModal, useCustom } = props
  const classes = useStyles()

  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: Image1,
    },
    {
      uid: '-2',
      name: 'image.png',
      status: 'done',
      url: Image2,
    },
    {
      uid: '-3',
      name: 'image.png',
      status: 'done',
      url: Image3,
    },
  ])

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  )

  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }

  const handleChange = ({ fileList }) => setFileList(fileList)

  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
    { key: 'customer', numeric: true, disablePadding: false, label: 'Customer' },
    { key: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { key: 'company', numeric: true, disablePadding: false, label: 'Company' },
  ]


  return (
    <Container>
      <Grid container >
        {editView &&
          <Grid item xs={12} md={12} >
            <Typography variant='h5' component="div"  >
              <Link onClick={() => history.goBack()} className={classes.editViewTitle} >
                <ChevronLeftIcon /> Edit Room Type {roomType}
              </Link>
            </Typography>
          </Grid>
        }
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={2} md={3}>
              <Typography variant='h6'>Discount Detail</Typography>
            </Grid>
            <Grid item xs={10} md={9} className={classes.roomInfoContent}>
              <Grid container>
                <Grid item xs={6} md={6} className={classes.padding15px}>
                  <Typography>Discount offer title</Typography>
                  <TextField
                    className={classes.formField}
                    // id="outlined-error"
                    label=""
                  // variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6} className={classes.padding15px}>
                  {/* <Typography>ID</Typography>
                  <TextField
                    className={classes.formField}
                    // id="outlined-error"
                    label=""
                  // variant="outlined"
                  /> */}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={12} className={classes.padding15px}>
                  <Typography className={classes.descriptionLabel}>Description</Typography>
                  <Editor />
                </Grid>
                <Grid item md={12} className={classes.padding15px}>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography>Discount limit/booking</Typography>
                      <TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                        defaultValue="-10%"
                      // variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Typography>Gift offer/booking</Typography>
                      <TextField
                        className={classes.formField}
                        // id="outlined-error"
                        label=""
                        // defaultValue="Morning"
                      // variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} className={classes.padding15px}>
                  <Grid container >
                    <Grid item xs={3} md={3} className={classes.roomCategoryLabelContent}>
                      <Typography>Room Category</Typography>
                    </Grid>
                    <Grid item xs={3} md={3} className={classes.radioContent}>
                      <Radio.Group>
                        <Radio value={1}>Once time only</Radio>
                        <Radio value={2}>Daily</Radio>
                        <Radio value={3}>Special day</Radio>
                      </Radio.Group>
                    </Grid>
                    <Grid item xs={6} md={6}>

                      <Toolbar className={classes.roomCombinedContent}>
                        <Typography variant="body1" component="h6">Time Setting</Typography>
                        <Grid container >
                          <Grid item xs={12}>
                            <Typography component="h6">Starting</Typography>

                          </Grid>
                          <Grid item xs={8}>
                            <DatePicker />
                          </Grid>
                          <Grid item xs={4}>
                            <TimePicker />
                          </Grid>
                        </Grid>
                        <Grid container >
                          <Grid item xs={12}>
                            <Typography component="h6">Ending</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <DatePicker />
                          </Grid>
                          <Grid item xs={4}>
                            <TimePicker />
                          </Grid>
                        </Grid>
                      </Toolbar>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={12}>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography variant="body1">Applied to</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.selectContent}>
                      <Select>
                        <Select.Option value={'room'}>Rooms</Select.Option>
                        <Select.Option value={'room_type'}>Room types</Select.Option>
                        <Select.Option value={'customer'}>Customer</Select.Option>
                      </Select>
                    </Grid>
                    <Grid item xs={3}>
                      <Radio.Group defaultValue="all" buttonStyle="solid">
                        <Radio.Button value="all">All</Radio.Button>
                        <Radio.Button value="custom"
                          onClick={() => openCustomDiscountModal()}
                        >Custom</Radio.Button>
                      </Radio.Group>
                    </Grid>
                  </Grid>
                  {useCustom &&
                    <Grid item xs={12}>
                      <MaterialTableComponent
                        columns={headCells}
                        rows={rows}
                        title="List Customer"
                      />
                    </Grid>
                  }
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container className={classes.roomImagesContent}>
          <Grid item xs={2} md={3}>
            <Typography variant='h6'>Thumbnail</Typography>
            <Typography variant='caption'>800x600 (px) Max 1Mb</Typography>
          </Grid>
          <Grid item xs={10} md={9} className={classes.roomImagesUploadContent}>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Grid>
        </Grid>
       */}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.footerContent}>
          <Button
            onClick={() => history.goBack()}
            className={classes.backButton}
            variant="contained" color="default"
            disableElevation
          >
            Discard
          </Button>
          <Link to={paths.ListRoomTypes}>
            <Button variant="contained" color="primary" disableElevation>
              {'Publish'}
            </Button>
          </Link>
        </div>
      </Grid>
    </Container >
  )
}

NewDiscount.propTypes = {
  editView: PropTypes.bool,
  roomType: PropTypes.string,
  openCustomDiscountModal: PropTypes.func,
  useCustom: PropTypes.bool,
}

NewDiscount.defaultProps = {
  editView: false,
  roomType: ''
}

export default NewDiscount
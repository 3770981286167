import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as C from '../../actionTypes/bookings/list'
import * as DETAIL_C from '../../actionTypes/bookings/detail'
import * as selector from '../../selectors/bookings/list'
import { BOOKING } from '../../constants/models'
import { history } from '../../config/store'

const { LIST_BY } = BOOKING

function* listBookings({api}) {
  const reqPayload = yield select(selector.generateReqPayload)
  const selectedTab = yield select(selector.getSelectedTab)

  try {
    let response = null
    if (selectedTab == LIST_BY.INCOMING) {
      response = yield call(api.booking.getListIncomingBookings, reqPayload)
    } else {
      response = yield call(api.booking.getListHistoryBookings, reqPayload)
    }
    
    yield put({type: C.LIST_BOOKING_COMPLETED, payload: response})

  } catch (e) {
    yield put({type: C.LIST_BOOKING_FAILED, payload: e})
    console.error(e)
  }
  
}

function* filterBookings({api}) {
  yield call(listBookings, {api})
  yield put({ type: C.SUBMIT_FILTER_COMPLETED })
}

function listSaga(deps) {
  return [
      takeLeading(C.LIST_BOOKING, listBookings, deps),
      takeLatest(C.CHANGE_PAGE, listBookings, deps),
      takeLatest(C.CHANGE_TAB, listBookings, deps),
      takeLatest(DETAIL_C.CANCEL_BOOKING_COMPLETED, listBookings, deps),
      takeLatest(C.SUBMIT_FILTER, filterBookings, deps),
  ]
}

export default listSaga

import { combineReducers } from 'redux'
import listUser from './list'
import detailUser from './detail'
import newUser from './new'
import editUser from './edit'


const reducers = combineReducers({
  list: listUser,
  detail: detailUser,
  new: newUser,
  edit: editUser,
});

export default reducers

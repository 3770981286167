import React, { Component, useState } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch } from 'react-router-dom'
import MdLink from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Skeleton, Modal } from 'antd'
import { Table, Tag, Divider, Input, Popconfirm, InputNumber } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import * as actions from '../../actions/bookings/detail'
import * as selectors from '../../selectors/bookings/detail'
import { Typography } from '@material-ui/core';



const Detail = (props) => {
  let match = useRouteMatch();

  const [cancelNotifyModalVisible, showCancelNotifyModal] = useState(false)
  const {
    t,

    isFetching,
    confirmationNo,
    paymentMethod,
    paymentStatus,
    paymentTotal,
    bookingDate,
    bookingStatus,
    additionalRequirement,
    customer,
    notes,
    canSaveNotes,
    savingNotes,
    invoice,
    canCancelBooking,
    cancellingBooking,

    isRefundVisible,
    isRefundable,
    invoiceNumber,
    chargedAmount,
    refundedAmount,

    onChangeNotes,
    onSaveNotes,
    onCancelBooking,
    showRefundModal,
  } = props


  return [
    <Paper style={{ border: "1px solid whitesmoke" }}>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t("BOOKING DETAIL")}
        subTitle={isFetching ? t("loading") + "..." : confirmationNo}
        extra={[
          <Popconfirm
            placement="bottomLeft"
            title={t("Are you sure to cancel this booking?")}
            okText={t("Yes")}
            cancelText={t("No")}
            onConfirm={() => showCancelNotifyModal(true)}
          >
            <Button
              size="small"
              variant="contained"
              disabled={!canCancelBooking || cancellingBooking}
            >
              {cancellingBooking && <CircularProgress size={12} />}
              {t("Cancel booking")}
          </Button>
          </Popconfirm>,
        ]}
      />

      <div style={{ padding: 24 }}>

        {isFetching ?
          <Skeleton /> :
          <Grid container justify="space-evenly">
            <Grid item xs={12} sm={6} container direction="column" spacing={1}>
              <Grid item><Typography variant="h6"><b>{t("Booking Info")}</b></Typography></Grid>

              <Grid item container spacing={2} >
                <Grid item xs={8} sm={4}><Typography variant="subtitle1">{t("Confirmation No")}</Typography></Grid>
                <Grid item><Typography variant="subtitle1"><b>{confirmationNo}</b></Typography></Grid>
              </Grid>

              <Grid item container spacing={2} >
                <Grid item xs={8} sm={4}><Typography variant="subtitle1">{t("Booking Date")}</Typography></Grid>
                <Grid item xs={8} sm={4} container direction="column">
                  <Grid item container justify="space-between">
                    <Grid item>{t("From")}</Grid>
                    <Grid item>{_.get(bookingDate, 'startDate')}, {_.get(bookingDate, 'startTime')}</Grid>
                  </Grid>

                  <Grid item container justify="space-between">
                    <Grid item>{t("To")}</Grid>
                    <Grid item>{_.get(bookingDate, 'endDate')}, {_.get(bookingDate, 'endTime')}</Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container spacing={2} >
                <Grid item xs={8} sm={4}><Typography variant="subtitle1">{t("Booking Status")}</Typography></Grid>
                <Grid item><Tag color={_.get(bookingStatus, 'color')}>{_.get(bookingStatus, 'name')}</Tag></Grid>
              </Grid>

              <Grid item container spacing={2} >
                <Grid item xs={8} sm={4}><Typography variant="subtitle1">{t("Additional Info")}</Typography></Grid>
                <Grid item><Typography variant="body1">{additionalRequirement}</Typography></Grid>
              </Grid>

            </Grid>

            <Grid item xs={12} sm={6} container direction="column" spacing={1}>
              <Grid item><Typography variant="h6"><b>{t("Customer Info")}</b></Typography></Grid>
              <Grid item container spacing={2} >
                <Grid item sm={4} md={3}><Typography variant="subtitle1">{t("Name")}</Typography></Grid>
                <Grid item><Typography variant="subtitle1"><b>{_.get(customer, 'name')}</b></Typography></Grid>
              </Grid>

              <Grid item container spacing={2} >
                <Grid item sm={4} md={3}><Typography variant="subtitle1">{t("Email")}</Typography></Grid>
                <Grid item><Typography variant="subtitle1"><b>{_.get(customer, 'email')}</b></Typography></Grid>
              </Grid>

              <Grid item container spacing={2} >
                <Grid item sm={4} md={3}><Typography variant="subtitle1">{t("Company")}</Typography></Grid>
                <Grid item><Typography variant="subtitle1"><b>{_.get(customer, 'company')}</b></Typography></Grid>
              </Grid>

              <Grid item container spacing={2} >
                <Grid item sm={4} md={3}><Typography variant="subtitle1">{t("Customer No")}</Typography></Grid>
                <Grid item><Typography variant="subtitle1"><b>{_.get(customer, 'customerNo')}</b></Typography></Grid>
              </Grid>

            </Grid>
          </Grid>
        }
      </div>
    </Paper>,

    <Paper style={{ marginTop: 32, padding: 24, border: "1px solid whitesmoke" }}>
      <Grid container justify="space-between">
        <Grid item><Typography variant="button"><b>{t("Payment")}</b></Typography></Grid>
        <Grid item xs={6} container spacing={2} justify="flex-end">
          <Grid item>
            {isRefundVisible &&
              <Button
                size="small"
                variant="contained"
                disabled={!isRefundable}
                onClick={showRefundModal}
              >
                {t("Refund")}
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>

      {isFetching ?
        <Skeleton /> :
        <Grid container style={{ marginTop: 16 }} justify="space-between" spacing={1}>
          <Grid item xs={12} sm={6} xl={4} container>
            <Grid item container spacing={2} >
              <Grid item xs={4} xm={2}><Typography variant="subtitle1">{t("Total")}</Typography></Grid>
              <Grid item><Typography variant="subtitle1"><b>{paymentTotal}</b></Typography></Grid>
            </Grid>

            <Grid item container spacing={2} >
              <Grid item xs={4} xm={2}><Typography variant="subtitle1">{t("Method")}</Typography></Grid>
              <Grid item><Tag color={_.get(paymentMethod, "color")}>{t(_.get(paymentMethod, 'name'))}</Tag></Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={4} xm={2}><Typography variant="subtitle1">{t("Status")}</Typography></Grid>
              <Grid item><Tag color={_.get(paymentStatus, "color")}>{t(_.get(paymentStatus, 'name'))}</Tag></Grid>
            </Grid>
          </Grid>


          <Grid item xs={12} sm={6} xl={4} container>
            <Grid item container spacing={2} >
              <Grid item xs={6} sm={3} style={{minWidth: 160}}><Typography variant="subtitle1">{t("Invoice Number")}</Typography></Grid>
              <Grid item><Typography variant="subtitle1"><b>{invoiceNumber}</b></Typography></Grid>
            </Grid>

            <Grid item container spacing={2} >
              <Grid item xs={6} sm={3} style={{minWidth: 160}}><Typography variant="subtitle1">{t("Charged Amount")}</Typography></Grid>
              <Grid item><Typography variant="subtitle1"><b>{chargedAmount}</b></Typography></Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={6} sm={3} style={{minWidth: 160}}><Typography variant="subtitle1">{t("Refunded Amount")}</Typography></Grid>
              <Grid item><Typography variant="subtitle1"><b>{refundedAmount}</b></Typography></Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    </Paper>,

    <Paper style={{ marginTop: 32, padding: 24, border: "1px solid whitesmoke" }}>
      <Grid container justify="space-between">
        <Grid item><Typography variant="button"><b>{t("Notes")}</b></Typography></Grid>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            disabled={!canSaveNotes || savingNotes}
            onClick={onSaveNotes}
          >
            {savingNotes && <CircularProgress size={16} />}
            {t("Save")}
          </Button>
        </Grid>
      </Grid>

      {isFetching ?
        <Skeleton /> :
        <Grid container style={{ marginTop: 16 }}>
          <Input.TextArea
            rows={6}
            value={notes}
            disabled={savingNotes}
            onChange={e => onChangeNotes(e.target.value)}
          />
        </Grid>
      }
    </Paper>,

    <Paper style={{ marginTop: 32, padding: 24, border: "1px solid whitesmoke" }}>
      <Grid container justify="space-between">
        <Grid item><Typography variant="button"><b>{t("DETAIL")}</b></Typography></Grid>
        <Grid item>
          <MdLink
            disabled={_.get(invoice, 'isFetching')}
            underline="none"
            rel="noopener noreferrer"
            target='_blank'
            download
            href={_.get(invoice, 'pdfUrl')}
          >
            <Button size="small" variant="contained">{t("Export PDF")}</Button>
          </MdLink>
        </Grid>
      </Grid>

      {isFetching || _.get(invoice, "isFetching") ?
        <Skeleton /> :
        <Grid container style={{ marginTop: 16 }}>
          <iframe
            srcDoc={_.get(invoice, 'html')}
            frameborder='0'
            allowfullscreen
            style={{ width: "100%", height: "968px" }}
          />
        </Grid>
      }
    </Paper>,

    <SendEmailNotifyModal
      {...props}
      visible={cancelNotifyModalVisible}
      onOk={() => {
        onCancelBooking({notify: true})
        showCancelNotifyModal(false)
      }}
      onCancel={() => {
        onCancelBooking({notify: false})
        showCancelNotifyModal(false)
      }}
    />
  ]
}

const RefundModal = props => {
  const {
    t,
    
    refundModal: {visible, refundAmount, isSubmitting},
    changeRefundAmount,
    closeRefundModal,
    submitRefund,
  } = props
  return (
    <Modal
      title={t("Refund amount")}
      visible={visible}
      onCancel={closeRefundModal}
      footer={[
        <Button
          key="closeBtn"
          onClick={closeRefundModal}
        >
          {t("Cancel")}
        </Button>,

        <Button 
          key="submitBtn"
          variant="contained"
          color="primary"
          disabled={isSubmitting} 
          onClick={submitRefund}
        >
          {t("OK")}
        </Button>
      ]}
    >
      <Input
        width={256}
        placeholder={t("Refund amount")}
        prefix="€"
        type="number"
        value={refundAmount}
        onChange={e => changeRefundAmount(e.target.value)}
        min={0}
      />
    </Modal>
  )
}

const SendEmailNotifyModal = (props) => {
  const {
    t,
  
    visible
  } = props

  return (
    <Modal
      visible={visible}
      title={t("Notify customer by email?")}
      okText={t("Yes")}
      cancelText={t("No")}
      {...props}
    >
      <p>{t("Do you want to notify cancelation status to customer by email?")}</p>
    </Modal>
  )
}

class BookingDetail extends Component {
  componentDidMount() {
    let { match: { params: { id } } } = this.props
    this.props.retrieveBooking(id)
  }

  render() {
    return [
      <Detail {...this.props} />,
      <RefundModal {...this.props} />
    ]
  }
}


const mapState = (state) => ({
  confirmationNo: selectors.getConfirmationNo(state),
  isFetching: selectors.isFetching(state),
  paymentMethod: selectors.getPaymentMethod(state),
  paymentStatus: selectors.getPaymentStatus(state),
  paymentTotal: selectors.getPaymentTotal(state),
  bookingDate: selectors.getBookingDate(state),
  bookingStatus: selectors.getBookingStatus(state),
  additionalRequirement: selectors.getAdditionalRequirement(state),
  customer: selectors.getCustomer(state),
  notes: selectors.getNotes(state),
  canSaveNotes: selectors.hasNotesChanged(state),
  invoice: selectors.getInvoice(state),
  savingNotes: selectors.savingNotes(state),

  isRefundVisible: selectors.isRefundVisible(state),
  isRefundable: selectors.isRefundable(state),
  invoiceNumber: selectors.getInvoiceNumber(state),
  chargedAmount: selectors.getChargedAmount(state),
  refundedAmount: selectors.getRefundedAmount(state),

  canCancelBooking: selectors.canCancelBooking(state),
  cancellingBooking: selectors.cancellingBooking(state),

  refundModal: selectors.getRefundModal(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveBooking: actions.retrieveBooking,
    onChangeNotes: actions.changeNotes,
    onSaveNotes: actions.saveNotes,
    onCancelBooking: actions.cancelBooking,

    showRefundModal: actions.showRefundModal,
    closeRefundModal: actions.closeRefundModal,
    changeRefundAmount: actions.changeRefundAmount,
    submitRefund: actions.submitRefund,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(withTranslation("booking_management")(BookingDetail)))

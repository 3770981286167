import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'

import { BOOKING, FORMAT } from '../../constants/models'
import * as helpers from '../../helpers/bookings'

const { DATE } = FORMAT
const { ITEM_TYPE, BOOKING_STATUS, PAYMENT_METHOD, PAYMENT_STATUS } = BOOKING

const getReducer = ({bookings}) => bookings.detail

export const getConfirmationNo = createSelector(
  [getReducer],
  ({data: {confirmation_prefix, id}}) => {
    return `${confirmation_prefix}${_.padStart(id, 4, '0')}`
  }
)

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const getPaymentMethod = createSelector(
  [getReducer],
  ({data: {payment_method}}) => helpers.getPaymentMethodTag(payment_method)
)

export const getPaymentStatus = createSelector(
  [getReducer],
  ({data: {payment_status, payment_method}}) => {
    if (payment_method == PAYMENT_METHOD.INVOICE) {
      return  {color: "green", name: "INVOICE"}
    }

    return helpers.getPaymentStatusTag(payment_status)
  }
)

export const getPaymentTotal = createSelector(
  [getReducer],
  ({data: {grand_total}}) => helpers.formatMoney(grand_total)
)

export const getBookingDate = createSelector(
  [getReducer],
  ({data: {booking_items}}) => {
    const roomItems = _.sortBy(_.filter(booking_items, it=>it.item_type == ITEM_TYPE.ROOM), it=>it.date)
    const startItem = _.first(roomItems)
    const lastItem = _.last(roomItems)
    return {
      startDate: moment(_.get(startItem, 'date')).format(DATE),
      startTime: helpers.slot2Time(_.get(startItem, 'timeslots', 0))[0],
      endDate: moment(_.get(lastItem, 'date')).format(DATE),
      endTime: helpers.slot2Time(_.get(lastItem, 'timeslots', 0))[1],
    }
  }
)

export const getBookingStatus = createSelector(
  [getReducer],
  ({data: {status}}) => helpers.getBookingStatusTag(status)
)

export const getAdditionalRequirement = createSelector(
  [getReducer],
  ({data: {additional_requirement}}) => additional_requirement
)

export const getCustomer = createSelector(
  [getReducer],
  ({data: {customer}}) => {
    return {
      name: `${_.get(customer, "first_name")} ${_.get(customer, "last_name")}`,
      email: _.get(customer, 'email'), 
      company: _.get(customer, 'company'), 
      phone: _.get(customer, 'phone'),
      customerNo: _.get(customer, 'customer_id')
    }
  }
)

export const getInvoiceNumber = createSelector(
  [getReducer],
  ({data}) => {
    const defaultInvoiceNo = _.get(data, 'invoice_number')
    return _.get(data, 'payment_charge.invoice_number', defaultInvoiceNo)
  }
)

export const getChargedAmount = createSelector(
  [getReducer],
  ({data}) => {
    return helpers.formatMoney(_.get(data, 'payment_charge.charge_amount', 0))
  }
)

export const getRefundedAmount = createSelector(
  [getReducer],
  ({data}) => {
    return helpers.formatMoney(_.get(data, 'payment_charge.refunded_amount', 0))
  }
)

export const isRefundable = createSelector(
  [getReducer],
  ({data}) => {
    return _.get(data, 'refundable', false)
  }
)

export const isRefundVisible = createSelector(
  [getReducer],
  ({data}) => {
    const isStripe = _.get(data, 'payment_method') == PAYMENT_METHOD.STRIPE
    const isCharged = _.includes(
      [
        PAYMENT_STATUS.DONE, 
        PAYMENT_STATUS.PARTIAL_REFUND,
        PAYMENT_STATUS.FULL_REFUND
      ], 
      _.get(data, 'payment_status')
    )
    
    return isStripe && isCharged
  }
)

export const getNotes = createSelector(
  [getReducer],
  ({data, notes}) => notes !== null ? notes : _.get(data, 'notes')
)

export const hasNotesChanged = createSelector(
  [getReducer],
  ({notes}) => notes !== null
)

export const savingNotes = createSelector(
  [getReducer],
  ({savingNotes}) => savingNotes
)

export const getInvoice = createSelector(
  [getReducer],
  ({invoice}) => invoice
)

export const canCancelBooking = createSelector(
  [getReducer],
  ({data: {status}}) => status !== BOOKING_STATUS.CANCELLED
)

export const cancellingBooking = createSelector(
  [getReducer],
  ({cancellingBooking}) => cancellingBooking
)

export const getRefundModal = createSelector(
  [getReducer],
  ({refundModal}) => refundModal
)

/** GENERATE REQ PAYLOAD */

export const generateSaveNotesReq = createSelector(
  [getReducer],
  ({data, notes}) => ({booking_id: _.get(data, 'id'), notes})
)

export const generateCancelBookingReq = createSelector(
  [getReducer],
  ({data}) => ({booking_id: _.get(data, 'id') })
)

export const generateRefundBookingReq = createSelector(
  [getReducer],
  ({data, refundModal: {refundAmount}}) => {
    return {
      booking_id: _.get(data, 'id'),
      amount: _.floor(Number(refundAmount) * 100),
    }
  }
)

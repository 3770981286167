import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination } from 'antd'
import { Table, Tag } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as _ from 'lodash'
import * as actions from '../../actions/bookings/list'
import BookingList from './BookingList'
import BookingDetail from './BookingDetail'


class AllBooking extends Component {
  componentDidMount() {
    this.props.listBookings()
  }

  render() {
    const {match} = this.props

    return (
      <Switch>
        <Route path={`${match.path}/detail/:id`} component={BookingDetail} />
        <Route path={`${match.path}`} component={BookingList} />
      </Switch>
    )
  }
}


const mapState = (state) => ({

})

const mapDispatch = dispatch => bindActionCreators(
  {
    listBookings: actions.listBookings,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(AllBooking))

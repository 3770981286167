import React, { useState, useEffect } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography, TextField, Button, IconButton } from '@material-ui/core'
import MaterialTableComponent from '../../../components/Table/MaterialTable'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import { Link } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { EditOutlined } from '@material-ui/icons'
import ServiceDetailModal from './modals/ServiceDetailModal'
import { bindActionCreators } from 'redux'
import {getListAllCaterings} from '../../../actions/caterings'
import {withRouter} from 'react-router-dom'
import LoadingIcon from 'mdi-react/LoadingIcon'
import { get } from 'lodash'
import {withTranslation} from 'react-i18next'


const useStyles = makeStyles(theme => ({
  root: {},
  formField: {
    borderRadius: '5px',
    width: '40%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  formFilterField: {
    borderRadius: '5px',
    width: '20%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px'
  },
  searchButton: {
    color: theme.colorAccent,
    marginRight: '20px'
  },
  newBookingButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '40px'
  }
}))


const CartingService = props => {
  const classes = useStyles()
  const {getListAllCaterings, history, isGettingCaterings, listCaterings, t, i18n} = props
  const [openServiceDetailModal, setOpenServiceDetailModal] = useState(false)

  useEffect(() => {
    getListAllCaterings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
    { key: 'name', numeric: true, disablePadding: false, label: t('SERVICES.Catering') },
    { key: 'is_combo', numeric: true, disablePadding: false, label: t('SERVICES.IsCombo'),
      render: (is_combo, row) => {
        return (
          <>
            {is_combo ? t('SERVICES.Yes') : t('SERVICES.No')}
            {/* {get(row, 'caterings', []).map((item, index) => {
              if(index === 0) return item.name
              return `, ${item.name}`
            })} */}
          </>
        )
      }
    },
    { key: 'description', numeric: true, disablePadding: false, label: t('SERVICES.Description') },
    {
      key: 'action',
      label: t('SERVICES.Action'),
      render: (_, row) => {
        return (
          <>
            <IconButton
              aria-label="toggle password visibility"
              // onClick={() => setOpenServiceDetailModal(true)}
            // onMouseDown={handleMouseDownPassword}
            >
              <EditOutlined />
            </IconButton>
          </>
        )
      }
    }
  ]



  return (
    <Container>
      {isGettingCaterings ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container>
        <Grid item md={12}>
          <Grid container>
            <Grid item md={8} className={classes.searchContent}>
              {/* <Typography component='div'>Search</Typography>
              <TextField
                // error
                className={classes.formField}
                // id="outlined-error"
                label="Room, Room Types, Facilies"
                variant="outlined"
              />
              <Button className={classes.searchButton} color="default" variant="contained" disableElevation>
                <MagnifyIcon />
              </Button>
              <Typography component='div'>Filter by</Typography>
              <TextField
                // error
                className={classes.formFilterField}
                // id="outlined-error"
                label="Date"
                variant="outlined"
              /> */}
            </Grid>
            <Grid item md={4} className={classes.newBookingButtonContent}>
              <Link to={paths.NewService}>
                <Button color="primary" variant="contained" disableElevation>
                  {t('SERVICES.AddService')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <MaterialTableComponent
            title={t('SERVICES.CateringsService')}
            columns={headCells}
            rows={listCaterings}
          />
        </Grid>
      </Grid>
      <ServiceDetailModal 
        isOpen={openServiceDetailModal}
        closeModal={() => setOpenServiceDetailModal(false)}
      />
    </Container>
  )
}

const mapState = ({cateringsReducer}) => ({
  isGettingCaterings: cateringsReducer.isGettingCaterings,
  listCaterings: cateringsReducer.listCaterings,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getListAllCaterings
  },
  dispatch
)

CartingService.propTypes = {
  className: PropTypes.string,
}

CartingService.defaultProps = {
  orders: []
}

export default connect(mapState, mapDispatch) (withTranslation('common') (withRouter(CartingService)))

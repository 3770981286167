import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as C from '../../actionTypes/users/edit'
import { history } from '../../config/store'
import { message } from 'antd'
import { USER, BOOKING } from '../../constants/models'
import * as selectors from '../../selectors/users/edit'
import * as _ from 'lodash'

function* retrieveUser({ api }, { payload }) {
  try {
    const response = yield call(api.user.getUserInfo, payload)
    yield put({ type: C.RETRIEVE_USER_COMPLETED, payload: response })
  } catch (e) {
    yield put({ type: C.RETRIEVE_USER_FAILED, payload: e })
    console.error(e)
  }
}

function* submit({ api }, { payload }) {
  const id = yield select(selectors.getUserId)
  try {
    const response = yield call(api.user.updateUser, id, payload)
    yield put({ type: C.SUBMIT_USER_COMPLETED, payload: response })
    history.goBack()
  } catch (e) {
    yield put({ type: C.SUBMIT_USER_FAILED, payload: e })
    message.error(JSON.stringify(_.get(e, 'data')))
    console.error(e)
  }
}

function editSaga(deps) {
  return [
    takeLeading(C.RETRIEVE_USER, retrieveUser, deps),
    takeLeading(C.SUBMIT_USER, submit, deps),
  ]
}

export default editSaga

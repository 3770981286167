import React, {useState} from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import MaterialTableComponent from '../../../../components/Table/MaterialTableHandlePaging'
import LoadingIcon from 'mdi-react/LoadingIcon'
import { withTranslation } from 'react-i18next'


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const BaseModal = (props) => {
  const { isOpen, closeModal, isLoading, rooms, onAddRooms, t } = props
  const headCells = [
    { key: 'id', disablePadding: true, label: '#ID' },
    { key: 'name', disablePadding: false, label: t('ROOMS.RoomName') },
    { key: 'capacity', disablePadding: false, label: t('ROOMS.Capacity') },
  ]

  const [selectedRooms, setSelectedRooms] = useState([])

  const onChangeSelectedRooms = (roomIds) => {
    setSelectedRooms(rooms.filter(r => roomIds.includes(r.id)))
  }

  return (
    <div>
      {isLoading ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Dialog onClose={closeModal} maxWidth="md" aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={closeModal}>
          {t('ROOMS.SelectRoomsToAdd')}
        </DialogTitle>
        <DialogContent dividers>
          <MaterialTableComponent
            columns={headCells}
            rows={rooms}
            onSelect={(ids) => onChangeSelectedRooms(ids)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeModal} color="default" disableElevation>
            {t('ROOMS.Cancel')}
          </Button>
          <Button autoFocus onClick={() => onAddRooms(selectedRooms)} color="primary" disableElevation>
            {t('ROOMS.Add')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default withTranslation('common')(BaseModal)


export default {
  colorAccent: '#cb4c4d',
  colorAccentHover: 'darken("#cb4c4d", 10%)',
  colorGreen: '#4ce1b6',
  colorGreenHover: 'darken("#4ce1b6", 10%)',
  colorAdditional: '#999999',
  colorAdditionalHover: 'darken("#999999", 10%)',
  colorYellow: '#f6da6e',
  colorYellowHover: 'darken("#f6da6e", 10%)',
  colorViolet: '#c88ffa',
  colorRed: '#ff4861',
  colorRedHover: 'darken("ff4861", 10%)',
  colorBlue: '#70bbfd',
  colorBlueHover: 'darken("#70bbfd", 10%)',
  colorGray: '#787985',
  palette: {
    primary: { 500: '#cb4c4d' },
  },
}
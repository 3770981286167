import React, { Component } from 'react'
import { withRouter, Link, useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Input, Form, Switch, Checkbox } from 'antd'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'

import ASSETS from '../../constants/assets'
import { USER } from '../../constants/models'
import { Select } from 'antd';
const { COUNTRIES } = ASSETS

const StaffView = ({
  t,

  isSubmitting,
  onSubmit,

  form,
}) => {
  const onFinish = (values) => {
    console.log('Success: ', values)
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const { getFieldDecorator, validateFields } = form

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onSubmit={e => {
        e.preventDefault()
        validateFields((errors, values) => {
          if (!errors) {
            // Do something
            console.log(values)
            values = _.set(values, 'is_staff', true)
            values = _.set(values, 'is_active', true)
            onSubmit(values)
          }
        })
      }}
    >
      <Form.Item
        label={t("First name")}
        name="firstName"
      >
        {getFieldDecorator('first_name', {
          rules: [{ required: true, message: t('Please input First Name!') }]
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Last name")}
        name="lastName"
      >
        {getFieldDecorator('last_name', {
          rules: [{ required: true, message: t('Please input Last Name!') }]
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Email")}
        name="email"
      >
        {getFieldDecorator('email', {
          rules: [{ required: true, message: t('Please input Email!') }]
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Is Admin")}
        name="isAdmin"
        valuePropName="checked"
      >
        {getFieldDecorator('is_admin', {
          valuePropName: 'checked',
          initialValue: false
        })(
          <Switch />
        )}
      </Form.Item>

      <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          type="primary"
          htmlType="submit"
          disabled={isSubmitting}
        >
          {t("Submit")}
        </Button>
      </Form.Item>
    </Form>
  )
}

const CustomerView = ({
  t,

  isSubmitting,
  onSubmit,

  form,
}) => {
  const { getFieldDecorator, validateFields } = form

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 12 }}
      layout="horizontal"
      onSubmit={e => {
        e.preventDefault()
        validateFields((errors, values) => {
          if (!errors) {
            // Do something
            console.log(values)
            onSubmit(values)
          }
        })
      }}
    >
      <Form.Item
        label={t("Is Company Customer")}
        name="isCompanyCustomer"
        valuePropName="checked"
      >
        {getFieldDecorator('is_belong_to_company', {
          valuePropName: 'checked',
          initialValue: false
        })(
          <Switch />
        )}
      </Form.Item>

      <Form.Item
        label={t("Company")}
        name="company"
      >
        {getFieldDecorator('company', {
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("First name")}
        name="firstName"
      >
        {getFieldDecorator('first_name', {
          rules: [{ required: true, message: t('Please input First Name!') }]
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Last name")}
        name="lastName"
      >
        {getFieldDecorator('last_name', {
          rules: [{ required: true, message: t('Please input Last Name!') }]
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Email")}
        name="email"
      >
        {getFieldDecorator('email', {
          rules: [{ required: true, message: t('Please input Email!'), type: "email" }]
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Phone")}
        name="phone"
      >
        {getFieldDecorator('phone', {
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Street")}
        name="street"
      >
        {getFieldDecorator('street', {
          rules: [{ required: true, message: t('Please input Street name!') }]
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("House Number")}
        name="houseNumber"
      >
        {getFieldDecorator('house_nbr', {
          rules: [{ required: true, message: t('Please input House Number!') }]
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Postcode")}
        name="postcode"
      >
        {getFieldDecorator('post_code', {
          rules: [{ required: true, message: t('Please input Postcode!') }]
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("City")}
        name="city"
      >
        {getFieldDecorator('city', {
          rules: [{ required: true, message: t('Please input City!') }]
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Country")}
        name="country"
      >
        {getFieldDecorator('country', {
          rules: [{ required: true, message: t('Please input Country!') }]
        })(
          // <Input />
          <Select
            showSearch
          >
            {_.map(COUNTRIES, c => (
              <Select.Option value={_.get(c, 'en')}>{_.get(c, 'en')}</Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item
        label={t("Customer No")}
        name="customerNo"
      >
        {getFieldDecorator('customer_id', {
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Addition")}
        name="addition"
      >
        {getFieldDecorator('addition', {
        })(
          <Input />
        )}
      </Form.Item>

      <Form.Item
        label={t("Is Long-Term Customer")}
        name="isLongTermCustomer"
        valuePropName="checked"
      >
        {getFieldDecorator('is_long_term_customer', {
          valuePropName: 'checked',
          initialValue: false
        })(
          <Switch />
        )}
      </Form.Item>

      <Form.Item
        label={t("Ad")}
        name="adAccepted"
        valuePropName="checked"
      >
        {getFieldDecorator('ad_accepted', {
          valuePropName: 'checked',
        })(
          <Switch />
        )}
      </Form.Item>

      <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          type="primary"
          htmlType="submit"
          disabled={isSubmitting}
        >
          {t("Submit")}
        </Button>
      </Form.Item>
    </Form>
  )
}

const UserNew = (props) => {
  let { match: { params: { detailType } } } = props
  const forStaff = detailType == 'staff'

  const {
    t,
  } = props

  return (
    <Paper>
      <PageHeader
        onBack={() => props.history.goBack()}
        title={t(`NEW ${forStaff ? "STAFF" : "CUSTOMER"}`)}
        subTitle={props.email}
      >
      </PageHeader>

      <div style={{ padding: 24 }}>
        {forStaff ? <StaffView {...props} /> : <CustomerView {...props} />}
      </div>
    </Paper>
  )
}

export default Form.create({
  name: "newUserForm"
})(withRouter(withTranslation("user_management")(UserNew)))

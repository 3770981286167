import React from 'react'
import PropTypes from 'prop-types'
import { Modal,  } from 'antd'
import { Grid, Typography, TextField, Button as MaterialButton, IconButton } from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import { RemoveRedEyeOutlined } from '@material-ui/icons'
import MaterialTableComponent from '../../../components/Table/MaterialTable'

const useStyles = makeStyles((theme) => ({
  formField: {
    borderRadius: '5px',
    width: '40%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  searchButton: {
    color: theme.colorAccent,
    marginRight: '20px'
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}))



function createData(id, customer, email, company) {
  return { id, customer, email, company}
}

const rows = [
  createData(12, 'Alice', 'alice@email.com', 'ABC'),
  createData(13, 'Angelina', 'agelina@email.com', 'DEF', ),
  createData(14, 'Micky Mouse', 'mouse.micky@email.com', 'GHI'),
]


const CustomDiscountModal = props => {
  const { isOpen, closeModal, confirmOffer } = props
  const classes = useStyles()

  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
    { key: 'customer', numeric: true, disablePadding: false, label: 'Customer' },
    { key: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { key: 'company', numeric: true, disablePadding: false, label: 'Company' },
  ]

  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      title="Customize this offer to"
      width={800}
      onOk={confirmOffer}
    >
      <Grid container>
        <Grid item xs={6} >
          <Typography variant="caption">
            {/* Total  */}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.searchContent} >
          <TextField
            // error
            className={classes.formField}
            // id="outlined-error"
            label=""
            variant="outlined"
          />
          <MaterialButton className={classes.searchButton} color="default" variant="contained" disableElevation>
            <MagnifyIcon />
          </MaterialButton>
        </Grid>
        <Grid item xs={12}>
          <MaterialTableComponent
            columns={headCells}
            rows={rows}
            title="List Customer"
          />
        </Grid>
      </Grid>

    </Modal>
  )
}

CustomDiscountModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  confirmOffer: PropTypes.func
}

export default CustomDiscountModal
import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as C from '../../actionTypes/discounts/list'
import * as selector from '../../selectors/discounts/list'
import { history } from '../../config/store'

function* listDiscounts({api}) {
  const reqPayload = yield select(selector.generateReqPayload)
  try {
    const response = yield call(api.discount.getListDiscount, reqPayload)
    yield put({type: C.LIST_DISCOUNT_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.LIST_DISCOUNT_FAILED, payload: e})
    console.error(e)
  }
}

function listSaga(deps) {
  return [
      takeLeading(C.LIST_DISCOUNT, listDiscounts, deps),
      takeLatest(C.CHANGE_PAGE, listDiscounts, deps),
      takeLatest(C.CHANGE_TAB, listDiscounts, deps),
  ]
}

export default listSaga

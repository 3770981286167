import { combineReducers } from 'redux'
import * as actionTypes from '../actionTypes/booking.actionTypes'
import * as userActionTypes from '../actionTypes/user.actionTypes'
// import * as storage from '../services/storage'
// import { LOCAL_STORAGE_KEY } from '../helpers/constant'
import moment from 'moment'
import { get, pick, omit, filter } from 'lodash'

const isGettingBookingSummary = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_BOOKING_SUMMARY:
    case actionTypes.LIST_BOOKINGS_BY_DATE_RANGE:
      return true
    case actionTypes.GET_BOOKING_SUMMARY_FAIL:
    case actionTypes.GET_BOOKING_SUMMARY_SUCCESS:
    case actionTypes.LIST_BOOKINGS_BY_DATE_RANGE_FAIL:
    case actionTypes.LIST_BOOKINGS_BY_DATE_RANGE_SUCCESS:
      return false
    default:
      return state
  }
}

const listBookingSummaries = (state = [], action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_BOOKING_SUMMARY_SUCCESS:
      return action.data
    default:
      return state
  }
}

const isSearchingAvailableRooms = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.SEARCH_AVAILABLE_ROOMS:
      return true
    case actionTypes.SEARCH_AVAILABLE_ROOMS_FAIL:
    case actionTypes.SEARCH_AVAILABLE_ROOMS_SUCCESS:
      return false
    default:
      return state
  }
}

const listAvailableRooms = (state = [], action) => {
  const { type } = action
  switch (type) {
    case actionTypes.SEARCH_AVAILABLE_ROOMS_SUCCESS:
      return action.data
    default:
      return state
  }
}

const bookingRequest = (state = {}, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.SAVE_BOOKING_REQUEST:
      return action.data
    default:
      return state
  }
}

const isCreatingBookingSession = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.CREATE_BOOKING_SESSION:
      return true
    case actionTypes.CREATE_BOOKING_SESSION_FAIL:
    case actionTypes.CREATE_BOOKING_SESSION_SUCCESS:
      return false
    default:
      return state
  }
}

const bookingSession = (state = {}, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.CREATE_BOOKING_SESSION_SUCCESS:
      return action.data
    case actionTypes.ATTACH_SESSION_CUSTOMER_SUCCESS:
      return action.data
    case actionTypes.ADD_EXTRA_FACILITIES_SUCCESS:
      return action.data
    case actionTypes.ADD_SESSION_CATERING_SUCCESS:
      return action.data
    case actionTypes.UPDATE_BOOKING_ROOM_SUCCESS:
      return action.data
    case actionTypes.REMOVE_SESSION_ITEMS_SUCCESS:
      return action.data
    case actionTypes.UPDATE_BOOKING_CATERINGS_SUCCESS:
      return action.data
    case actionTypes.UPDATE_BOOKING_EXTRA_FACILITIES_SUCCESS:
      return action.data
    case actionTypes.GET_CREATED_BOOKING_SUMMARY_SUCCESS:
      return action.data
    case actionTypes.CHANGE_BOOKING_ROOM_PRICE_SUCCESS:
      return action.data
    case actionTypes.REMOVE_BOOKING_STATE:
      // const {state, ...rest} = state
      return omit(state, ['state'])
    // case actionTypes.DESTROY_BOOKING_SESSION_SUCCESS:
    //   return {}
    case actionTypes.RESET_BOOKING_SESSION:
      return {}
    default:
      return state
  }
}

const isAttachingBookingCustomer = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.ATTACH_SESSION_CUSTOMER:
      return true
    case actionTypes.ATTACH_SESSION_CUSTOMER_FAIL:
    case actionTypes.ATTACH_SESSION_CUSTOMER_SUCCESS:
      return false
    default:
      return state
  }
}

const isGettingListRoomsBooked = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_LIST_ROOMS_BOOKED:
      return true
    case actionTypes.GET_LIST_ROOMS_BOOKED_FAIL:
    case actionTypes.GET_LIST_ROOMS_BOOKED_SUCCESS:
      return false
    default:
      return state
  }
}

const isListingRoomBookingsByDate = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.LIST_BOOKINGS_BY_DATE_RANGE:
      return true
    case actionTypes.LIST_BOOKINGS_BY_DATE_RANGE_FAIL:
    case actionTypes.LIST_BOOKINGS_BY_DATE_RANGE_SUCCESS:
      return false
    default:
      return state
  }
}

const listRoomsBooked = (state = [], action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_LIST_ROOMS_BOOKED_SUCCESS:
      return get(action, 'data', [])
    default:
      return state
  }
}


const isGettingExtraFacilities = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES:
      return true
    case actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES_SUCCESS:
    case actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES_FAIL:
      return false
    default:
      return state
  }
}

const listExtraFacilities = (state = [], action) => {
  const { type, data } = action
  switch (type) {
    case actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES_SUCCESS:
      return filter(data, it => it.is_active)
    case actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES_SUCCESS:
      console.log(data)
      state.map(item => {
        if (item.id === data.id) {
          item.listAvailable = data.listAvailable
        }
      })
      return state
    default:
      return state
  }
}

const listBookingsByDateRange = (state = [], action) => {
  const { type } = action
  switch (type) {
    case actionTypes.LIST_BOOKINGS_BY_DATE_RANGE_FAIL:
      return []
    case actionTypes.LIST_BOOKINGS_BY_DATE_RANGE_SUCCESS:
      return action.data
    default:
      return state
  }
}

const isGettingCaterings = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_LIST_CATERINGS:
    case actionTypes.ADD_SESSION_CATERING:
    case actionTypes.SEARCH_AVAILABLE_CATERINGS:
      return true
    case actionTypes.GET_LIST_CATERINGS_FAIL:
    case actionTypes.GET_LIST_CATERINGS_SUCCESS:
    case actionTypes.ADD_SESSION_CATERING_FAIL:
    case actionTypes.ADD_SESSION_CATERING_SUCCESS:
    case actionTypes.SEARCH_AVAILABLE_CATERINGS_FAIL:
    case actionTypes.SEARCH_AVAILABLE_CATERINGS_SUCCESS:
      return false
    default:
      return state
  }
}

const listCaterings = (state = [], action) => {
  const { type, data } = action
  switch (type) {
    case actionTypes.GET_LIST_CATERINGS_SUCCESS:
      return data
    default:
      return state
  }
}

const isSearchingAvailableExtraFacilities = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES:
    case actionTypes.ADD_EXTRA_FACILITIES:
      return true
    case actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES_FAIL:
    case actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES_SUCCESS:
    case actionTypes.ADD_EXTRA_FACILITIES_FAIL:
    case actionTypes.ADD_EXTRA_FACILITIES_SUCCESS:
      return false
    default:
      return state
  }
}

const chosenDateInBookingTable = (state = moment(), action) => {
  const { type } = action
  switch (type) {
    case actionTypes.SET_BOOKING_TABLE_DATE:
      return action.data
    default:
      return state
  }
}

const createdBookingSummary = (state = null, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_CREATED_BOOKING_SUMMARY_SUCCESS:
      return action.data
    case actionTypes.GET_CREATED_BOOKING_SUMMARY_FAIL:
      return null
    default:
      return state
  }
}

const isUpdatingBookingRoom = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.UPDATE_BOOKING_ROOM:
      return true
    case actionTypes.UPDATE_BOOKING_ROOM_FAIL:
    case actionTypes.UPDATE_BOOKING_ROOM_SUCCESS:
      return false
    default:
      return state
  }
}

const isLinkingSessionPayment = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.LINK_SESSION_PAYMENT:
      return true
    case actionTypes.LINK_SESSION_PAYMENT_FAIL:
    case actionTypes.LINK_SESSION_PAYMENT_SUCCESS:
      return false
    default:
      return state
  }
}

const newCreatedBooking = (state = null, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.CONFIRM_BOOKING_SUCCESS:
      return get(action, 'data')
    default:
      return state
  }
}

const isGettingListIncomingBookings = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_LIST_INCOMING_BOOKING:
    case actionTypes.GET_LIST_HISTORY_BOOKING:
      return true
    case actionTypes.GET_LIST_INCOMING_BOOKING_FAIL:
    case actionTypes.GET_LIST_INCOMING_BOOKING_SUCCESS:
      return false
    default:
      return state
  }
}

const listIncomingBookings = (state = [], action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_LIST_INCOMING_BOOKING_SUCCESS:
      return get(action, 'data.results', [])
    default:
      return state
  }
}

const listIncomingBookingsMeta = (state = {}, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_LIST_INCOMING_BOOKING_SUCCESS:
      return pick(get(action, 'data', {}), ['count', 'page', 'page_size'])
    default:
      return state
  }
}

const isUpdatingBookingState = (state = {}, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.UPDATE_BOOKING_STATE:
      return true
    case actionTypes.UPDATE_BOOKING_STATE_FAIL:
    case actionTypes.UPDATE_BOOKING_STATE_SUCCESS:
    case actionTypes.GET_CREATED_BOOKING_SUMMARY_SUCCESS:
    case actionTypes.GET_CREATED_BOOKING_SUMMARY_FAIL:
      return false
    default:
      return state
  }
}

const isCreatingCustomerInBooking = (state = false, action) => {
  const { type } = action
  switch (type) {
    case userActionTypes.CREATE_USER_IN_BOOKING:
      return true
    case userActionTypes.CREATE_USER_IN_BOOKING_SUCCESS:
    case userActionTypes.CREATE_USER_IN_BOOKING_FAIL:
    case actionTypes.GET_CREATED_BOOKING_SUMMARY_SUCCESS:
    case actionTypes.GET_CREATED_BOOKING_SUMMARY_FAIL:
      return false
    default:
      return state
  }
}



const isSearchingListCustomerInBooking = (state = [false], action) => {
  const { type } = action
  switch (type) {
    case actionTypes.SEARCH_LIST_CUSTOMER_IN_BOOKING:
      return true
    case actionTypes.SEARCH_LIST_CUSTOMER_IN_BOOKING_FAIL:
    case actionTypes.SEARCH_LIST_CUSTOMER_IN_BOOKING_SUCCESS:
      return false
    default:
      return state
  }
}

const listCustomerInBooking = (state = [], action) => {
  const { type } = action
  switch (type) {
    case actionTypes.SEARCH_LIST_CUSTOMER_IN_BOOKING_SUCCESS:
      return get(action, 'data.results')
    default:
      return state
  }
}

const listCustomerInBookingMeta = (state = {}, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.SEARCH_LIST_CUSTOMER_IN_BOOKING_SUCCESS:
      return pick(get(action, 'data', {}), ['count', 'page', 'page_size'])
    default:
      return state
  }
}

const backupDataInSelectCustomerBooking = (state = {}, action) => {
  const { type, data } = action
  switch (type) {
    case actionTypes.CHANGE_BACKUP_DATA_IN_SELECT_CUSTOMER_BOOKING:
      return { ...state, ...data }
    case actionTypes.CONFIRM_BOOKING_SUCCESS:
      return {}
    default:
      return state
  }
}

const backupDataInSelectRoomBooking = (state = {}, action) => {
  const { type, data } = action
  switch (type) {
    case actionTypes.CHANGE_BACKUP_DATA_IN_SELECT_ROOM_BOOKING:
      return { ...state, ...data }
    case actionTypes.CONFIRM_BOOKING_SUCCESS:
      return {}
    default:
      return state
  }
}

const isChangingBookingRoomPrice = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.CHANGE_BOOKING_ROOM_PRICE:
      return true
    case actionTypes.CHANGE_BOOKING_ROOM_PRICE_FAIL:
    case actionTypes.CHANGE_BOOKING_ROOM_PRICE_SUCCESS:
      return false
    default:
      return state
  }
}

const isGettingBookingDetail = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_BOOKING_DETAIL:
      return true
    case actionTypes.GET_BOOKING_DETAIL_SUCCESS:
    case actionTypes.GET_BOOKING_DETAIL_FAIL:
      return false
    default:
      return state
  }
}

const bookingDetail = (state = [], action) => {
  const { type } = action
  switch (type) {
    case actionTypes.GET_BOOKING_DETAIL_SUCCESS:
      return action.data
    default:
      return state
  }
}

const isGettingExtraFacilityPrice = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS:
      return true
    case actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_FAIL:
    case actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_SUCCESS:
      return false
    default:
      return state
  }
}

const extraFacilityPricings = (state = [], action) => {
  const { type } = action
  switch (type) {
    case actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_SUCCESS:
      return action.data
    default:
      return state
  }
}

const isGettingCateringPrice = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS:
      return true
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_FAIL:
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS:
      return false
    default:
      return state
  }
}


const cateringPricings = (state = [], action) => {
  const { type } = action
  switch (type) {
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS:
      return action.data
    default:
      return state
  }
}

const isConfirming = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.CONFIRM_BOOKING:
      return true
    case actionTypes.CONFIRM_BOOKING_FAIL:
    case actionTypes.CONFIRM_BOOKING_SUCCESS:
      return false
    default:
      return state
  }
}

const isDownloadingPDF = (state = false, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.DOWNLOAD_PDF:
      return true
    case actionTypes.DOWNLOAD_PDF_FAIL:
    case actionTypes.DOWNLOAD_PDF_SUCCESS:
      return false
    default:
      return state
  }
}

const bookingReducer = combineReducers({
  isGettingBookingSummary,
  listBookingSummaries,
  isSearchingAvailableRooms,
  listAvailableRooms,
  bookingRequest,
  isCreatingBookingSession,
  bookingSession,
  isAttachingBookingCustomer,
  isGettingListRoomsBooked,
  listRoomsBooked,
  isSearchingAvailableExtraFacilities,
  isGettingExtraFacilities,
  listExtraFacilities,
  isGettingCaterings,
  listCaterings,
  chosenDateInBookingTable,
  listBookingsByDateRange,
  isListingRoomBookingsByDate,
  createdBookingSummary,
  isUpdatingBookingRoom,
  isLinkingSessionPayment,
  newCreatedBooking,
  isGettingListIncomingBookings,
  listIncomingBookings,
  listIncomingBookingsMeta,
  isUpdatingBookingState,
  isCreatingCustomerInBooking,
  isSearchingListCustomerInBooking,
  listCustomerInBooking,
  listCustomerInBookingMeta,
  backupDataInSelectCustomerBooking,
  backupDataInSelectRoomBooking,
  isChangingBookingRoomPrice,
  isGettingBookingDetail,
  bookingDetail,
  isGettingExtraFacilityPrice,
  extraFacilityPricings,
  isGettingCateringPrice,
  cateringPricings,
  isConfirming,
  isDownloadingPDF
})

export default bookingReducer
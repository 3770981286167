import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Toolbar } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Image from '../../../assets/images/Stockholm_1.jpg'
import * as paths from '../../../constants/paths.constants'
import ASSETS from '../../../constants/assets'
import { history } from '../../../config/store'
import { login } from '../../../actions/auth'
import { Form, Input, Icon, Button } from 'antd'
import {withTranslation} from 'react-i18next'

const useStyles = makeStyles((theme) => {
  return ({
    logo: {
      marginTop: '50px',
      // marginBottom: '50px',
      color: theme.colorAccent,
      width: '20%',
      cursor: 'pointer',
      position: 'absolute',
      top: '0',
      left: '40%',
      // width: '300px',
    },
    background: {
      minHeight: '230px',
      height: '25%',
      backgroundImage: `url(${Image})`,
      '& img': {
        // height: '55%',
        width: '20%'
      },
      backgroundSize: '100%',
      backgroundPositionY: '455px',
      opacity: 0.2
    },
    title: {
      marginTop: '50px'
    },
    // formRoot: {
    //   textAlign: 'center',
    //   '& .MuiTextField-root': {
    //     margin: theme.spacing(1),
    //     width: 300,
    //   },
    // },
    formRoot: {
      maxWidth: '300px',
      margin: '0 auto',
      // textAlign: 'center',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 300,
      },
    },
    formField: {
      '& .MuiInputBase-root': {
        // backgroundColor: 'white',
        borderRadius: '15px'
      }
    },
    fontBold: {
      fontWeight: 'bold'
    },
    textWelcome: {
      padding: '20px 0',
      color: theme.colorGray
    },
    buttonGroup: {
      justifyContent: 'center',
      '& button': {
        width: 300,
        margin: '25px'
      }
    },
    forgotPassword: {
      cursor: 'pointer',
      textAlign: 'center'
    },
    registerContent: {
      marginTop: '30px'
    },
    registerLink: {
      cursor: 'pointer'
    }
  })
})

const Login = props => {
  const { t, login, form: { getFieldDecorator, validateFields }, isLogining } = props
  const classes = useStyles()
  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        login(values)
      }
    })
  }
  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid item md={12} sm={12} xs={12}>
        <Typography variant='h2' align='center' className={classes.background}>
        </Typography>
        <img src={ASSETS.LOGO} className={classes.logo} onClick={() => history.push(paths.HomePage)}
          alt=""
        />
        <Typography variant='h4' align='center' className={classes.title}>
          <span className='color-accent'>{t('AUTH.SignIn')}</span>
        </Typography>
        <Typography variant='h5' align='center' className={classes.textWelcome}>
          {t('AUTH.WelcomeBack')}
        </Typography>
        <Form className={classes.formRoot} autoComplete="off"
          onSubmit={handleSubmit}
          hideRequiredMark={true}
        // className={classes.loginForm}
        >
          <Grid container >
            <Grid item xs={12}>
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  rules: [
                    { required: true, message: t('AUTH.PleaseInputYourEmail') },
                    { type: 'email', message: t('AUTH.PleaseInputCorrectYourEmail') }
                  ],
                })(
                  <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                  />,
                )}
              </Form.Item>
              <Form.Item label={t('AUTH.Password')} >
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: t('AUTH.PleaseInputYourPassword') }],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={t('AUTH.Password')}
                  />,
                )}
              </Form.Item>
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                // error
                id="standard-basic"
                className={classes.formField}
                // id="outlined-error"
                label="Password"
              />
            </Grid> */}
            <Grid item xs={12}>
              <Toolbar className={classes.buttonGroup}>
                <Form.Item >
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLogining}
                  >
                    {t('AUTH.LogIn')}
                  </Button>
                </Form.Item>
              </Toolbar>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' className={classes.forgotPassword}>
                <Link to={paths.ForgotPasswordPage}>
                  {t('AUTH.ForgotPassword')}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Form>
      </Grid>
      {/* <Grid item md={8} sm={8} xs={8} className={classes.background}>
        <img src={Image}  alt="" />
      </Grid> */}
    </Grid>
  )
}

const mapState = ({ authReducer }) => ({
  isLogining: authReducer.isLogining
})

const mapDispatch = dispatch => bindActionCreators(
  {
    login
  },
  dispatch
)

Login.propTypes = {
  login: PropTypes.func,
  form: PropTypes.object,
  isLogining: PropTypes.bool,
}

// export default () ( () ())

export default Form.create({
  name: 'loginForm'
})(connect(mapState, mapDispatch)( withTranslation('common') (Login)))
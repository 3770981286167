import { combineReducers } from 'redux'
import listBooking from './list'
import detailBooking from './detail'


const reducers = combineReducers({
  list: listBooking,
  detail: detailBooking,
});

export default reducers

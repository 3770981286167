import { USER } from '../constants/models'
import moment from 'moment'
import * as _ from 'lodash'

const { ROLE } = USER

export const getRole = (user) => {
  const role = _.get(user, 'is_admin') || _.get(user, 'is_supper_user') ? 
    ROLE.ADMIN:
    _.get(user, 'is_staff') ? ROLE.STAFF: ROLE.CUSTOMER
  return role
}

export const getRoleTag = (role) => {
  
  switch (role) {
    case ROLE.ADMIN:
      return {color: "violet", name: "Admin"}
    case ROLE.STAFF:
      return {color: "orange", name: "Staff"}
    case ROLE.CUSTOMER:
      return {color: "blue", name: "Customer"}
    default:
      return {color: "gray", name: "Unknow"}
  }
}

export const getStatusTag = (status) => {
  if (status) {
    return {color: "green", name: "Active"}
  }

  return {color: "red", name: "Inactive"}
}

export const getTermTag = (is_longterm) => {
  if (is_longterm) {
    return {color: "violet", name: "Long Term"}
  }

  return {color: "blue", name: "Short Term"}
}

export const getCustomerTypeTag = (is_belong_to_company) => {
  if (is_belong_to_company) {
    return {color: "purple", name: "Company"}
  }

  return {color: "cyan", name: "Private"}
}

export const getAdTag = (ad_accepted) => {
  if (ad_accepted) {
    return {color: "green", name: "YES"}
  }

  return {color: "orange", name: "NO"}
}

import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import rootReducers from '../reducers'
import rootSaga from '../sagas/index'
import deps from './dependencies'

export const history = createBrowserHistory()

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  const getRootSaga = rootSaga()
  const dependencies = deps()

  const getSagaMiddleware = () => {
    if (process.env.NODE_ENV === 'development') {
      return [sagaMiddleware, createLogger({collapsed: true})]
    } else {
      return [sagaMiddleware]
    }
  }

  const store = createStore(
    rootReducers(history),
    composeEnhancer(
      applyMiddleware(
        thunkMiddleware,
        routerMiddleware(history),
        ...getSagaMiddleware(),
      )
    )
  )

  getRootSaga.forEach(saga => {
    sagaMiddleware.run(saga(dependencies))
  })

  return store
}

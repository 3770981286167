/* eslint-disable react/prop-types */
import { makeStyles } from '@material-ui/styles'
import { Badge, Calendar } from 'antd'
import { get, replace } from 'lodash'
import LoadingIcon from 'mdi-react/LoadingIcon'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { differenceInCalendarDays } from 'date-fns'

import { getBookingSummary } from '../../../actions/booking'

import * as paths from '../../../constants/paths.constants'

const useStyle = makeStyles(() => ({
  calendar: {
    '& .events': {
      listStyleType: 'unset',
    },
    '& .ant-fullcalendar-header': {
      '& .ant-radio-group': {
        display: 'none',
      },
    },
    '& .ant-fullcalendar-tbody': {
      '& .ant-fullcalendar-next-month-btn-day': {
        pointerEvents: 'none',
        '& .ant-fullcalendar-date': {
          display: 'none',
        },
      },
      '& .ant-fullcalendar-last-month-cell': {
        pointerEvents: 'none',
        '& .ant-fullcalendar-date': {
          display: 'none',
        },
      },
    },
  },
}))

function getMonthData(value) {
  if (value.month() === 8) {
    return 1394
  }
}

function monthCellRender(value) {
  const num = getMonthData(value)
  return num ? (
    <div className='notes-month'>
      <section>{num}</section>
      <span>Backlog number</span>
    </div>
  ) : null
}

const ViewAllBooking = (props) => {
  const {
    history,
    listBookingSummaries,
    i18n,
    t,
    isGettingBookingSummary,
    getBookingSummary = (f) => f,
  } = props
  const classes = useStyle()

  moment.locale(i18n.language === 'en' ? 'en-gb' : 'de')

  const dateCellRender = (value) => {
    const bookingInfo = listBookingSummaries.find(
      (item) => moment(item.date).date() === value.date()
    )
    const successBookings = get(bookingInfo, 'nb_successful_bookings', 0)
    const cancelledBookings = get(bookingInfo, 'nb_failed_bookings', 0)
    return (
      <ul className='events'>
        <li>
          {successBookings ? (
            <Badge
              status='success'
              text={`${t('{num} booking').replace('{num}', successBookings)}`}
            />
          ) : (
            ''
          )}
        </li>
      </ul>
    )
  }

  const changeMonth = (value) => {
    const startOfMonth = moment(value).startOf('month').format('YYYY-MM-DD')
    const endOfMonth = moment(value).endOf('month').format('YYYY-MM-DD')
    getBookingSummary({
      date_range: [startOfMonth, endOfMonth],
    })
  }

  const handleSelectDate = (data) => {
    const pathWithChosenDate = replace(
      paths.BookingTable,
      ':date',
      data.format('YYYY-MM-DD')
    )
    
    return history.push(pathWithChosenDate)
  }

  return (
    <div>
      {isGettingBookingSummary ? (
        <div className='loading-refresh'>
          <LoadingIcon />
        </div>
      ) : (
        ''
      )}

      <Calendar
        {...{ dateCellRender, monthCellRender }}
        className={classes.calendar}
        onSelect={(data) => handleSelectDate(data)}
        onPanelChange={(value) => changeMonth(value)}
      />
    </div>
  )
}

const mapState = ({ bookingReducer }) => ({
  isGettingBookingSummary: bookingReducer.isGettingBookingSummary,
})

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getBookingSummary,
    },
    dispatch
  )

export default withRouter(
  connect(mapState, mapDispatch)(withTranslation('common')(ViewAllBooking))
)

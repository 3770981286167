import {createSelector} from 'reselect'
import { DISCOUNT } from '../../constants/models'
import * as _ from 'lodash'

const getReducer = ({discounts}) => discounts.new

export const getName = createSelector(
  [getReducer],
  ({name}) => name
)

export const getDescription = createSelector(
  [getReducer],
  ({description}) => description
)

export const getSelectableApplyToes = () => [
  { id: DISCOUNT.OBJECT_TYPE.CUSTOMER, name: "Customer" },
  { id: DISCOUNT.OBJECT_TYPE.ROOM, name: "Room" }
]

export const getSelectedApplyTo = createSelector(
  [getReducer],
  ({selectedApplyTo}) => selectedApplyTo
)

export const getSelectableApplyTypes = createSelector(
  [getReducer],
  ({selectedApplyTo}) => {
    return [
      {id: DISCOUNT.APPLY_TYPE.ALL, name: "All"},
      ...(selectedApplyTo == DISCOUNT.OBJECT_TYPE.CUSTOMER ? [{id: DISCOUNT.APPLY_TYPE.NEW, name: "New"}]: []),
      {id: DISCOUNT.APPLY_TYPE.CUSTOM, name: "Custom"}
    ]
  }
)

export const getSelectedApplyType = createSelector(
  [getReducer],
  ({selectedApplyType}) => selectedApplyType
)

export const isSelectedCustomersVisiable = createSelector(
  [getReducer],
  ({selectedApplyType, selectedApplyTo}) => {
    return selectedApplyTo == DISCOUNT.OBJECT_TYPE.CUSTOMER && selectedApplyType == DISCOUNT.APPLY_TYPE.CUSTOM
  }
)

export const getSelectableCustomers = createSelector(
  [getReducer],
  ({searchCustomer: {items}, selectedCustomers}) => {

    return _.map(items, it => ({
      id: it.id,
      name: `${it.first_name} ${it.last_name}`,
      email: it.email,
      company: it.company,
      customerNo: it.customer_id,
      selected: !!_.find(selectedCustomers, cus => cus.id == it.id),
    }))
  }
)

export const getSelectedCustomers = createSelector(
  [getReducer],
  ({selectedCustomers}) => selectedCustomers
)

export const getSearchCustomerPagination = createSelector(
  [getReducer],
  ({searchCustomer: {page, pageSize, total}}) => ({page, pageSize, total})
)

export const getSearchCustomerText = createSelector(
  [getReducer],
  ({searchCustomer: {searchText}}) => searchText
)

export const isSearchingCustomer = createSelector(
  [getReducer],
  ({searchCustomer: {isSearching}}) => isSearching
)

export const getSelectedRooms = createSelector(
  [getReducer],
  ({selectedRooms}) => selectedRooms
)

export const isSelectedRoomsVisible = createSelector(
  [getReducer],
  ({selectedApplyType, selectedApplyTo}) => {
    return selectedApplyTo == DISCOUNT.OBJECT_TYPE.ROOM && selectedApplyType == DISCOUNT.APPLY_TYPE.CUSTOM
  }
)

export const getSelectableRooms = createSelector(
  [getReducer],
  ({searchRoom: {items}, selectedRooms}) => _.map(items, it => ({
    id: it.id,
    name: it.name,
    size: it.size,
    maxParticipants: it.max_attendances,
    roomType: it.room_type,
    selected: !!_.find(selectedRooms, room => room.id === it.id)
  }))
)

export const getRoomSearchText = createSelector(
  [getReducer],
  ({searchRoom: {searchText}}) => searchText
)

export const getSearchRoomPagination = createSelector(
  [getReducer],
  ({searchRoom: {page, pageSize, total}}) => ({page, pageSize, total})
)

export const isSearchingRooms = createSelector(
  [getReducer],
  ({searchRoom: {isSearching}}) => isSearching
)

export const getRoomDiscount = createSelector(
  [getReducer],
  ({roomDiscount}) => roomDiscount
)

export const getFacilityDiscount = createSelector(
  [getReducer],
  ({facilityDiscount}) => facilityDiscount
)

export const getCateringDiscount = createSelector(
  [getReducer],
  ({cateringDiscount}) => cateringDiscount
)

export const getStartDate = createSelector(
  [getReducer],
  ({startDate}) => startDate
)

export const getEndDate = createSelector(
  [getReducer],
  ({endDate}) => endDate
)

export const isActive = createSelector(
  [getReducer],
  ({isActive}) => isActive
)

export const isCreating = createSelector(
  [getReducer],
  ({isCreating}) => isCreating
)

export const isLoadingSuggestCustomers = createSelector(
  [getReducer],
  ({isLoadingSuggestCustomers}) => isLoadingSuggestCustomers
)

export const getSuggestCustomers = createSelector(
  [getReducer],
  ({selectedCustomers, suggestedCustomers}) => {
    const existIds = _.map(selectedCustomers, item => item.id)
    const results = _.filter(suggestedCustomers, it => !_.includes(existIds, it.id))
    return _.map(results, it => ({
      id: it.id,
      name: `${it.first_name} ${it.last_name}`,
      email: it.email,
      company: it.company,
      customerNo: it.customer_id,
    }))
  }
)

export const getValidation = createSelector(
  [getReducer],
  ({validation}) => validation
)

/**
 * ==================================================================================================================
 * GENERATE PAYLOAD BASED ON REDUCER DATA
 * ==================================================================================================================
 */
export const generateSearchCustomersReq = createSelector(
  [getReducer],
  ({searchCustomer: {page, searchText}}) => {
    return { filter: "customer", page, page_size: 5, search: searchText }
  }
)


export const generateSearchRoomsReq = createSelector(
  [getReducer],
  ({searchRoom: {page, searchText}}) => {
    return {q: searchText, page, page_size: 5}
  }
)


export const generateSuggestCustomersReq = createSelector(
  [getReducer],
  ({selectedCustomers}) => {
    const companies = _.filter(_.map(selectedCustomers, it => it.company), c => !!c)
    const firstCompany = _.first(companies)
    if (firstCompany) {
      return {page: 1, page_size: 100, company: firstCompany, filter: "customer"}
    }
    return null
  }
)


export const generateCreateReq = createSelector(
  [getReducer],
  ({
    name, 
    description, 
    selectedApplyTo, 
    selectedApplyType, 
    selectedCustomers, 
    selectedRooms,
    roomDiscount,
    facilityDiscount,
    cateringDiscount,
    startDate,
    endDate,
    isActive,
  }) => {
    let apply_objects = selectedApplyTo == DISCOUNT.OBJECT_TYPE.CUSTOMER ? selectedCustomers : selectedRooms
    apply_objects = _.map(apply_objects, it => it.id)
    return {
      name, 
      description, 
      object_type: selectedApplyTo,
      apply_type: selectedApplyType,
      apply_objects,
      discount_room: roomDiscount,
      discount_exfacility: facilityDiscount,
      discount_catering: cateringDiscount,
      start_at: startDate,
      end_at: endDate,
      is_active: isActive
    }
  }
)

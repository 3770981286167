import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListFacilitiesComponents from './components/ListFacilities'
import ExfacilityManagement from '../../containers/exfacilities/Management'
import ListCategory from './components/ListCategory'
import Header from './components/Header'
import {Switch, Route} from 'react-router-dom'
import * as paths from '../../constants/paths.constants'
class ListFacilities extends Component {
  render() {
    return (
      <div>
        <Route path={paths.ListFacilities} component={ExfacilityManagement} />
      </div>
    )
  }
}

ListFacilities.propTypes = {

}

export default ListFacilities
import * as actionTypes from '../actionTypes/booking.actionTypes'

export function getBookingSummary(data) {
  return ({type: actionTypes.GET_BOOKING_SUMMARY, data})
}
export function getBookingSummarySuccess(data) {
  return ({type: actionTypes.GET_BOOKING_SUMMARY_SUCCESS, data})
}
export function getBookingSummaryFail(data) {
  return ({type: actionTypes.GET_BOOKING_SUMMARY_FAIL, data})
}

export function searchAvailableRooms(data) {
  return ({type: actionTypes.SEARCH_AVAILABLE_ROOMS, data})
}

export function searchAvailableRoomsSuccess(data) {
  return ({type: actionTypes.SEARCH_AVAILABLE_ROOMS_SUCCESS, data})
}

export function searchAvailableRoomsFail() {
  return ({type: actionTypes.SEARCH_AVAILABLE_ROOMS_FAIL})
}

export function saveBookingRequest(data) {
  return ({type: actionTypes.SAVE_BOOKING_REQUEST, data})
}

export function createBookingSession(data) {
  return ({type: actionTypes.CREATE_BOOKING_SESSION, data})
}

export function createBookingSessionSuccess(data) {
  return ({type: actionTypes.CREATE_BOOKING_SESSION_SUCCESS, data})
}

export function createBookingSessionFail() {
  return ({type: actionTypes.CREATE_BOOKING_SESSION_FAIL})
}

export function attachSessionCustomer(data) {
  return ({type: actionTypes.ATTACH_SESSION_CUSTOMER, data})
}

export function attachSessionCustomerSuccess(data) {
  return ({type: actionTypes.ATTACH_SESSION_CUSTOMER_SUCCESS, data})
}

export function attachSessionCustomerFail(data) {
  return ({type: actionTypes.ATTACH_SESSION_CUSTOMER_FAIL, data})
}

export function getListRoomsBooked(data) {
  return ({type: actionTypes.GET_LIST_ROOMS_BOOKED, data})
}

export function getListRoomsBookedSuccess(data) {
  return ({type: actionTypes.GET_LIST_ROOMS_BOOKED_SUCCESS, data})
}

export function getListRoomsBookedFail(data) {
  return ({type: actionTypes.GET_LIST_ROOMS_BOOKED_FAIL, data})
}

export function searchAvailableExtraFacilities(data) {
  return ({type: actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES, data})
}

export function searchAvailableExtraFacilitiesSuccess(data) {
  return ({type: actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES_SUCCESS, data})
}

export function searchAvailableExtraFacilitiesFail(data) {
  return ({type: actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES_FAIL, data})
}

export function removeSessionItems(data) {
  return ({type: actionTypes.REMOVE_SESSION_ITEMS, data})
}

export function removeSessionItemsSuccess(data) {
  return ({type: actionTypes.REMOVE_SESSION_ITEMS_SUCCESS, data})
}

export function removeSessionItemsFail(data) {
  return ({type: actionTypes.REMOVE_SESSION_ITEMS_FAIL, data})
}

export function addExtraFacility(data) {
  return ({type: actionTypes.ADD_EXTRA_FACILITIES, data})
}

export function addExtraFacilitySuccess(data) {
  return ({type: actionTypes.ADD_EXTRA_FACILITIES_SUCCESS, data})
}

export function addExtraFacilityFail(data) {
  return ({type: actionTypes.ADD_EXTRA_FACILITIES_FAIL, data})
}

export function searchCaterings(data) {
  return ({type: actionTypes.SEARCH_AVAILABLE_CATERINGS, data})
}

export function searchCateringsSuccess(data) {
  return ({type: actionTypes.SEARCH_AVAILABLE_CATERINGS_SUCCESS, data})
}

export function searchCateringsFail(data) {
  return ({type: actionTypes.SEARCH_AVAILABLE_CATERINGS_FAIL, data})
}

export function addSessionCatering(data) {
  return ({type: actionTypes.ADD_SESSION_CATERING, data})
}

export function addSessionCateringSuccess(data) {
  return ({type: actionTypes.ADD_SESSION_CATERING_SUCCESS, data})
}

export function addSessionCateringFail(data) {
  return ({type: actionTypes.ADD_SESSION_CATERING_FAIL, data})
}

export function getListExtraFacilities(data) {
  return ({type: actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES, data})
}

export function getListExtraFacilitiesSuccess(data) {
  return ({type: actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES_SUCCESS, data})
}

export function getListExtraFacilitiesFail() {
  return ({type: actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES_FAIL})
}

export function getListCaterings(data) {
  return ({type: actionTypes.GET_LIST_CATERINGS, data})
}

export function getListCateringsSuccess(data) {
  return ({type: actionTypes.GET_LIST_CATERINGS_SUCCESS, data})
}

export function getListCateringsFail() {
  return ({type: actionTypes.GET_LIST_CATERINGS_FAIL})
}
export function setBookingTableDate(data) {
  return ({type: actionTypes.SET_BOOKING_TABLE_DATE, data})
}

export function listBookingsByDate(data) {
  return ({type: actionTypes.LIST_BOOKINGS_BY_DATE_RANGE, data})
}

export function listBookingsByDateSuccess(data) {
  return ({type: actionTypes.LIST_BOOKINGS_BY_DATE_RANGE_SUCCESS, data})
}

export function listBookingsByDateFail() {
  return ({type: actionTypes.LIST_BOOKINGS_BY_DATE_RANGE_FAIL })
}

export function updateBookingRoom(data) {
  return ({type: actionTypes.UPDATE_BOOKING_ROOM, data})
}

export function updateBookingRoomSuccess(data) {
  return ({type: actionTypes.UPDATE_BOOKING_ROOM_SUCCESS, data})
}

export function updateBookingRoomFail() {
  return ({type: actionTypes.UPDATE_BOOKING_ROOM_FAIL })
}

export function updateBookingCaterings(data) {
  return ({type: actionTypes.UPDATE_BOOKING_CATERINGS, data})
}

export function updateBookingCateringsSuccess(data) {
  return ({type: actionTypes.UPDATE_BOOKING_CATERINGS_SUCCESS, data})
}

export function updateBookingCateringsFail() {
  return ({type: actionTypes.UPDATE_BOOKING_CATERINGS_FAIL })
}

export function updateBookingExtraFacilities(data) {
  return ({type: actionTypes.UPDATE_BOOKING_EXTRA_FACILITIES, data})
}

export function updateBookingExtraFacilitiesSuccess(data) {
  return ({type: actionTypes.UPDATE_BOOKING_EXTRA_FACILITIES_SUCCESS, data})
}

export function updateBookingExtraFacilitiesFail() {
  return ({type: actionTypes.UPDATE_BOOKING_EXTRA_FACILITIES_FAIL })
}

export function getCreatedBookingSummary() {
  return ({type: actionTypes.GET_CREATED_BOOKING_SUMMARY})
}
export function getCreatedBookingSummarySuccess(data) {
  return ({type: actionTypes.GET_CREATED_BOOKING_SUMMARY_SUCCESS, data})
}
export function getCreatedBookingSummaryFail() {
  return ({type: actionTypes.GET_CREATED_BOOKING_SUMMARY_FAIL})
}

export function linkSessionPayment(data) {
  return ({type: actionTypes.LINK_SESSION_PAYMENT, data})
}

export function linkSessionPaymentSuccess(data) {
  return ({type: actionTypes.LINK_SESSION_PAYMENT_SUCCESS, data})
}

export function linkSessionPaymentFail(data) {
  return ({type: actionTypes.LINK_SESSION_PAYMENT_FAIL, data})
}


export function confirmBooking(data) {
  return ({type: actionTypes.CONFIRM_BOOKING, data})
}

export function confirmBookingSuccess(data) {
  return ({type: actionTypes.CONFIRM_BOOKING_SUCCESS, data})
}

export function confirmBookingFail(data) {
  return ({type: actionTypes.CONFIRM_BOOKING_FAIL, data})
}

export function destroyBookingSession(data) {
  return ({type: actionTypes.DESTROY_BOOKING_SESSION, data})
}

export function destroyBookingSessionSuccess(data) {
  return ({type: actionTypes.DESTROY_BOOKING_SESSION_SUCCESS, data})
}

export function destroyBookingSessionFail(data) {
  return ({type: actionTypes.DESTROY_BOOKING_SESSION_FAIL, data})
}

export function getListIncomingBookings(data) {
  return ({type: actionTypes.GET_LIST_INCOMING_BOOKING, data})
}

export function getListIncomingBookingsSuccess(data) {
  return ({type: actionTypes.GET_LIST_INCOMING_BOOKING_SUCCESS, data})
}

export function getListIncomingBookingsFail(data) {
  return ({type: actionTypes.GET_LIST_INCOMING_BOOKING_FAIL, data})
}

export function getListHistoryBookings(data) {
  return ({type: actionTypes.GET_LIST_HISTORY_BOOKING, data})
}

export function getListHistoryBookingsSuccess(data) {
  return ({type: actionTypes.GET_LIST_HISTORY_BOOKING_SUCCESS, data})
}

export function getListHistoryBookingsFail(data) {
  return ({type: actionTypes.GET_LIST_HISTORY_BOOKING_FAIL, data})
}

export function updateBookingState(data) {
  return ({type: actionTypes.UPDATE_BOOKING_STATE, data})
}

export function updateBookingStateSuccess(data) {
  return ({type: actionTypes.UPDATE_BOOKING_STATE_SUCCESS, data})
}

export function updateBookingStateFail(data) {
  return ({type: actionTypes.UPDATE_BOOKING_STATE_FAIL, data})
}

export function retrieveExtraFacilityItemPricings(data) {
  return ({type: actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS, data})
}

export function retrieveExtraFacilityItemPricingsSuccess(data) {
  return ({type: actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_SUCCESS, data})
}

export function retrieveExtraFacilityItemPricingsFail(data) {
  return ({type: actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_FAIL, data})
}

export function retrieveCateringItemPricings(data) {
  return ({type: actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS, data})
}

export function retrieveCateringItemPricingsSuccess(data) {
  return ({type: actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS, data})
}

export function retrieveCateringItemPricingsFail(data) {
  return ({type: actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_FAIL, data})
}

export function searchListCustomerInBooking(data) {
  return ({type: actionTypes.SEARCH_LIST_CUSTOMER_IN_BOOKING, data})
}

export function searchListCustomerInBookingSuccess(data) {
  return ({type: actionTypes.SEARCH_LIST_CUSTOMER_IN_BOOKING_SUCCESS, data})
}

export function searchListCustomerInBookingFail(data) {
  return ({type: actionTypes.SEARCH_LIST_CUSTOMER_IN_BOOKING_FAIL, data})
}

export function onChangeBackupDateInSelectCustomerBooking(data) {
  return ({type: actionTypes.CHANGE_BACKUP_DATA_IN_SELECT_CUSTOMER_BOOKING, data})
}

export function onChangeBackupDateInSelectRoomBooking(data) {
  return ({type: actionTypes.CHANGE_BACKUP_DATA_IN_SELECT_ROOM_BOOKING, data})
}

export function changeBookingRoomPrice(data) {
  return ({type: actionTypes.CHANGE_BOOKING_ROOM_PRICE, data})
}

export function changeBookingRoomPriceSuccess(data) {
  return ({type: actionTypes.CHANGE_BOOKING_ROOM_PRICE_SUCCESS, data})
}

export function changeBookingRoomPriceFail(data) {
  return ({type: actionTypes.CHANGE_BOOKING_ROOM_PRICE_FAIL, data})
}

export function getBookingDetail(data) {
  return ({type: actionTypes.GET_BOOKING_DETAIL, data})
}

export function getBookingDetailSuccess(data) {
  return ({type: actionTypes.GET_BOOKING_DETAIL_SUCCESS, data})
}

export function getBookingDetailFail(data) {
  return ({type: actionTypes.GET_BOOKING_DETAIL_FAIL, data})
}

export function resetBookingSession() {
  return ({type: actionTypes.RESET_BOOKING_SESSION})
}

export function removeBookingState() {
  return ({type: actionTypes.REMOVE_BOOKING_STATE})
}

export function downloadPdf(data) {
  return ({type: actionTypes.DOWNLOAD_PDF, data})
}

export function downloadPdfSuccess(data) {
  return ({type: actionTypes.DOWNLOAD_PDF_SUCCESS, data})
}

export function downloadPdfFail(data) {
  return ({type: actionTypes.DOWNLOAD_PDF_FAIL, data})
}
import {createSelector} from 'reselect'
import * as _ from 'lodash'
import {
  CURRENCY_MAPPING
} from '../helpers/constant'


export const getLocale = ({publicReducer}) => _.get(publicReducer, 'locale.lang', 'en')
export const getCcy = ({publicReducer}) => _.get(publicReducer, 'configurationInfo.currency', "EUR")
export const getCcySymbol = ({publicReducer}) => CURRENCY_MAPPING[_.get(publicReducer, 'configurationInfo.currency', "EUR")]
export const getUnit = ({publicReducer}) => _.get(publicReducer, 'configurationInfo.unit', 100)

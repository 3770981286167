import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { replace } from 'lodash'
import { history } from '../../../config/store'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography, TextField, Button, IconButton } from '@material-ui/core'
import MaterialTableComponent from '../../../components/Table/MaterialTableHandlePaging'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { pathWithId } from '../../../helpers/helpers'
import { CreateOutlined, RemoveRedEyeOutlined, EditOutlined } from '@material-ui/icons'
import { bindActionCreators } from 'redux'
import * as selectors from '../../../selectors/room'
import { listRooms } from '../../../actions/room'
import { withTranslation } from 'react-i18next'
import {Form} from 'antd'
import LoadingIcon from 'mdi-react/LoadingIcon'


const useStyles = makeStyles(theme => ({
  root: {},
  formField: {
    borderRadius: '5px',
    width: '40%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  formFilterField: {
    borderRadius: '5px',
    width: '20%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px'
  },
  searchButton: {
    color: theme.colorAccent,
    marginRight: '20px'
  },
  newBookingButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '40px'
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const ListRooms = props => {
  const {
    listRooms,
    roomRows,
    isListingRoom,
    t
  } = props

  const classes = useStyles()

  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID' },
    { key: 'roomName', numeric: true, disablePadding: false, label: t('ROOMS.RoomName') },
    { key: 'facility', numeric: true, disablePadding: false, label: t('ROOMS.PermanentFacilities') },
    { key: 'roomType', numeric: true, disablePadding: false, label: t('ROOMS.RoomType') },
    { key: 'capacity', numeric: true, disablePadding: false, label: t('ROOMS.Capacity') },
    { key: 'size', numeric: true, disablePadding: false, label: `${t('ROOMS.Size')}  (m2)` },
    {
      key: 'action',
      label: t('ROOMS.Action'),
      render: (_, row) => {
        return (
          <>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => history.push(pathWithId(paths.RoomDetail, row.id))}
            // onMouseDown={handleMouseDownPassword}
            >
              <RemoveRedEyeOutlined />
            </IconButton>
            <IconButton
              aria-label="toggle"
              onClick={() => history.push(pathWithId(paths.UpdateRoom, row.id))}
            // onMouseDown={handleMouseDownPassword}
            >
              <EditOutlined />
            </IconButton>
          </>
        )
      }
    }
  ]

  const [searchValue, setSearchValue] = useState('')
  useEffect(() => {
    listRooms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    listRooms({search: searchValue})
  }

  return (
    <Container>
      {isListingRoom ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container>
        <Grid item md={12}>
          <Grid container>
            <Grid item md={8} className={classes.searchContent}>
              <Form className={classes.form} onSubmit={handleSubmit}
                layout="inline"
              >
                <Typography component='div' className={classes.alignItemsCenter}>{t('ROOMS.Search')}</Typography>
                <TextField
                  // error
                  className={classes.formField}
                  // id="outlined-error"
                  label="Room, Room Types, Facilities"
                  variant="outlined"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Button
                  className={classes.searchButton}
                  color="default"
                  variant="contained"
                  type="submit"
                  disableElevation
                  loading={isListingRoom}
                >
                  <MagnifyIcon />
                </Button>
              </Form>
            </Grid>
            <Grid item md={4} className={classes.newBookingButtonContent}>
              <Link to={paths.NewRoom}>
                <Button color="primary" variant="contained" disableElevation>
                  {t('ROOMS.AddRoom')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <MaterialTableComponent
            title={t('ROOMS.Rooms')}
            columns={headCells}
            rows={roomRows}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

const mapState = ({ roomReducer }) => ({
  isListingRoom: roomReducer.isListingRoom,
  roomRows: selectors.roomRows({ roomReducer }),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    listRooms
  },
  dispatch
)

ListRooms.propTypes = {
  className: PropTypes.string,
  listRooms: PropTypes.func,
}

ListRooms.defaultProps = {
  orders: []
}

export default withRouter(connect(mapState, mapDispatch)(withTranslation('common')(ListRooms)))

import {combineReducers} from 'redux'
import * as actionTypes from '../actionTypes/user.actionTypes'
// import * as storage from '../services/storage'
// import { LOCAL_STORAGE_KEY } from '../helpers/constant'


const isSearchingListUser = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.SEARCH_LIST_CUSTOMER:
    return true
  case actionTypes.SEARCH_LIST_CUSTOMER_FAIL:
  case actionTypes.SEARCH_LIST_CUSTOMER_SUCCESS:
    return false
  default:
    return state
  }
}

const isListingUser = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.LIST_USERS:
    return true
  case actionTypes.LIST_USERS_FAIL:
  case actionTypes.LIST_USERS_SUCCESS:
    return false
  default:
    return state
  }
}

const listUsers = (state = {}, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.SEARCH_LIST_CUSTOMER_SUCCESS:
    return action.data
  case actionTypes.LIST_USERS_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isGettingUserInfo = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_USER_INFO:
    return true
  case actionTypes.GET_USER_INFO_FAIL:
  case actionTypes.GET_USER_INFO_SUCCESS:
    return false
  default:
    return state
  }
}

const userInfo = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_USER_INFO_SUCCESS:
    return action.data
  default:
    return state
  }
}

const createdCustomer = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.CREATE_USER_IN_BOOKING_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isSavingUser = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.UPDATE_USER:
  case actionTypes.CREATE_USER:
    return true
  case actionTypes.UPDATE_USER_FAIL:
  case actionTypes.UPDATE_USER_SUCCESS:
  case actionTypes.CREATE_USER_FAIL:
  case actionTypes.CREATE_USER_SUCCESS:
    return false
  default:
    return state
  }
}

const updatedUser = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.UPDATE_USER_SUCCESS:
    return action.data
  default:
    return state
  }
}

const createdUser = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.CREATE_USER_SUCCESS:
    return action.data
  default:
    return state
  }
}

const filter = (state = {}, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.SET_USER_FILTER:
    return action.data
  default:
    return state
  }
}

const isFetchingUserBookingAnalytics = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_USER_ANALYTICS:
    return true
  case actionTypes.GET_USER_ANALYTICS_FAIL:
  case actionTypes.GET_USER_ANALYTICS_SUCCESS:
    return false
  default:
    return state
  }
}

const userAnalytics = (state = null, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_USER_ANALYTICS_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isRequestingChangePassword = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.REQUEST_RESET_PASSWORD:
    return true
  case actionTypes.REQUEST_RESET_PASSWORD_FAIL:
  case actionTypes.REQUEST_RESET_PASSWORD_SUCCESS:
    return false
  default:
    return state
  }
}

const isChangingPassword = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.CHANGE_PASSWORD:
    return true
  case actionTypes.CHANGE_PASSWORD_FAIL:
  case actionTypes.CHANGE_PASSWORD_SUCCESS:
    return false
  default:
    return state
  }
}

const isResetSuccessful = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.RESET_PASSWORD:
  case actionTypes.RESET_PASSWORD_FAIL:
    return false
  case actionTypes.RESET_PASSWORD_SUCCESS:
    return true
  default:
    return state
  }
}

const isResetingPassword = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.RESET_PASSWORD:
    return true
  case actionTypes.RESET_PASSWORD_FAIL:
  case actionTypes.RESET_PASSWORD_SUCCESS:
    return false
  default:
    return state
  }
}

const userReducer = combineReducers({
  isSearchingListUser,
  isListingUser,
  listUsers,
  isGettingUserInfo,
  userInfo,
  createdUser,
  updatedUser,
  isSavingUser,
  createdCustomer,
  filter,

  isFetchingUserBookingAnalytics,
  userAnalytics,

  isRequestingChangePassword,
  isChangingPassword,

  isResetingPassword,
  isResetSuccessful,
})

export default userReducer
/* eslint-disable react/prop-types */
import { Button, Grid, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { AddOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { replace } from 'lodash'
import CalendarOutlineIcon from 'mdi-react/CalendarOutlineIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import LoadingIcon from 'mdi-react/LoadingIcon'
import moment from 'moment'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {differenceInCalendarDays} from 'date-fns'
import 'moment/locale/de'
import 'moment/locale/en-gb'

import { listBookingsByDate } from '../../../actions/booking'

import * as paths from '../../../constants/paths.constants'

import { pathWithId } from '../../../helpers/helpers'

import * as selectors from '../../../selectors/booking'

const useStyles = makeStyles(() => ({
  // root: {
  //   position: 'fixed',
  //   top: '64px',
  //   height: '100%',
  //   right: '0',
  //   width: '30%',
  //   zIndex: '1',
  //   backgroundColor: 'white',
  //   borderLeft: '1px solid #ddd',
  //   boxShadow: '-2px 0px 10px #ddd'
  // },
  root: {
    //  '& table, th, td': {
    //     border: '1px solid black'
    //   }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 10px 20px',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '25px',
  },
  closeButton: {
    cursor: 'pointer',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      borderRadius: '50%',
      boxShadow: '0px 0px 15px #8c8c8c',
    },
  },
  roomCard: {
    // height: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    backgroundColor: '#f7f7f7',
    margin: '10px',
  },
  roomCardMedia: {
    width: '30%',
    /* height: 100px; */
    // paddingTop: unset;
  },
  cardContent: {
    padding: '16px',
    flexGrow: 1,
    borderBottom: '1px solid #ddd',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ffeaea',
    },
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  roomTypeInfoContent: {
    paddingRight: '20px',
  },

  listRank: {
    // overflowY: 'scroll',
    '& .MuiTableCell-root': {
      padding: '10px 10px',
    },
  },
  slideRoomnfo: {
    paddingTop: '10px',
    borderTop: '1px solid #ddd',
  },
  roomInfoDetailContent: {
    padding: '0 10px',
    '& p': {
      marginBottom: '0',
    },
  },
  cardButtonGroup: {
    textAlign: 'right',
  },
  bookingTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid #ddd',
  },
  bookedBlock: {
    backgroundColor: '#fff1f1',
    // border: 'px solid #fff1f1',
    borderLeft: '1px solid #e0e0e0',
    borderRight: '1px solid #e0e0e0',
    width: '120px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#ffe0e1',
      borderColor: '#ffe0e1',
    },
  },
  drawerContent: {
    '& .ant-drawer-close': {
      top: 15,
      right: 10,
    },
  },
  bookedBlockContent: {
    display: 'block',
    backgroundColor: '#fff1f1',
    // border: 'px solid #fff1f1',
    width: '120px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#ffe0e1',
      borderColor: '#ffe0e1',
    },
  },
  footerButton: {
    marginTop: '20px',
  },
  dateTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  previousButton: {
    cursor: 'pointer',
  },
  nextButton: {
    cursor: 'pointer',
  },
}))

const BookingTable = (props) => {
  const {
    history,
    booking,
    roomBookings,
    listBookingsByDate,
    isListingRoomBookingsByDate,
    i18n,
    t,
    match,
  } = props
  moment.locale(i18n.language === 'en' ? 'en-gb' : 'de')

  const date = match.params.date

  useEffect(() => {
    listBookingsByDate(moment(date))
  }, [date])

  const classes = useStyles()

  const handlePrevious = (_date) => {
    const pathWithChosenDate = replace(
      paths.BookingTable,
      ':date',
      moment(_date).subtract(1, 'days').format('YYYY-MM-DD')
    )
    history.push(pathWithChosenDate)
  }

  const handleNext = (_date) => {
    const pathWithChosenDate = replace(
      paths.BookingTable,
      ':date',
      moment(_date).add(1, 'days').format('YYYY-MM-DD')
    )
    history.push(pathWithChosenDate)
  }
  
  return (
    <div className={classes.drawerContent}>
      {isListingRoomBookingsByDate ? (
        <div className='loading-refresh'>
          <LoadingIcon />
        </div>
      ) : (
        ''
      )}
      <div className={classes.root}>
        <Typography component='div' className={classes.header}>
          <span className={classes.dateTitle}>
            <ChevronLeftIcon
              className={classes.previousButton}
              onClick={() => handlePrevious(date)}
            />
            &nbsp;{date}&nbsp;
            <ChevronRightIcon
              className={classes.nextButton}
              onClick={() => handleNext(date)}
            />
          </span>
          <div className={classes.buttonGroup}>
            <Button
              variant='contained'
              onClick={() => history.push(paths.BookingCalendar)}
              style={{ marginRight: '20px' }}
              disableElevation
            >
              <CalendarOutlineIcon />
              &nbsp;{t('BOOKING.MonthlyView')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              disabled={differenceInCalendarDays(new Date(moment(date)), new Date()) < 0}
              onClick={() => booking()}
            >
              <AddOutlined />
              &nbsp;{t('BOOKING.NewBooking')}
            </Button>
          </div>
        </Typography>

        <Grid container className={classes.listRank}>
          <Grid item xs={12}>
            <TableContainer>
              <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '100px' }}>
                      {moment(date).format('dddd')}
                    </TableCell>
                    {roomBookings.map((item, index) => {
                      return <TableCell key={index}>{item.name}</TableCell>
                    })}
                    <TableCell align='left'>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    15,
                    16,
                    17,
                    18,
                    19,
                    20,
                    21,
                    22,
                  ].map((row, index) => {
                    return (
                      <TableRow key={index} style={{ height: '41px' }}>
                        <TableCell>{row}:00</TableCell>
                        {roomBookings.map((item, idx) => {
                          const { booked } = item
                          const bookingTime =
                            booked && booked.find((it) => it.start === row)
                          if (bookingTime) {
                            const rowSpanValue =
                              bookingTime.end - bookingTime.start
                            if (row === bookingTime.start) {
                              return (
                                <TableCell
                                  key={idx}
                                  rowSpan={rowSpanValue}
                                  className={classes.bookedBlock}
                                  onClick={() =>
                                    history.push(
                                      pathWithId(paths.BookingDetail, item.id)
                                    )
                                  }
                                  style={{ overflowY: 'scroll' }}
                                >
                                  <div
                                    className={''}
                                    style={{ height: `${40 * rowSpanValue}px` }}
                                  >
                                    <p>
                                      {t('BOOKING.Customer')}:{' '}
                                      {item.customerName}
                                    </p>
                                    <p>
                                      {t('BOOKING.Company')}:{' '}
                                      {item.customerCompany}
                                    </p>
                                    <p>Email: {item.customerEmail}</p>
                                  </div>
                                </TableCell>
                              )
                            }
                          } else {
                            let hidden = false
                            booked &&
                              booked.map((book) => {
                                if (row > book.start && row <= book.end - 1) {
                                  hidden = true
                                }
                              })
                            if (hidden) {
                              return null
                            }
                            return (
                              <TableCell key={idx} align='left'>
                                &nbsp;
                              </TableCell>
                            )
                          }
                        })}
                        <TableCell align='left'>&nbsp;</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const mapState = ({ bookingReducer }) => ({
  roomBookings: selectors.advancedInfoBookings({ bookingReducer }),
  isListingRoomBookingsByDate: bookingReducer.isListingRoomBookingsByDate,
  date: selectors.displayedDate({ bookingReducer }),
})

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      listBookingsByDate,
    },
    dispatch
  )

BookingTable.defaultProps = {
  isShowBooking: false,
}

export default withRouter(
  connect(mapState, mapDispatch)(withTranslation('common')(BookingTable))
)

import {createSelector} from 'reselect'
import {ITEM_TYPES} from '../helpers/constant'
import {
  timeslotsToTimeline,
  convert24To12HoursClock
} from '../helpers/helpers'
import * as _ from 'lodash'
import moment from 'moment'

// helpers

const _isRoom = (bookingItem) => bookingItem.item_type == ITEM_TYPES.ROOM
const _isExFac = (bookingItem) => bookingItem.item_type == ITEM_TYPES.EXTRA_FACILITY
const _isCatering = (bookingItem) => bookingItem.item_type == ITEM_TYPES.CATERING_SERVICE

const _getRoomName = (bookingItems) => {
  const room = bookingItems.find(i => _isRoom(i))
  return _.get(room, 'item.name', '')
}

const _getDisplayedDate = (bookingItems) => {
  const room = bookingItems.find(i => _isRoom(i))
  return moment(_.get(room, 'date')).format("MMM DD, YYYY")
}

const _getTimeslot = (bookingItems) => {
  const room = bookingItems.find(i => _isRoom(i))
  return _.get(room, 'timeslots', 0)
}

const _getExtraFacilities = (bookingItems) => bookingItems.filter(i => _isExFac(i))
const _getCaterings = (bookingItems) => bookingItems.filter(i => _isCatering(i))

const _transformDisplayedBookedItem = (timelineArr) => {
  return [
    {
      start: timelineArr[0],
      end: timelineArr[1]
    }
  ]
}

const _serializeAdvancedInfoBooking = (booking) => {
  const bookingItems = _.get(booking, 'booking_items', [])
  const timeline = timeslotsToTimeline(_getTimeslot(bookingItems))
  const extraFacilities = _getExtraFacilities(bookingItems)
  const caterings = _getCaterings(bookingItems)
  return ({
    ...booking, 
    name: _getRoomName(bookingItems),
    date: _getDisplayedDate(bookingItems),
    booked: _transformDisplayedBookedItem(timeline),
    startTime: convert24To12HoursClock(timeline[0]),
    endTime: convert24To12HoursClock(timeline[1]),
    duration: timeline[1] - timeline[0],
    customerName: _.get(booking, 'customer.first_name', '')
      + ' '
      + _.get(booking, 'customer.last_name', ''),
    customerCompany: _.get(booking, 'customer.company', ''),
    customerEmail: _.get(booking, 'customer.email', ''),
    extraFacilities: extraFacilities,
    caterings: caterings,
    extraFacilityCategories: _.uniq(extraFacilities.map(ef=>_.get(ef, 'item.name', ''))),
    cateringNames: _.uniq(caterings.map(cs=>_.get(cs, 'item.name', ''))),
    paymentMethod: _.get(booking, 'payment_method', ''),
    htmlSrc: _.get(booking, 'htmlSrc', ''),
  })
}

const _serializeAdvancedInfoBookingSession = (booking) => {
  const bookingItems = _.get(booking, 'items', [])
  const timeline = timeslotsToTimeline(_getTimeslot(bookingItems))
  const extraFacilities = _getExtraFacilities(bookingItems)
  const caterings = _getCaterings(bookingItems)
  return ({
    ...booking, 
    name: _getRoomName(bookingItems),
    date: _getDisplayedDate(bookingItems),
    booked: _transformDisplayedBookedItem(timeline),
    startTime: convert24To12HoursClock(timeline[0]),
    endTime: convert24To12HoursClock(timeline[1]),
    duration: timeline[1] - timeline[0],
    customerName: _.get(booking, 'customer.first_name', '')
      + ' '
      + _.get(booking, 'customer.last_name', ''),
    customerCompany: _.get(booking, 'customer.company', ''),
    customerEmail: _.get(booking, 'customer.email', ''),
    extraFacilities: extraFacilities,
    caterings: caterings,
    extraFacilityCategories: _.uniq(extraFacilities.map(ef=>_.get(ef, 'item.category.name', ''))),
    cateringNames: _.uniq(caterings.map(cs=>_.get(cs, 'item.name', ''))),
    additionalRequirements: _.get(booking, 'additional_requirement', ''),
  })
}

const _transformInvoiceToRows = (invoice, ccunit) => {
  /**
   * invoice object is sth likes:
   * {
   *   "item_type": [
   *     {
   *       "date": "2020-02-02",
   *       "price": xx,
   *        "name": "Mobile"
   *     }
   *   ]
   * }
   */
  let rows = []
  Object.keys(invoice).forEach((k) => {
    const _rows = invoice[k]
      .map(i => ({...i, item: k})) // rows with item name
      .map(i => ({
        ...i,
        avg_discount_pct: Number(i.avg_discount_pct * 100).toFixed(2),
        avg_price: i.avg_price,
        total_price: i.total_price
      })) // calculate price
    rows.push(..._rows)
  })
  return rows
}

// Booking

export const roomBookings = ({bookingReducer}) => bookingReducer.listBookingsByDateRange
export const advancedInfoBookings = createSelector(
  [
    roomBookings
  ], (bookings) => bookings.map(
      b => _serializeAdvancedInfoBooking(b)
    )
)
export const displayedDate = ({bookingReducer}) => 
  bookingReducer.chosenDateInBookingTable.format("YYYY-MM-DD")
export const summaryCreatedBookingSessionInfo = ({bookingReducer}) => _serializeAdvancedInfoBookingSession(
  bookingReducer.createdBookingSummary)
export const getCurrencyUnit = ({bookingReducer}) => _.get(bookingReducer, 'createdBookingSummary.currency_unit', 100)
export const getBookingInvoice = createSelector(
  [
    ({bookingReducer}) => bookingReducer.createdBookingSummary,
    getCurrencyUnit
  ], (booking, ccunit) => _transformInvoiceToRows(_.get(booking, 'invoice', {}), ccunit)
)

export const getGrandTotalInvoice = createSelector(
  [
    ({bookingReducer}) => bookingReducer.createdBookingSummary,
    getCurrencyUnit
  ], (booking, ccunit) => ({
    subTotal: _.get(booking, 'total'),
    tax: _.get(booking, 'tax'),
    grandTotal: (_.get(booking, 'grand_total'))
  })
)

// Booking detail

export const getBookingDetail = ({bookingReducer}) => _serializeAdvancedInfoBooking(
    _.get(bookingReducer, 'bookingDetail'))

export const getBookingDetailInvoiceRows = createSelector(
  [
    ({bookingReducer}) => bookingReducer.bookingDetail,
    getCurrencyUnit
  ], (booking) => _transformInvoiceToRows(_.get(booking, 'invoice', {}), 100)
)

export const getGrandTotalBookingDetail = createSelector(
  [
    ({bookingReducer}) => bookingReducer.bookingDetail,
    getCurrencyUnit
  ], (booking, ccunit) => ({
    subTotal: _.get(booking, 'total'),
    tax: _.get(booking, 'tax'),
    grandTotal: (_.get(booking, 'grand_total'))
  })
)
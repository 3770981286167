import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import {connect} from 'react-redux'

const PrivateRoute = ({ component: Component, isLogined, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      isLogined
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
  )
}

const mapState = state => ({
  isLogined: state.authReducer.isLogined
})

PrivateRoute.propTypes = {
  component: PropTypes.any,
  isLogined: PropTypes.bool,
  location: PropTypes.object
}

PrivateRoute.defaultProps = {
  isLogined: false,
}

export default React.memo(connect(mapState)(PrivateRoute))
import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Detail from '../../components/users/Detail'
import * as selectors from '../../selectors/users/detail'
import * as actions from '../../actions/users/detail'
import { sl } from 'date-fns/esm/locale'


class UserDetail extends Component {
  componentDidMount() {
    let { match: { params: { detailType, id } } } = this.props
    this.props.retrieveUser(id)
  }

  render() {

    return (
      <Detail {...this.props} />
    )
  }
}


const mapState = (state) => ({
  fullName: selectors.getFullname(state),
  email: selectors.getEmail(state),
  customerNo: selectors.getCustomerNo(state),
  company: selectors.getCompany(state),
  term: selectors.getTermTag(state),
  accountType: selectors.getAccountTypeTag(state),
  status: selectors.getStatusTag(state),
  role: selectors.getRoleTag(state),
  address: selectors.getAddress(state),
  phone: selectors.getPhone(state),
  ad: selectors.getAdTag(state),
  currentIsAdmin: selectors.currentIsAdmin(state),

  bookingItems: selectors.getBookingItems(state),
  selectedTab: selectors.getSelectedTab(state),
  bookingPage: selectors.getBookingPage(state),
  bookingPageSize: selectors.getBookingPageSize(state),
  bookingTotal: selectors.getBookingTotal(state),
  isBookingFetching: selectors.isBookingFetching(state),
  isFetching: selectors.isFetching(state),
  analyticsData: selectors.getAnalyticsData(state),
  isAnalyticsFetching: selectors.isAnalyticsFetching(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveUser: actions.retrieveUser,
    onChangeTab: actions.changeTab,
    onChangePage: actions.changePage,
    onDeleteUser: actions.deleteUser,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(UserDetail))

import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import Store from '@material-ui/icons/Store'
import PersonIcon from '@material-ui/icons/PersonOutlined'
import LocalOfferOutlined from '@material-ui/icons/LocalOfferOutlined'
import ViewAgendaOutlined from '@material-ui/icons/ViewAgendaOutlined'
import PersonalVideo from '@material-ui/icons/PersonalVideo'
import FreeBreakfastOutlined from '@material-ui/icons/FreeBreakfastOutlined'

import * as paths from '../../../../../constants/paths.constants'

// import { Label } from '../../../../../components'
export default [
  {
    title: 'NAV_BAR.Pages',
    pages: [
      // {
      //   title: 'Dashboards',
      //   href: paths.Main,
      //   icon: DashboardIcon,
      //   exact: true
      // },
      {
        title: 'NAV_BAR.Booking',
        // href: paths.,
        icon: ViewAgendaOutlined,
        children: [
          // {
          //   title: 'Create new booking',
          //   href: paths.CreateNewBooking
          // },
          {
            title: 'NAV_BAR.Calendar',
            href: paths.BookingCalendar
          },
          {
            title: 'NAV_BAR.AllBookings',
            href: paths.ViewAllBooking
          },
        ]
      },
      {
        title: 'NAV_BAR.Rooms',
        href: paths.ListRooms,
        icon: Store,
      },
      {
        title: 'NAV_BAR.Users',
        href: paths.ListUser,
        icon: PersonIcon,
        // children: [
        //   {
        //     title: 'Create new users',
        //     href: paths.NewUser
        //   },
        //   {
        //     title: 'All users',
        //     href: paths.ListUser
        //   },
        // ]
      },
      {
        title: 'NAV_BAR.Facilities',
        href: paths.ListFacilities,
        icon: PersonalVideo,
        // children: [
        //   {
        //     title: 'Create Facility',
        //     href: paths.NewFacility
        //   },
        //   {
        //     title: 'All Facilities',
        //     href: paths.ListFacilities
        //   },
        // ]
      },
      {
        title: 'NAV_BAR.Service',
        href: paths.CateringService,
        icon: FreeBreakfastOutlined,
        // children: [
        //   {
        //     title: 'Create service',
        //     href: paths.NewService
        //   },
        //   {
        //     title: 'Catering service',
        //     href: paths.CateringService
        //   },
        // ]
      },
      {
        title: 'NAV_BAR.Discounts',
        href: paths.ListDiscounts,
        icon: LocalOfferOutlined,
      },
    ]
  },
  {
    title: 'NAV_BAR.Support',
    pages: [
      
    ]
  }
]

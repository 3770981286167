import { takeLatest, put, call, all, select } from 'redux-saga/effects'

import listSaga from './list'
import detailSaga from './detail'
import editSaga from './edit'
import newSaga from './new'

function cateringSaga(deps) {
  return function* saga() {
    yield all([
      ...listSaga(deps),
      ...detailSaga(deps),
      ...editSaga(deps),
      ...newSaga(deps),
    ])
  }
}

export default cateringSaga

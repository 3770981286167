import { bind } from 'decko'
import BaseApi from './baseApi'
import {GetListExtraFacilityCategories, GetListExtraFacilities, CreateNewFacilityCategory, UpdateFacilityCategory,
  IncreaseQuantityFacilityCategory
} from '../../constants/endpoints.constants'
export default class Facilities extends BaseApi {
  @bind
  async getListExtraFacilityCategories(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(GetListExtraFacilityCategories(), data)
  }

  @bind
  async retrieveExtraFacility(id, data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(`/ex-facility-categories/${id}`, data)
  }

  @bind
  async getListExtraFacilities(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(GetListExtraFacilities(), data)
  }
  @bind
  async createNewFacilityCategory(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(CreateNewFacilityCategory(), data)
  }
  @bind
  async updateFacilityCategory(id, data) {
    await this.checkAuthenticatedStatus()
    return await this.http.patch(`/ex-facility-categories/${id}`, data)
  }
  @bind
  async increaseQuantityFacilityCategory(data) {
    const {id, ...rest} = data
    await this.checkAuthenticatedStatus()
    return await this.http.get(IncreaseQuantityFacilityCategory(id), rest)
  }
}

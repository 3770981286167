import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import New from '../../components/rooms/NewRoomType'
import * as actions from '../../actions/rooms/new'
import {roomType as selectors} from '../../selectors/rooms/new'

class NewRoomType extends Component {
  render() {

    return (
      <New {...this.props} />
    )
  }
}


const mapState = (state) => ({
  isSubmitting: selectors.isSubmitting(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    onSubmit: actions.submitRoomType,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(NewRoomType))

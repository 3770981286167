import * as C from '../../actionTypes/bookings/detail'

export const retrieveBooking = (payload) => ({type: C.RETRIEVE_BOOKING, payload })
export const changeNotes = (payload) => ({type: C.CHANGE_NOTES, payload })
export const saveNotes = () => ({type: C.SAVE_NOTES})
export const cancelBooking = (payload) => ({ type: C.CANCEL_BOOKING, payload })

export const showRefundModal = () => ({ type: C.SHOW_REFUND_MODAL })
export const closeRefundModal = () => ({ type: C.CLOSE_REFUND_MODAL })
export const changeRefundAmount = (payload) => ({ type: C.CHANGE_REFUND_AMOUNT, payload })
export const submitRefund = () => ({ type: C.SUBMIT_REFUND })

import { bind } from 'decko'
import BaseApi from './baseApi'
import {GetListDiscount, CreateNewDiscount
} from '../../constants/endpoints.constants'
export default class Catering extends BaseApi {
  @bind
  async retrieveDiscount(id) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(`/discounts/${id}`)
  }

  @bind
  async getListDiscount(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(GetListDiscount(), data)
  }
  @bind
  async createNewDiscount(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post('/discounts', data)
  }
  @bind
  async updateDiscount(data) {
    const {id} = data
    await this.checkAuthenticatedStatus()
    return await this.http.patch(`/discounts/${id}`, data)
  }
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
// import CardHeader from '@material-ui/core/CardHeader'
import DeleteIcon from '@material-ui/icons/Delete'
import { GenericMoreButton } from '../../components'
import { withTranslation } from 'react-i18next'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import themeDefault from '../../helpers/theme'
import { deDE } from '@material-ui/core/locale'
import { enUS } from '@material-ui/core/locale'

const langTheme = {
  en: enUS,
  de: deDE
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  // console.log(stabilizedThis.map(el => el[0]))
  return stabilizedThis.map(el => el[0])
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, singleSelect } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {!singleSelect &&
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />}
        </TableCell>
        {columns.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : 'asc'}
              onClick={createSortHandler(headCell.key)}
            >
              {headCell.label}
              {orderBy === headCell.key ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.array,
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}))

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles()
  const { numSelected, title, t } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} {t('BOOKING.selected')}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {title}
        </Typography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
          <GenericMoreButton />
        )} */}
      {/* <CardHeader
        action={<GenericMoreButton />}
        title="Orders"
      /> */}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function EnhancedTable(props) {
  const { columns, rows, title, singleSelect, emptyBool, emptyContent, onSelect,
    pageSize, page, total, onChangePage, onChangePageSize, pagination, selectedItem = [],
    t, i18n
  } = props

  // console.log(selectedItem)
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const selected = selectedItem
  // const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  // const [rowsPerPage, setRowsPerPage] = React.useState(5)
  // useEffect(() => {
  //   if(selectedItem) {
  //     setSelected(selectedItem)
  //   }
  //   console.log(selectedItem)
  // }, [selectedItem])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id)
      // setSelected(newSelecteds)
      return
    }
    // setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (singleSelect) {
      newSelected = newSelected.concat([], name)
      onSelect && onSelect(rows.find(item => item.id === name))
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }
      onSelect && onSelect(newSelected)
    }
    // console.log(newSelected)
    // setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    // setPage(newPage)
    console.log(newPage)
    onChangePage(newPage + 1)
  }

  const handleChangeRowsPerPage = event => {
    onChangePageSize(parseInt(event.target.value))
    // setRowsPerPage(parseInt(event.target.value, 10))
    // setPage(0)
  }

  const handleChangeDense = event => {
    setDense(event.target.checked)
  }

  const isSelected = name => selected.indexOf(name) !== -1
  // console.log(selected)
  const emptyRows = !rows.length
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          t={t}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              columns={columns}
              singleSelect={singleSelect}
              t={t}
            />
            <TableBody>
              {emptyBool && !rows.length && emptyContent &&
                <TableRow>
                  <TableCell colSpan={columns.length + 1}>
                    {emptyContent()}
                  </TableCell>
                </TableRow>
              }
              {stableSort(rows, getComparator(order, orderBy))
                .slice(0, pageSize)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`
                  // console.log(row)
                  return (
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                    // selected={isItemSelected}
                    >

                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={event => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      {columns.map((item, idx) => {
                        if (idx === 0) {
                          if (typeof (item.render) === 'function') {
                            return (
                              <TableCell key={idx} component="th" id={labelId}
                                scope="row"
                                padding="none"
                                colSpan={item.colSpan}
                              >
                                {item.render(row[item.key], row)}
                              </TableCell>
                            )
                          } else {

                            return (
                              <TableCell
                                key={idx}
                                component="th"
                                id={labelId}
                                colSpan={item.colSpan}
                                scope="row" padding="none"
                              >
                                {row[item.key]}
                              </TableCell>
                            )
                          }

                        } else {
                          if (typeof (item.render) === 'function') {
                            return (
                              <TableCell
                                key={idx}
                                align="left"
                                colSpan={item.colSpan}
                              >
                                {item.render(row[item.key], row)}
                              </TableCell>
                            )
                          } else {

                            return (
                              <TableCell
                                key={idx}
                                align="left"
                                colSpan={item.colSpan}
                              >
                                {row[item.key]}
                              </TableCell>
                            )
                          }
                        }
                      })}
                      {/* <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.room}</TableCell>
                      <TableCell align="left">{row.customer}</TableCell>
                      <TableCell align="left">{row.price}</TableCell>
                      <TableCell align="left">{row.date}</TableCell>
                      <TableCell align="left">{row.time}</TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align="left">{row.link}</TableCell> */}
                    </TableRow>
                  )
                })}
              {emptyRows && (
                <TableRow style={{ height: (dense ? 33 : 53) * 1 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {page &&
          <ThemeProvider theme={createMuiTheme(themeDefault, langTheme[i18n.language])}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={pageSize || 5}
              page={page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              t={t}
            />
          </ThemeProvider>
        }
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  )
}

EnhancedTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  title: PropTypes.string,
  singleSelect: PropTypes.bool,
  emptyBool: PropTypes.bool,
  emptyContent: PropTypes.any,
  emptyText: PropTypes.string,
  onSelect: PropTypes.func,
  pageSize: PropTypes.any,
  page: PropTypes.any,
  total: PropTypes.any,
  onChangePage: PropTypes.func,
  onChangePageSize: PropTypes.func,
  pagination: PropTypes.bool,
  selectedItem: PropTypes.any,
}

EnhancedTable.defaultProps = {
  columns: [],
  rows: [],
  title: '',
  pagination: true
}

export default withTranslation('common')(EnhancedTable)
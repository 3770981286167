import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'
import * as helpers from '../../helpers/user'
import * as bookingHelpers from '../../helpers/bookings'
import {convertNumToMonthName} from '../../helpers/helpers'

import { BOOKING, FORMAT } from '../../constants/models'

const { LIST_BY } = BOOKING
const { DATE } = FORMAT

const getReducer = ({users}) => users.detail

export const currentIsAdmin = ({publicReducer: {profile}}) => _.get(profile, 'is_admin')

export const getFullname = createSelector(
  [getReducer],
  ({data}) => `${_.get(data, 'first_name')} ${_.get(data, 'last_name')}`
)

export const getEmail = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'email', "")
)

export const getCustomerNo = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'customer_id')
)

export const getCompany = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'company')
)

export const getAddress = createSelector(
  [getReducer],
  ({data}) => {
    return `${_.get(data, 'street') || ""} ${_.get(data, 'house_nbr') || ""}, ${_.get(data, 'post_code', "")} ${_.get(data, 'city') || ""}, ${_.get(data, 'country') || ""}`
  }
)

export const getPhone = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'phone')
)

export const getTermTag = createSelector(
  [getReducer],
  ({data}) => {
    return helpers.getTermTag(_.get(data, 'is_long_term_customer'))
  }
)

export const getAccountTypeTag = createSelector(
  [getReducer],
  ({data}) => {
    return helpers.getCustomerTypeTag(_.get(data, 'is_belong_to_company'))
  }
)

export const getStatusTag = createSelector(
  [getReducer],
  ({data}) => {
    return helpers.getStatusTag(_.get(data, 'is_active'))
  }
)

export const getRoleTag = createSelector(
  [getReducer],
  ({data}) => {
    return helpers.getRoleTag(helpers.getRole(data))
  }
)

export const getAdTag = createSelector(
  [getReducer],
  ({data}) => {
    return helpers.getAdTag(_.get(data, 'ad_accepted'))
  }
)

export const getSelectableTabs = () => {
  return [
    {id: LIST_BY.INCOMING, name: "Incoming"},
    {id: LIST_BY.HISTORY, name: "History"}
  ]
}

export const getSelectedTab = createSelector(
  [getReducer],
  ({bookings: {selectedTab}}) => selectedTab
)

export const getBookingItems = createSelector(
  [getReducer],
  ({bookings: {items}}) => {
    return _.map(items, it => ({
      id: it.id,
      confirmationNo: `${it.confirmation_prefix}${_.padStart(it.id, 4, '0')}`,
      room: it.room_name,
      customer: {
        name: `${_.get(it, "customer.first_name")} ${_.get(it, "customer.last_name")}`,
        email: _.get(it, "customer.email"),
        company: _.get(it, "customer.company"),
      },
      bookingDate: {
        startDate: moment(_.get(it, 'start_date')).format(DATE),
        startTime: bookingHelpers.slot2Time(_.get(it, 'start_slot_int'))[0],
        endDate: moment(_.get(it, 'end_date')).format(DATE),
        endTime: bookingHelpers.slot2Time(_.get(it, 'end_slot_int'))[1],
      },
      paymentStatus: bookingHelpers.getPaymentStatusTag(_.get(it, 'payment_status')),
      bookingStatus: bookingHelpers.getBookingStatusTag(_.get(it, 'status')),
    }))
  }
)

export const getBookingPage = createSelector(
  [getReducer],
  ({bookings: {page}}) => page
)

export const getBookingPageSize = createSelector(
  [getReducer],
  ({bookings: {pageSize}}) => pageSize
)

export const getBookingTotal = createSelector(
  [getReducer],
  ({bookings: {total}}) => total
)

export const isBookingFetching = createSelector(
  [getReducer],
  ({bookings: {isFetching}}) => isFetching
)


export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const getAnalyticsData = createSelector(
  [getReducer],
  ({analytics: {items}}) => {
    return {thisYear: {
      data: _.map(items, res => res.count),
      labels: _.map(items, res => convertNumToMonthName(res.month)),
    }}
  }
)

export const isAnalyticsFetching = createSelector(
  [getReducer],
  ({analytics: {isFetching}}) => isFetching
)

/**GENERATE REQUEST PAYLOAD */

export const generateListBookingReqPayload = createSelector(
  [getReducer],
  ({bookings: {page, pageSize}, data: {id}}) => {
    let payload = {page, page_size: pageSize, user_id: id}
    return payload
  }
)

export const generateBookingAnalyticsReqPayload = createSelector(
  [getReducer],
  ({data: {id}}) => {
    const year = moment().year()
    return {year, user_id: id}
  }
)

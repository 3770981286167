import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {get} from 'lodash'
import { bindActionCreators } from 'redux'
import { Container, Grid, Typography, Card, CardMedia, CardContent, CardActions, TextField, Button,  } from '@material-ui/core'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import MagnifyIcon from 'mdi-react/MagnifyIcon'

import { lighten, makeStyles, withStyles } from '@material-ui/core/styles'
import Image from '../../../assets/images/Kopenhagen_1-1.jpg'
import { history } from '../../../config/store'
import { Link, withRouter } from 'react-router-dom'
import * as paths from '../../../constants/paths.constants'
import { ArrowDropDown } from '@material-ui/icons'
import {
  getRoomType,
  clearRoomTypeInfo,
} from '../../../actions/room'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withTranslation } from 'react-i18next'
import * as selectors from '../../../selectors/room'
import { pathWithId } from '../../../helpers/helpers'

const useStyles = makeStyles((theme) => ({
  rootTitleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `2px solid ${theme.colorAccent}`,
    paddingBottom: '10px',
    marginBottom: '20px',
    marginTop: '30px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorAccent,
  },
  roomListTitle: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    borderBottom: '2px solid #ddd',
    marginTop: '50px',
  },
  topBookedTitle: {
    color: theme.colorAccent,
    marginTop: '20px'
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f7f7f7'
  },
  cardMedia: {
    paddingTop: '80%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
    borderBottom: '1px solid #ddd',
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold'
  },
  footerPrice: {
    color: theme.colorAccent,
    fontWeight: 'bold'
  },
  roomTypeInfoContent: {
    paddingRight: '20px'
  },
  topBookedContent: {
    paddingLeft: '20px',
    border: '1px solid #ddd',
    padding: '10px',
    borderRadius: '5px'
  },
  topBookedAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chooseDateContent: {
    display: 'flex',
    alignItems: 'center',
  },
  timeSelected: {
    color: theme.colorAccent,
  },
  roomCard: {
    // height: '100%',
    display: 'flex',
    // flexDirection: 'column',
    backgroundColor: '#f7f7f7',
    margin: '10px'
  },
  roomCardMedia: {
    width: '30%'
    /* height: 100px; */
    // paddingTop: unset;
  },
  roomInfoDetail: {
    marginBottom: '0'
  },
  rankNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listRank: {
    overflowY: 'scroll',
    height: '525px'
  },
  facilitiesContent: {
    display: 'flex',
    alignItems: 'center'
  },
  changeListRoomActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px 0'
  },
  justifyContentCenter: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  showMoreGrid: {
    padding: '20px'
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    // marginBottom: '40px'
  },
  searchButton: {
    color: theme.colorAccent,
    marginRight: '20px'
  },
  formField: {
    borderRadius: '5px',
    width: '40%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  editRoomTypeButton: {
    color: theme.colorAccent
  }
}))

const BorderLinearProgress = withStyles({
  root: {
    height: 5,
    borderRadius: 20,
    backgroundColor: 'rgb(234, 234, 234)',
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#2ed47a',
  },
})(LinearProgress);

const RoomInfo = (props) => {
  const classes = useStyles()

  const { roomInfo, t } = props
  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={get(roomInfo, 'mainImageUrl')}
        title="Image title"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h6" component="h2" align="left">
          {roomInfo.name}
        </Typography>
        <Typography variant="subtitle1" align="left">
          {get(roomInfo, 'description')}
        </Typography>
        <Typography variant="subtitle1" align="left" className={classes.roomExtraServiceContent}>
          <p>- {t('ROOMS.MaxPeoples')}: {get(roomInfo, 'capacity')}</p>
          <p>- {t('ROOMS.Facilities')}: {get(roomInfo, 'facilities')}</p>
          <p>- {t('ROOMS.Orthers')}:</p>
        </Typography>
      </CardContent>
    </Card>
  )
}

const RoomTypeInfo = (props) => {
  const classes = useStyles()

  const { roomTypeInfo, t } = props
  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={get(roomTypeInfo, 'mainImageUrl')}
        title="Image title"
      />
      <CardContent className={classes.cardContent}>
        {/* <Typography gutterBottom variant="h6" component="h2" align="left">
          {roomTypeInfo.title}
        </Typography> */}
        <Typography variant="subtitle1" align="left">
          {get(roomTypeInfo, 'description')}
        </Typography>
        {/* <Typography variant="subtitle1" align="left" className={classes.roomExtraServiceContent}>
          <p>- Max people:</p>
          <p>- Facilities:</p>
          <p>- Orthers:</p>
        </Typography> */}
      </CardContent>
      {/* <CardActions className={classes.cardFooter}>
        <Typography variant="body2" component="div" className={classes.footerPrice}>
          PRICE per hour: 20EUR
        </Typography>
      </CardActions> */}
    </Card>
  )
}

const RoomCard = (props) => {
  const classes = useStyles()
  const { roomInfo, t } = props
  return (
    <Card className={classes.roomCard}>
      <CardMedia
        className={classes.roomCardMedia}
        image={get(roomInfo, "mainImageUrl")}
        title="Image title"
      />
      <CardContent className={classes.cardContent}>
        {/* <Typography gutterBottom variant="h6" component="h2" align="left">
          {roomInfo.title}
        </Typography> */}
        <Typography variant="subtitle1" align="left">
          {get(roomInfo, 'description')}
        </Typography>
        <Typography align="left" className={classes.roomInfoDetailContent}>
          <p className={classes.roomInfoDetail}>- {t('ROOMS.MaxPeoples')}: {get(roomInfo, 'capacity')}</p>
          <p className={classes.roomInfoDetail}>- {t('ROOMS.Facilities')}: {get(roomInfo, 'facilities')}</p>
          <p className={classes.roomInfoDetail}>- {t('ROOMS.Orthers')}:</p>
        </Typography>
      </CardContent>
    </Card>
  )
}

const ViewRoomTypeDetail = props => {
  const classes = useStyles()

  const {
    getRoomType,
    clearRoomTypeInfo,
    match,
    isFetching,
    roomTypeInfo,
    configurationInfo,
    t
  } = props
  const roomTypeId = match.params.id
  useEffect(() => {
    // first load
    clearRoomTypeInfo()
    getRoomType(roomTypeId)
  }, [])

  console.log(roomTypeInfo)
  return (
    <Container>
      <Grid container >
        <Grid item xs={12} md={12} >
          <Typography variant='h5' component="div" className={classes.rootTitleContent}  >
            <Link to={paths.ListRoomTypes} className={classes.title}>
              <ChevronLeftIcon /> {get(roomTypeInfo, 'room_type')}
            </Link>
            <Link to={pathWithId(paths.UpdateRoomType, roomTypeId)}>
              <Button color="default" variant="contained" disableElevation
                className={classes.editRoomTypeButton}
              >{t('ROOMS.EditRoomType')}</Button>
            </Link>
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container >
            <Grid item md={5} xs={12} className={classes.roomTypeInfoContent}>
              <RoomTypeInfo
                roomTypeInfo={roomTypeInfo}
                t={t}
              />
            </Grid>
            <Grid item md={7} xs={12} className={classes.topBookedContent}>
              <Typography component="div" className={classes.topBookedAction}>
                {/* <span>8 rooms booked out of 10</span> */}
                <div className={classes.chooseDateContent}><span>Show:</span> <span className={classes.timeSelected}>Today </span><ArrowDropDown /></div>
              </Typography>
              <Typography>
                <BorderLinearProgress
                  className={classes.margin}
                  variant="determinate"
                  color="success"
                  value={80}
                />
              </Typography>
              <Grid container>
                <Grid item md={12}>
                  <Typography variant='h6' className={classes.topBookedTitle} >{t('ROOMS.TopBookedRooms')}</Typography>
                </Grid>
                <Grid item md={12} className={classes.listRank}>
                  {get(roomTypeInfo, 'rooms').map((room, index) => {
                    return (
                      <Grid container key={index}>
                        <Grid item md={3} className={classes.rankNumber}>
                          <Typography variant='h4' align="center">#{room.id}</Typography>
                        </Grid>
                        <Grid item md={9}>
                          <RoomCard roomInfo={room} t={t}/>
                        </Grid>
                      </Grid>

                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h6' component="div" className={classes.roomListTitle} >
                {t('ROOMS.RoomsList')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} className={classes.facilitiesContent}>
              <Grid container >
                <Grid item xs={12} md={9} className={classes.searchContent} >
                  <TextField
                    // error
                    className={classes.formField}
                    // id="outlined-error"
                    label={t("ROOMS.SearchFields")}
                    variant="outlined"
                  />
                  <Button className={classes.searchButton} color="default" variant="contained" disableElevation>
                    <MagnifyIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} className={classes.changeListRoomActions}>
              <Typography component='div' className={classes.changeListRoomActions}>
                <span>1-6 of {get(roomTypeInfo, 'rooms').length}</span> <ChevronLeftIcon />&nbsp;&nbsp;<ChevronRightIcon />
              </Typography>
            </Grid>
            <Grid spacing={8} container className={classes.textAlignCenter}>
              {get(roomTypeInfo, 'rooms').map((room, index) => {
                return (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <RoomInfo roomInfo={room} t={t}/>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.showMoreGrid}>
          <Typography
            component="h1"
            variant="subtitle1"
            color="inherit"
            align="center"
            noWrap
          >{t('ROOMS.ClickToShowMore')}</Typography>
          <Typography
            variant="subtitle1"
            color="inherit"
            align="center"
            noWrap
          ><ChevronDownIcon /></Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

const mapState = (state) => ({
  isFetching: selectors.isFetchingRoomTypeDetail(state),
  roomTypeInfo: selectors.getRoomTypeInfo(state),

  configurationInfo: state.publicReducer.configurationInfo,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getRoomType,
    clearRoomTypeInfo,
  },
  dispatch
)

ViewRoomTypeDetail.propTypes = {
  className: PropTypes.string,
  getRoomType: PropTypes.func,
  clearRoomTypeInfo: PropTypes.func,
}

ViewRoomTypeDetail.defaultProps = {
  orders: []
}

export default withRouter(connect(mapState, mapDispatch)(withTranslation('common')(ViewRoomTypeDetail)))

import i18n from 'i18next'
import backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {resources} from '../translations'

// console.log(localStorage.getItem('language'))
// console.log(LanguageDetector)

i18n
  .use(backend)
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem('language') || (navigator.language.slice(0,2) === 'en' ? 'en' : 'de'),
    fallbackLng: localStorage.getItem('language') || (navigator.language.slice(0,2) === 'en' ? 'en' : 'de'),
    interpolation: {
      escapeValue: true,
    },
    react: {
      wait: true,
      useSuspense: false
    }
  })

export default i18n

import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import List from '../../components/users/List'
import * as selectors from '../../selectors/users/list'
import * as actions from '../../actions/users/list'


class UserList extends Component {
  render() {

    return (
      <List {...this.props} />
    )
  }
}


const mapState = (state) => ({
  selectableTabs: selectors.getSelectableTabs(state),
  selectedTab: selectors.getSelectedTab(state),
  items: selectors.getItems(state),
  isFetching: selectors.isFetching(state),
  currentIsAdmin: selectors.currentIsAdmin(state),

  page: selectors.getPage(state),
  pageSize: selectors.getPageSize(state),
  total: selectors.getTotal(state),

  searchText: selectors.getSearchText(state),
  exportToken: selectors.getExportToken(state),
  retrievingToken: selectors.isRetrievingExportToken(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    onChangeTab: actions.changeTab,
    onChangePage: actions.changePage,
    onChangeSearchText: actions.changeSearchText,
    onSearch: actions.submitSearch,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(UserList))

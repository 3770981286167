import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Form, DatePicker, Input, Table, Button, Pagination } from 'antd';
import MdFab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Switch, Modal, Divider } from 'antd';
import { Select, Radio } from 'antd';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash';


const PickCustomerModal = props => {
  const { t, visible } = props
  const columns = [
    { title: t("Name"), dataIndex: "name", key: "name" },
    { title: t("Email"), dataIndex: "email", key: "email" },
    { title: t("Company"), dataIndex: "company", key: "company" },
    { title: t("Customer No"), dataIndex: "customerNo", key: "customerNo" },
    {
      title: t('Action'),
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary"
          onClick={() => props.onAddCustomer(record)}
          disabled={record.selected}
        >
          Add
        </Button>),
    }
  ]

  return (
    <Modal
      title={t("Add customer to discount list")}
      visible={visible}
      width={960}
      footer={[
        <Button type="primary" onClick={props.onCancel}>Done</Button>
      ]}
      {...props}
    >
      <Input.Search
        size="large"
        placeholder="input search text"
        enterButton
        value={props.searchText}
        onChange={e => props.onChangeSearchText(e.target.value)}
        onSearch={props.onSearch}
      />
      <Divider></Divider>
      <Table
        columns={columns}
        dataSource={props.items}
        pagination={false}
      />
      {props.isSearching && <LinearProgress color="secondary" />}
      <Grid container justify="flex-end" style={{ marginTop: 32 }}>
        <Grid item>
          <Pagination {...props} />
        </Grid>
      </Grid>
    </Modal>
  )
}

const PickRoomModal = props => {
  const { t, visible } = props
  const columns = [
    { title: t("Name"), dataIndex: "name", key: "name" },
    { title: t("Room Type"), dataIndex: "roomType", key: "roomType" },
    { title: t("Size"), dataIndex: "size", key: "size" },
    { title: t("Max participants"), dataIndex: "maxParticipants", key: "maxParticipants" },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary"
          disabled={record.selected}
          onClick={() => props.onAddRoom(record)}
        >
          Add
        </Button>),
    }
  ]

  return (
    <Modal
      title={t("Add room")}
      visible={visible}
      width={960}
      footer={[
        <Button type="primary" onClick={props.onCancel}>Done</Button>
      ]}
      {...props}
    >
      <Input.Search
        size="large"
        placeholder="input search text"
        enterButton
        value={props.searchText}
        onSearch={props.onSearch}
        onChange={e => props.onChangeSearchText(e.target.value)}
      />
      <Divider></Divider>
      <Table
        columns={columns}
        dataSource={props.items}
        pagination={false}
      />
      {props.isSearching && <LinearProgress color="secondary" />}
      <Grid container justify="flex-end" style={{ marginTop: 32 }}>
        <Grid item>
          <Pagination {...props} onChange={props.onChangePage} />
        </Grid>
      </Grid>
    </Modal>
  )
}

const View = props => {
  let {
    t,

    name,
    description,
    roomDiscount,
    facilityDiscount,
    cateringDiscount,

    selectableApplyToes,
    selectedApplyTo,
    selectableApplyTypes,
    selectedApplyType,

    selectedCustomers,
    isSelectedCustomersVisible,
    selectedCustomersPagination,
    isFechingSelectedCustomers,

    selectedRooms,
    isSelectedRoomsVisible,
    selectedRoomsPagination,
    isFetchingSelectedRooms,

    startDate,
    endDate,
    isActive,

    validation={},

    loadingSuggestCustomers=false,
    onChangeName,
    onChangeDescription,
    onChangeSelectedApplyTo,
    onChangeSelectedApplyType,
    onChangeRoomDiscount,
    onChangeFacilityDiscount,
    onChangeCateringDiscount,

    onChangeStartDate,
    onChangeEndDate,
    onChangeIsActive,

    onChangeCustomersPage,
    onChangeRoomsPage,

  } = props

  if (selectedCustomersPagination) {
    selectedCustomersPagination = {
      ...selectedCustomersPagination, 
      hideOnSinglePage: true,
      onChange: (page, pageSize)=>onChangeCustomersPage(page)
    }
  }

  if (selectedRoomsPagination) {
    selectedRoomsPagination = {
      ...selectedRoomsPagination, 
      hideOnSinglePage: true,
      onChange: (page, pageSize)=>onChangeRoomsPage(page)
    }
  }

  const columns = [
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      render: (text) => <div>{text}</div>,
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Customer No'),
      dataIndex: 'customerNo',
      key: 'customerNo',
    },
    {
      title: t('Company'),
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: (
        <MdFab
          color="primary"
          size="small"
          onClick={props.onShowPickCustomerModal}
        >
          {!loadingSuggestCustomers && <AddIcon />}
          {loadingSuggestCustomers && <CircularProgress size={12} color="default" />}
        </MdFab>
      ),
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <IconButton size="small"
          onClick={() => props.onRemoveCustomer(record)}
        >
          <DeleteIcon />
        </IconButton>),
    }
  ];

  const roomColumns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      render: (text) => <div>{text}</div>,
    },
    {
      title: t('Room Type'),
      dataIndex: 'roomType',
      key: 'roomType',
    },
    {
      title: t('Size'),
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: t('Max Participants'),
      dataIndex: 'maxParticipants',
      key: 'maxParticipants',
    },
    {
      title: (
        <MdFab
          color="primary"
          size="small"
          onClick={props.onShowPickRoomModal}
        >
          <AddIcon />
        </MdFab>
      ),
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <IconButton size="small"
          onClick={() => props.onRemoveRoom(record)}
        >
          <DeleteIcon />
        </IconButton>),
    }
  ];

  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Form>
          <Form.Item 
            label={t("Discount Name")}
            required
            validateStatus={_.get(validation, "name")}
          >
            <Input
              placeholder={t("Name of the discount")}
              value={name}
              onChange={e => onChangeName(e.target.value)}
            />
          </Form.Item>

          <Form.Item 
            label={t("Description")}
            required
            validateStatus={_.get(validation, "description")}
          >
            <Input.TextArea
              rows={4}
              placeholder={t("Short description of the discount")}
              value={description}
              onChange={e => onChangeDescription(e.target.value)}
            />
          </Form.Item>
          <Divider></Divider>

          <Form.Item label={t("Apply To")}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Select value={selectedApplyTo} onChange={onChangeSelectedApplyTo}>
                  {_.map(selectableApplyToes, it => (
                    <Select.Option
                      key={_.get(it, 'id')}
                      value={_.get(it, 'id')}
                    >
                      {_.get(it, 'name')}
                    </Select.Option>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <Radio.Group value={selectedApplyType} onChange={e => onChangeSelectedApplyType(e.target.value)}>
                  {_.map(selectableApplyTypes, it => (
                    <Radio.Button value={it.id} key={it.id}>{it.name}</Radio.Button>
                  ))}
                </Radio.Group>
              </Grid>
            </Grid>
          </Form.Item>

          {isSelectedCustomersVisible &&
            <Form.Item label={t("Customers")}>
              <Table
                size="small"
                columns={columns}
                pagination={selectedCustomersPagination || false}
                dataSource={selectedCustomers}
                loading={isFechingSelectedCustomers}
              />
            </Form.Item>
          }

          {isSelectedRoomsVisible &&
            <Form.Item label={t("Rooms")}>
              <Table
                size="small"
                columns={roomColumns}
                pagination={selectedRoomsPagination || false}
                dataSource={selectedRooms}
                loading={isFetchingSelectedRooms}
              />
            </Form.Item>
          }

          <div style={{ marginTop: 32 }}></div>
          <Form.Item label={t("Discount Amount") + " (%)"}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Input 
                  addonBefore="Room" 
                  max={100} 
                  min={0} 
                  type="number"
                  suffix="%"
                  value={roomDiscount}
                  onChange={e => onChangeRoomDiscount(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <Input 
                  addonBefore={t("Facility")} 
                  max={100} 
                  min={0} 
                  type="number" 
                  suffix="%" 
                  value={facilityDiscount}
                  onChange={e => onChangeFacilityDiscount(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <Input 
                  addonBefore={t("Catering")} 
                  max={100} 
                  min={0} 
                  type="number" 
                  suffix="%"
                  value={cateringDiscount} 
                  onChange={e => onChangeCateringDiscount(e.target.value)}
                />
              </Grid>
            </Grid>
          </Form.Item>

          <Form.Item label={t("Valid date")}>
            <Grid container spacing={2}>
              <Grid item>
                <DatePicker
                  placeholder={t("Start Date")} 
                  disabledTime
                  value={startDate}
                  onChange={onChangeStartDate}
                />
              </Grid>
              <Grid item>
                <DatePicker 
                  placeholder={t("End Date")}
                  disabledTime
                  value={endDate}
                  onChange={onChangeEndDate}
                />
              </Grid>
            </Grid>
          </Form.Item>

          <Form.Item label={t("Is Active")} >
            <Switch checked={isActive} onChange={onChangeIsActive} />
          </Form.Item>
        </Form>
      </Grid>
    </Grid>
  )
}

const SuggestCustomersModal = props => {
  const { suggestedCustomers } = props
  return (
    <Modal
    centered
      {...props}
    >
      <Typography variant="body1">
      We found {_.size(suggestedCustomers)} customers in the same company {_.get(_.first(suggestedCustomers), 'company')}
      </Typography>
      <Typography variant="body1">
        Do you want to add all of them into this disount?
      </Typography>
    </Modal>
  )
}

class DiscountEditor extends Component {
  state = { 
    showPickCustomer: false, 
    showPickRoom: false, 
  }

  render() {
    const {
      suggestedCustomers=[],
      onClosePickCustomer=f=>f,
      onAddSuggestedCustomers=f=>f,
    } = this.props

    return (
      <div>
        <View
          {...this.props}
          onShowPickCustomerModal={() => this.setState({ showPickCustomer: true })}
          onShowPickRoomModal={() => this.setState({ showPickRoom: true })}
        />

        <PickCustomerModal
          {...this.props}
          searchText={this.props.customerSearchText}
          items={this.props.selectableCustomers}
          visible={this.state.showPickCustomer}
          onCancel={() => {
            this.setState({ showPickCustomer: false })
            onClosePickCustomer()
          }}
          onChangeSearchText={this.props.onChangeCustomerSearchText}
          hideOnSinglePage={true}
          {...this.props.customerSearchPagination}
          onChange={(page, pageSize) => this.props.onChangeCustomerSearchPage(page)}
          onSearch={this.props.onSearchCustomers}
          isSearching={this.props.isSearchingCustomer}
        />

        <PickRoomModal
          {...this.props}
          visible={this.state.showPickRoom}
          onCancel={() => this.setState({ showPickRoom: false })}
          items={this.props.selectableRooms}
          searchText={this.props.roomSearchText}
          hideOnSinglePage={true}
          {...this.props.roomSearchPagination}
          onChangeSearchText={this.props.onChangeRoomSearchText}
          onChangePage={(page, pageSize) => this.props.onChangeRoomSearchPage(page)}
          onSearch={this.props.onSearchRooms}
          isSearching={this.props.isSearchingRooms}
        />

        <SuggestCustomersModal
          {...this.props}
          visible={_.size(suggestedCustomers)}
          onCancel={() => onAddSuggestedCustomers([])}
          onOk={() => onAddSuggestedCustomers(suggestedCustomers)}
        />
      </div>
    )
  }
}

export default withTranslation("discount_management")(DiscountEditor)

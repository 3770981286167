import React, { useState } from 'react'
import { Upload, Modal, Icon, message } from 'antd';
import * as _ from 'lodash'
import { withTranslation } from 'react-i18next'

import { MAX_IMG_SIZE_MB } from '../../helpers/constant'
import Api from '../../services/api'

const api = new Api()

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
  };

  componentDidMount() {
    const {images = []} = this.props
    this.setState({fileList: _.map(images, im => ({
      uid: im.id,
      url: im.url,
      status: 'done',
    }))})
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ file, fileList }) => {
    const { onChange=f=>f, images=[] } = this.props
    switch (file.status) {
      case "uploading":
        this.setState({fileList})
        break;
      case "done":
        this.setState({fileList:_.map(fileList,
          f => _.get(f, 'url') ? f : ({
            ...f,
            url: _.get(f, 'response.url'),
            uid: _.get(f, 'response.id'),
          }))})
        
        images.push(file.response)
        onChange(images)
        break;
      case "removed":
        this.setState({fileList})
        onChange(_.filter(images, im => im.id !== file.uid))
        break;
      default:
        break;
    }
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(`You can only upload JPG or PNG`);
    }
    const isLt2M = file.size / 1024 / 1024 < MAX_IMG_SIZE_MB.ROOM_TYPE;
    if (!isLt2M) {
      message.error(`Image cannot be bigger than ${MAX_IMG_SIZE_MB.ROOM_TYPE}MB!`);
    }
    return isJpgOrPng && isLt2M;
  }

  handleUpload = async ({ file, onSuccess }) => {
    const data = new FormData()
    data.append('file', file)
    const res = await api.image.uploadSingleImage(data)
    onSuccess(res)
  }

  render() {
    const { t } = this.props
    
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">{t("Upload")}</div>
      </div>
    );
    return (
      <div className="clearfix">
        <Upload
          listType="picture-card"
          beforeUpload={this.beforeUpload}
          customRequest={this.handleUpload}
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default withTranslation("common")(PicturesWall)

const C = "USERS.LIST"

export const LIST_USER = `${C}.LIST_USER`
export const LIST_USER_COMPLETED = `${C}.LIST_USER_COMPLETED`
export const LIST_USER_FAILED = `${C}.LIST_USER_FAILED`
export const CHANGE_PAGE = `${C}.CHANGE_PAGE`
export const CHANGE_TAB = `${C}.CHANGE_TAB`
export const CHANGE_SEARCH_TEXT = `${C}.CHANGE_SEARCH_TEXT`
export const SUBMIT_SEARCH = `${C}.SUBMIT_SEARCH`

export const RETRIEVE_EXPORT_TOKEN = `${C}.RETRIEVE_EXPORT_TOKEN`
export const RETRIEVE_EXPORT_TOKEN_COMPLETED = `${C}.RETRIEVE_EXPORT_TOKEN_COMPLETED`
export const RETRIEVE_EXPORT_TOKEN_FAILED = `${C}.RETRIEVE_EXPORT_TOKEN_FAILED`

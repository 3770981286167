import { all, takeLatest, put, call } from 'redux-saga/effects'
import * as actions from '../actions/discount'
import * as actionTypes from '../actionTypes/discount.actionTypes'
import { history } from '../config/store'
import * as paths from '../constants/paths.constants'
import { message } from 'antd'

function* getListAllDiscount({api}, {data}) {
  try {
    // yield delay(2000)
    const response = yield call(api.discount.getListDiscount, data)
    yield put(actions.getListDiscountSuccess(response))
    // history.push(paths.ListFacilities)
  } catch (e) {
    yield put(actions.getListDiscountFail())
  }
}

function* createNewDiscount({api}, {data}) {
  try {
    // yield delay(2000)
    const response = yield call(api.discount.createNewDiscount, data)
    yield put(actions.createNewDiscountSuccess(response))
    message.success('Create successful')
    history.push(paths.ListDiscounts)
  } catch (e) {
    yield put(actions.createNewDiscountFail())
  }
}

function publicSaga(deps) {
  return function* saga() {
    yield all([
      takeLatest(actionTypes.GET_LIST_DISCOUNT, getListAllDiscount, deps),
      takeLatest(actionTypes.CREATE_NEW_DISCOUNT, createNewDiscount, deps),
    ])
  }
}

export default publicSaga
import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography, TextField, Button, IconButton } from '@material-ui/core'
import MaterialTableComponent from '../../../components/Table/MaterialTable'
import * as paths from '../../../constants/paths.constants'
import { RemoveRedEyeOutlined, EditOutlined } from '@material-ui/icons'
import { bindActionCreators } from 'redux'
import {getListIncomingBookings, getListHistoryBookings} from '../../../actions/booking'
import {openSearchAvailableRooms} from '../../../actions/modal'
import { useEffect } from 'react'
import {withRouter} from 'react-router-dom'
import {CURRENCY_MAPPING, BOOKING_STATUS} from '../../../helpers/constant'
import {timeslotsToBinary, pathWithId} from '../../../helpers/helpers'
import { formatMoney } from '../../../helpers/bookings'
import {get} from 'lodash'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

import LoadingIcon from 'mdi-react/LoadingIcon'
import {withTranslation} from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {},
  formField: {
    borderRadius: '5px',
    width: '30%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px'
  },
  searchButton: {
    color: theme.colorAccent
  },
  newBookingButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '40px'
  }
}))

const UpcomingTable = props => {
  const classes = useStyles()
  const {getListIncomingBookings, listIncomingBookings, listIncomingBookingsMeta, 
    isGettingListIncomingBookings, history, configurationInfo, mode, getListHistoryBookings,
    openSearchAvailableRooms, t , i18n
  } = props

  moment.locale(i18n.language === 'en' ? 'en-gb' : 'de')

  useEffect(() => {
    if(mode) {
      getListHistoryBookings({
        page_size: 5,
        page: 1
      })
    } else {
      getListIncomingBookings({
        page_size: 5,
        page: 1
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, mode])

  const headCells = [
    { key: 'id', numeric: false, disablePadding: true, label: '#ID'},
    { key: 'room_name', numeric: true, disablePadding: false, label: t('BOOKING.Room') },
    { key: 'customer', numeric: true, disablePadding: false, label: t('BOOKING.Customer'),
      render: (customer) => `${customer.first_name} ${customer.last_name}`
    },
    { key: 'paid_total', numeric: true, disablePadding: false, label: t('BOOKING.Paid'),
      render: (paid_total) => formatMoney(paid_total/configurationInfo.unit)
    },
    { key: 'start_date', numeric: true, disablePadding: false, label: t('BOOKING.StartAt'),
      render: (start_date, row) => {
        const binary = timeslotsToBinary(get(row, 'start_slot_int', 0))
        const startTime = binary.indexOf('1') + 8
        return `${ moment(start_date).format('YYYY-MM-DD')} -- ${startTime}:00`
      }
    },
    { key: 'end_date', numeric: true, disablePadding: false, label: t('BOOKING.EndAt'),
      render: (end_date, row) => {
        const binary = timeslotsToBinary(get(row, 'end_slot_int', 0))
        const endTime = binary.lastIndexOf('1') + 9
        return `${moment(end_date).format('YYYY-MM-DD')} -- ${endTime}:00`
      }
    },
    { key: 'status', numeric: true, disablePadding: false, label: t('BOOKING.Status'),
      render: (status) => t(`BOOKING.${BOOKING_STATUS[status]}`)
    },
    { key: 'action', numeric: true, disablePadding: false, label: t('BOOKING.Action'),
      render: (_, row) => {
        return (
          <>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => history.push(pathWithId(paths.BookingDetail, row.id))}
            // onMouseDown={handleMouseDownPassword}
            >
              <RemoveRedEyeOutlined />
            </IconButton>
          </>
        )
      }
    },
  ]

  const handleChangePage = page => {
    if(mode) {
      getListHistoryBookings({
        page_size: get(listIncomingBookingsMeta, 'page_size', 5),
        page: page
      })
    } else {
      getListIncomingBookings({
        page_size: get(listIncomingBookingsMeta, 'page_size', 5),
        page: page
      })
    }
  }

  const handleChangePageSize = size => {
    if(mode) {
      getListHistoryBookings({
        page_size: size,
        page: 1
      })
    } else {
      getListIncomingBookings({
        page_size: size,
        page: 1
      })
    }
  }

  return (
    <Container>
      <Grid container>
        <Grid item md={12}>
          <Grid container>
            <Grid item md={8} className={classes.searchContent}>
              {/* <Typography component='div'>Search a booking</Typography>
              <TextField
                // error
                className={classes.formField}
                // id="outlined-error"
                label="Booking ID, Room ID"
                variant="outlined"
              />
              <TextField
                // error
                className={classes.formField}
                // id="outlined-error"
                label="Pick a date"
                variant="outlined"
              />
              <Button className={classes.searchButton} color="default" variant="contained" disableElevation>
                <MagnifyIcon/>
              </Button> */}
            </Grid>
            <Grid item md={4} className={classes.newBookingButtonContent}>
              <Button color="primary" variant="contained" disableElevation
                onClick={() => openSearchAvailableRooms()}
              >
                {t('BOOKING.NewBooking')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          {isGettingListIncomingBookings ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
          <MaterialTableComponent
            title={t('BOOKING.RoomBooking')}
            columns={headCells}
            rows={listIncomingBookings}
            pageSize={get(listIncomingBookingsMeta, 'page_size')}
            page={get(listIncomingBookingsMeta, 'page')}
            total={get(listIncomingBookingsMeta, 'count')}
            onChangePage={(page) => handleChangePage(page)}
            onChangePageSize={(size) => handleChangePageSize(size)}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

const mapState = ({bookingReducer, publicReducer}) => ({
  isGettingListIncomingBookings: bookingReducer.isGettingListIncomingBookings,
  listIncomingBookings: bookingReducer.listIncomingBookings,
  listIncomingBookingsMeta: bookingReducer.listIncomingBookingsMeta,
  configurationInfo: publicReducer.configurationInfo,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    getListIncomingBookings,
    getListHistoryBookings,
    openSearchAvailableRooms
  },
  dispatch
)

UpcomingTable.propTypes = {
  className: PropTypes.string,
  isGettingListIncomingBookings: PropTypes.bool,
  listIncomingBookings: PropTypes.array,
  listIncomingBookingsMeta: PropTypes.object,
  configurationInfo: PropTypes.object,
  history: PropTypes.object,
  i18n: PropTypes.object,
  mode: PropTypes.bool,
  getListIncomingBookings: PropTypes.func,
  getListHistoryBookings: PropTypes.func,
  t: PropTypes.func,
}

UpcomingTable.defaultProps = {
  orders: []
}

export default connect(mapState, mapDispatch) (withTranslation('common') (withRouter(UpcomingTable)))

import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import New from '../../components/caterings/New'
import * as actions from '../../actions/caterings/new'
import * as selectors from '../../selectors/caterings/new'


class CateringNew extends Component {
  componentDidMount() {

  }

  render() {

    return (
      <New {...this.props} />
    )
  }
}


const mapState = (state) => ({
  isSubmitting: selectors.isSubmitting(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    onSubmit: actions.submit,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(CateringNew))

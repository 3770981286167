import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, TextField, Toolbar, Button } from '@material-ui/core'
import { Form, Input } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import {get} from 'lodash'

import Image from '../../../assets/images/Stockholm_1.jpg'
import * as paths from '../../../constants/paths.constants'
import {history} from '../../../config/store' 
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import ASSETS from '../../../constants/assets'
import {resetPasswordConfirm} from '../../../actions/user'

const useStyles = makeStyles((theme) => {
  return ({
    logo: {
      marginTop: '50px',
      // marginBottom: '50px',
      color: theme.colorAccent,
      width: '20%',
      cursor: 'pointer',
      position: 'absolute',
      top: '0',
      left: '40%',
      // width: '300px',
    },
    background: {
      minHeight: '230px',
      height: '25%',
      backgroundImage: `url(${Image})`,
      '& img': {
        // height: '55%',
        width: '20%'
      },
      backgroundSize: '100%',
      backgroundPositionY: '455px',
      opacity: 0.2
    },
    title: {
      marginTop: '50px'
    },
    checkIcon: {
      marginTop: '50px',
      '& svg': {
        width: '50px',
        height: '50px',
        color: theme.colorAccent,
      }
    },
    formRoot: {
      textAlign: 'center',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 300,
      },
    },
    formField: {
      '& .MuiInputBase-root': {
        // backgroundColor: 'white',
        borderRadius: '15px'
      }
    },
    fontBold: {
      fontWeight: 'bold'
    },
    textWelcome: {
      marginTop: '50px',
      padding: '20px 0',
      color: theme.colorAccent
    },
    verifySuccessText: {
      padding: '0px 0 10px',
      color: theme.colorGray
    },
    titleSubText: {
      color: theme.colorGray,
      padding: '0 20%'
    },
    buttonGroup: {
      justifyContent: 'center',
      '& button': {
        width: 300,
        padding: '10px 0',
        margin: '25px'
      }
    },
    forgotPassword: {
      cursor: 'pointer'
    },
    registerContent: {
      marginTop: '30px'
    },
    registerLink: {
      cursor: 'pointer'
    }
  })
})

const Verify = props => {
  const classes = useStyles()
  const {form: {getFieldDecorator, validateFields, getFieldValue}} = props
  const { qryParams, resetPasswordConfirm, resetSuccessful, t } = props
  const handleSubmit = async(e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        resetPasswordConfirm({
          ...qryParams,
          ...values
        })
      }
    })
  }

  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid item md={12} sm={12} xs={12}>
        <Typography variant='h2' align='center' className={classes.background}>
        </Typography>
        <img src={ASSETS.LOGO} className={classes.logo} onClick={() => history.push(paths.HomePage)}  alt=""/>
        {!resetSuccessful ?
          <>
            <Typography variant='h5' align='center' className={classes.textWelcome}>
              Reset Password
            </Typography>
            <Typography variant='body2' align='center' className={classes.titleSubText}>
              Please enter the reset code sent to your registered email and new password
            </Typography>
            <Form 
              initialValues={{email: ''}}
              onSubmit={handleSubmit} 
              className={classes.formRoot}>
              <Grid container >
                <Grid item xs={12}>
                  <Form.Item 
                    name="new_password"
                    label={t('USERS.NewPassword')}
                    hasFeedback
                  >
                    {getFieldDecorator('new_password', {  
                      rules: [
                        { 
                          required: true,
                          message: 'Please input your password!',
                        }
                      ],
                      initialValue: ''
                    }) (<TextField
                        // error
                        className={classes.formField}
                        // id="outlined-error"
                        id="standard-basic"
                        type="password"/>)}
                  </Form.Item>
                </Grid>
                <Grid item xs={12}>
                  <Form.Item
                    name="re_new_password"
                    label={t('USERS.ConfirmNewPassword')}
                    dependencies={['new_password']}
                    hasFeedback
                  >{getFieldDecorator('re_new_password', {  
                    rules: [
                      {
                        required: true,
                        message: 'Please re-enter your new password!',
                      },
                      {validator: (rule, value, callback) => {
                          console.log(value)
                          if (!value || getFieldValue('new_password') === value) {
                            return callback();
                          }
                          return callback('The two passwords do not match!');
                      }},
                    ],
                    initialValue: ''
                  })(<TextField
                      // error
                      className={classes.formField}
                      // id="outlined-error"
                      id="standard-basic"
                      type="password"/>)}
                  </Form.Item>
                </Grid>
                <Grid item xs={12}>
                  <Toolbar className={classes.buttonGroup}>
                    <Button autoFocus color="primary" variant="contained" type="submit" >
                      Reset Password
                    </Button>
                  </Toolbar>
                </Grid>
              </Grid>
            </Form>
          </> :
          <>
            <Typography variant='h5' align='center' className={classes.checkIcon}>
              <CheckCircleIcon />
            </Typography>
            <Typography variant='h5' align='center' className={classes.verifySuccessText}>
              Password<br/>
              Successfully Reset!
            </Typography>
            <Typography variant='body2' align='center' className={classes.titleSubText}>
              Now you can sign in with your new password. <br/>
              Check your new email for the update!
            </Typography>
            <Grid item xs={12}>
              <Toolbar className={classes.buttonGroup}>
                <Button autoFocus color="primary" variant="contained" onClick={() => history.push(paths.LoginPage)} >
                  Go to SIGN IN
                </Button>
              </Toolbar>
            </Grid>
          </>
        }
      </Grid>
      {/* <Grid item md={8} sm={8} xs={8} className={classes.background}>
        <img src={Image}  alt="" />
      </Grid> */}
    </Grid>
  )
}

const mapState = ({ userReducer, router }) => ({
  isSubmitting: userReducer.isResetingPassword,
  resetSuccessful: userReducer.isResetSuccessful,
  qryParams: get(router, 'location.query')
})

const mapDispatch = dispatch => bindActionCreators(
  {
    resetPasswordConfirm,
    
  },
  dispatch
)

Verify.propTypes = {
  className: PropTypes.string,
}

Verify.defaultProps = {
}

export default  Form.create({
  name: 'resetPasswordConfirm'
})(withRouter(connect(mapState, mapDispatch)(withTranslation('common')(Verify))))
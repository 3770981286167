const C = "USER.DETAIL"

export const RETRIEVE_USER = `${C}.RETRIEVE_USER`
export const RETRIEVE_USER_COMPLETED = `${C}.RETRIEVE_USER_COMPLETED`
export const RETRIEVE_USER_FAILED = `${C}.RETRIEVE_USER_FAILED`

export const LIST_BOOKINGS = `${C}.LIST_BOOKINGS`
export const LIST_BOOKINGS_COMPLETED = `${C}.LIST_BOOKINGS_COMPLETED`
export const LIST_BOOKINGS_FAILED = `${C}.LIST_BOOKINGS_FAILED`
export const CHANGE_TAB = `${C}.CHANGE_TAB`
export const CHANGE_PAGE = `${C}.CHANGE_PAGE`

export const LIST_BOOKING_ANALYTICS = `${C}.LIST_BOOKING_ANALYTICS`
export const LIST_BOOKING_ANALYTICS_COMPLETED = `${C}.LIST_BOOKING_ANALYTICS_COMPLETED`
export const LIST_BOOKING_ANALYTICS_FAILED = `${C}.LIST_BOOKING_ANALYTICS_FAILED`

export const DELETE_USER = `${C}.DELETE_USER`
export const DELETE_USER_COMPLETED = `${C}.DELETE_USER_COMPLETED`
export const DELETE_USER_FAILED = `${C}.DELETE_USER_FAILED`

import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as _ from 'lodash'
import { message } from 'antd';
import * as C from '../../actionTypes/bookings/detail'
import * as selectors from '../../selectors/bookings/detail'
import {getLocale} from '../../selectors/public'
import { BOOKING } from '../../constants/models'
const URL = process.env.REACT_APP_BASE_URL


function* retrieveBooking({api}, {payload}) {
  try {
    const response = yield call(api.booking.getBookingDetail, payload)
    yield put({ type: C.RETRIEVE_BOOKING_COMPLETED, payload: response })
  } catch (e) {
    yield put({type: C.RETRIEVE_BOOKING_FAILED, payload: e})
    console.error(e)
  }
}

function* retrieveBookingHtml({api}, {payload}) {
  const id = _.get(payload, 'id')
  const lang = yield select(getLocale)
  try {
    yield put({type: C.RETRIEVE_BOOKING_HTML})
    const bookingToken = yield call(api.booking.getBookingToken)
    const {token} = bookingToken
    const html = yield call(api.booking.downloadBookingInvoiceHTML, {
      booking_id: id,
      token,
      lang
    })
    
    yield put({
      type: C.RETRIEVE_BOOKING_HTML_COMPLETED, 
      payload: {
        html, 
        pdfUrl: `${URL}/booking/download_invoice_pdf?token=${token}&booking_id=${id}&lang=${lang}`
      }
    })
  } catch (e) {
    yield put({type: C.RETRIEVE_BOOKING_HTML_FAILED, payload: e})
    console.error(e)
  }
}

function* saveNotes({api}) {
  const req = yield select(selectors.generateSaveNotesReq)
  try {

    const response = yield call(api.booking.updateNotes, req)
    yield put({type: C.SAVE_NOTES_COMPLETED, payload: response})

  } catch (e) {
    yield put({type: C.SAVE_NOTES_FAILED, payload: e})
    message.error(_.get(e, 'data.detail', 'Error'))
    console.error(e)
  }
}

function* cancelBooking({api}, {payload}) {
  const req = yield select(selectors.generateCancelBookingReq)
  const lang = yield select(getLocale)
  try {

    const response = yield call(api.booking.cancelBooking, {...req, ...payload}, lang)
    yield put({type: C.CANCEL_BOOKING_COMPLETED, payload: response})

  } catch (e) {
    yield put({type: C.CANCEL_BOOKING_FAILED, payload: e})
    message.error(_.get(e, 'data.detail', 'Error'))
    console.error(e)
  }
}

function* refundBooking({api}) {
  const req = yield select(selectors.generateRefundBookingReq)
  try {

    const response = yield call(api.booking.refundBooking, req)
    yield put({ type: C.SUBMIT_REFUND_SUCCESS, payload: response })

  } catch (e) {
    yield put({type: C.SUBMIT_REFUND_FAILED, payload: e})
    message.error(_.get(e, 'data.detail', 'Error'))
    console.error(e)
  }
}

function detailSaga(deps) {
  return [
      takeLeading(C.RETRIEVE_BOOKING, retrieveBooking, deps),
      takeLeading(C.RETRIEVE_BOOKING_COMPLETED, retrieveBookingHtml, deps),
      takeLeading(C.SAVE_NOTES, saveNotes, deps),
      takeLeading(C.CANCEL_BOOKING, cancelBooking, deps),
      takeLeading(C.SUBMIT_REFUND, refundBooking, deps),
  ]
}

export default detailSaga

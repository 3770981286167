import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
// import MuiDialogTitle from '@material-ui/core/DialogTitle'
// import MuiDialogContent from '@material-ui/core/DialogContent'
// import IconButton from '@material-ui/core/IconButton'
// import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { Grid, Container } from '@material-ui/core'
import Prototype from 'prop-types'
import { history } from '../../../config/store'
import * as paths from '../../../constants/paths.constants'
import { Link, withRouter } from 'react-router-dom'
import { Form, Input, Select, Switch } from 'antd'
import { createCustomerInBooking } from '../../../actions/user'
import { get } from 'lodash'
import LoadingIcon from 'mdi-react/LoadingIcon'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'

import ASSETS from '../../../constants/assets'
const { COUNTRIES } = ASSETS

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    padding: '20px 0',
    // textAlign: 'left'
    display: 'flex',
    justifyContent: 'space-between'
  },
  root: {
    padding: '0 0'
  },
  title: {
    padding: '30px 0 10px',
    marginBottom: '20px',
    color: theme.colorAccent,
    width: 'max-content',
    borderBottom: `2px solid ${theme.colorAccent}`
  },
  searchButton: {
    padding: '5px 40px'
  },
  formRoot: {
    '& .ant-form-item': {
      display: 'flex',
      alignItems: 'center'
    }
  }


}))


const marks = [
  {
    value: 8,
    label: '8am',
  },
  {
    value: 10,
    label: '10am',
  },
  {
    value: 14,
    label: '2pm',
  },
  {
    value: 22,
    label: '10pm',
  },

]

function valuetext(value) {
  return `${value}`
}

const AddCustomerInfo = (props) => {
  const {
    createCustomerInBooking,
    form: { getFieldDecorator, validateFields, setFieldsValue }, history,
    isCreatingCustomerInBooking, t
  } = props
  const classes = useStyles()
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    await validateFields((errors, values) => {
      if (!errors) {
        createCustomerInBooking(values)
      }
    })
  }

  return (
    <Container>
      <Grid container className={classes.root}>
        {isCreatingCustomerInBooking ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
        {/* <Grid item sm={12} xs={12} md={12}>
          <Typography className={classes.title} variant="h4" align="left">
            Create New Booking
          </Typography>
        </Grid> */}
        <Grid item sm={12} xs={12} md={12}>
          <Typography variant="h6" align="left">
            {t('BOOKING.CustomerInfo')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Form {...formItemLayout} onSubmit={handleSubmit} className={classes.formRoot}>
            <Form.Item
              label="Is Company Customer"
              name="isCompanyCustomer"
              valuePropName="checked"
            >
              {getFieldDecorator('is_belong_to_company', {
                valuePropName: 'checked',
                initialValue: false
              })(
                <Switch />
              )}
            </Form.Item>

            <Form.Item
              label="Company"
              name="company"
            >
              {getFieldDecorator('company', {
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="First name"
              name="firstName"
            >
              {getFieldDecorator('first_name', {
                rules: [{ required: true, message: 'Please input First Name!' }]
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="Last name"
              name="lastName"
            >
              {getFieldDecorator('last_name', {
                rules: [{ required: true, message: 'Please input Last Name!' }]
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
            >
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Please input Email!', type: "email" }]
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
            >
              {getFieldDecorator('phone', {
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="Street"
              name="street"
            >
              {getFieldDecorator('street', {
                rules: [{ required: true, message: 'Please input Street name!' }]
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="House Number"
              name="houseNumber"
            >
              {getFieldDecorator('house_nbr', {
                rules: [{ required: true, message: 'Please input House Number!' }]
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="Postcode"
              name="postcode"
            >
              {getFieldDecorator('post_code', {
                rules: [{ required: true, message: 'Please input Postcode!' }]
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="City"
              name="city"
            >
              {getFieldDecorator('city', {
                rules: [{ required: true, message: 'Please input City!' }]
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="Country"
              name="country"
            >
              {getFieldDecorator('country', {
                rules: [{ required: true, message: 'Please input Country!' }]
              })(
                // <Input />
                <Select
                  showSearch
                >
                  {_.map(COUNTRIES, c => (
                    <Select.Option value={_.get(c, 'en')}>{_.get(c, 'en')}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              label="Customer No"
              name="customerNo"
            >
              {getFieldDecorator('customer_id', {
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="Addition"
              name="addition"
            >
              {getFieldDecorator('addition', {
              })(
                <Input />
              )}
            </Form.Item>

            <Form.Item
              label="Is Long-Term Customer"
              name="isLongTermCustomer"
              valuePropName="checked"
            >
              {getFieldDecorator('is_long_term_customer', {
                valuePropName: 'checked',
                initialValue: false
              })(
                <Switch />
              )}
            </Form.Item>
            <Grid item xs={12} className={classes.buttonGroup}>
              <Link onClick={() => history.goBack()}>
                <Button variant="contained" className={classes.searchButton}
                // onClick={() => onSearch()}
                >
                  {t('BOOKING.Cancel')}
                </Button>
              </Link>
              <Button
                variant="contained"
                color="primary"
                className={classes.searchButton}
                type="submit"
              // onClick={() => onSearch()}
              >
                {t('BOOKING.Next')}
              </Button>
            </Grid>
          </Form>
        </Grid>

      </Grid>
    </Container>
  )
}

const mapState = ({ userReducer, bookingReducer }) => ({
  customer: userReducer.createdCustomer,
  isCreatingCustomerInBooking: bookingReducer.isCreatingCustomerInBooking,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    createCustomerInBooking
  },
  dispatch
)

AddCustomerInfo.propTypes = {
  isOpen: Prototype.bool,
  closeModal: Prototype.func,
  isCreatingCustomerInBooking: Prototype.bool
}


export default Form.create({
  name: 'customerInfo'
})(withRouter(connect(mapState, mapDispatch)(withTranslation('common')(AddCustomerInfo))))
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  Grid, Typography, Card,
  CardContent, Container,
  Button, Button as MaterialButton, 
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
// import Image from '../../../assets/images/Kopenhagen_1-1.jpg'
import RoomeTimetable from './RoomTimetable'
// import BookingSummary from './BookingSummary'
import * as paths from '../../../constants/paths.constants'
import { Form, Divider, Input } from 'antd'
import LoadingIcon from 'mdi-react/LoadingIcon'
import AddExtraFacilitiesForm from './AddExtraFacilitiesForm'
import AddDiscountsForm from './AddDiscountsForm'
import AddCateringForm from './AddCateringForm'
import RoomPricingTable from './RoomPricingTable'
import {updateBookingState} from '../../../actions/booking'
import { get } from 'lodash'
import {withTranslation} from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 0'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f7f7f7'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
    borderBottom: '1px solid #ddd',
  },
  showMoreGrid: {
    padding: '20px'
  },
  title: {
    padding: '15px 0'
  },
  roomExtraServiceContent: {
    marginTop: '20px'
  },
  roomTimetable: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '5%'
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0'
    },

  },
  tableTitleContent: {
    borderBottom: '1px solid #ddd',
    padding: '0 0 15px',
    marginBottom: '10px'
  },
  pointContent: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  selectingPoint: {
    padding: '0px 8px',
    backgroundColor: theme.colorBlue,
    borderRadius: '50%',
    marginRight: '2px'
  },
  bookedPoint: {
    padding: '0px 8px',
    backgroundColor: theme.colorAccent,
    borderRadius: '50%',
    marginRight: '2px'
  },
  availablePoint: {
    padding: '0px 8px',
    backgroundColor: theme.colorGray,
    borderRadius: '50%',
    marginRight: '2px'
  },
  disablePoint: {
    padding: '0px 8px',
    // backgroundColor: theme.colorAccent,
    border: '1px solid #ddd',
    borderRadius: '50%',
    marginRight: '2px'
  },
  formControl: {
    width: '100%',
    minWidth: '100px',
  },
  formField: {
    borderRadius: '5px',
    width: '100%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '10px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  formSelectField: {
    borderRadius: '5px',
    width: '95%',
    margin: '8px',
    minWidth: '100px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '5px',
      marginRight: '20px'
    },
    '& .MuiSelect-root': {
      backgroundColor: 'white',
      borderRadius: '5px'
    },
    '& .MuiInputBase-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-formControl': {
      top: '-7px'
    },
  },
  formLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  // facilitiesServiceContent: {
  //   [theme.breakpoints.up('md')]: {
  //     paddingLeft: '0%'
  //   },
  //   [theme.breakpoints.down('md')]: {
  //     paddingLeft: '0'
  //   },
  // },
  addBookingButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxContent: {
    paddingLeft: '8px'
  },
  roomCardInfo: {
    marginBottom: '20px'
  },
  footerContent: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectField: {
    '& .ant-select': {
      width: '50%',

    }
  },
  faclityItem: {
    margin: '5px 0'
  },
  inputField: {
    '& .ant-input': {
      width: '50%',

    }
  },
  facilitiesServiceContent: {
    borderTop: '1px solid #ddd',
    paddingTop: '20px'
  },
  cateringContent: {
    borderTop: '1px solid #ddd',
    padding: '20px'
  },
  buttonAddItem: {
    color: theme.colorAccent
  },
  additionalContent: {
    borderTop: '1px solid #ddd',
    padding: '20px'
  }
}))

const CustomerInfoComponent = (props) => {
  const classes = useStyles()

  const { customerInfo, roomBooked } = props
  // console.log(get(roomBooked, 'item'))
  // console.log(roomBooked)
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h6" component="h2" align="left">
          Customer: {get(customerInfo, 'first_name', '')}&nbsp;{get(customerInfo, 'last_name', '')}
        </Typography>
        <Typography variant="subtitle1" align="left" className={classes.roomExtraServiceContent}>
          <p>- Company:&nbsp;{get(customerInfo, 'company', '')}</p>
          <p>- Email:&nbsp;{get(customerInfo, 'email', '')}</p>
          <Divider />
          <p>- Max participants:&nbsp;{get(roomBooked, '[0].item.max_attendances', 0)}</p>
          <p>- In room facility:&nbsp;{get(roomBooked, '[0].item.permanent_facilities', []).map((item, index) => {
            if (index > 0) return `, ${item.name}`
            return item.name
          })} </p>
        </Typography>
      </CardContent>
    </Card>
  )
}

CustomerInfoComponent.propTypes = {
  customerInfo: PropTypes.object,
  roomBooked: PropTypes.object,
}


const AddBookingDetails = props => {
  const { t, i18n, form: { getFieldDecorator, validateFields }, bookingSession, history, updateBookingState, isUpdatingBookingState } = props
  const classes = useStyles()
  const [addtional, setAdditional] = useState(get(bookingSession, 'additional_requirement', ''))

  useEffect(() => {
    if (!get(bookingSession, 'customer.id')) {
      history.push(paths.CreateNewBooking_SelectCustomer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingSession])

  const handleNext = () => {
    const data = {state: 3}
    if(addtional !== '' ) {
      data.additional_requirement = addtional
    }
    updateBookingState(data)
    history.push(paths.CreateNewBooking_NewBookingCreated)
  }
  const customerInfo = get(bookingSession, 'customer', {})
  const roomBooked = get(bookingSession, 'items', []).filter(item => item.item_type === 1)
  return (
    <Container>
      {isUpdatingBookingState ? <div className="loading-refresh"><LoadingIcon /></div> : ''}
      <Grid container className={classes.root}>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid itm xs={12} md={4} className={classes.roomCardInfo}>
              <CustomerInfoComponent
                customerInfo={customerInfo}
                roomBooked={roomBooked}
              />
            </Grid>
            <Grid item xs={12} md={8} className={classes.roomTimetable}>
              <Grid container className={classes.tableTitleContent}>
                <Grid item md={5} xs={4}>
                  <Typography variant="h6" component="span">
                    {t('BOOKING.Room')} - {get(roomBooked, '[0].item.name', '')}
                  </Typography>
                </Grid>
                <Grid item md={7} xs={8} className={classes.pointContent}>
                  <Grid container >
                    <Grid item xs={3} md={3}><span className={classes.selectingPoint}></span>{t('BOOKING.Selecting')}</Grid>
                    <Grid item xs={3} md={3}><span className={classes.bookedPoint}></span>{t('BOOKING.Booked')}</Grid>
                    <Grid item xs={3} md={3}><span className={classes.availablePoint}></span>{t('BOOKING.NotAvailable')}</Grid>
                    <Grid item xs={3} md={3}><span className={classes.disablePoint}></span>{t('BOOKING.Free')}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <RoomeTimetable
                 
                />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} >
          <RoomPricingTable />
          <AddExtraFacilitiesForm />
          <AddCateringForm />
          <AddDiscountsForm />
          {/* <Grid className={classes.additionalContent} >
            <Typography variant="h6" component="h6">
              {('BOOKING.AdditionalRequirement')}:
            </Typography>
            <Input.TextArea rows={4}
              value={addtional}
              onChange={e => setAdditional(e.target.value)}
            />
          </Grid> */}
        </Grid>
        {/* <Grid item xs={12}>
          <BookingSummary />
        </Grid> */}
        <Divider/>
        <Grid item xs={12}>
          <div className={classes.footerContent}>
            <Button
              onClick={() => {
                updateBookingState({state: 1})
                history.goBack()
              }}
              className={classes.backButton}
            >
              {t('BOOKING.Back')}
            </Button>
            <Button variant="contained" color="primary"
              onClick={() => handleNext()}
            >
              {t('BOOKING.Next')}
            </Button>
          </div>
        </Grid>
      </Grid>

    </Container>
  )
}

const mapState = ({ bookingReducer }) => ({
  bookingSession: bookingReducer.bookingSession,
  isUpdatingBookingState: bookingReducer.isUpdatingBookingState,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    updateBookingState
  },
  dispatch
)
AddBookingDetails.propTypes = {
  form: PropTypes.object,
  bookingSession: PropTypes.object,
  isUpdatingBookingState: PropTypes.bool,
  history: PropTypes.object,
  updateBookingState: PropTypes.func,
}

export default Form.create({
  name: 'catering'
})(connect(mapState, mapDispatch) (withTranslation('common') (withRouter(AddBookingDetails))))


import publicSaga from './public'
import authSaga from './auth'
import userSaga from './user'
import bookingSaga from './booking'
import roomSaga from './room'
import facilitiesSaga from './facilities'
import cateringsSaga from './caterings'
import discountSaga from './discount'

import discountV2Saga from './discounts'
import bookingV2Saga from './bookings'
import userV2Saga from './users'
import roomV2Saga from './rooms'
import facilityV2Saga from './exfacilities'
import cateringV2Saga from './caterings/index'

export default function rootSaga() {
  return [
    publicSaga,
    authSaga,
    userSaga,
    bookingSaga,
    roomSaga,
    facilitiesSaga,
    cateringsSaga,
    discountSaga,
    
    discountV2Saga,
    bookingV2Saga,
    userV2Saga,
    roomV2Saga,
    facilityV2Saga,
    cateringV2Saga,
  ]
}

import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as C from '../../actionTypes/discounts/detail'
import * as selectors from '../../selectors/discounts/detail'
import { history } from '../../config/store'
import { DISCOUNT } from '../../constants/models'
import * as _ from 'lodash'

const { APPLY_TYPE, OBJECT_TYPE } = DISCOUNT

function* retrieveDiscount({api}, {payload}) {
  try {
    const response = yield call(api.discount.retrieveDiscount, payload)
    yield put({type: C.RETRIEVE_DISCOUNT_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.RETRIEVE_DISCOUNT_FAILED, payload: e})
    console.error(e)
  }
}

function* listApplyObjects({api}, {payload}) {
  
  if (_.get(payload, 'apply_type') == APPLY_TYPE.CUSTOM) {
    if (_.get(payload, 'object_type') == OBJECT_TYPE.ROOM) {

      yield put({type: C.LIST_ROOMS})
      try {
        const req = yield select(selectors.generateListRoomsReq)
        const response = yield call(api.room.searchRooms, req)
        yield put({type: C.LIST_ROOMS_COMPLETED, payload: response})
      } catch (e) {
        yield put({type: C.LIST_ROOMS_FAILED, payload: e})
        console.error(e)
      }

    } else {

      yield put({type: C.LIST_CUSTOMERS})
      try {
        const req = yield select(selectors.generateListCustomersReq)
        const response = yield call(api.user.searchListCustomer, req)
        yield put({type: C.LIST_CUSTOMERS_COMPLETED, payload: response})
      } catch (e) {
        yield put({type: C.LIST_CUSTOMERS_FAILED, payload: e})
        console.error(e)
      }

    }
  }
}

function* listRooms({api}) {
  yield put({type: C.LIST_ROOMS})
  try {
    const req = yield select(selectors.generateListRoomsReq)
    const response = yield call(api.room.searchRooms, req)
    yield put({type: C.LIST_ROOMS_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.LIST_ROOMS_FAILED, payload: e})
    console.error(e)
  }
}

function* listCustomers({api}) {
  yield put({type: C.LIST_CUSTOMERS})
  try {
    const req = yield select(selectors.generateListCustomersReq)
    const response = yield call(api.user.searchListCustomer, req)
    yield put({type: C.LIST_CUSTOMERS_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.LIST_CUSTOMERS_FAILED, payload: e})
    console.error(e)
  }
}

function detailSaga(deps) {
  return [
      takeLeading(C.RETRIEVE_DISCOUNT, retrieveDiscount, deps),
      takeLatest(C.RETRIEVE_DISCOUNT_COMPLETED, listApplyObjects, deps),
      takeLatest(C.CHANGE_ROOMS_LIST_PAGE, listRooms, deps),
      takeLatest(C.CHANGE_CUSTOMERS_LIST_PAGE, listCustomers, deps),
  ]
}

export default detailSaga

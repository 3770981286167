import { bind } from 'decko'
import BaseApi from './baseApi'
import {GetListAllCaterings, UpdateCatering, CreateNewCatering
} from '../../constants/endpoints.constants'
export default class Catering extends BaseApi {
  @bind
  async getListAllCaterings(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(GetListAllCaterings(), data)
  }
  @bind
  async retrieveCatering(id, data) {
    await this.checkAuthenticatedStatus()
    return await this.http.get(`/caterings/${id}`, data)
  }
  @bind
  async updateCatering(id, data) {
    await this.checkAuthenticatedStatus()
    return await this.http.patch(`/caterings/${id}`, data)
  }
  @bind
  async createNewCatering(data) {
    await this.checkAuthenticatedStatus()
    return await this.http.post(CreateNewCatering(), data)
  }
}

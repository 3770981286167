import React from 'react'
import {renderRoutes} from 'react-router-config'
import routes from '../../Routes/ListPrivateRoutes'
import PrivateRoute from '../../Routes/PrivateRoute'
import { BrowserRouter, Switch } from 'react-router-dom'
import * as paths from '../../constants/paths.constants'
import Dashboard from '../../containers/layouts/Dashboard'
//Page 

const RenderRoutes = () => {
  return (
    <BrowserRouter>
      {renderRoutes(routes)}
    </BrowserRouter>
  )
}

function App() {
  return (
    <div className="App">
      <Switch>
        <PrivateRoute path={paths.HomePage} 
          component={Dashboard}
        />
      </Switch>
    </div>
  )
}

export default App

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import React from 'react'
import PropTypes from 'prop-types'
// import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { history } from '../../../config/store'
import * as paths from '../../../constants/paths.constants'
import { withRouter, Link } from 'react-router-dom'
import { setUserFilter } from '../../../actions/user'
import { getSortOfUsers } from '../../../selectors/user'
import { withTranslation } from 'react-i18next'


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '40px'
  },
}))

const Header = props => {
  const classes = useStyles()
  const { setUserFilter, sortOfUsers, t } = props

  const handleChange = (event, newValue) => {
    setUserFilter({ filter: newValue })
  }

  return (
    <Container className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={sortOfUsers}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={t('USERS.Staff')} {...a11yProps(0)} value="staff"/>
          <Tab label={t('USERS.Customer')} {...a11yProps(1)} value="customer"/>
        </Tabs>
      </AppBar>
    </Container>
  )
}


const mapState = ({ userReducer }) => ({
  userFilter: userReducer.userFilter,
  sortOfUsers: getSortOfUsers({userReducer})
})

const mapDispatch = dispatch => bindActionCreators(
  {
    setUserFilter
  },
  dispatch
)

Header.propTypes = {
  className: PropTypes.string,
  setUserFilter: PropTypes.func,
}

Header.defaultProps = {
  orders: []
}

export default withRouter(connect(mapState, mapDispatch)(withTranslation('common')(Header)))

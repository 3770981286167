import { combineReducers } from 'redux'
import listFacilities from './list'
import detailFacility from './detail'
import editFacility from './edit'
import newFacility from './new'


const reducers = combineReducers({
  list: listFacilities,
  detail: detailFacility,
  edit: editFacility,
  new: newFacility,
});

export default reducers

import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as C from '../../actionTypes/rooms/detail'
import { history } from '../../config/store'
import { message } from 'antd'
import * as _ from 'lodash'
import { BOOKING } from '../../constants/models'
import { room as roomSelectors } from '../../selectors/rooms/detail'

const { LIST_BY } = BOOKING

function* retrieveRoomType({api}, {payload}) {
  try {
    const response = yield call(api.room.getRoomType, payload)
    yield put({type: C.RETRIEVE_ROOM_TYPE_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.RETRIEVE_ROOM_TYPE_FAILED, payload: e})
    message.error("Error", _.get(e, 'data.detail'))
    console.error(e)
  }
}

function* retrieveRoom({api}, {payload}) {
  try {
    const response = yield call(api.room.getRoom, payload)
    yield put({type: C.RETRIEVE_ROOM_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.RETRIEVE_ROOM_FAILED, payload: e})
    message.error("Error", _.get(e, 'data.detail'))
    console.error(e)
  }

  yield call(retrieveAnalytics, {api})
  yield call(listBookings, {api})
}

function* retrieveAnalytics({api}) {
  const analyticsReq = yield select(roomSelectors.generateBookingAnalyticsReqPayload)
  try {
    const analytics = yield call(api.analytics.roomBooking, analyticsReq)
    yield put({type: C.LIST_BOOKING_ANALYTICS_COMPLETED, payload: analytics})
  } catch (e) {
    yield put({type: C.LIST_BOOKING_ANALYTICS_FAILED, payload: e})
    console.error(e)
  }
}

function* listBookings({api}) {
  yield put({type: C.LIST_BOOKINGS})
  const reqPayload = yield select(roomSelectors.generateListBookingReqPayload)
  const selectedTab = yield select(roomSelectors.getSelectedTab)

  try {
    let response = null
    if (selectedTab == LIST_BY.INCOMING) {
      response = yield call(api.booking.getListIncomingBookings, reqPayload)
    } else {
      response = yield call(api.booking.getListHistoryBookings, reqPayload)
    }
    
    yield put({type: C.LIST_BOOKINGS_COMPLETED, payload: response})

  } catch (e) {
    yield put({type: C.LIST_BOOKINGS_FAILED, payload: e})
    console.error(e)
  }
  
}

function detailSaga(deps) {
  return [
      takeLeading(C.RETRIEVE_ROOM_TYPE, retrieveRoomType, deps),

      takeLeading(C.RETRIEVE_ROOM, retrieveRoom, deps),
      takeLatest(C.CHANGE_TAB, listBookings, deps),
      takeLatest(C.CHANGE_PAGE, listBookings, deps),
  ]
}

export default detailSaga

import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'
import { slots2Timerange } from '../../helpers/timeslot'
import { formatMoney } from '../../helpers/bookings'
import * as helpers from "../../helpers/caterings"

const getReducer = ({caterings}) => caterings.edit

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const getName = createSelector(
  [getReducer],
  ({data}) => _.get(data, "name")
)

export const getDescription = createSelector(
  [getReducer],
  ({data}) => _.get(data, "description")
)

export const isCombo = createSelector(
  [getReducer],
  ({data}) => _.get(data, "is_combo")
)

export const isActive = createSelector(
  [getReducer],
  ({data}) => _.get(data, "is_active")
)

export const getMinParticipants = createSelector(
  [getReducer],
  ({data}) => _.get(data, 'min_participants')
)

export const getPricings = createSelector(
  [getReducer],
  ({data}) => {
    const pricings = _.map(_.get(data, 'pricings', []), pricing => {
      return {
        id: pricing.id,
        minQuantity: _.get(pricing, 'min_quantity'),
        price: (pricing.price / 100 || 0)
      }
    })
    return pricings
  }
)

export const isSubmitting = createSelector(
  [getReducer],
  ({isSubmitting}) => isSubmitting
)


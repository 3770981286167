import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Edit from '../../components/rooms/EditRoom'
import * as actions from '../../actions/rooms/edit'
import { room as selectors } from '../../selectors/rooms/edit'

class EditRoom extends Component {
  componentDidMount() {
    let { match: { params: { id } } } = this.props
    this.props.retrieveRoom(id)

    this.props.onSearchRoomType("")
    this.props.onSearchCombinableRooms("")
    this.props.listPermanentFacilities()
  }

  render() {

    return (
      <Edit {...this.props} />
    )
  }
}


const mapState = (state) => ({
  name: selectors.getName(state),
  description: selectors.getDescription(state),
  isFetching: selectors.isFetching(state),
  size: selectors.getSize(state),
  maxParticipants: selectors.getMaxParticipants(state),
  leadTime: selectors.getLeadTime(state),
  freeCancelDays: selectors.getFreeCancelDays(state),
  isPublic: selectors.isPublic(state),
  selectedRoomType: selectors.getSelectedRoomType(state),
  permanentFacilities: selectors.getPermanentFacilities(state),
  images: selectors.getImages(state),
  pricings: selectors.getPricings(state),
  isCombinedRoom: selectors.isCombinedRoom(state),
  combinedRooms: selectors.getCombinedRooms(state),

  isSubmitting: selectors.isSubmitting(state),

  selectableRoomTypes: selectors.selectableRoomTypes(state),
  selectableCombinedRooms: selectors.selectableCombinedRooms(state),
  selectablePermanentFacilities: selectors.selectablePermanentFacilities(state),
}) 

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveRoom: actions.retrieveRoom,

    onSearchRoomType: actions.searchRoomTypes,
    onSearchCombinableRooms: actions.searchCombinableRooms,
    listPermanentFacilities: actions.listPermanentFacilities,

    onSubmit: actions.submitRoom,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(EditRoom))

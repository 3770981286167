import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NewUserComponent from './components/NewUser'
class NewUser extends Component {
  render() {
    return (
      <div>
        <NewUserComponent />
      </div>
    )
  }
}

NewUser.propTypes = {

}

export default NewUser
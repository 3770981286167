import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import { message } from 'antd'
import * as C from '../../actionTypes/exfacilities/new'
import { history } from '../../config/store'
import * as _ from 'lodash'

function* submitFacility({api}, {payload}) {
  try {
    const response = yield call(api.facilities.createNewFacilityCategory, payload)
    yield put({type: C.SUBMIT_COMPLETED, payload: response})
    history.goBack()
  } catch (e) {
    yield put({type: C.SUBMIT_FAILED, payload: e })
    message.error("Error: ", _.get(e, 'data.detail'))
    console.error(e)
  }
}

function newSaga(deps) {
  return [
      takeLeading(C.SUBMIT, submitFacility, deps),
  ]
}

export default newSaga

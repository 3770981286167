import { takeLatest, put, call, all, select } from 'redux-saga/effects'

import listSaga from './list'
import detailSaga from './detail'


function bookingSaga(deps) {
  return function* saga() {
    yield all([
      ...listSaga(deps),
      ...detailSaga(deps),
    ])
  }
}

export default bookingSaga

import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import List from '../../components/rooms/List'
import * as actions from '../../actions/rooms/list'
import * as selectors from '../../selectors/rooms/list'


class RoomList extends Component {
  render() {

    return (
      <List {...this.props} />
    )
  }
}


const mapState = (state) => ({
  selectedTab: selectors.getSelectedTab(state),
  selectableTabs: selectors.getSelectableTabs(state),
  items: selectors.getItems(state),
  isFetching: selectors.isFetching(state),

  page: selectors.getPage(state),
  pageSize: selectors.getPageSize(state),
  total: selectors.getTotal(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    onChangeTab: actions.changeTab,
    onChangePage: actions.changePage,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(RoomList))

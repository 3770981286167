import { takeLatest, put, call, all, select, takeLeading } from 'redux-saga/effects'
import * as C from '../../actionTypes/rooms/edit'
import { history } from '../../config/store'
import { message } from 'antd'
import * as _ from 'lodash'

function* retrieveRoomType({api}, {payload}) {
  try {
    const response = yield call(api.room.getRoomType, payload)
    yield put({type: C.RETRIEVE_ROOM_TYPE_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.RETRIEVE_ROOM_TYPE_FAILED, payload: e})
    message.error("Error", _.get(e, 'data.detail'))
    console.error(e)
  }
}

function* submitRoomType({api}, {payload}) {
  try {
    const response = yield call(api.room.updateRoomType, payload.id, payload)
    yield put({type: C.SUBMIT_ROOM_TYPE_COMPLETED, payload: response})
    history.goBack()
    
  } catch (e) {
    yield put({type: C.SUBMIT_ROOM_TYPE_FAILED, payload: e})
    message.error("Error ", _.get(e, "data.detail"))
    console.error(e)
  }
}


function* retrieveRoom({api}, {payload}) {
  try {
    const response = yield call(api.room.getRoom, payload)
    yield put({type: C.RETRIEVE_ROOM_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.RETRIEVE_ROOM_FAILED, payload: e})
    message.error("Error", _.get(e, 'data.detail'))
    console.error(e)
  }
}


function* searchRoomTypes({api}, {payload}) {
  try {
    const response = yield call(api.room.searchRoomTypes, {q: payload})
    yield put({type: C.SEARCH_ROOM_TYPES_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.SEARCH_ROOM_TYPES_FAILED, payload: e})
    message.error("Error ", _.get(e, "data.detail"))
    console.error(e)
  }
}

function* searchCombinedRooms({api}, {payload}) {
  try {
    const response = yield call(api.room.searchRooms, {q: payload, is_combined_room: false, page_size: 100})
    yield put({type: C.SEARCH_COMBINABLE_ROOM_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.SEARCH_COMBINABLE_ROOM_FAILED, payload: e})
    message.error("Error ", _.get(e, 'data.detail'))
    console.error(e)
  }
}

function* listPermanentFacilities({api}) {
  try {
    const response = yield call(api.room.listPermFacs)
    yield put({type: C.LIST_PERMANENT_FACILITY_COMPLETED, payload: response})
  } catch (e) {
    yield put({type: C.LIST_PERMANENT_FACILITY_FAILED, payload: e})
    message.error("Error ", _.get(e, 'data.detail'))
    console.error(e)
  }
}

function* submitRoom({api}, {payload}) {
  console.log(payload)
  try {
    const response = yield call(api.room.updateRoom, payload.id, payload)
    yield put({type: C.SUBMIT_ROOM_COMPLETED, payload: response})
    history.goBack()
  } catch (e) {
    yield put({type: C.SUBMIT_ROOM_COMPLETED, payload: e})
    message.error("Error ", _.get(e, "data.detail"))
    console.error(e)
  }
}

function detailSaga(deps) {
  return [
      takeLeading(C.RETRIEVE_ROOM_TYPE, retrieveRoomType, deps),
      takeLeading(C.SUBMIT_ROOM_TYPE, submitRoomType, deps),

      takeLeading(C.RETRIEVE_ROOM, retrieveRoom, deps),

      takeLatest(C.SEARCH_ROOM_TYPES, searchRoomTypes, deps),
      takeLatest(C.SEARCH_COMBINABLE_ROOM, searchCombinedRooms, deps),
      takeLatest(C.LIST_PERMANENT_FACILITY, listPermanentFacilities, deps),
      takeLeading(C.SUBMIT_ROOM, submitRoom, deps),
  ]
}

export default detailSaga

import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'
import * as helpers from '../../helpers/room'
import { slots2Timerange } from "../../helpers/timeslot";

const getReducer = ({rooms}) => rooms.edit

export const room = {
  isFetching: createSelector(
    [getReducer],
    ({room: {isFetching}}) => isFetching
  ),

  getName: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'name')
  ),

  getDescription: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'description')
  ),

  getSize: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'size')
  ),

  getMaxParticipants: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'max_attendances')
  ),

  getLeadTime: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'lead_time')
  ),

  getFreeCancelDays: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'free_cancel_days')
  ),

  isPublic: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'is_published')
  ),

  getSelectedRoomType: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'room_type')
  ),

  getPermanentFacilities: createSelector(
    [getReducer],
    ({room: {data}}) => _.map(_.get(data, 'permanent_facilities'), it=>it.id)
  ),

  getImages: createSelector(
    [getReducer],
    ({room: {data}}) => _.get(data, 'images', [])
  ),

  getPricings: createSelector(
    [getReducer],
    ({room: {data}}) => {
      return _.map(_.get(data, 'pricings', []), it => {
        const [start, end] = slots2Timerange(it.apply_range_int)
        const price = (it.price / 100 || 0).toFixed(2)
        return {
          ...it,
          start,
          end,
          price,
        }
      })
    }
  ),

  isCombinedRoom: createSelector(
    [getReducer],
    ({room: {data}}) => {
      return _.get(data, 'is_combined_room')
    }
  ),

  getCombinedRooms: createSelector(
    [getReducer],
    ({room: {data}}) => {
      return _.map(_.get(data, 'combined_rooms', []), it => it.id)
    }
  ),

  selectableRoomTypes: createSelector(
    [getReducer],
    ({room: {selectableRoomTypes}}) => _.map(selectableRoomTypes, it => ({
      id: it.id,
      name: _.get(it, 'room_type'),
    }))
  ),

  selectableCombinedRooms: createSelector(
    [getReducer],
    ({room: {selectableCombinedRooms}}) => _.map(selectableCombinedRooms, it => ({
      id: it.id,
      name: it.name,
    }))
  ),

  selectablePermanentFacilities: createSelector(
    [getReducer],
    ({room: {selectablePermanentFacilities}}) => _.map(selectablePermanentFacilities, it => ({
      id: it.id,
      name: it.name
    }))
  ),

  isSubmitting: createSelector(
    [getReducer],
    ({room: {isSubmitting}}) => isSubmitting
  ),
}

export const roomType = {
  isFetching: createSelector(
    [getReducer],
    ({roomType: {isFetching}}) => isFetching
  ),

  getName: createSelector(
    [getReducer],
    ({roomType: {data}}) => _.get(data, 'room_type')
  ),

  getDescription: createSelector(
    [getReducer],
    ({roomType: {data}}) => _.get(data, 'description')
  ),
  
  isPublic: createSelector(
    [getReducer],
    ({roomType: {data}}) => _.get(data, 'is_published')
  ),

  getImages: createSelector(
    [getReducer],
    ({roomType: {data}}) => _.get(data, 'images')
  ),

  isSubmitting: createSelector(
    [getReducer],
    ({roomType: {isSubmitting}}) => isSubmitting
  )
}

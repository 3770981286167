import {createSelector} from 'reselect'
import { BOOKING, FORMAT } from '../../constants/models'
import * as helpers from '../../helpers/bookings'
import moment from 'moment'
import * as _ from 'lodash'

const { DATE } = FORMAT
const { LIST_BY, PAYMENT_STATUS, BOOKING_STATUS, PAYMENT_METHOD } = BOOKING

const getReducer = ({bookings}) => bookings.list

export const getItems = createSelector(
  [getReducer],
  ({items}) => {
    const getPaymentStatus = ({payment_method, payment_status}) => {
      if (payment_method == PAYMENT_METHOD.INVOICE) {
        return  {color: "green", name: "INVOICE"}
      }
      return helpers.getPaymentStatusTag(payment_status)
    }

    return _.map(items, it => ({
      id: it.id,
      confirmationNo: `${it.confirmation_prefix}${_.padStart(it.id, 4, '0')}`,
      room: it.room_name,
      customer: {
        name: `${_.get(it, "customer.first_name")} ${_.get(it, "customer.last_name")}`,
        email: _.get(it, "customer.email"),
        company: _.get(it, "customer.company"),
      },
      bookingDate: {
        startDate: moment(_.get(it, 'start_date')).format(DATE),
        startTime: helpers.slot2Time(_.get(it, 'start_slot_int'))[0],
        endDate: moment(_.get(it, 'end_date')).format(DATE),
        endTime: helpers.slot2Time(_.get(it, 'end_slot_int'))[1],
      },
      paymentStatus: getPaymentStatus(it),
      bookingStatus: helpers.getBookingStatusTag(_.get(it, 'status')),
    }))
  }
)

export const getPage = createSelector(
  [getReducer],
  ({page}) => page
)

export const getPageSize = createSelector(
  [getReducer],
  ({pageSize}) => pageSize
)

export const getTotal = createSelector(
  [getReducer],
  ({total}) => total
)

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const getSelectableTabs = () => {
  return [
    {id: LIST_BY.INCOMING, name: "INCOMING"},
    {id: LIST_BY.HISTORY, name: "HISTORY"}
  ]
}

export const getSelectedTab = createSelector(
  [getReducer],
  ({selectedTab}) => selectedTab
)

export const getSelectablePaymentStatuses = () => {
  return [
    {id: PAYMENT_STATUS.OUTSTANDING, name: "OUTSTANDING"},
    {id: PAYMENT_STATUS.DONE, name: "DONE"},
    {id: PAYMENT_STATUS.PARTIAL_REFUND, name: "PARTIAL REFUND"},
    {id: PAYMENT_STATUS.FULL_REFUND, name: "FULL REFUND"},
    {id: PAYMENT_STATUS.FAILED, name: "FAILED"},
    {id: PAYMENT_STATUS.CANCELLED, name: "CANCELLED"}
  ]
}

export const getSelectableBookingStatuses = () => {
  return [
    {id: BOOKING_STATUS.SCHEDULED, name: "SCHEDULED"},
    {id: BOOKING_STATUS.CANCELLED, name: "CANCELLED"}
  ]
}

export const getFilter = createSelector(
  [getReducer],
  ({filter}) => filter
)

/**
 * GENERATE REQUEST PAYLOAD
 */


export const generateReqPayload = createSelector(
  [getReducer],
  ({page, pageSize, filter}) => {
    let payload = {page, page_size: pageSize}
    const paymentStatuses = _.get(filter, 'selectedPaymentStatuses', [])
    const bookingStatuses = _.get(filter, 'selectedBookingStatuses', [])
    if (paymentStatuses.length > 0) {
      payload = {...payload, payment_statuses: paymentStatuses}
    }

    if (bookingStatuses.length > 0) {
      payload = {...payload, booking_statuses: bookingStatuses}
    }

    return payload
  }
)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from './components/Header'
import CreateNewRoom from './components/CreateNewRoom'
import CreateNewRoomTypes from './components/CreateNewRoomTypes'
import {Switch, Route} from 'react-router-dom'
import * as paths from '../../constants/paths.constants'
class ListingNew extends Component {
  render() {
    return (
      <div>
        <Header />
        <Switch>
          <Route path={paths.UpdateRoom} component={CreateNewRoom} />
          <Route path={paths.NewRoom} component={CreateNewRoom} />
          <Route path={paths.UpdateRoomType} component={CreateNewRoomTypes} />
          <Route path={paths.NewRoomTypes} component={CreateNewRoomTypes} />
        </Switch>
      </div>
    )
  }
}

ListingNew.propTypes = {

}

export default ListingNew

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { makeStyles } from '@material-ui/core/styles'
import CardSection from './CardSession'
import { Button } from 'antd'
import { bindActionCreators } from 'redux'
import { linkSessionPayment, confirmBooking, updateBookingState } from '../../../actions/booking'
import { get } from 'lodash'
import { withTranslation } from 'react-i18next'
import { BOOKING } from "../../../constants/models";
import { Typography, Grid } from '@material-ui/core'

const { PAYMENT_METHOD } = BOOKING

// const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
const useStyles = makeStyles((theme) => ({
  confirmButton: {
    float: 'right',
    position: 'relative',
    top: '50px',
    marginLeft: '20px '
  }
}))

// const session = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.session)
// const authorization = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization)

// async function stripeTokenHandler(token, data) {
//   const paymentData = {payment_token: token.id, ...data}

//   // Use fetch to send the token ID and any other payment data to your server.
//   // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
//   const response = await fetch(`${REACT_APP_BASE_URL}${LinkSessionPayment()}`, {
//     method: 'POST',
//     headers: {
//       'Session': session,
//       'Authorization': `JWT ${authorization.access}`,
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(paymentData),
//   })

//   // Return and display the result of the charge.
//   return response.json()
// }

const CheckoutForm = (props) => {
  const { customer_id, payment_method, linkSessionPayment, isLinkingSessionPayment, t } = props
  // useEffect(() => {
  //   // console.log(payment_id)
  //   if(payment_method > 1) {
  //     updateBookingState({state: 5})
  //   }
  //   if(payment_id !== null) {
  //     console.log(payment_id)
  //     updateBookingState({state: 5})
  //   }
  // }, [bookingSession])
  const stripe = useStripe()
  const elements = useElements()
  const classes = useStyles()
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return
    }

    const card = elements.getElement(CardElement)
    const result = await stripe.createToken(card)

    if (result.error) {
      // Show error to your customer.
      console.log(result.error.message)
    } else {
      // Send the token to your server.
      // This function does not exist yet we will define it in the next step.

      linkSessionPayment({ payment_token: result.token.id, customer_id, payment_method })
    }
  }
  const confirmPayment = (payment_method) => {
    linkSessionPayment({ customer_id, payment_method })
  }

  return (
    <form onSubmit={handleSubmit}>
      {payment_method == PAYMENT_METHOD.STRIPE ? [
        <CardSection />,
        <Button
          htmlType='submit'
          type='primary'
          disabled={!stripe}
          loading={isLinkingSessionPayment}
          className={classes.confirmButton}
        >{t('Next')}</Button>,
        <Button
          disabled={!stripe}
          loading={isLinkingSessionPayment}
          className={classes.confirmButton}
          onClick={() => confirmPayment(3)}
        >{t('BOOKING.Skip')}</Button>
      ] : [
          <Grid container spacing={2} style={{marginTop: 32, marginBottom: 32}}>
            <Grid item><Typography variant="subtitle1">{t("Payment Method")}</Typography></Grid>
            <Grid item><Typography variant="subtitle1"><b>{t("INVOICE")}</b></Typography></Grid>
          </Grid>,
          <Button
            htmlType='submit'
            type='primary'
            loading={isLinkingSessionPayment}
            className={classes.confirmButton}
            onClick={() => confirmPayment(payment_method)}
          >
            {t("Next")}
          </Button>
        ]}


    </form>
  )
}

const mapState = ({ bookingReducer }) => ({
  customer_id: get(bookingReducer, 'bookingSession.customer.id'),
  payment_method: get(bookingReducer, 'bookingSession.payment_method'),
  payment_id: get(bookingReducer, 'bookingSession.payment_id'),
  isLinkingSessionPayment: bookingReducer.isLinkingSessionPayment,
  bookingSession: bookingReducer.bookingSession,
})

const mapDispatch = dispatch => bindActionCreators(
  {
    linkSessionPayment,
    confirmBooking,
    updateBookingState
  },
  dispatch
)

CheckoutForm.propTypes = {
  customer_id: PropTypes.any,
  payment_method: PropTypes.any,
  linkSessionPayment: PropTypes.func,
  isLinkingSessionPayment: PropTypes.bool,
  bookingSession: PropTypes.bool,
  confirmBooking: PropTypes.func,
  payment_id: PropTypes.any,
}

export default connect(mapState, mapDispatch)(withTranslation('common')(CheckoutForm))
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {get} from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core'

import { GenericMoreButton } from '../../../../components'
import { Chart } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'unset'
  },
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 275,
    minWidth: 275
  },
  chart: {
    height: '100%'
  }
}))

const PerformanceOverTime = props => {
  const { className, data, period, ...rest } = props
  const classes = useStyles()

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={<GenericMoreButton />}
        title=""
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              data={get(data, `${period}.data`)}
              labels={get(data, `${period}.labels`) }
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

PerformanceOverTime.propTypes = {
  className: PropTypes.string
}

export default PerformanceOverTime

/* eslint-disable camelcase */

import base_de from './de/base.json'
import booking_management_de from './de/booking_management.json'
import user_management_de from './de/user_management.json'
import room_management_de from './de/room_management.json'
import exfacility_management_de from './de/exfacility_management.json'
import catering_management_de from './de/catering_management.json'
import discount_management_de from './de/discount_management.json'

import base_en from './en/base.json'
import booking_management_en from './en/booking_management.json'
import user_management_en from './en/user_management.json'
import room_management_en from './en/room_management.json'
import exfacility_management_en from './en/exfacility_management.json'
import catering_management_en from './en/catering_management.json'
import discount_management_en from './en/discount_management.json'

export default {
  en: {
    common: base_en,
    booking_management: {...base_en, ...booking_management_en},
    user_management: {...base_en, ...booking_management_en, ...user_management_en},
    room_management: {...base_en, ...booking_management_en, ...room_management_en},
    exfacility_management: {...base_en, ...exfacility_management_en},
    catering_management: {...base_en, ...catering_management_en},
    discount_management: {...base_en, ...user_management_en, ...room_management_en, ...discount_management_en},
  },
  de: {
    common: base_de,
    booking_management: {...base_de, ...booking_management_de},
    user_management: {...base_de, ...booking_management_de, ...user_management_de},
    room_management: {...base_de, ...booking_management_de, ...room_management_de},
    exfacility_management: {...base_de, ...exfacility_management_de},
    catering_management: {...base_de, ...catering_management_de},
    discount_management: {...base_de, ...user_management_de, ...room_management_de, ...discount_management_de},
  },
}

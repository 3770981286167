import {createSelector} from 'reselect'
import moment from 'moment'
import * as _ from 'lodash'
import { slots2Timerange } from "../../helpers/timeslot";

const getReducer = ({facilities}) => facilities.edit

export const isFetching = createSelector(
  [getReducer],
  ({isFetching}) => isFetching
)

export const getName = createSelector(
  [getReducer],
  ({data}) => _.get(data, "name")
)

export const getDescription = createSelector(
  [getReducer],
  ({data}) => _.get(data, "description")
)

export const getQuantity = createSelector(
  [getReducer],
  ({data}) => _.get(data, "quantity")
)

export const isActive = createSelector(
  [getReducer],
  ({data}) => _.get(data, "is_active")
)

export const getPricings = createSelector(
  [getReducer],
  ({data}) => {
    return _.map(_.get(data, 'pricings', []), it => {
      const [start, end] = slots2Timerange(it.apply_range_int)
      const price = (it.price / 100 || 0).toFixed(2)
      return {
        ...it,
        start,
        end,
        price,
      }
    })
  }
)

export const isSubmitting = createSelector(
  [getReducer],
  ({isSubmitting}) => isSubmitting
)

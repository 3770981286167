/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

// import AuthLayout from './containers/layouts/Auth'
import ErrorLayout from '../containers/layouts/Error'
import DashboardLayout from '../containers/layouts/Dashboard'
import ProfileView from '../views/Profile'
import * as paths from '../constants/paths.constants'

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={paths.BookingCalendar} />
  },
  {
    path: paths.ProfilePage,
    // exact: true,
    component: ProfileView
  },
  {
    path: paths.Main,
    exact: true,
    component: lazy(() => import('../views/Overview'))
  },
  {
    path: paths.BookingCalendar,
    exact: true,
    component: lazy(() => import('../views/BookingCalendar'))
  },
  {
    path: paths.BookingTable,
    exact: true,
    component: lazy(() => import('../views/BookingTable'))
  },
  {
    path: paths.CreateNewBooking_FoundRoom,
    exact: true,
    component: lazy(() => import('../views/CreateNewBooking'))
  },
  {
    path: paths.CreateNewBooking_SelectCustomer,
    exact: true,
    component: lazy(() => import('../views/CreateNewBooking'))
  },
  {
    path: paths.CreateNewBooking_AddBookingDetail,
    exact: true,
    component: lazy(() => import('../views/CreateNewBooking'))
  },
  {
    path: paths.CreateNewBooking_NewBookingCreated,
    exact: true,
    component: lazy(() => import('../views/CreateNewBooking'))
  },
  {
    path: paths.CreateNewBooking,
    exact: true,
    component: lazy(() => import('../views/CreateNewBooking')),
  },
  {
    path: paths.CreateNewBooking_Payment,
    exact: true,
    component: lazy(() => import('../views/CreateNewBooking')),
  },
  {
    path: paths.ViewAllBooking,
    exact: true,
    component: lazy(() => import('../views/ViewAllBooking'))
  },
  {
    path: paths.NewRoom,
    exact: true,
    component: lazy(() => import('../views/ListingNew'))
  },
  {
    path: paths.NewRoomTypes,
    exact: true,
    component: lazy(() => import('../views/ListingNew'))
  },
  {
    path: paths.RoomDetail,
    exact: true,
    component: lazy(() => import('../views/RoomDetail'))
  },
  {
    path: paths.ListRooms,
    exact: true,
    component: lazy(() => import('../views/ViewAllRoom'))
  },
  {
    path: paths.UpdateRoomType,
    exact: true,
    component: lazy(() => import('../views/UpdateRoomType'))
  },
  {
    path: paths.ViewRoomTypeDetail,
    exact: true,
    component: lazy(() => import('../views/RoomTypeDetail'))
  },
  {
    path: paths.ListRoomTypes,
    exact: true,
    component: lazy(() => import('../views/ViewAllRoom'))
  },
  {
    path: paths.ListFacilities,
    exact: true,
    component: lazy(() => import('../views/ListFacilities'))
  },
  {
    path: paths.NewFacility,
    exact: true,
    component: lazy(() => import('../views/NewFacility'))
  },
  {
    path: paths.CateringService,
    exact: true,
    component: lazy(() => import('../views/CateringService'))
  },
  {
    path: paths.NewService,
    exact: true,
    component: lazy(() => import('../views/NewService'))
  },
  {
    path: paths.NewUser,
    exact: true,
    component: lazy(() => import('../views/NewUser'))
  },
  {
    path: paths.UserDetail,
    exact: true,
    component: lazy(() => import('../views/UserDetail'))
  },
  {
    path: paths.ListUser,
    exact: true,
    component: lazy(() => import('../views/ListUsers'))
  },
  {
    path: paths.ListDiscounts,
    exact: true,
    component: lazy(() => import('../views/ListDiscounts'))
  },
  {
    path: paths.NewDiscount,
    exact: true,
    component: lazy(() => import('../views/NewDiscounts'))
  },
]

export default routes

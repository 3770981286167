import {combineReducers} from 'redux'
import * as actionTypes from '../actionTypes/facilities.actionTypes'

const isGettingFaclities = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_FACILITIES:
    return true
  case actionTypes.GET_FACILITIES_FAIL:
  case actionTypes.GET_FACILITIES_SUCCESS:
    return false
  default:
    return state
  }
}

const listFaclities = (state = [], action) => {
  const {type} = action
  switch (type) {
  case actionTypes.GET_FACILITIES_SUCCESS:
    return action.data
  default:
    return state
  }
}

const isCreatingFacilityCategory = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.CREATE_NEW_FACILITY_CATEGORY:
    return true
  case actionTypes.CREATE_NEW_FACILITY_CATEGORY_FAIL:
  case actionTypes.CREATE_NEW_FACILITY_CATEGORY_SUCCESS:
    return false
  default:
    return state
  }
}

const isUpdatetingFacilityCategory = (state = false, action) => {
  const {type} = action
  switch (type) {
  case actionTypes.UPDATE_FACILITY_CATEGORY:
  case actionTypes.INCREASE_QUANTITY_FACILITY_CATEGORY:
    return true
  case actionTypes.UPDATE_FACILITY_CATEGORY_SUCCESS:
  case actionTypes.UPDATE_FACILITY_CATEGORY_FAIL:
  case actionTypes.INCREASE_QUANTITY_FACILITY_CATEGORY_FAIL:
  case actionTypes.INCREASE_QUANTITY_FACILITY_CATEGORY_SUCCESS:
    return false
  default:
    return state
  }
}

const facilitiesReducer = combineReducers({
  isGettingFaclities,
  listFaclities,
  isCreatingFacilityCategory,
  isUpdatetingFacilityCategory
})

export default facilitiesReducer
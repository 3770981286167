import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Detail from '../../components/rooms/DetailRoom'
import * as actions from '../../actions/rooms/detail'
import {room as selectors} from '../../selectors/rooms/detail'


class RoomDetail extends Component {
  componentDidMount() {
    let { match: { params: { id } } } = this.props
    this.props.retrieveRoom(id)
  }

  render() {

    return (
      <Detail {...this.props} />
    )
  }
}


const mapState = (state) => ({
  name: selectors.getName(state),
  isFetching: selectors.isFetching(state),
  coverImage: selectors.getCoverImage(state),
  description: selectors.getDescription(state),
  facilities: selectors.getFacilities(state),
  maxParticipants: selectors.getMaxParticipants(state),
  size: selectors.getSize(state),
  leadTime: selectors.getLeadTime(state),
  freeCancelDays: selectors.getFreeCancelDays(state),
  roomType: selectors.getRoomType(state),
  publicTag: selectors.getPublicTag(state),
  pricings: selectors.getPricings(state),

  analyticsData: selectors.getAnalyticsData(state),

  bookingItems: selectors.getBookingItems(state),
  selectedTab: selectors.getSelectedTab(state),
  bookingPage: selectors.getBookingPage(state),
  bookingPageSize: selectors.getBookingPageSize(state),
  bookingTotal: selectors.getBookingTotal(state),
  isBookingFetching: selectors.isBookingFetching(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveRoom: actions.retrieveRoom,
    onChangeTab: actions.changeTab,
    onChangePage: actions.changePage,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(RoomDetail))

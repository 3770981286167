import React, { Component } from 'react'
import { Switch, Route, withRouter, Link, useRouteMatch } from 'react-router-dom'
import MdLink from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { PageHeader, Pagination, Input } from 'antd'
import { Table, Tag } from 'antd';
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { USER } from '../../constants/models'
const URL = process.env.REACT_APP_BASE_URL

const { Search } = Input;
const { LIST_BY } = USER

const UserList = (props) => {
  let match = useRouteMatch();

  const {
    t,

    searchText,
    currentIsAdmin,

    items = [],
    isFetching = false,
    selectableTabs,
    selectedTab,

    page,
    pageSize,
    total,

    retrievingToken,
    exportToken,

    onChangeTab,
    onChangePage,
    onChangeSearchText,
    onSearch,

  } = props

  const forStaff = selectedTab == LIST_BY.STAFF
  const detailType = forStaff ? "staff" : "customer"

  const columns = [
    {
      title: t('Full name'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => <Link to={`${match.path}/detail/${detailType}/${record.id}`}>{text}</Link>,
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email'
    },
    ...(forStaff ? [{
      title: t("Role"),
      dataIndex: "role",
      key: "role",
      render: (role, record) => <Tag color={role.color}>{t(role.name)}</Tag>
    }] : [
        {
          title: t("Customer No"),
          dataIndex: "customerNo",
          key: "customerNo"
        },
        {
          title: t("Company"),
          dataIndex: "company",
          key: "company"
        },
        {
          title: t("Type"),
          dataIndex: "customerType",
          key: "customerType",
          render: (type) => <Tag color={type.color}>{type.name}</Tag>
        },
        {
          title: t("Term"),
          dataIndex: "term",
          key: "term",
          render: (term) => <Tag color={term.color}>{term.name}</Tag>
        },
        {
          title: t("Ad"),
          dataIndex: "ad",
          key: "ad",
          render: (ad) => <Tag color={ad.color}>{ad.name}</Tag>
        },
      ]),
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color={status.color}>{t(status.name)}</Tag>
    }
  ]

  return (
    <Paper>
      <PageHeader
        backIcon={false}
        onBack={() => props.history.back()}
        title={t("USER MANAGEMENT")}
        subTitle={t("Manage all users")}
        extra={[
          <Link
            to={`${match.path}/new/${detailType}`}
            disabled={forStaff&&!currentIsAdmin}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={forStaff && !currentIsAdmin}
            >
              {forStaff ? t("New Staff") : t("New Customer")}
            </Button>
          </Link>,

          <MdLink
            style={{marginLeft: 12}}
            href={`${URL}/user/export_users?token=${exportToken}&user_type=${forStaff?'staff':'customer'}`}
            disabled={retrievingToken || !exportToken}
            underline="none"
            rel="noopener noreferrer"
            target='_blank'
            download
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
            >
              {forStaff ? t("Staff Export") : t("Customer Export")}
            </Button>
          </MdLink>
        ]}
      >
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item container direction="column" style={{ width: 480 }}>
            <Grid item>
              <Search
                placeholder={t("Input search text")}
                enterButton={t("Search")}
                size="medium"
                value={searchText}
                onChange={e => onChangeSearchText(e.target.value)}
                onSearch={onSearch}
              />
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>

      <div style={{ padding: 24 }}>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          onChange={(e, value) => onChangeTab(value)}
        >
          {_.map(selectableTabs, tab => (
            <Tab label={t(tab.name)} value={tab.id} />
          ))}
        </Tabs>

        <Table
          columns={columns}
          dataSource={items || []}
          pagination={false}
          loading={isFetching}
        />

        <Grid container style={{ paddingTop: 24 }} justify="flex-end" alignItems="flex-end">
          <Grid item>
            <Pagination
              hideOnSinglePage={true}
              current={page}
              pageSize={pageSize}
              total={total}
              onChange={(page, pageSize) => onChangePage(page)}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}

export default withRouter(withTranslation("user_management")(UserList))

import React, { Component } from 'react'
import { Switch, Route, withRouter, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Edit from '../../components/exfacilities/Edit'
import * as actions from '../../actions/exfacilities/edit'
import * as selectors from '../../selectors/exfacilities/edit'


class ExfacilityEdit extends Component {
  componentDidMount() {
    let { match: { params: { id } } } = this.props
    this.props.retrieveDetail(id)
  }

  render() {

    return (
      <Edit {...this.props} />
    )
  }
}


const mapState = (state) => ({
  isFetching: selectors.isFetching(state),
  name: selectors.getName(state),
  description: selectors.getDescription(state),
  quantity: selectors.getQuantity(state),
  isActive: selectors.isActive(state),
  pricings: selectors.getPricings(state),
  isSubmitting: selectors.isSubmitting(state),
})

const mapDispatch = dispatch => bindActionCreators(
  {
    retrieveDetail: actions.retrieveExfacility,
    onSubmit: actions.submit,
  },
  dispatch
)

export default connect(mapState, mapDispatch)(withRouter(ExfacilityEdit))
